import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Atom, ChevronLeft, ChevronRight } from "lucide-react";

const images = [
  "https://images.unsplash.com/photo-1532187863486-abf9dbad1b69?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  "https://images.unsplash.com/photo-1576086213369-97a306d36557?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2080&q=80",
  "https://images.unsplash.com/photo-1582719471384-894fbb16e074?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
];

const LabCard = ({title, description, image}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      const timer = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % images.length);
      }, 3000);
      return () => clearInterval(timer);
    }
  }, [isHovered]);

  const nextImage = () => setCurrentIndex((prev) => (prev + 1) % images.length);
  const prevImage = () =>
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="relative w-full max-w-4xl mx-auto bg-gradient-to-br from-blue-50 to-indigo-50 rounded-[2.5rem] p-1.5 shadow-lg"
    >
      <div className="relative bg-white rounded-[2rem] overflow-hidden shadow-sm">
        {/* Animated Background Pattern */}
        <div className="absolute inset-0 opacity-30">
          <motion.div
            animate={{
              backgroundPosition: ["0px 0px", "100px 100px"],
              opacity: [0.1, 0.15, 0.1],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            className="absolute inset-0"
            style={{
              backgroundImage:
                "radial-gradient(circle at 2px 2px, #ED1C2E 1px, transparent 0)",
              backgroundSize: "50px 50px",
            }}
          />
        </div>

        {/* Main Content Container */}
        <div className="relative">
          {/* Image Carousel */}
          <div className="relative aspect-[16/9] overflow-hidden">
            <AnimatePresence initial={false} mode="wait">
              <motion.img
                key={currentIndex}
                src={images[currentIndex]}
                initial={{ opacity: 0, scale: 1.1 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.7 }}
                className="absolute inset-0 w-full h-full object-cover"
                alt="Lab"
              />
            </AnimatePresence>

            {/* Interactive Navigation */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: isHovered ? 1 : 0 }}
              transition={{ duration: 0.3 }}
              className="absolute inset-x-0 top-1/2 -translate-y-1/2 flex justify-between px-6 pointer-events-none"
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={prevImage}
                className="w-12 h-12 rounded-full bg-white/80 backdrop-blur-md border border-white/50 shadow-lg flex items-center justify-center pointer-events-auto hover:bg-white transition-colors"
              >
                <ChevronLeft className="w-6 h-6 text-indigo-600" />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={nextImage}
                className="w-12 h-12 rounded-full bg-white/80 backdrop-blur-md border border-white/50 shadow-lg flex items-center justify-center pointer-events-auto hover:bg-white transition-colors"
              >
                <ChevronRight className="w-6 h-6 text-indigo-600" />
              </motion.button>
            </motion.div>

            {/* Progress Bar */}
            <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-100">
              <motion.div
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                }}
                style={{ originX: 0 }}
                className="h-full bg-gradient-to-r from-primary-700 to-primary-500"
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="relative px-8 py-6">
            {/* Floating Icon */}
            <motion.div
              animate={{
                y: [0, -10, 0],
                rotate: [0, 5, -5, 0],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
              }}
              className="absolute -top-12 right-8 w-24 h-24 rounded-2xl bg-gradient-to-br from-white to-blue-50 shadow-lg border border-white/80 flex items-center justify-center"
            >
              <Atom className="w-12 h-12 text-primary-600" />
            </motion.div>

            {/* Title & Description */}
            <div className="relative z-10">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <h2 className="text-4xl font-bold bg-gradient-to-r from-primary-600 to-primary-500 bg-clip-text text-transparent mb-4">
                  {title}
                </h2>
                <p className="text-lg text-gray-600 max-w-2xl leading-relaxed">
                  Exploring the frontiers of quantum mechanics and computational
                  physics. Our cutting-edge facility pushes the boundaries of
                  what's possible in quantum computing and particle physics
                  research.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default LabCard;
