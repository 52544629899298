/* eslint-disable react/prop-types */
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ImageHeading from "../components/ui/ImageHeading";
import headerImage from "../assets/header/header.jpeg";
// import { cn } from "@/lib/utils";
import { X } from "lucide-react";
import DepartmentFacultyCard from "../components/ui/Card/DepartmentFacultyCard";
import { adminHeads, administrationData } from "../data/administrationData";

// Replace schoolTabs with facultyData
const Administration = () => {
  const [activeSchool, setActiveSchool] = useState("0");
  const [selectedFaculty, setSelectedFaculty] = useState(null);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-gray-100 to-gray-200">
      <ImageHeading heading="Administration" imageUrl={headerImage} />

      <div className="container px-4 py-6 mx-auto sm:py-12 sm:px-6 lg:px-8">
        {/* School Tabs */}
        {/* <div className="mb-8 flex flex-row items-center justify-center overflow-scroll [perspective:1000px] relative sm:overflow-visible h-full no-visible-scrollbar max-w-full w-full">
          <div className="px-8 py-4 bg-white rounded-md shadow-xl lg:rounded-full w-fit">
            {Object.entries(administrationData).map(([key, value]) => (
              <button
                key={key}
                onClick={() => setActiveSchool(key)}
                className={cn("relative px-4 py-2 rounded-full")}
                style={{ transformStyle: "preserve-3d" }}
              >
                {activeSchool === key && (
                  <motion.div
                    layoutId="activeSchool"
                    transition={{ type: "spring", bounce: 0.3, duration: 0.6 }}
                    className="absolute inset-0 text-white rounded-full bg-primary"
                  />
                )}
                <span
                  className={`relative block ${
                    activeSchool === key ? "text-white" : "text-black"
                  }`}
                >
                  {value.name}
                </span>
              </button>
            ))}
          </div>
        </div> */}

        <motion.div className="flex flex-col md:flex-row justify-around gap-6 mb-12">
          {adminHeads.members.map((faculty) => (
            <AnimatePresence>
              <div className=" cursor-pointer">
                <DepartmentFacultyCard
                  name={faculty.name}
                  title={faculty.title}
                  department={faculty.department}
                  expertise={faculty.expertise}
                  email={faculty.email}
                  phone={faculty.phone}
                  bio={faculty.bio}
                  image={faculty.image}
                />
              </div>
            </AnimatePresence>
          ))}
        </motion.div>

        <h2 className="text-center mb-8 font-domine font-bold text-primary text-2xl md:text-4xl">
          Administrations & Office
        </h2>
        {/* Faculty Grid */}
        <motion.div
          layout
          className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4"
        >
          <AnimatePresence>
            {administrationData[activeSchool].members.map((faculty) => (
              <div
                key={faculty.id}
                onClick={() => setSelectedFaculty(faculty)}
                className="cursor-pointer"
              >
                <DepartmentFacultyCard
                  name={faculty.name}
                  title={faculty.title}
                  department={faculty.department}
                  expertise={faculty.expertise}
                  email={faculty.email}
                  phone={faculty.phone}
                  bio={faculty.bio}
                  image={faculty.image}
                />
              </div>
            ))}
          </AnimatePresence>
        </motion.div>

        {/* Faculty Modal remains the same */}
        {/* <AnimatePresence>
          {selectedFaculty && (
            <FacultyModal
              faculty={selectedFaculty}
              onClose={() => setSelectedFaculty(null)}
            />
          )}
        </AnimatePresence> */}
      </div>
    </div>
  );
};

const FacultyModal = ({ faculty, onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        onClick={(e) => e.stopPropagation()}
        className="bg-white rounded-xl shadow-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto"
      >
        <div className="relative">
          <button
            onClick={onClose}
            className="absolute p-2 transition-colors rounded-full right-4 top-4 bg-white/80 backdrop-blur-sm hover:bg-white"
          >
            <X className="w-5 h-5" />
          </button>
          <div className="h-48 overflow-hidden">
            <img
              src={faculty.image}
              alt={faculty.name}
              className="object-cover w-full h-full"
            />
          </div>
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-800">{faculty.name}</h2>
            <p className="font-medium text-primary-600">{faculty.title}</p>
            <p className="mb-4 text-gray-600">{faculty.department}</p>

            <div className="space-y-6">
              <div>
                <h3 className="mb-2 text-lg font-semibold text-gray-800">
                  Contact
                </h3>
                <p className="text-gray-600">Email: {faculty.email}</p>
                <p className="text-gray-600">Phone: {faculty.phone}</p>
              </div>

              <div>
                <h3 className="mb-2 text-lg font-semibold text-gray-800">
                  Bio
                </h3>
                <p className="text-gray-600">{faculty.bio}</p>
              </div>

              <div>
                <h3 className="mb-2 text-lg font-semibold text-gray-800">
                  Areas of Expertise
                </h3>
                <ul className="space-y-1 text-gray-600 list-disc list-inside">
                  {faculty.expertise.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Administration;
