/* eslint-disable react/prop-types */
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown } from "lucide-react";

// const semesterData = [
//   {
//     id: "semester-1",
//     title: "Semester 1",
//     subtitles: ["5 Courses", "13.5 Credits"],
//     courses: [
//       { code: "CSE 101", name: "Introduction to Computer Science", credits: 3 },
//       {
//         code: "MAT 101",
//         name: "Differential Calculus & Co-ordinate Geometry",
//         credits: 3,
//       },
//       { code: "PHY 101", name: "Physics I", credits: 3 },
//       { code: "ENG 101", name: "Basic English", credits: 3 },
//       { code: "CSE 102", name: "Computer Programming Lab", credits: 1.5 },
//     ],
//   },
//   {
//     id: "semester-2",
//     title: "Semester 2",
//     subtitles: ["5 Courses", "13.5 Credits"],
//     courses: [
//       { code: "CSE 111", name: "Structured Programming", credits: 3 },
//       {
//         code: "MAT 111",
//         name: "Integral Calculus & Differential Equations",
//         credits: 3,
//       },
//       { code: "PHY 111", name: "Physics II", credits: 3 },
//       {
//         code: "ENG 111",
//         name: "Technical Writing and Communication",
//         credits: 3,
//       },
//       { code: "CSE 112", name: "Structured Programming Lab", credits: 1.5 },
//     ],
//   },
//   {
//     id: "semester-3",
//     title: "Semester 3",
//     subtitles: ["5 Courses", "12 Credits"],
//     courses: [
//       { code: "CSE 201", name: "Object Oriented Programming", credits: 3 },
//       { code: "CSE 203", name: "Digital Logic Design", credits: 3 },
//       {
//         code: "MAT 201",
//         name: "Complex Variables & Fourier Analysis",
//         credits: 3,
//       },
//       { code: "CSE 204", name: "Digital Logic Design Lab", credits: 1.5 },
//       {
//         code: "CSE 202",
//         name: "Object Oriented Programming Lab",
//         credits: 1.5,
//       },
//     ],
//   },
//   {
//     id: "semester-4",
//     title: "Semester 4",
//     subtitles: ["5 Courses", "12 Credits"],
//     courses: [
//       { code: "CSE 211", name: "Data Structures", credits: 3 },
//       { code: "CSE 213", name: "Computer Architecture", credits: 3 },
//       {
//         code: "MAT 211",
//         name: "Linear Algebra & Numerical Methods",
//         credits: 3,
//       },
//       { code: "CSE 212", name: "Data Structures Lab", credits: 1.5 },
//       {
//         code: "CSE 214",
//         name: "Assembly Language Programming Lab",
//         credits: 1.5,
//       },
//     ],
//   },
//   {
//     id: "semester-5",
//     title: "Semester 5",
//     subtitles: ["5 Courses", "12 Credits"],
//     courses: [
//       { code: "CSE 301", name: "Database Management Systems", credits: 3 },
//       { code: "CSE 303", name: "Operating Systems", credits: 3 },
//       { code: "CSE 305", name: "Software Engineering", credits: 3 },
//       {
//         code: "CSE 302",
//         name: "Database Management Systems Lab",
//         credits: 1.5,
//       },
//       { code: "CSE 304", name: "Operating Systems Lab", credits: 1.5 },
//     ],
//   },
//   {
//     id: "semester-6",
//     title: "Semester 6",
//     subtitles: ["5 Courses", "12 Credits"],
//     courses: [
//       { code: "CSE 311", name: "Computer Networks", credits: 3 },
//       { code: "CSE 313", name: "Artificial Intelligence", credits: 3 },
//       { code: "CSE 315", name: "Web Technologies", credits: 3 },
//       { code: "CSE 312", name: "Computer Networks Lab", credits: 1.5 },
//       { code: "CSE 316", name: "Web Technologies Lab", credits: 1.5 },
//     ],
//   },
//   {
//     id: "semester-7",
//     title: "Semester 7",
//     subtitles: ["5 Courses", "12 Credits"],
//     courses: [
//       { code: "CSE 401", name: "Machine Learning", credits: 3 },
//       { code: "CSE 403", name: "Computer Graphics", credits: 3 },
//       { code: "CSE 405", name: "Cloud Computing", credits: 3 },
//       { code: "CSE 402", name: "Machine Learning Lab", credits: 1.5 },
//       { code: "CSE 404", name: "Computer Graphics Lab", credits: 1.5 },
//     ],
//   },
//   {
//     id: "semester-8",
//     title: "Semester 8",
//     subtitles: ["5 Courses", "13 Credits"],
//     courses: [
//       { code: "CSE 411", name: "Cyber Security", credits: 3 },
//       { code: "CSE 413", name: "Mobile Application Development", credits: 3 },
//       { code: "CSE 499", name: "Capstone Project", credits: 4 },
//       { code: "CSE 412", name: "Cyber Security Lab", credits: 1.5 },
//       {
//         code: "CSE 414",
//         name: "Mobile Application Development Lab",
//         credits: 1.5,
//       },
//     ],
//   },
// ];

const CurriculumCSE = ({ data, title2 }) => {
  const [expandedSemester, setExpandedSemester] = useState(null);

  return (
    <div className="max-w-6xl p-8 mx-auto">
      <h2 className="mb-12 text-xl font-bold text-center md:text-2xl lg:text-4xl font-domine">
        {title2} Curriculum
      </h2>

      <div className="space-y-4">
        {data?.map((semester) => (
          <motion.div
            key={semester.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="overflow-hidden border-b border-gray-200"
          >
            <motion.button
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
              onClick={() =>
                setExpandedSemester(
                  expandedSemester === semester.id ? null : semester.id
                )
              }
              className="flex items-center justify-between w-full px-4 py-4 transition-all duration-300 sm:px-6 bg-gradient-to-r from-white to-gray-50 hover:from-gray-50 hover:to-gray-100"
              aria-expanded={expandedSemester === semester.id}
            >
              <div className="text-left">
                <motion.h2
                  className="text-base font-semibold text-transparent sm:text-lg bg-clip-text bg-gradient-to-r from-primary-600 to-primary-400"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                >
                  {semester.title}
                </motion.h2>
                {semester.subtitles && semester.subtitles.length > 0 && (
                  <motion.p
                    className="mt-1 text-xs text-gray-600 sm:text-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    {semester.subtitles.join(" • ")}
                  </motion.p>
                )}
              </div>
              <motion.div
                animate={{ rotate: expandedSemester === semester.id ? 180 : 0 }}
                transition={{ type: "spring", stiffness: 200, damping: 15 }}
                className="flex-shrink-0 ml-2 text-gray-500"
              >
                <ChevronDown className="w-4 h-4 sm:w-5 sm:h-5" />
              </motion.div>
            </motion.button>

            <AnimatePresence>
              {expandedSemester === semester.id && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ type: "spring", stiffness: 100, damping: 20 }}
                  className="overflow-hidden"
                >
                  <div className="overflow-x-auto">
                    <motion.table
                      className="min-w-full divide-y divide-gray-200"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.1 }}
                    >
                      <thead className="bg-gradient-to-r from-gray-50 to-gray-100">
                        <tr>
                          <th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase sm:px-6">
                            Course Code
                          </th>
                          <th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase sm:px-6">
                            Course Name
                          </th>
                          <th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase sm:px-6">
                            Credits
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {semester.courses.map((course, index) => (
                          <motion.tr
                            key={course.code}
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: index * 0.1 }}
                            whileHover={{
                              scale: 1.01,
                              backgroundColor: "rgba(249, 250, 251, 0.5)",
                            }}
                            className="transition-all duration-200 transform"
                          >
                            <td className="px-4 py-4 sm:px-6 whitespace-nowrap">
                              <motion.span
                                className="text-sm font-medium text-primary-600"
                                whileHover={{ scale: 1.05 }}
                              >
                                {course.code}
                              </motion.span>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-900 sm:px-6 whitespace-nowrap">
                              {course.name}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 sm:px-6 whitespace-nowrap">
                              {course.credits}
                            </td>
                          </motion.tr>
                        ))}
                      </tbody>
                    </motion.table>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CurriculumCSE;
