import ImageHeading from "../components/ui/ImageHeading";
import headerImage from "../assets/admissinBanner.jpg";
import academics from "../assets/admission/tution.jpeg";
import academics2 from "../assets/Academics/academics2.webp";
import academics3 from "../assets/admission/caps.webp";
import academics4 from "../assets/admission/advising-center.webp";
import { Tabs } from "../components/ui/Tabs";

import { ExpandableContent } from "../components/shared/ExpandableContent";
import RoundedButton from "../components/ui/RoundedButton";

// import { AnimatedTabs } from "./Admission3.page";

export function AdmissionPage2() {
  const tabs = [
    {
      title: "Undergraduate",
      value: "undergraduate",
      content: (
        <div className="flex flex-col items-center h-full -mt-40 bg-white md:-mt-0 lg:flex-row">
          <div className="hidden aspect-video w-full h-full lg:block lg:w-1/2">
            <img
              src={headerImage}
              className="object-cover w-full h-full"
              alt="Undergraduate Admission"
            />
          </div>
          <div className="w-full p-8 space-y-4 lg:w-2/3">
            <h2 className="text-2xl font-semibold text-primary">
              Undergraduate Admission Requirements
            </h2>
            <ExpandableContent
              content={
                <div>
                  {" "}
                  {/* Minimum requirements */}
                  <div>
                    <h3 className="text-base font-semibold lg:text-lg">
                      Minimum requirements for Programs:
                    </h3>
                    <p className="py-2 text-xs lg:text-sm">
                      For all programs of School of Science & Engineering,
                      candidates must have Math or Physics, with minimum B grade
                      in HSC or C grade in A-levels to be eligible to apply.
                    </p>
                  </div>
                  {/* Minimum qualifications */}
                  <div className="space-y-3 ">
                    <h3 className="text-base font-semibold lg:text-lg">
                      Minimum Qualifications for admission:
                    </h3>
                    <h4 className="text-sm font-semibold lg:text-base">
                      1. Academic Qualifications:
                    </h4>
                    <ul className="pl-8 space-y-2 list-disc ">
                      <li>
                        Combined GPA of 6.0 in both SSC and HSC or minimum GPA
                        2.5 in each OR GCE O-Levels and A-Levels: O-Levels in
                        five subjects with Average Grade Point of 2.5 or above
                        and A-levels in two subjects with Average Grade Point of
                        2.0 or above. (scale A=5, B=4, C=3, D=2 & E=1) OR
                      </li>
                      <li>
                        {" "}
                        US High School Diploma, IB Diploma Program (IB-DP) or
                        equivalent
                      </li>
                      <li>
                        For academic qualifications other than the above one
                        needs to contact Admission Office to check the
                        eligibility
                      </li>
                      <li>
                        Foreign applicants should send their academic
                        transcripts and name of two referees for initial
                        screening to admission@zums.edu.bd. After verification,
                        the Admission Office will contact them about their
                        eligibility for admission.
                      </li>
                    </ul>
                    <h4 className="text-sm font-semibold lg:text-base">
                      2. Acceptance by ZUMS admission committee.
                    </h4>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      ),
    },
    {
      title: "Graduate",
      value: "graduate",
      content: (
        <div className="flex flex-col items-center h-full -mt-40 bg-white md:-mt-0 lg:flex-row">
          <div className="hidden aspect-video w-full h-full lg:block lg:w-1/2">
            <img
              src={academics3}
              className="object-cover w-full h-full"
              alt="Graduate Admission"
            />
          </div>
          <div className="w-full p-8 md:w-2/3">
            <h2 className="text-2xl font-semibold text-primary">
              Graduate Admission Requirements
            </h2>
            <ExpandableContent
              content={
                <div>
                  {" "}
                  {/* Minimum requirements */}
                  <div>
                    <h3 className="text-base font-semibold lg:text-lg">
                      Minimum requirements for Programs:
                    </h3>
                  </div>
                  {/* Minimum qualifications */}
                  <div className="space-y-3 ">
                    <ul className="pl-8 space-y-2 list-disc ">
                      <li>
                        A minimum of 15 years of schooling prior to MBA. A
                        bachelor’s degree in any discipline. (Please note that
                        two-year BA/B.Sc./B. Com degrees do not fulfill the
                        15-year schooling requirement; a master’s degree will be
                        required in such cases)
                      </li>
                      <li>
                        A minimum CGPA of 2.50 on a 5-point scale or at least
                        Second Class in all exams
                      </li>
                      <li>Acceptance by ZUMS admission committee</li>
                    </ul>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      ),
    },
    {
      title: "International",
      value: "international",
      content: (
        <div className="flex flex-col items-center h-full -mt-40 bg-white md:-mt-0 lg:flex-row">
          <div className="hidden aspect-video w-full h-full lg:block lg:w-1/2">
            <img
              src={academics2}
              className="object-cover w-full h-full"
              alt="International Admission"
            />
          </div>
          <div className="w-full p-8 lg:w-2/3">
            <h2 className="text-2xl font-semibold text-primary">
              International Admission
            </h2>
            <ExpandableContent
              content={
                <div>
                  {" "}
                  {/* Minimum requirements */}
                  <div>
                    <h3 className="text-base font-semibold lg:text-lg">
                      Participation in International Programs
                    </h3>
                  </div>
                  {/* Minimum qualifications */}
                  <div className="mt-3 space-y-3">
                    <p>
                      ZUMS is committed to providing students with the learning,
                      teaching and research training to excel in the global
                      economy. In this regard the students are provided with
                      opportunities based on teaching and research links of ZUMS
                      including credit transfer and undergraduate programs
                      (2+2), with universities in advanced economies including
                      Australia, Canada, UK and USA.
                    </p>
                    <p>
                      Moreover, the students are provided with the opportunity
                      to participate in international experiences including
                      student exchange, study abroad, international industry
                      placements and research programs.
                    </p>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      ),
    },
    {
      title: "Tuition & Aid",
      value: "tuition",
      content: (
        <div className="flex flex-col items-center h-full -mt-40 bg-white md:-mt-0 lg:flex-row">
          <div className="hidden aspect-video w-full h-full lg:block lg:w-1/2">
            <img
              src={academics}
              className="object-cover w-full h-full"
              alt="Tuition and Aid"
            />
          </div>
          <div className="w-full p-8 lg:w-2/3">
            <h2 className="text-2xl font-semibold text-primary">
              Tuition Fees
            </h2>
            <ExpandableContent
              content={
                <div>
                  <div className="mt-3 space-y-3">
                    <p>
                      The process is designed to attract a high quality student
                      body, add diversity to the student population, remove
                      financial barriers for qualified but financially
                      disadvantaged students and assist in meeting institutional
                      and regulatory compliance requirements.
                    </p>
                  </div>
                  <div className="mt-5 ">
                    <RoundedButton
                      href={"/admission/tuition-fees"}
                      className={"text-primary"}
                    >
                      See Details
                    </RoundedButton>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      ),
    },
    {
      title: "Scholarship",
      value: "scholarship",
      content: (
        <div className="flex flex-col items-center h-full -mt-40 bg-white md:-mt-0 lg:flex-row">
          <div className="hidden aspect-video w-full h-full lg:block lg:w-1/2">
            <img
              src={academics3}
              className="object-cover w-full h-full"
              alt="Scholarship"
            />
          </div>
          <div className="w-full p-8 lg:w-2/3">
            <h2 className="text-2xl font-semibold text-primary">
              Scholarships and Financial Aid
            </h2>
            <ExpandableContent
              content={
                <div>
                  <div className="mt-3 space-y-3">
                    <p>
                      The Board of Trustees of ZUMS has made a decision to bring
                      higher education within reasonable reach for students and
                      their families from different socio-economic backgrounds.
                      Many factors contribute to the ability to earn a
                      university degree, and the leadership of ZUMS management
                      are committed to address them all in order to increase
                      access to higher education, especially for disadvantaged
                      people in the society who have historically faced barriers
                      to entry.
                    </p>
                  </div>
                  <div className="mt-5 ">
                    <RoundedButton
                      href={"/admission/scholarship"}
                      className={"text-primary"}
                    >
                      See Details
                    </RoundedButton>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      ),
    },
    {
      title: "Advising Center",
      value: "center",
      content: (
        <div className="flex flex-col items-center h-full -mt-40 bg-white md:-mt-0 lg:flex-row">
          <div className="hidden aspect-video w-full h-full bg-red-600 lg:block lg:w-1/2">
            <img
              src={academics4}
              className="object-cover object-center w-full h-full"
              alt="Admission Center"
            />
          </div>
          <div className="w-full p-8 lg:w-2/3">
            <h2 className="text-2xl font-semibold text-primary">
              Advising Center
            </h2>
            <ExpandableContent
              content={
                <div>
                  {" "}
                  {/* Minimum requirements */}
                  <div>
                    <h3 className="text-base font-semibold lg:text-lg">
                      Faculty Advisors
                    </h3>
                  </div>
                  {/* Minimum qualifications */}
                  <div className="mt-3 space-y-3">
                    <p>
                      A faculty advisoris assigned at the end of a student’s
                      first year, who mainly guides the student through all
                      aspects of degree completion. Faculty Advisors also
                      discuss research possibilities for students with other
                      faculty, career opportunities, and in general, act as a
                      mentor for the student.
                    </p>
                  </div>
                  <div>
                    <h3 className="mt-5 text-base font-semibold lg:text-lg">
                      Peer Advisors
                    </h3>
                  </div>
                  {/* Minimum qualifications */}
                  <div className="mt-3 space-y-3">
                    <p>
                      The peer advisors are current students who assist new
                      students as they transition to the university life, by
                      sharing experiences, providing insight, and responding to
                      questions and concerns.
                    </p>
                    <p>
                      At the beginning of a semester, peer advisors introduce
                      themselves to the new students, answer any initial
                      questions the student might have, and assist them with
                      planning for curricular and extra-curricular activities.
                      Peer advisors also work closely with the office of student
                      affairs and faculty advisors, and maintain contact with
                      students throughout the year.
                    </p>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <ImageHeading
        heading="Admissions"
        imageUrl={headerImage}
        subHeading="Lorem ipsum dolor sit amet consectetur adipisicing elit."
      />
      <div className="container h-auto px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="h-[77rem] -mt-36 md:-mt-12 md:h-[50rem] lg:h-[55rem] [perspective:1000px] relative b flex flex-col max-w-6xl mx-auto w-full  items-start justify-start mb-10 lg:mb-0">
          <Tabs tabs={tabs} />
        </div>
        {/* <AnimatedTabs /> */}
      </div>
    </>
  );
}
