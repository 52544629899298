/* eslint-disable react/prop-types */
"use client";
import { motion, AnimatePresence } from "framer-motion";

const gradeInfoData = {
  "I** Incomplete": {
    title: "Incomplete (I)",
    description:
      'The Incomplete (I) grade indicates that a student has not completed all required coursework or assessments due to an unavoidable reason/circumstance but has the potential to complete the course requirements within a specified time. The "I" may be given only at the end of a semester to a student whose work is progressing but who has left unfinished a small amount of work for completion without further class attendance.',
    steps: [
      "The student is responsible for taking the initiative to complete the work and is expected to make up the incomplete grade as specified by the instructor.",
      "The instructor must file an Incomplete Grade Form with the Registrar, describing the work to be completed.",
      "The student must complete specific assignments or assessments by a new deadline.",
      'In the event the instructor from whom a student received an "I" grade is not available, the disposition of a case involving an incomplete grade resides with the Head of the Department.',
      'If action is not taken, the "I" grade will revert to the tentative final grade. The final grade automatically becomes an "F" if the student fails to appear in the make-up examination/if no tentative grade is assigned.',
      'The grade "I" must be replaced within one semester after the assigned grade.',
    ],
  },
  "W** Withdrawal": {
    title: "Withdrawal (W)",
    description:
      'The grade "Withdrawal" (W) is assigned when a student officially drops a course within the date mentioned in the Academic Calendar for the semester. Prior to that time, if a student drops a course, no entry is made on the academic record.',
    steps: ['A "W" does not affect the student\'s GPA.'],
  },
  "R** Repeat/Retake": {
    title: "Retake (R)",
    description:
      'The grade "R" is recorded when students retake a course to improve their understanding or grade. A student may repeat a course with a \'B\' or a lower grade. When a student retakes a course, the actual grade will be recorded. In case of a retake course, only the best grade will be used to calculate the CGPA. An "F" grade earned in any credit course will be used to calculate the CGPA until the course is retaken/replaced by taking another appropriate course and a better grade is obtained. The retake policy for courses with "F" grades applies to all students irrespective of their enrollment dates at ZNRF University.',
    steps: [
      "A student with an 'F' Grade(s) in a course(s) may retake the same any number of times to pass within the time limit allowed for graduation.",
      "Students who wish to retake a course must obtain previous written permission from the Head of the Department concerned.",
      "A student who wishes to retake a course must register for the course again and will be required to pay the usual tuition fees including lab (if applicable) and other charges.",
    ],
  },
  "CA** Course Abandonment": {
    title: "Course Abandonment (CA)",
    description:
      'Course Abandonment refers to cases where a student has registered for a course but does not attend, any classes or engage in required activities (e.g., class attendance, submitting assignments, giving presentations, participating in the exams). If a student is enrolled but remains absent throughout the semester, they will receive a "CA" (Course Abandonment) grade. "Course Abandonment" occurs when a student registers for a course but fails to:',
    steps: [
      "Attend any scheduled classes (in-person or online) without notifying the instructor.",
      "Engage in required academic activities (e.g., assignments, quizzes, or discussions).",
      "Communicate their intentions regarding continued enrollment in the course.",
      'A student with a "CA" grade must register for the course again and will be required to pay the usual tuition fees including lab (if applicable) and other charges.',
      'If the student fails to complete the course in next two semesters, the "CA" will automatically change to an "F."',
    ],
  },
};

const GradeInfo = ({ selectedGrade }) => {
  const info = selectedGrade ? gradeInfoData[selectedGrade] : null;

  return (
    <div className="min-h-[300px]">
      <AnimatePresence mode="wait">
        {info ? (
          <motion.div
            key={selectedGrade}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="p-4 border border-gray-200 rounded-lg"
          >
            <h3 className="text-xl font-semibold text-primary mb-3">
              {info.title}
            </h3>
            <p className="mb-4 text-gray-700">{info.description}</p>

            {info.steps.length > 0 && (
              <>
                <h4 className="font-semibold text-primary mb-2">
                  Steps for Changing &ldquo;{info.title.split(" ")[0]}&ldquo;
                  Grades:
                </h4>
                <ul className="list-disc pl-5 space-y-1">
                  {info.steps.map((step, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1, duration: 0.3 }}
                      className="text-gray-700"
                    >
                      {step}
                    </motion.li>
                  ))}
                </ul>
              </>
            )}
          </motion.div>
        ) : (
          <motion.div
            key="placeholder"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col items-center justify-center h-[300px] text-center p-4 border border-gray-200 rounded-lg"
          >
            <svg
              className="w-16 h-16 text-primary mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <p className="text-lg font-medium text-gray-700">
              Select a special grade to view detailed information
            </p>
            <p className="text-gray-500 mt-2">
              Click on one of the special grade cards above
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GradeInfo;
