import ResearchHome from "../components/home/ResearchHome";
import ImageHeading from "../components/ui/ImageHeading";
import headerImage from "../assets/header/header.jpeg";
const School = () => {
  return (
    <div>
      <ImageHeading heading={"All Schools"} imageUrl={headerImage} />
      <div className="container px-4 py-12 mx-auto md:px-8">
        <ResearchHome />
      </div>
    </div>
  );
};

export default School;
