import { FaFacebook, FaInstagram, FaLinkedin, FaMapMarkerAlt } from "react-icons/fa";
import whiteLogo from "../../assets/logoWhite.png";
import bdgovt from "../../assets/bdgovt.svg";
import ugc from "../../assets/ugc.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="overflow-hidden ">
      {/* <div className="h-[100px]  "></div> */}

      <div className="relative text-gray-200  bg-gradient-to-t from-[#AC0D30] via-[#AC0D30] to-transparent bg-[linear-gradient(180deg, rgba(172,13,48,1) 40%, rgba(172,13,48,0.8) 55%, rgba(172,13,48,0.6) 60%, rgba(172,13,48,0.4) 75%, transparent 100%)] overflow-hidden">
        {/* Background Pattern */}
        <div
          className="absolute inset-0 opacity-20"
          style={{
            backgroundImage:
              "radial-gradient(circle at 25px 25px, rgba(255, 255, 255, 0.2) 2%, transparent 0%), " +
              "radial-gradient(circle at 75px 75px, rgba(255, 255, 255, 0.2) 2%, transparent 0%)",
            backgroundSize: "100px 100px",
          }}
        />

        {/* Gradient Overlays */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#AC0D30]/50 to-[#AC0D30]" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />

        {/* Content */}
        <div className="container relative z-10 px-4 pb-10 mx-auto pt-28">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-4 lg:gap-10">
            {/* Logo and Description */}
            <div className="space-y-5 ">
              <img
                className="w-48 mx-auto md:w-60 lg:w-72 md:mx-0"
                src={whiteLogo}
                alt="ZUMS Logo"
              />
              <div className="hidden h-px md:block bg-gradient-to-r from-transparent via-gray-200/50 to-transparent" /> 
              <div className="flex items-center gap-2 text-gray-200/90 hover:text-white transition-colors">
                <FaMapMarkerAlt className="text-xl" />
                <p>F-15, Pragati Sarani, Middle Badda, Dhaka- 1212</p>
              </div>
              <div className="flex justify-center md:justify-start">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d720.6027108640723!2d90.42511810143834!3d23.77692390938194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c71c74180e11%3A0x4834e74fa7ce7213!2sZNRF%20University%20of%20Management%20Sciences%20(ZUMS)!5e0!3m2!1sen!2sbd!4v1734430996456!5m2!1sen!2sbd"
                  width="300"
                  height="150"
                  className="w-full max-w-md"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                />
              </div>
            </div>

            {/* About Links */}
            {/* <div className="col-span-1 text-center md:text-left">
              <h1 className="text-lg font-medium font-domine">
                <span className="font-extralight">About</span> ZUMS
              </h1>
              <div className="w-16 h-[1px] bg-gradient-to-r from-gray-200/50 to-transparent mt-2 mx-auto md:mx-0"></div>
              <div className="flex flex-col items-center gap-3 mt-5 text-sm lg:text-base md:items-start">
                <Link
                  to="/why-zums"
                  className="transition-colors hover:text-white"
                >
                  About ZUMS
                </Link>
                <Link
                  to="/news-and-events"
                  className="transition-colors hover:text-white"
                >
                  News & Events
                </Link>
                <Link
                  to="/contact-us"
                  className="transition-colors hover:text-white"
                >
                  Contact Us
                </Link>
              </div>
            </div> */}

            {/* Student Corner */}
            {/* <div className="col-span-1 text-center md:text-left">
              <h1 className="text-lg font-medium font-domine">
                <span className="font-extralight">Student</span> Corner
              </h1>
              <div className="w-16 h-[1px] bg-gradient-to-r from-gray-200/50 to-transparent mt-2 mx-auto md:mx-0"></div>
              <div className="flex flex-col items-center gap-3 mt-5 text-sm lg:text-base md:items-start">
                <Link
                  to="/student/employability"
                  className="transition-colors hover:text-white"
                >
                  Current Students
                </Link>
                <Link
                  to="/student/accommodation"
                  className="transition-colors hover:text-white"
                >
                  Future Students
                </Link>
                <Link
                  to="/student/transport"
                  className="transition-colors hover:text-white"
                >
                  Faculty & Staff
                </Link>
              </div>
            </div> */}

            {/* Action Buttons */}
            <div className="flex flex-wrap items-center justify-center w-full gap-4 md:flex-col lg:justify-center">
              <Link
                to={"https://103.60.173.170/zumsadmission/Admission/Home.aspx"}
              >
                <button className="px-4 py-2 min-w-52 text-xs w-fit font-medium transition-all duration-300 ease-in-out bg-white border rounded-md shadow-lg h-fit lg:text-sm border-primary text-[#AC0D30] hover:bg-gray-100 hover:text-primary hover:scale-105">
                  Apply Now
                </button>
              </Link>
              <Link
                to="/academics/course-catalog"
                className="px-4 text-center min-w-52 py-2 w-fit text-xs font-medium transition-all duration-300 ease-in-out bg-white border rounded-md shadow-lg h-fit lg:text-sm border-primary text-[#AC0D30] hover:bg-gray-100 hover:text-primary hover:scale-105"
              >
                Course Catalog
              </Link>
              <Link
                to="/academics/academic-Calendar"
                className="px-4 text-center min-w-52 py-2 w-fit text-xs font-medium transition-all duration-300 ease-in-out bg-white border rounded-md shadow-lg h-fit lg:text-sm border-primary text-[#AC0D30] hover:bg-gray-100 hover:text-primary hover:scale-105"
              >
                Academic Calendar
              </Link>
              <Link
                to="/info"
                className="px-4 text-center min-w-52 py-2 w-fit text-xs font-medium transition-all duration-300 ease-in-out bg-white border rounded-md shadow-lg h-fit lg:text-sm border-primary text-[#AC0D30] hover:bg-gray-100 hover:text-primary hover:scale-105"
              >
                Request For Info
              </Link>
            </div>
            <div className="flex flex-col items-center justify-center space-y-2 font-medium lg:justify-start lg:mt-4">
              <div>
                <Link to={"/info"}>Contact ZUMS </Link>
              </div>
              <div>
                <Link to={"/news-and-events"}>News & Events </Link>
              </div>
              {/* <div>
                <Link to={"/privacy-policy"}>Policies & Guidelines </Link>
              </div> */}
              <div>
                <Link to={"/terms-and-condition"}>Terms & Condition</Link>
              </div>
            </div>

            {/* Related Links and Social */}
            <div className="flex flex-col items-center md:items-end">
              <div className="mb-6 text-center md:text-right">
                <h2 className="text-lg font-medium font-domine">Approved by</h2>
                <div className="flex items-center justify-center gap-4 my-6 md:justify-end">
                  <div className="flex items-center justify-center h-16 bg-white md:h-20 aspect-square rounded-xl">
                    <img
                      src={bdgovt}
                      alt="BD Government"
                      className="h-12 md:h-16"
                    />
                  </div>
                  <div className="flex items-center justify-center h-16 bg-white md:h-20 aspect-square rounded-xl">
                    <img src={ugc} alt="UGC" className="h-12 md:h-16" />
                  </div>
                </div>
              </div>

              {/* Social Icons */}
              <div className="flex items-center justify-center gap-4 mb-6 md:justify-end">
                {[
                  {
                    Icon: FaFacebook,
                    link: "https://www.facebook.com/zumsuniversity",
                  },
                  {
                    Icon: FaInstagram,
                    link: "https://www.instagram.com/zums200/",
                  },
                  {
                    Icon: FaLinkedin,
                    link: "https://www.linkedin.com/znrfuniversity",
                  },
                ].map(({ Icon, link }, index) => (
                  <a
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      size={28}
                      className="transition-all cursor-pointer hover:text-white hover:scale-110"
                    />
                  </a>
                ))}
              </div>

              {/* Legal Links */}
              {/* <div className="flex flex-col items-center justify-center gap-2 text-sm md:flex-row md:justify-end">
                <Link
                  to="/copyright"
                  className="transition-colors hover:text-white"
                >
                  Copyright
                </Link>
                <span className="hidden md:inline">|</span>
                <Link
                  to="/terms-and-conditions"
                  className="transition-colors hover:text-white"
                >
                  Terms & Conditions
                </Link>
                <span className="hidden md:inline">|</span>
                <Link
                  to="/privacy-policy"
                  className="transition-colors hover:text-white"
                >
                  Privacy Policy
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
