import React from "react";
import { motion } from "framer-motion";
import ResourceCard from "../ui/Card/ResourceCard";
import doaj from "../../assets/logos/doaj.svg";
import projectMUSE from "../../assets/logos/project_MUSE.png";
import resarch4life from "../../assets/logos/r4l.png";
import doab from "../../assets/logos/doab.png";
import imfelibrary from "../../assets/logos/imfelibrary.png";
const resources = [
  {
    title: "The Directory of Open Access Journals",
    description:
      "was launched in 2003 at Lund University, Sweden, with 300 open access journals and today contains ca",
    logoUrl: doaj,
  },
  {
    title: "Project MUSE",
    description:
      "is a leading provider of digital humanities and social science content for the scholarly community",
    logoUrl: projectMUSE,
  },
  {
    title: "Research4life",
    description:
      "Research4Life provides institutions in low-and middle-income countries with online access to academic and professional peer-reviewed content",
    logoUrl: resarch4life,
  },
  {
    title: "Directory for Open Access Books",
    description:
      "s a community-driven discovery service that indexes and provides access to scholarly",
    logoUrl: doab,
  },
  {
    title: "The International Monetary Fund's eLibrary",
    description:
      "simplifies analysis and research with direct access to the IMF’s periodicals, books, working papers and studies, and data and statistical tools",
    logoUrl: imfelibrary,
  },
  // {
  //   title: "Specialized Wikis",
  //   description: "Topic-specific wikis for detailed information.",
  //   logoUrl: "/placeholder.svg?height=96&width=96",
  // },
];

const ResourceGrid = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <motion.h2
        className="text-4xl font-bold text-center mb-12 text-gray-800 font-domine"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        Explore Our Digital Resources
      </motion.h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              staggerChildren: 0.2,
            },
          },
        }}
        initial="hidden"
        animate="visible"
      >
        {resources.map((resource, index) => (
          <motion.div
            key={index}
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  type: "spring",
                  stiffness: 100,
                  damping: 10,
                },
              },
            }}
          >
            <ResourceCard {...resource} />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default ResourceGrid;
