/* eslint-disable react/prop-types */

function WhyCard({ title, details }) {
  return (
    <div className="w-full cursor-pointer relative mt-4 h-[300px] md:h-[350px] overflow-hidden group mx-auto bg-gradient-to-t from-[#AC0D30]/90 via-[#AC0D30]/70 to-[#AC0D30]/40 dark:border-0 border rounded-md text-black flex flex-col justify-center items-center">
      {/* Header in the Center */}
      <div className="flex items-center justify-center w-full h-full p-4">
        <p className="text-base font-bold text-center text-white font-domine md:text-2xl lg:text-3xl">
          {title}
        </p>
      </div>

      {/* Hover Overlay */}
      <article className="absolute top-0 z-10 flex flex-col justify-end w-full h-full p-4 overflow-hidden transition-all duration-300 bg-white rounded-md opacity-0 group-hover:opacity-100">
        <div className="space-y-2 transition-all duration-300 translate-y-10 group-hover:translate-y-0">
          {/* <h1 className="hidden font-semibold md:block font-domine md:text-2xl">
            {title}
          </h1> */}
          <p className="text-sm sm:text-base">{details}</p>
        </div>
      </article>
    </div>
  );
}

export default WhyCard;
