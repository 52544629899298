export const academicCalenderData = [
  {
    id: "1",
    title: "Advising and Course Registration for Newly Admitted Students",
    date: { start: new Date(2025, 0, 5), end: new Date(2025, 0, 9) },
    type: "academic",
    description:
      "Advising and course registration period for newly admitted students.",
  },
  {
    id: "2",
    title: "Form Fill-up of Retake Exam & Payment (Final Exam Fall 2024)",
    date: new Date(2025, 0, 5),
    type: "academic",
    description:
      "Form fill-up and payment for retake exams for Fall 2024 final exams.",
  },
  {
    id: "3",
    title: "Retake Exam (Final Exam Fall 2024)",
    date: { start: new Date(2025, 0, 9), end: new Date(2025, 0, 11) },
    type: "exam",
    description: "Retake exams for Fall 2024 final exams.",
  },
  {
    id: "4",
    title: "Orientation & Reception for Newly Admitted Students",
    date: new Date(2025, 0, 12),
    type: "academic",
    description: "Orientation and reception event for newly admitted students.",
  },
  {
    id: "5",
    title: "First Day of Class (Undergraduate)",
    date: new Date(2025, 0, 13),
    type: "academic",
    description: "First day of classes for undergraduate students.",
  },
  {
    id: "6",
    title: "First Day of Class (Graduate)",
    date: new Date(2025, 0, 17),
    type: "academic",
    description: "First day of classes for graduate students.",
  },
  {
    id: "7",
    title: "Advising and Course Registration for Ongoing Students",
    date: new Date(2025, 0, 27),
    type: "academic",
    description:
      "Advising and course registration period for ongoing students.",
  },
  {
    id: "8",
    title: "Shab e-Barat (Night of Records)",
    date: new Date(2025, 1, 15),
    type: "holiday",
    description: "University closed for Shab e-Barat.",
  },
  {
    id: "9",
    title: "Shahid Dibosh (International Mother Language Day)",
    date: new Date(2025, 1, 21),
    type: "holiday",
    description: "University closed for Shahid Dibosh.",
  },
  {
    id: "10",
    title: "Last Day of Course Registration; and Course Drop with 100% Refund",
    date: new Date(2025, 1, 27),
    type: "academic",
    description:
      "Last day to register for courses or drop courses with a 100% refund.",
  },
  {
    id: "11",
    title: "Mid-Semester Assessment",
    date: { start: new Date(2025, 2, 7), end: new Date(2025, 2, 13) },
    type: "exam",
    description: "Mid-semester assessments conducted by respective faculties.",
  },
  {
    id: "12",
    title: "Independence Day (National Day)",
    date: new Date(2025, 2, 26),
    type: "holiday",
    description: "University closed for Independence Day.",
  },
  {
    id: "13",
    title: "Shab-e-Qadar (Night of Destiny)",
    date: new Date(2025, 2, 28),
    type: "holiday",
    description: "University closed for Shab-e-Qadar.",
  },
  {
    id: "14",
    title: "Jumatul Bidah",
    date: new Date(2025, 2, 28),
    type: "holiday",
    description: "University closed for Jumatul Bidah.",
  },
  {
    id: "15",
    title: "Eid-ul-Fitr Holiday",
    date: { start: new Date(2025, 2, 29), end: new Date(2025, 3, 2) },
    type: "holiday",
    description: "University closed for Eid-ul-Fitr holidays.",
  },
  {
    id: "16",
    title: "Last Day to Drop Courses with 75% Refund",
    date: new Date(2025, 2, 30),
    type: "academic",
    description: "Last day to drop courses with a 75% refund.",
  },
  {
    id: "17",
    title: "Pahela Baishakh (Bangla New Year)",
    date: new Date(2025, 3, 14),
    type: "holiday",
    description: "University closed for Pahela Baishakh.",
  },
  {
    id: "18",
    title: "May Day",
    date: new Date(2025, 4, 1),
    type: "holiday",
    description: "University closed for May Day.",
  },
  {
    id: "19",
    title: "Buddha Purnima (Buddha Day)",
    date: new Date(2025, 4, 12),
    type: "holiday",
    description: "University closed for Buddha Purnima.",
  },
  {
    id: "20",
    title: "Last Class of Graduate Program",
    date: new Date(2025, 4, 30),
    type: "academic",
    description: "Last day of classes for graduate students.",
  },
  {
    id: "21",
    title: "Last Class of Undergraduate Program",
    date: new Date(2025, 5, 2),
    type: "academic",
    description: "Last day of classes for undergraduate students.",
  },
  {
    id: "22",
    title: "Eid-ul-Azha Holiday",
    date: { start: new Date(2025, 5, 5), end: new Date(2025, 5, 10) },
    type: "holiday",
    description: "University closed for Eid-ul-Azha holidays.",
  },
  {
    id: "23",
    title: "Final Exam",
    date: { start: new Date(2025, 5, 15), end: new Date(2025, 5, 30) },
    type: "exam",
    description: "Final examination period.",
  },
  {
    id: "24",
    title: "1st Installment Payment",
    date: { start: new Date(2025, 0, 5), end: new Date(2025, 0, 13) },
    type: "financial",
    description: "First installment payment period for tuition fees.",
  },
  {
    id: "25",
    title: "2nd Installment Payment",
    date: { start: new Date(2025, 1, 23), end: new Date(2025, 2, 2) },
    type: "financial",
    description: "Second installment payment period for tuition fees.",
  },
  {
    id: "26",
    title: "3rd Installment Payment",
    date: { start: new Date(2025, 5, 1), end: new Date(2025, 5, 10) },
    type: "financial",
    description: "Third installment payment period for tuition fees.",
  },
];
