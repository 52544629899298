import { Code2, Rocket, Shield, Users, Zap } from "lucide-react";
import { motion } from "framer-motion";

const features = [
  {
    icon: Rocket,
    title: "Startup Support and Mentorship",
    points: [
      "Guidance from mentors and industry leaders",
      "Support for idea validation and business models",
      "End-to-end startup success assistance",
    ],
  },
  {
    icon: Shield,
    title: "State-of-the-Art Facilities",
    points: [
      "Co-working spaces and innovation labs",
      "Resources for R&D across domains",
      "Prototyping tools and equipment",
    ],
  },
  {
    icon: Users,
    title: "Workshops and Training",
    points: [
      "Workshops, training, and hackathons",
      "Focus on strategy, design, and marketing",
      "Skills development for members",
    ],
  },
  {
    icon: Code2,
    title: "Access to Funding and Networking",
    points: [
      "Grants, seed funding, and venture capital",
      "Networking with investors and partners",
    ],
  },
  {
    icon: Zap,
    title: "Collaboration with Industry and Academia",
    points: [
      "Partnerships with organizations and government",
      "Access to research and technical expertise",
    ],
  },
];

const WhatWeOffer2 = () => {
  return (
    <>
      <section className="flex flex-col max-w-lg px-4 py-10 mx-auto lg:max-w-screen-xl lg:flex-row">
        <div className="max-w-md pr-20 lg:pt-28">
          <img
            src="https://assets-global.website-files.com/6009ec8cda7f305645c9d91b/601074ed0f05cd25097215a4_6002086f72b7277e1f01d682_ryan-morrison-illustration-1.png"
            alt="Illustration"
          />
        </div>
        <div>
          <div className="grid gap-y-12 gap-x-8 lg:grid-cols-2">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <div className="flex items-center mb-6">
                  <p className="pl-4 text-2xl leading-10 border-l-4 border-primary">
                    {feature.title}
                  </p>
                </div>
                <ul className="text-lg text-gray-800 list-disc list-inside">
                  {feature.points.map((point, idx) => (
                    <li key={idx}>{point}</li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatWeOffer2;
