import { motion } from "framer-motion";
import { useRef } from "react";
import { useInView } from "framer-motion";
import logo from "../../assets/ZUIC-logo.png";
import missionImage from "../../assets/mision.jpg";
import vision from "../../assets/vision.jpg";

import RoundedButton from "../../components/ui/RoundedButton";
import WhatWeOffer from "../../components/WhatWeOffer";
// import JournalCarousel from "../../components/ui/JurnalCarousel";
import ResearchCTA from "../../components/ui/ResearchCTA";
// import ResearchContactForm from "../../components/ui/ResearchContactForm";
// import FocusArea from "../../components/ui/FocusArea";
// import FocusCard from "../../components/home/Research/FocusCard";
import JournalCarousel2 from "../../components/home/Research/JournalCarousel2";
// import FocusArea2 from "../../components/home/Research/FocusArea2";
import FocusArea3 from "../../components/home/Research/FocusArea3";
import researchBgVideo from "../../assets/researchBg.webm";
// import TeamCarousel from "../../components/CenterOfExcellence/TeamCarousel";
import PublicationCarousel from "../../components/CenterOfExcellence/PublicationCarousel";
const ResearchPage2 = () => {
  const missionRef = useRef(null);
  const visionRef = useRef(null);

  const isMissionInView = useInView(missionRef, {
    once: false,
    margin: "-10%",
  });
  const isVisionInView = useInView(visionRef, { once: false, margin: "-10%" });

  return (
    <div className="min-h-screen">
      {/* Banner Section */}
      <div className="relative w-full h-[80vh] overflow-hidden">
        {/* Background Video */}
        <video
          className="absolute inset-0 object-cover w-full h-full"
          src={researchBgVideo}
          autoPlay
          loop
          muted
        ></video>

        {/* Overlay */}
        <div className="absolute bottom-0 z-20 flex flex-col items-center justify-center w-full h-full px-8 pb-10 bg-black bg-opacity-50 lg:items-start lg:justify-end"></div>
        <div className="absolute bottom-0 z-20 h-44 flex flex-col items-center justify-center w-full px-8 pb-10 bg-gradient-to-t from-[#F9FAFB] via-[#F9FAFB]/50 to-transparent lg:items-start lg:justify-end"></div>
        <div className="absolute z-30 flex flex-col items-center justify-center w-full h-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          {/* <motion.h1
              initial={{ y: "400px" }}
              animate={{ y: "0%" }}
              transition={{ duration: 1, ease: "linear" }}
              className="text-base font-medium text-gray-300 font-base lg:text-lg"
            >
              Research to
            </motion.h1> */}
          <motion.h1
            initial={{ y: "400px" }}
            animate={{ y: "0%" }}
            transition={{ duration: 1, delay: 0.5, ease: "linear" }}
            className="mt-2 text-3xl font-bold text-white font-domine md:text-4xl lg:text-7xl"
          >
            What Will You Discover?
          </motion.h1>
        </div>

        {/* Logo */}
        <div className="absolute z-50 flex items-center justify-center w-full bottom-1/2 lg:bottom-0">
          <img className="w-48 md:w-72" src={logo} alt="Logo" />
        </div>
      </div>

      {/* Content Section */}
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <p className="text-2xl text-center text-gray-800">
          The ZNRF University Incubation Center (ZUIC) at ZUMS fosters
          innovation, empowering start-ups and researchers with workspaces,
          training, mentorship, and collaboration to scale impactful ventures
          globally.
        </p>

        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
          {/* Mission Section */}
          <div className="relative min-h-[60vh] mt-12 flex justify-start items-center">
            <div
              className="absolute inset-0 rounded-xl"
              style={{
                backgroundImage: `url(${missionImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "16px",
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 rounded-xl"></div>
            </div>
            <motion.div
              ref={missionRef}
              initial={{ x: "-80%" }}
              animate={isMissionInView ? { x: "0%" } : {}}
              transition={{ duration: 1, ease: "easeIn" }}
              id="mission"
              style={{
                clipPath: "polygon(0 0, 30% 0, 100% 100%, 0% 100%)",
                borderRadius: "10px",
              }}
              className="relative flex justify-start items-center z-10 min-h-[60vh] p-4 lg:p-10 h-full w-[100vw] lg:max-w-[64vw] text-black bg-white rounded-2xl"
            >
              <div className="max-w-[50vw] lg:max-w-xs">
                <h1 className="text-xl font-semibold lg:text-3xl">Ambition</h1>
                <p className="mt-2 text-sm md:text-base">
                  To provide comprehensive support and resources to innovators,
                  enabling them to develop sustainable solutions that address
                  real-world challenges.
                </p>
                <RoundedButton
                  className={`mt-2`}
                  spanClassName={`text-primary`}
                >
                  Learn More
                </RoundedButton>
              </div>
            </motion.div>
          </div>

          {/* Vision Section */}
          <div className="relative min-h-[60vh] mt-12 flex justify-end items-center">
            <div
              className="absolute inset-0 rounded-xl"
              style={{
                backgroundImage: `url(${vision})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px", // Add rounded corners for the image section
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 rounded-2xl"></div>
            </div>
            <motion.div
              ref={visionRef}
              initial={{ x: "80%" }}
              animate={isVisionInView ? { x: "0%" } : {}}
              transition={{ duration: 1, ease: "easeIn" }}
              id="vision"
              style={{
                clipPath: "polygon(70% 0, 100% 0, 100% 100%, 0% 100%)",
                borderRadius: "10px",
              }}
              className="relative flex justify-start items-center z-10 min-h-[60vh] p-4 lg:p-10 h-full w-[100vw] lg:w-[70vw] text-black bg-white rounded-xl"
            >
              <div className="max-w-[100vw] absolute right-0 p-4 lg:p-10 lg:max-w-xl">
                <h1 className="text-xl font-semibold lg:text-3xl text-end">
                  Commitment
                </h1>
                <p className="mt-2 text-sm max-w-[50vw] md:max-w-sm lg:max-w-xs text-end md:text-base">
                  To establish a thriving ecosystem where academia, industry,
                  and startups converge, promoting economic growth and social
                  development.
                </p>
                <div className="flex justify-end ">
                  <RoundedButton
                    className={`mt-2`}
                    spanClassName={`text-primary`}
                  >
                    Learn More
                  </RoundedButton>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      {/* <JournalCarousel /> */}
      <JournalCarousel2 />
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div>
          {/* <div className="w-full">
            <h2 className="mb-4 text-4xl font-bold text-center font-domine">
              Focus Area
            </h2>
            <div className="w-16 h-1 mx-auto my-4 bg-primary"></div>
          </div> */}
          {/* <FocusArea /> */}
          {/* <FocusCard /> */}
          {/* <div className="w-full">
            <h2 className="mb-4 text-4xl font-bold text-center font-domine">
              Focus Area
            </h2>
            <div className="w-16 h-1 mx-auto my-4 bg-primary"></div>
          </div> */}
          {/* <FocusArea2 /> */}
          <div className="w-full mt-16">
            <h2 className="mb-4 text-4xl font-bold text-center font-domine">
              Focus Area
            </h2>
            <div className="w-16 h-1 mx-auto my-4 bg-primary"></div>
          </div>
          <FocusArea3 />
        </div>
        <div className="pb-14">
          <PublicationCarousel title={"Projects"} />
        </div>
        {/* <TeamCarousel title={"ZUIC Team"} /> */}
        <div className="w-full mt-12">
          <h2 className="mb-4 text-4xl font-bold text-center font-domine">
            What We Offer
          </h2>
          <div className="w-16 h-1 mx-auto my-4 bg-primary"></div>
        </div>

        <WhatWeOffer />
      </div>

      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <ResearchCTA
          title={"Contact ZNRF University Incubation Center"}
          description={
            " Are you ready to bring your ideas to life? ZUIC welcomes students, faculty members, and external entrepreneurs who are passionate about making a difference. Join us to access the  resources, mentorship, and community you need to turn your vision into reality."
          }
          number={"+880-1626373519"}
          mail={"araf@zums.edu.bd"}
        />
      </div>
      {/* <ResearchContactForm /> */}
    </div>
  );
};

export default ResearchPage2;
