/* eslint-disable react/prop-types */
import React from "react";
import { motion } from "framer-motion";

const BookFlipCard = ({ book }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      className="relative w-full h-full overflow-hidden cursor-pointer aspect-square"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Front - Book Cover */}
      <img
        src={book.image}
        alt={book.title}
        className="object-cover w-full h-full rounded-lg shadow-lg"
      />

      {/* Back - Book Details (Slides from Bottom) */}
      <motion.div
        initial={{ y: "100%" }} // Start from below
        animate={{ y: isHovered ? "0%" : "100%" }} // Slide up on hover
        transition={{ duration: 0.4, ease: "easeInOut" }}
        className="absolute bottom-0 left-0 flex flex-col items-center justify-center w-full h-full p-4 text-white bg-indigo-900 rounded-lg shadow-lg"
      >
        <h3 className="mb-2 text-xl font-bold">{book.title}</h3>
        <p className="mb-2 text-sm">By {book.author}</p>
        <p className="text-sm line-clamp-4">{book.description}</p>
      </motion.div>
    </div>
  );
};

export default BookFlipCard;
