import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { AnimatePresence, motion } from "framer-motion";
import { Menu, X } from "lucide-react";
import { NewsEventCard } from "../components/ui/Card/NewsEventCard";
import { FilterPanel } from "../components/ui/FilterPanel";
import { useNewsFilter } from "../hooks/useNewsFilter";
import ImageHeading from "../components/ui/ImageHeading";
import libraryImage from "../assets/library-bg4.webp";

const mockNews = [
  {
    id: "1",
    title: "Tech Conference 2024",
    description:
      "Join us for the biggest tech conference of the year. Learn about the latest trends in AI, blockchain, and more. Our expert speakers will share insights on emerging technologies, best practices, and future predictions. Network with industry leaders and participate in hands-on workshops.",
    category: "Events",
    date: "2024-04-15",
    image:
      "https://images.unsplash.com/photo-1540575467063-178a50c2df87?auto=format&fit=crop&q=80",
    audience: ["Developers", "Tech Leaders"],
  },
  {
    id: "2",
    title: "New AI Research Breakthrough",
    description:
      "Scientists have made a groundbreaking discovery in artificial intelligence that could revolutionize machine learning. The new approach combines neural networks with quantum computing principles, achieving unprecedented accuracy in complex pattern recognition tasks.",
    category: "News",
    date: "2024-03-20",
    image:
      "https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80",
    audience: ["Researchers", "Students"],
  },
  {
    id: "3",
    title: "Startup Pitch Competition",
    description:
      "Present your innovative ideas to top investors and win funding for your startup. Selected teams will have the opportunity to pitch their projects to a panel of venture capitalists and industry experts. Mentorship sessions and networking events included.",
    category: "Events",
    date: "2024-05-01",
    image:
      "https://images.unsplash.com/photo-1551818255-e6e10975bc17?auto=format&fit=crop&q=80",
    audience: ["Entrepreneurs", "Investors"],
  },
];

const categories = ["Events", "News", "Workshops", "Announcements"];
const audiences = [
  "Developers",
  "Tech Leaders",
  "Researchers",
  "Students",
  "Entrepreneurs",
  "Investors",
];
const NewsAndEvents = () => {
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const { ref, inView } = useInView();
  const [displayCount, setDisplayCount] = useState(6);

  const { filters, setFilters, filteredItems } = useNewsFilter(mockNews);

  const itemCounts = {
    categories: mockNews.reduce((acc, item) => {
      acc[item.category] = (acc[item.category] || 0) + 1;
      return acc;
    }, {}),
    audiences: mockNews.reduce((acc, item) => {
      item.audience.forEach((a) => {
        acc[a] = (acc[a] || 0) + 1;
      });
      return acc;
    }, {}),
  };

  React.useEffect(() => {
    if (inView) {
      setDisplayCount((prev) => Math.min(prev + 6, filteredItems.length));
    }
  }, [inView, filteredItems.length]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-purple-50">
      <ImageHeading heading={"News And Events"} imageUrl={libraryImage} />
      <div className="container px-4 py-8 mx-auto">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="flex items-center justify-between mb-8"
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setMobileFilterOpen(!mobileFilterOpen)}
            className="p-3 border shadow-lg lg:hidden rounded-xl bg-white/80 backdrop-blur-sm border-white/20"
          >
            <Menu size={24} className="text-gray-700" />
          </motion.button>
        </motion.div>

        <div className="flex flex-col gap-8 lg:flex-row">
          {/* Filter Panel - Mobile */}
          <AnimatePresence>
            {mobileFilterOpen && (
              <motion.div
                initial={{ x: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "100%", opacity: 0 }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto shadow-2xl mt-14 sm:w-96 bg-white/80 backdrop-blur-xl lg:hidden"
              >
                <div className="p-6">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setMobileFilterOpen(false)}
                    className="absolute p-2 transition-colors bg-gray-100 top-4 right-4 rounded-xl hover:bg-gray-200"
                  >
                    <X size={24} className="text-gray-700" />
                  </motion.button>
                  <FilterPanel
                    filters={filters}
                    onFilterChange={setFilters}
                    categories={categories}
                    audiences={audiences}
                    itemCounts={itemCounts}
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Main Content */}
          <div className="flex-1">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
              <AnimatePresence mode="popLayout">
                {filteredItems.slice(0, displayCount).map((item, index) => (
                  <motion.div
                    key={item.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <NewsEventCard item={item} />
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>

            {filteredItems.length === 0 && (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                className="py-16 text-center"
              >
                <p className="text-2xl font-medium text-gray-500">
                  No results found
                </p>
                <p className="mt-2 text-gray-400">Try adjusting your filters</p>
              </motion.div>
            )}

            {displayCount < filteredItems.length && (
              <div ref={ref} className="h-24" />
            )}
          </div>

          {/* Filter Panel - Desktop */}
          <div className="hidden lg:block w-96">
            <FilterPanel
              filters={filters}
              onFilterChange={setFilters}
              categories={categories}
              audiences={audiences}
              itemCounts={itemCounts}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsAndEvents;
