import React from "react";
import { motion } from "framer-motion";

const FeatureCard = ({ icon: Icon, title, points, index, primaryColor }) => {
  return (
    <motion.div
      initial={{ opacity: 0, rotateY: -30, translateZ: -100 }}
      whileInView={{
        opacity: 1,
        rotateY: 0,
        translateZ: 0,
      }}
      whileHover={{
        scale: 1.05,
        rotateY: 5,
        translateZ: 20,
      }}
      viewport={{ once: true }}
      transition={{
        duration: 0.8,
        delay: index * 0.2,
        type: "spring",
        stiffness: 100,
      }}
      style={{
        perspective: "1000px",
        transformStyle: "preserve-3d",
      }}
      className="relative h-full group"
    >
      <div
        className="relative cursor-pointer h-full bg-white/80 backdrop-blur-lg rounded-2xl p-8 shadow-[0_8px_32px_rgba(0,0,0,0.1)] 
        border border-white/20 hover:shadow-[0_16px_48px_rgba(0,0,0,0.2)] transition-all duration-500"
      >
        {/* Glow effect */}
        <div
          className="absolute inset-0 transition-opacity duration-500 opacity-0 rounded-2xl group-hover:opacity-100"
          style={{
            background: `linear-gradient(135deg, ${primaryColor}80, ${primaryColor}30)`,
          }}
        />

        <div className="relative z-10">
          {/* Icon container with floating animation */}
          <motion.div
            animate={{
              y: [0, -10, 0],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut",
            }}
            className="inline-flex p-4 mb-6 rounded-2xl"
            style={{
              background: `linear-gradient(135deg, ${primaryColor}20, ${primaryColor}30)`,
            }}
          >
            <Icon className="w-8 h-8" style={{ color: primaryColor }} />
          </motion.div>

          <h3 className="mb-4 text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-900 to-gray-600">
            {title}
          </h3>

          <ul className="space-y-4">
            {points.map((point, idx) => (
              <motion.li
                key={idx}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 + idx * 0.1 }}
                className="flex items-start space-x-3"
              >
                <span
                  className="w-1.5 h-1.5 rounded-full mt-2"
                  style={{ backgroundColor: primaryColor }}
                />
                <span className="text-gray-600">{point}</span>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default FeatureCard;
