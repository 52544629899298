import headerImage from "../assets/header/header.jpeg";
import ImageHeading from "../components/ui/ImageHeading";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Layout, Image as ImageIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import LabCard from "../components/ui/Card/LabCard";
import { labCards } from "../data/labData";

// const cards = [
//   {
//     id: 1,
//     title: "Circuit Lab",
//     description: "Contemporary design meets functional aesthetics",
//     image:
//       "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&q=80&w=800",
//     category: "engineering-applied-sciences",
//   },
//   {
//     id: 2,
//     title: "Interior Spaces",
//     description: "Minimalist approach to living spaces",
//     image:
//       "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?auto=format&fit=crop&q=80&w=800",
//     category: "engineering-applied-sciences",
//   },
//   {
//     id: 3,
//     title: "Nature's Beauty",
//     description: "Capturing the essence of landscapes",
//     image:
//       "https://images.unsplash.com/photo-1472214103451-9374bd1c798e?auto=format&fit=crop&q=80&w=800",
//     category: "software-computing",
//   },
//   {
//     id: 4,
//     title: "Urban Life",
//     description: "City moments frozen in time",
//     image:
//       "https://images.unsplash.com/photo-1449824913935-59a10b8d2000?auto=format&fit=crop&q=80&w=800",
//     category: "software-computing",
//   },
// ];

const Lab2 = () => {
  const [active, setActive] = useState("engineering-applied-sciences");

  const tabs = [
    {
      id: "engineering-applied-sciences",
      label: "Engineering Applied Sciences",
      icon: Layout,
    },
    {
      id: "software-computing",
      label: "Software & Computing",
      icon: ImageIcon,
    },
  ];
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-gray-100 to-gray-200">
      <ImageHeading
        heading="ZUMS Technology Innovation Lab"
        imageUrl={headerImage}
      />

      <div className="px-4 py-12 mx-auto max-w-7xl">
        {/* Tab Navigation */}
        {/* <nav className="relative flex justify-center mb-16 space-x-8">
          {tabs.map((tab) => {
            const isActive = active === tab.id;
            const Icon = tab.icon;

            return (
              <button
                key={tab.id}
                onClick={() => setActive(tab.id)}
                className={`relative px-4 py-2 text-sm font-medium transition-colors duration-200 ${
                  isActive
                    ? "text-blue-600"
                    : "text-gray-600 hover:text-gray-900"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <Icon className="w-4 h-4" />
                  <span>{tab.label}</span>
                </div>
                {isActive && (
                  <motion.div
                    layoutId="active"
                    className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary-600"
                    initial={false}
                  />
                )}
              </button>
            );
          })}
        </nav> */}

        <div
          className={cn(
            "mb-8 flex flex-row items-center justify-center overflow-scroll [perspective:1000px] relative sm:overflow-visible h-full no-visible-scrollbar max-w-full w-full"
          )}
        >
          <div className="px-8 py-4 bg-white rounded-md shadow-xl lg:rounded-full w-fit">
            {/* {tabs.map((tab) => {
            const isActive = active === tab.id;
            const Icon = tab.icon;

            return (
              <button
                key={tab.id}
                onClick={() => setActive(tab.id)}
                className={`relative px-4 py-2 text-sm font-medium transition-colors duration-200 ${
                  isActive
                    ? "text-blue-600"
                    : "text-gray-600 hover:text-gray-900"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <Icon className="w-4 h-4" />
                  <span>{tab.label}</span>
                </div>
                {isActive && (
                  <motion.div
                    layoutId="active"
                    className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary-600"
                    initial={false}
                  />
                )}
              </button>
            );
          })} */}
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActive(tab.id)}
                // onMouseEnter={() => setHovering(true)}
                // onMouseLeave={() => setHovering(false)}
                className={cn("relative px-4 py-2 rounded-full")}
                style={{
                  transformStyle: "preserve-3d",
                }}
              >
                {active === tab.id && (
                  <motion.div
                    layoutId="clickedbutton"
                    transition={{ type: "spring", bounce: 0.3, duration: 0.6 }}
                    className={cn(
                      "absolute inset-0 bg-primary text-white rounded-full"
                    )}
                  />
                )}

                <span
                  className={`relative block ${
                    active === tab.id ? "text-white" : "text-black"
                  }`}
                >
                  {tab.label}
                </span>
              </button>
            ))}
          </div>
        </div>

        {/* Card Grid */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {/* <LabCard /> */}
          <AnimatePresence mode="wait">
            {labCards
              .filter((card) => card.category === active)
              .map((card, index) => (
                // <motion.div
                //   key={card.id}
                //   initial={{ opacity: 0, y: 20 }}
                //   animate={{ opacity: 1, y: 0 }}
                //   exit={{ opacity: 0, y: -20 }}
                //   transition={{ duration: 0.3, delay: index * 0.1 }}
                //   whileHover={{ y: -8 }}
                //   className="relative transition-all duration-300 bg-white shadow-sm group rounded-xl hover:shadow-xl"
                // >
                //   <div className="overflow-hidden rounded-t-xl">
                //     <motion.img
                //       src={card.image}
                //       alt={card.title}
                //       className="object-cover w-full h-64 transition-transform duration-300 transform group-hover:scale-110"
                //     />
                //   </div>
                //   <div className="p-6">
                //     <h3 className="mb-2 text-xl font-semibold text-gray-900">
                //       {card.title}
                //     </h3>
                //     <p className="text-gray-600">{card.description}</p>
                //   </div>
                // </motion.div>
                <LabCard key={card.id} {...card} />
              ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Lab2;
