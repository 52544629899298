export const termsData = {
  sections: [
    {
      id: "1",
      title: "Acceptance of Terms",
      content: [
        {
          type: "paragraph",
          text: "By accessing or using our website, you confirm that you accept the following terms and conditions and agree to comply with them. These terms apply to all visitors, users, and others who access or use the website.",
        },
      ],
    },
    {
      id: "2",
      title: "Changes to Terms",
      content: [
        {
          type: "paragraph",
          text: "ZNRF University of Management Sciences reserves the right to modify or update these terms at any time without prior notice. Any changes will be effective immediately upon posting on this page. Your continued website use after changes are made constitutes your acceptance of the revised terms.",
        },
      ],
    },
    {
      id: "3",
      title: "Disclaimer and Limitation of Liability",
      content: [
        {
          type: "paragraph",
          text: "The University strives to ensure the accuracy of information on the Website. If you find any inaccuracies, please notify us at [contact email], and we will correct them as soon as possible.",
        },
        {
          type: "list",
          items: [
            {
              id: "3.1",
              text: "The University strives to ensure the accuracy of information on the Website. If you find any inaccuracies, please notify us at [contact email], and we will correct them as soon as possible.",
            },
            {
              id: "3.2",
              text: "The University does not guarantee the accuracy, completeness, or timeliness of the content published on the University website. The Website is under constant development, and content may change without notice. While we make every effort to ensure accuracy, we cannot accept liability for errors or omissions.",
            },
            {
              id: "3.3",
              text: "The University makes every effort to ensure its computer infrastructure is secure and virus-free. However, we do not warrant that materials available for download are free from harmful code. You are responsible for implementing sufficient security measures to protect your devices.",
            },
            {
              id: "3.4",
              text: "The Website may contain links to third-party websites. These links are provided for convenience only, and the University does not endorse or assume responsibility for the content or practices of third-party sites.",
            },
            {
              id: "3.5",
              text: "The University is not responsible for opinions, advice, or statements made by third parties on the website. Such content does not reflect the University's views and should not be relied upon without independent verification.",
            },
          ],
        },
      ],
    },
    {
      id: "4",
      title: "Policies and Procedures",
      subsections: [
        {
          id: "4.1",
          title: "Privacy Policy",
          content: [
            {
              type: "paragraph",
              text: "ZNRF University of Management Sciences is committed to protecting the privacy and security of all personal information collected from students, faculty, staff, and visitors. This Privacy Policy outlines how we collect, use, and safeguard your data in compliance with applicable laws and regulations.",
            },
          ],
          subsections: [
            {
              id: "4.1.1",
              title: "Information We Collect",
              content: [
                {
                  type: "list",
                  items: [
                    {
                      id: "4.1.2",
                      text: "Personal identification details (e.g., name, address, student ID)",
                    },
                    {
                      id: "4.1.3",
                      text: "Academic records and enrollment information",
                    },
                    {
                      id: "4.1.4",
                      text: "Financial and payment details",
                    },
                    {
                      id: "4.1.5",
                      text: "Website usage data through cookies and analytics",
                    },
                  ],
                },
              ],
            },
            {
              id: "4.2",
              title: "How We Use Your Information",
              content: [
                {
                  type: "list",
                  items: [
                    {
                      id: "4.2.1",
                      text: "To provide and improve university services",
                    },
                    {
                      id: "4.2.2",
                      text: "To communicate important updates and announcements",
                    },
                    {
                      id: "4.2.3",
                      text: "To comply with legal and regulatory requirements",
                    },
                    {
                      id: "4.2.4",
                      text: "Confidential records may only be released with the student's written consent or as required by law.",
                    },
                    {
                      id: "4.2.5",
                      text: "Access to confidential information is restricted to authorized university officials, including:",
                      subItems: [
                        "The Registrar",
                        "Academic Advisors",
                        "Department Chairs",
                        "Deans",
                        "The Vice-Chancellor/ Pro-Vice-Chancellor",
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "5",
      title: "Advising Center",
      content: [
        {
          type: "paragraph",
          text: "The Academic Advising Center actively supports students in achieving their academic and personal goals. Our team of professional advisors guides course selection, degree planning, academic policies, and career exploration. We are committed to helping students navigate their educational journey and make informed decisions about their future.",
        },
      ],
      subsections: [
        {
          id: "5.1",
          title: "Services Include",
          content: [
            {
              type: "list",
              items: [
                {
                  id: "5.1.1",
                  text: "Personalized academic advising",
                },
                {
                  id: "5.1.2",
                  text: "Major and minor exploration",
                },
                {
                  id: "5.1.3",
                  text: "Assistance with degree requirements and graduation planning",
                },
                {
                  id: "5.1.4",
                  text: "Support for academic challenges and strategies for success",
                },
                {
                  id: "5.1.5",
                  text: "Referrals to campus resources",
                },
              ],
            },
          ],
        },
        {
          id: "5.2",
          title: "Schedule an Appointment",
          content: [
            {
              type: "paragraph",
              text: "Students can book one-on-one advising sessions through our online portal or visit our office during walk-in hours. We also offer workshops and group advising sessions throughout the academic year.",
            },
          ],
        },
      ],
    },
    {
      id: "6",
      title: "Code of Conduct",
      content: [
        {
          type: "paragraph",
          text: "ZNRF University of Management Sciences' Code of Conduct outlines the ethical and behavioral standards expected of all students, faculty, staff, and visitors. Our community is built on mutual respect, integrity, and a commitment to excellence.",
        },
      ],
      subsections: [
        {
          id: "6.1",
          title: "Core Principles",
          content: [
            {
              type: "list",
              items: [
                {
                  id: "6.1.1",
                  text: "Treat all individuals with dignity and respect.",
                },
                {
                  id: "6.1.2",
                  text: "Uphold academic honesty and avoid plagiarism or cheating.",
                },
                {
                  id: "6.1.3",
                  text: "Respect university property and resources.",
                },
                {
                  id: "6.1.4",
                  text: "Foster an inclusive environment free from discrimination and harassment.",
                },
                {
                  id: "6.1.5",
                  text: "Report violations of the Code of Conduct through the appropriate channels.",
                },
                {
                  id: "6.1.6",
                  text: "Violations of the Code of Conduct may result in disciplinary action, including expulsion or termination.",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "7",
      title: "Academic Offences",
      content: [
        {
          type: "paragraph",
          text: "Students are expected to uphold the highest standards of academic integrity. The following actions constitute academic offenses, and any student found guilty of such misconduct will be subject to disciplinary action.",
        },
      ],
      subsections: [
        {
          id: "7.1",
          title: "It is an offence for a student to",
          content: [
            {
              type: "list",
              items: [
                {
                  id: "7.1.1",
                  text: "Forge, alter, or falsify any document or evidence required for admission to ZNRF University, or to use, circulate, or rely on any such fraudulent document, whether in print or electronic form, even if the alterations were made by someone else.",
                },
                {
                  id: "7.1.2",
                  text: "Forge, alter, or falsify any academic record, or to use, circulate, or rely on any such fraudulent record, whether in print or electronic form, regardless of who made the changes.",
                },
                {
                  id: "7.1.3",
                  text: "Use or possess unauthorized aids, or obtain unauthorized assistance, during any academic examination, test, or other form of academic work.",
                },
                {
                  id: "7.1.4",
                  text: "Impersonate another person, or allow another person to impersonate them, during any academic examination, test, or other form of academic work.",
                },
                {
                  id: "7.1.5",
                  text: "Commit plagiarism by representing another person's ideas, expressions, or work as their own in any academic examination, test, or other form of academic work.",
                },
                {
                  id: "7.1.6",
                  text: "Submit academic work for credit without the explicit approval of the faculty member, if the work has been previously submitted for credit in another course or program at ZNRF University or elsewhere.",
                },
                {
                  id: "7.1.7",
                  text: "Submit academic work containing fabricated statements of fact, falsified data, or fictitious references to sources.",
                },
                {
                  id: "7.1.8",
                  text: "Engage in any form of cheating, academic dishonesty, misconduct, fraud, or misrepresentation not explicitly described above, with the intent to gain academic credit or any other academic advantage.",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "8",
      title: "Harassment and Discrimination",
      content: [
        {
          type: "paragraph",
          text: "ZNRF University is dedicated to providing a safe and inclusive environment for all members of our community. The ZNRF University adheres to its strict principles of equality as outlined below:",
        },
      ],
      subsections: [
        {
          id: "8.1",
          title: "Principles of Equality",
          content: [
            {
              type: "list",
              items: [
                {
                  id: "8.1.1",
                  text: "The University upholds the principle of equality as enshrined in the Constitution of the People's Republic of Bangladesh, ensuring inclusivity regardless of religion, caste, age, profession, or gender.",
                },
                {
                  id: "8.1.2",
                  text: "The University recognizes that harassment, in any form, has severe physical and mental consequences, deprives individuals of equal opportunities, violates the Constitution of Bangladesh, and constitutes a criminal offense.",
                },
                {
                  id: "8.1.3",
                  text: "The University is dedicated to fostering ethical standards, values, and norms, and is unwavering in its commitment to equal opportunity, transparency, and non-discrimination.",
                },
                {
                  id: "8.1.4",
                  text: "The University prioritizes the well-being of its students and staff, striving to create a supportive, dynamic, and inspiring environment that encourages productive learning and innovative thinking.",
                },
                {
                  id: "8.1.5",
                  text: "The University acknowledges that all forms of harassment, including sexual harassment, have a profoundly negative impact on individuals and are incompatible with the university’s values.",
                },
              ],
            },
          ],
        },
        {
          id: "8.2",
          title: "Disciplinary Actions",
          content: [
            {
              type: "paragraph",
              text: "If someone is found guilty of harassment or discrimination, the university will take strong measures to address the conduct, support the affected individual, and uphold the integrity of this institution. Actions that can be taken include, but are not limited to, the following:",
            },
            {
              type: "list",
              items: [
                {
                  id: "8.2.1",
                  text: "Verbal or Written Warning",
                },
                {
                  id: "8.2.2",
                  text: "Counselling",
                },
                {
                  id: "8.2.3",
                  text: "Probation",
                },
                {
                  id: "8.2.4",
                  text: "Suspension",
                },
                {
                  id: "8.2.5",
                  text: "Termination or Expulsion",
                },
                {
                  id: "8.2.6",
                  text: "Legal Action",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "9",
      title: "Health and Safety",
      content: [
        {
          type: "paragraph",
          text: "The health and safety of our students, faculty, staff, and visitors are our top priorities. ZNRF University follows proper health and safety protocols to ensure a secure campus environment.",
        },
      ],
      subsections: [
        {
          id: "9.1",
          title: "Key Initiatives",
          content: [
            {
              type: "list",
              items: [
                {
                  id: "9.1.1",
                  text: "Regular maintenance and inspection of campus facilities.",
                },
                {
                  id: "9.1.2",
                  text: "Emergency preparedness plans, including evacuation procedures and safety drills.",
                },
                {
                  id: "9.1.3",
                  text: "Access to on-campus health services, including counseling and medical care.",
                },
                {
                  id: "9.1.4",
                  text: "Compliance with govt. health regulations.",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
