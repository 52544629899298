import {
  Briefcase,
  CircleCheckBig,
  GraduationCap,
  Lightbulb,
  Settings,
  Users,
  ClipboardCheck,
  Presentation,
  BarChart3,
  FileText,
  MonitorSmartphone,
} from "lucide-react";

import bannerImage from "../../assets/centers/iqac_11zon.jpg";
import ImageHeading from "../../components/ui/ImageHeading";
import CoreActivities from "../../components/CenterOfExcellence/CoreActivities";
import KeyObjectives from "../../components/CenterOfExcellence/KeyObjectives";
import IQACMission from "../../components/CenterOfExcellence/IQACMission";
import PublicationCarousel from "../../components/CenterOfExcellence/PublicationCarousel";
// import TeamCarousel from "../../components/CenterOfExcellence/TeamCarousel";
import FeaturedEvents from "../../components/ui/FeaturedEvents";
import ResearchCTA from "../../components/ui/ResearchCTA";

const objectives = [
  {
    id: 1,
    icon: <GraduationCap className="w-6 h-6" />,
    title: "Enhance Teaching & Learning",
    description:
      "Improve the quality of education through modern methodologies and resources.",
    important: true,
  },
  {
    id: 2,
    icon: <Lightbulb className="w-6 h-6" />,
    title: "Foster Research & Innovation",
    description:
      "Encourage groundbreaking research and creative problem-solving across disciplines.",
    important: true,
  },
  {
    id: 3,
    icon: <Settings className="w-6 h-6" />,
    title: "Optimize Resources & Governance",
    description:
      "Ensure efficient institutional management and resource utilization.",
    important: true,
  },
  {
    id: 4,
    icon: <Users className="w-6 h-6" />,
    title: "Professional Development",
    description:
      "Support continuous learning and skill development for faculty and staff.",
    important: true,
  },
  {
    id: 5,
    icon: <Briefcase className="w-6 h-6" />,
    title: "Stakeholder Engagement",
    description:
      "Collaborate with students, employers, and alumni to enhance institutional quality.",
    important: true,
  },
];
const missionData = [
  {
    id: 1,
    title: "Enhancing Academic Excellence",
    description:
      "To promote academic innovation, encourage faculty development, and continuously improve teaching and learning processes to meet global standards.",
  },
  {
    id: 2,
    title: "Quality Assurance Mechanisms",
    description:
      "To develop, implement, and monitor comprehensive quality assurance processes to ensure the delivery of superior education and institutional services.",
  },
  {
    id: 3,
    title: "Research and Innovation",
    description:
      "To support a research-driven academic environment that nurtures creativity, critical thinking, and problem-solving skills.",
  },
  {
    id: 4,
    title: "Stakeholder Engagement",
    description:
      "To involve all stakeholders—students, faculty, administrative staff, and industry partners—in ensuring the university's commitment to quality and continuous improvement.",
  },
  {
    id: 5,
    title: "National Development Goals",
    description:
      "To contribute to the socio-economic development of Bangladesh by producing skilled, ethical, and socially responsible graduates equipped to address local and global challenges.",
  },
];
const activities = [
  {
    id: 1,
    icon: <ClipboardCheck className="w-6 h-6" />,
    title: "Quality Assessments",
    description:
      "Conducting regular internal and external quality evaluations.",
    important: true,
  },
  {
    id: 2,
    icon: <Presentation className="w-6 h-6" />,
    title: "Workshops & Training",
    description:
      "Organizing workshops, seminars, and training for faculty and staff.",
    important: true,
  },
  {
    id: 3,
    icon: <BarChart3 className="w-6 h-6" />,
    title: "Process Evaluation",
    description:
      "Monitoring and evaluating academic and administrative processes.",
    important: true,
  },
  {
    id: 4,
    icon: <FileText className="w-6 h-6" />,
    title: "Curriculum Development",
    description:
      "Supporting curriculum revisions and academic program reviews.",
    important: true,
  },
  {
    id: 5,
    icon: <MonitorSmartphone className="w-6 h-6" />,
    title: "Tech-Driven Learning",
    description:
      "Promoting technology integration in teaching, learning, and assessment.",
    important: true,
  },
];

const IQAC = () => {
  return (
    <div>
      <ImageHeading
        heading="Institutional Quality Assurance Cell (IQAC)"
        imageUrl={bannerImage}
      />
      <div className="container px-4 mx-auto md:px-8 ">
        <div className="gap-8 my-12 ">
          <div>
            <div>
              <h3 className="text-2xl font-bold text-black lg:text-4xl font-domine">
                Welcome to the Institutional Quality Assurance Cell (IQAC)
              </h3>
              <p className="py-6 leading-loose text-gray-700 ">
                Welcome to the Institutional Quality Assurance Cell (IQAC) of
                ZNRF University of Management Sciences. IQAC is dedicated to
                ensuring the highest standards of academic excellence, research,
                and institutional governance. The IQAC at ZNRF University serves
                as the nucleus of quality enhancement initiatives, fostering a
                culture of continuous improvement. Established to align with
                global quality assurance standards, IQAC ensures the
                university&apos;s academic and administrative frameworks meet
                international benchmarks.
                <br />
              </p>
            </div>
            <KeyObjectives objectives={objectives} />
            <CoreActivities data={activities} title={" Core Activities"} />
            <IQACMission
              title={"Ambition"}
              details={
                " We are committed to academic excellence, research, and societal impact."
              }
              data={missionData}
            />
            <div className="grid grid-cols-1 gap-8 my-12 lg:grid-cols-2">
              {/* <div className="">
                <h4 className="text-2xl font-bold text-black lg:text-3xl font-domine">
                  Ambition
                </h4>
                <p className="text-sm leading-loose text-gray-500 lg:text-base ">
                  To foster a culture of excellence in education, research, and
                  community engagement at ZNRF University by ensuring a robust
                  and sustainable quality assurance framework that aligns with
                  international standards and national aspirations.
                </p>
                <ul className="py-6 space-y-6 leading-loose text-gray-700">
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Enhancing
                    Academic Excellence: To promote academic innovation,
                    encourage faculty development, and continuously improve
                    teaching and learning processes to meet global standards.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" />
                    Quality Assurance Mechanisms
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Research
                    and Innovation
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Stakeholder
                    Engagement
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Engage
                    stakeholders, including students, employers, and alumni, in
                    quality enhancement initiatives.
                  </li>
                </ul>
              </div> */}
              <div className="">
                <h4 className="text-2xl font-bold text-black lg:text-3xl font-domine">
                  Core Values
                </h4>
                <ul className="py-6 space-y-6 leading-loose text-gray-700">
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Integrity:
                    Upholding transparency and ethical standards in all
                    institutional activities.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Excellence:
                    Striving for the highest standards in education, research,
                    and community service.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" />
                    Innovation: Encouraging creativity and adaptability to meet
                    the evolving demands of the academic and professional world.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" />{" "}
                    Collaboration: Building partnerships with national and
                    international institutions to enhance academic and research
                    opportunities.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" />{" "}
                    Inclusivity: Promoting diversity and equal opportunities for
                    all members of the university community.
                  </li>
                </ul>
              </div>
              <div className="">
                <h4 className="text-2xl font-bold text-black lg:text-3xl font-domine">
                  Achievements
                </h4>
                <ul className="py-6 space-y-6 leading-loose text-gray-700">
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Successful
                    implementation of various quality enhancement projects.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" />{" "}
                    Collaboration with national and international quality
                    assurance agencies. development.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Recognition
                    for academic excellence and innovative practices.
                  </li>
                </ul>
              </div>
            </div>
            <div className="py-12 space-y-20">
              <PublicationCarousel title={"Publication & Report"} />

              {/* <TeamCarousel title={"   IQAC Team"} /> */}
              <FeaturedEvents />
              <ResearchCTA
                title={"Contact IQAC"}
                mail={"iqac@gmail.com"}
                number={"+880187863784"}
                description={
                  " Are you ready to bring your ideas to life? IQAC welcomes students, faculty members, and external entrepreneurs who are passionate about making a difference. Join us to access the  resources, mentorship, and community you need to turn your vision into reality."
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IQAC;
