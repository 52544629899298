import React from "react";
import ImageHeading from "../components/ui/ImageHeading";
import headingImage from "../assets/research.webp";
import logo from "../assets/ZUIC-logo.png";
import bgImage from "../assets/bgimage.webp";
import WhatWeOffer from "../components/WhatWeOffer";
const Research = () => {
  return (
    <div className="min-h-screen">
      <ImageHeading heading="Research" imageUrl={headingImage} subHeading="" />
      <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-12 relative">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 flex gap-12 as">
            <div className="">
              <div className="w-4 h-1/3 bg-primary"></div>
              <div className="w-4 h-1/3 bg-primary-600"></div>
              <div className="w-4 h-1/3 bg-primary-700"></div>
            </div>

            <div className="w-full">
              <h2 className="text-5xl font-domine text-primary font-bold mb-4">
                Overview
              </h2>
              <p className="text-gray-700">
                LThe ZNRF University Incubation Center (ZUIC) is a dynamic hub
                of innovation and entrepreneurship within ZNRF University of
                Management Sciences (ZUMS). Established to foster a culture of
                creativity and enterprise, ZUIC serves as a launchpad for
                aspiring entrepreneurs, innovators, and researchers to transform
                their ideas into impactful ventures. At ZUIC, we empower
                entrepreneurs, start-ups, and spin-outs to grow, expand, and
                scale up nationally and internationally. Incubation at ZUMS is
                an inclusive community centered around flexible workspaces,
                training programs, research collaboration, and mentorship.
              </p>
            </div>
          </div>
          <div className="w-full h-auto">
            <img src={logo} alt="" className="motion-preset-slide-right" />
          </div>
        </div>
      </section>
      <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-3/5 lg:col-span-2 h-auto aspect-video overflow-hidden">
            <img
              src={headingImage}
              alt=""
              className="w-full h-full object-cover object-center"
            />
          </div>
          <div className="border-4 w-2/5 border-l-0 border-primary border-dotted p-6">
            <div className="bg-white p-8">
              <h2 className="text-3xl font-bold text-primary font-domine">
                Mission
              </h2>
              <div className="h-1 w-16 bg-primary my-4"></div>
              <p className="mt-8 text-gray-700">
                To provide comprehensive support and resources to innovators,
                enabling them to develop sustainable solutions that address
                real-world challenges
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row items-center">
          <div className="border-4 w-2/5 border-r-0 border-primary border-dotted p-6">
            <div className="bg-white p-8">
              <h2 className="text-3xl font-bold text-primary font-domine">
                Vision
              </h2>
              <div className="h-1 w-16 bg-primary my-4"></div>
              <p className="mt-8 text-gray-700">
                To establish a thriving ecosystem where academia, industry, and
                startups converge, promoting economic growth and social
                development.
              </p>
            </div>
          </div>
          <div className="w-3/5 lg:col-span-2 h-auto aspect-video overflow-hidden">
            <img
              src={headingImage}
              alt=""
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
      </section>
      <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="w-full">
          <h2 className="text-4xl font-domine text-center font-bold mb-4">
            What We Offer
          </h2>
          <div className="h-1 w-16 bg-primary my-4 mx-auto"></div>
        </div>
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="flex flex-col justify-center items-center gap-y-3 group w-full p-8 rounded-xl hover:bg-white hover:scale-105 transition-all duration-300 ease-in-out hover:drop-shadow-lg">
            <GoRocket size={64} className="group-hover:text-primary" />
            <h3 className="text-2xl font-bold group-hover:text-primary font-domine">
              Startup Support and Mentorship
            </h3>
            <p className="text-gray-700 text-center px-6 mt-4">
              Expert guidance from seasoned mentors, industry leaders, and
              academicians. End-to-end support from idea validation to business
              model development.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-3 group w-full p-8 rounded-xl hover:bg-white hover:scale-105 transition-all duration-300 ease-in-out hover:drop-shadow-lg">
            <GoRocket size={64} className="group-hover:text-primary" />
            <h3 className="text-2xl font-bold group-hover:text-primary font-domine">
              Startup Support and Mentorship
            </h3>
            <p className="text-gray-700 text-center px-6 mt-4">
              Expert guidance from seasoned mentors, industry leaders, and
              academicians. End-to-end support from idea validation to business
              model development.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-3 group w-full p-8 rounded-xl hover:bg-white hover:scale-105 transition-all duration-300 ease-in-out hover:drop-shadow-lg">
            <GoRocket size={64} className="group-hover:text-primary" />
            <h3 className="text-2xl font-bold group-hover:text-primary font-domine">
              Startup Support and Mentorship
            </h3>
            <p className="text-gray-700 text-center px-6 mt-4">
              Expert guidance from seasoned mentors, industry leaders, and
              academicians. End-to-end support from idea validation to business
              model development.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-3 group w-full p-8 rounded-xl hover:bg-white hover:scale-105 transition-all duration-300 ease-in-out hover:drop-shadow-lg">
            <GoRocket size={64} className="group-hover:text-primary" />
            <h3 className="text-2xl font-bold group-hover:text-primary font-domine">
              Startup Support and Mentorship
            </h3>
            <p className="text-gray-700 text-center px-6 mt-4">
              Expert guidance from seasoned mentors, industry leaders, and
              academicians. End-to-end support from idea validation to business
              model development.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-3 group w-full p-8 rounded-xl hover:bg-white hover:scale-105 transition-all duration-300 ease-in-out hover:drop-shadow-lg">
            <GoRocket size={64} className="group-hover:text-primary" />
            <h3 className="text-2xl font-bold group-hover:text-primary font-domine">
              Startup Support and Mentorship
            </h3>
            <p className="text-gray-700 text-center px-6 mt-4">
              Expert guidance from seasoned mentors, industry leaders, and
              academicians. End-to-end support from idea validation to business
              model development.
            </p>
          </div>
        </div> */}
        <WhatWeOffer />
      </section>
    </div>
  );
};

export default Research;
