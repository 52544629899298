/* eslint-disable react/prop-types */
import ImageHeading from "../components/ui/ImageHeading";
import libraryImage from "../assets/library-bg4.webp";
import AnimatedQuote from "../components/ui/Card/AnimatedQuoteCard";
import LibraryHours from "../components/ui/Card/libraryHoursCard";
import LibraryRules from "../components/Library/LibraryRules";
import NewArrivalSlide from "../components/Library/NewArrivalSlide";
import { CircleCheckBig } from "lucide-react";
import NumberCounter from "../components/home/NumberCounter";
import bgimage from "../assets/bgImage.webp";
import {
  LibraryBig,
  BookOpen,
  NotebookPen,
  Newspaper,
  TvMinimal,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import ResourceGrid from "../components/ui/ResourceGrid";
import RoundedButton from "../components/ui/RoundedButton";
import { useState, useEffect } from "react";
import { X } from "lucide-react";
import { Input } from "../components/ui/Input";

const BackgroundElement = ({ color, size, left, top, duration }) => (
  <motion.div
    className={`absolute rounded-full ${color} ${size}`}
    style={{ left, top }}
    animate={{
      scale: [1, 1.2, 1],
      opacity: [0.1, 0.2, 0.1],
    }}
    transition={{
      duration,
      repeat: Number.POSITIVE_INFINITY,
      ease: "easeInOut",
    }}
  />
);
const Library = () => {
  const [isMemberOpen, setIsMemberOpen] = useState(false);
  const [isRequestOpen, setIsRequestOpen] = useState(false);

  // Prevent background scrolling
  useEffect(() => {
    document.body.style.overflow = isMemberOpen ? "hidden" : "auto";
    document.body.style.overflow = isRequestOpen ? "hidden" : "auto";
  }, [isMemberOpen, isRequestOpen]);

  // book request
  const [bookEntries, setBookEntries] = useState([
    { id: 1, author: "", title: "", publisher: "", price: "", quantity: "" },
  ]);

  const addBookEntry = () => {
    const newId = bookEntries.length + 1;
    setBookEntries([
      ...bookEntries,
      {
        id: newId,
        author: "",
        title: "",
        publisher: "",
        price: "",
        quantity: "",
      },
    ]);
  };

  const removeBookEntry = (id) => {
    if (bookEntries.length > 1) {
      setBookEntries(bookEntries.filter((entry) => entry.id !== id));
    }
  };

  const updateBookEntry = (id, field, value) => {
    setBookEntries(
      bookEntries.map((entry) =>
        entry.id === id ? { ...entry, [field]: value } : entry
      )
    );
  };

  const calculateTotal = () => {
    return bookEntries
      .reduce((total, entry) => {
        const price = Number.parseFloat(entry.price) || 0;
        const quantity = Number.parseInt(entry.quantity) || 0;
        return total + price * quantity;
      }, 0)
      .toFixed(2);
  };

  return (
    <div className="min-h-screen">
      <ImageHeading heading="ZUMS Library" imageUrl={libraryImage} />
      <div className="container mx-auto sm:px-6 lg:px-8">
        {/* quote */}
        <div className="">
          <AnimatedQuote
            author={"Albert Einstein"}
            quote="The only thing that you absolutely have to know, is the location of the library."
          />
        </div>
        {/* new arrival */}
        <div className="my-8">
          <h3 className="text-2xl font-bold text-black lg:text-4xl font-domine">
            New Arrival
          </h3>
          <NewArrivalSlide />
        </div>
        {/* open time & zums library mission vision */}
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div className="col-span-2 ">
            <h3 className="text-2xl font-bold text-black lg:text-4xl font-domine">
              Welcome to ZUMS Library, Your Academic Partner
            </h3>
            <p className="py-6 leading-loose text-gray-700">
              Welcome to the “ZNRF University Library”, the heart of learning
              and research at ZNRF University of Management Sciences. This is
              your space to explore, learn, and grow. Our library is here to
              support you with a wide range of resources, from books, journals
              to digital tools and research materials.
              <br />
              <br />
              We aim to provide everything you need to succeed in your academic
              journey. Our mission is simple: to help you achieve your goals and
              make your learning experience as smooth and enriching as possible.
              <br />
              <br />
              Together, let’s turn knowledge into success!
            </p>
            <div className="grid grid-cols-1 gap-8 mt-8 lg:grid-cols-2">
              <div className="">
                <h4 className="text-2xl font-bold text-black lg:text-3xl font-domine">
                  Ambition
                </h4>
                <ul className="py-6 space-y-6 leading-loose text-gray-700">
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Ensure easy
                    access to diverse resources.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Drive
                    innovation with modern library services.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Support
                    learning, teaching, and research.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Encourage
                    lifelong learning and growth.
                  </li>
                </ul>
              </div>
              <div className="">
                <h4 className="text-2xl font-bold text-black lg:text-3xl font-domine">
                  Commitment
                </h4>
                <ul className="py-6 space-y-6 leading-loose text-gray-700">
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Be a hub
                    for academic excellence and growth.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Embrace
                    innovative technologies for learning.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Promote
                    global collaboration and knowledge access.
                  </li>
                  <li className="flex flex-row items-center gap-5 ">
                    <CircleCheckBig className=" text-primary-500" /> Cultivate
                    lifelong learning and research excellence.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="">
            <LibraryHours />
          </div>
        </div>
        {/* stats */}
        <div className="">
          <div className="grid grid-cols-1 gap-6 mt-8 sm:grid-cols-2 lg:grid-cols-5 md:gap-8">
            <div className="flex items-center gap-4 p-4 transition-colors duration-300 bg-white/50 rounded-xl hover:bg-white">
              <div className="p-4 bg-white shadow-md rounded-2xl sm:p-6">
                <LibraryBig size={24} className="text-primary" />
              </div>
              <div>
                <NumberCounter endValue={4000} duration={2000} suffix="+" />
                <p className="mt-1 text-sm text-gray-600">Books</p>
              </div>
            </div>

            <div className="flex items-center gap-4 p-4 transition-colors duration-300 bg-white/50 rounded-xl hover:bg-white">
              <div className="p-4 bg-white shadow-md rounded-2xl sm:p-6">
                <BookOpen size={24} className="text-primary" />
              </div>
              <div>
                <NumberCounter endValue={100} duration={2000} suffix="+" />
                <p className="mt-1 text-sm text-gray-600">Ebook</p>
              </div>
            </div>

            <div className="flex items-center gap-4 p-4 transition-colors duration-300 bg-white/50 rounded-xl hover:bg-white">
              <div className="p-4 bg-white shadow-md rounded-2xl sm:p-6">
                <NotebookPen size={24} className="text-primary" />
              </div>
              <div>
                <NumberCounter endValue={50} duration={2000} suffix="+" />
                <p className="mt-1 text-sm text-gray-600">Research Journals</p>
              </div>
            </div>

            <div className="flex items-center gap-4 p-4 transition-colors duration-300 bg-white/50 rounded-xl hover:bg-white">
              <div className="p-4 bg-white shadow-md rounded-2xl sm:p-6">
                <Newspaper size={24} className="text-primary" />
              </div>
              <div>
                <NumberCounter endValue={7} duration={2000} suffix="+" />
                <p className="mt-1 text-sm text-gray-600">Newspapers</p>
              </div>
            </div>
            <div className="flex items-center gap-4 p-4 transition-colors duration-300 bg-white/50 rounded-xl hover:bg-white">
              <div className="p-4 bg-white shadow-md rounded-2xl sm:p-6">
                <TvMinimal size={24} className="text-primary" />
              </div>
              <div>
                <NumberCounter endValue={95} duration={2000} suffix="+" />
                <p className="mt-1 text-sm text-gray-600">Digital Resources</p>
              </div>
            </div>
          </div>
        </div>
        {/* resources */}
        <div className="relative overflow-hidden ">
          <BackgroundElement
            color="bg-primary-100"
            size="w-64 h-64"
            left="5%"
            top="10%"
            duration={7}
          />
          <BackgroundElement
            color="bg-primary-100"
            size="w-48 h-48"
            left="80%"
            top="60%"
            duration={8}
          />
          <BackgroundElement
            color="bg-primary-100"
            size="w-32 h-32"
            left="70%"
            top="20%"
            duration={6}
          />
          <BackgroundElement
            color="bg-primary-100"
            size="w-40 h-40"
            left="20%"
            top="70%"
            duration={9}
          />
          <div className="relative z-10">
            <ResourceGrid />
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${bgimage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container grid grid-cols-1 gap-10 px-4 py-12 mx-auto lg:gap-0 sm:px-6 lg:px-8 md:grid-cols-2">
          <div className="z-20 border-gray-200 lg:border-r lg:pr-24">
            <h3 className="text-3xl font-bold text-center text-white font-domine lg:text-4xl">
              Become a Member
            </h3>
            <p className="mt-8 text-center text-gray-200">
              Discover, learn, and grow with ZUMS Library. Complete the form
              below to request your library membership and gain access to an
              extensive collection of books, journals, and digital resources.
            </p>

            <div className="flex items-center justify-center w-full mt-8 ">
              <button
                onClick={() => setIsMemberOpen(true)}
                className={`relative px-6 py-2.5 overflow-hidden font-semibold text-white group `}
              >
                {/* L-shaped border corners */}
                <div className="absolute inset-0 pointer-events-none">
                  <div className="absolute top-0 left-0 w-4 h-full border-l-[3px] border-t-[3px] border-primary"></div>
                  <div className="absolute top-0 right-0 w-4 h-4 border-r-[3px] border-t-[3px] border-primary"></div>
                  <div className="absolute bottom-0 left-0 w-4 h-full border-b-[3px] border-l-[3px] border-primary"></div>
                  <div className="absolute bottom-0 right-0 w-4 h-full border-b-[3px] border-r-[3px] border-primary"></div>
                </div>

                {/* Hover background effect */}
                <div
                  className={`absolute inset-0 group-hover:opacity-100 bg-primary transition-all scale-0 opacity-0 group-hover:scale-100 duration-500 ease-in-out origin-center 
               `}
                ></div>

                {/* Button text */}
                <span className="relative z-10 transition-colors duration-500 ease-in-out group-hover:text-white">
                  Request Membership
                </span>
              </button>
            </div>
          </div>
          <div className="z-20 lg:pl-24">
            <h3 className="text-3xl font-bold text-center text-white font-domine lg:text-4xl">
              Book Requisition (For Faculty Use)
            </h3>
            <p className="mt-8 text-center text-gray-200">
              Your recommendations create a dynamic library that supports
              excellence in teaching and research. Our library grows with your
              vision. Request books that inspire and enrich learning at ZUMS.
            </p>

            <div className="flex items-center justify-center w-full mt-8 ">
              <button
                onClick={() => setIsRequestOpen(true)}
                className={`relative px-6 py-2.5 overflow-hidden font-semibold text-white group `}
              >
                {/* L-shaped border corners */}
                <div className="absolute inset-0 pointer-events-none">
                  <div className="absolute top-0 left-0 w-4 h-full border-l-[3px] border-t-[3px] border-primary"></div>
                  <div className="absolute top-0 right-0 w-4 h-4 border-r-[3px] border-t-[3px] border-primary"></div>
                  <div className="absolute bottom-0 left-0 w-4 h-full border-b-[3px] border-l-[3px] border-primary"></div>
                  <div className="absolute bottom-0 right-0 w-4 h-full border-b-[3px] border-r-[3px] border-primary"></div>
                </div>

                {/* Hover background effect */}
                <div
                  className={`absolute inset-0 group-hover:opacity-100 bg-primary transition-all scale-0 opacity-0 group-hover:scale-100 duration-500 ease-in-out origin-center 
               `}
                ></div>

                {/* Button text */}
                <span className="relative z-10 transition-colors duration-500 ease-in-out group-hover:text-white">
                  Request Book
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto sm:px-6 lg:px-8">
        {/* rules */}
        <LibraryRules />
      </div>

      {/* form membership model */}
      <AnimatePresence>
        {isMemberOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsMemberOpen(false)}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
            aria-hidden={!isMemberOpen}
            role="dialog"
            aria-labelledby="contact-form-title"
          >
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="relative w-full max-w-md p-6 overflow-hidden bg-white rounded-lg shadow-xl"
            >
              {/* Close Button */}
              <button
                onClick={() => setIsMemberOpen(false)}
                className="absolute text-gray-500 transition-colors duration-300 top-4 right-4 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>

              <h2
                id="contact-form-title"
                className="mb-6 text-3xl font-bold text-gray-900"
              >
                Membership Form
              </h2>

              {/* Form */}
              <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
                <FormField
                  id="name"
                  label="Name"
                  type="text"
                  placeholder="Your name"
                  autoFocus={true}
                />
                <FormField
                  label="Email"
                  type="email"
                  placeholder="your@email.com"
                />
                <FormField
                  label="Student ID"
                  type="number"
                  placeholder="18103363"
                />
                <FormField
                  label="Phone Number"
                  type="text"
                  placeholder="016XXXXXXXXX"
                />

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full px-6 py-3 text-lg font-semibold text-white transition-all duration-300 rounded-md bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  Send Message
                </motion.button>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* form book request model */}
      <AnimatePresence>
        {isRequestOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsRequestOpen(false)}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
            aria-hidden={!isRequestOpen}
            role="dialog"
            aria-labelledby="contact-form-title"
          >
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="relative w-full max-w-5xl p-6 overflow-hidden bg-white rounded-lg shadow-xl"
            >
              {/* Close Button */}
              <button
                onClick={() => setIsRequestOpen(false)}
                className="absolute text-gray-500 transition-colors duration-300 top-4 right-4 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>

              <h2
                id="contact-form-title"
                className="mb-6 text-3xl font-bold text-gray-900"
              >
                Book Requisition Form
              </h2>
              {/* Form Header */}
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <Input label="Requisition No." id="requisitionNo" />
                <Input label="Date" id="date" type="date" />
              </div>

              {/* Faculty Information */}
              <div className="mb-6 space-y-4">
                <Input label="Name of Faculty/Officer" id="facultyName" />
                <Input label="Designation & Department" id="designation" />
                <Input label="Course No & Title" id="courseNo" />
              </div>

              {/* Book Entries Table */}
              <div className="mb-6 overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr>
                      <th className="p-2 text-left border">Sl.</th>
                      <th className="p-2 text-left border">Author(s)</th>
                      <th className="p-2 text-left border">Title</th>
                      <th className="p-2 text-left border">Publisher/Year</th>
                      <th className="p-2 text-left border">
                        Unit Price Tk/US$/€
                      </th>
                      <th className="p-2 text-left border">Qty.</th>
                      <th className="p-2 text-left border">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <AnimatePresence>
                      {bookEntries.map((entry, index) => (
                        <motion.tr
                          key={entry.id}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.2 }}
                        >
                          <td className="p-2 border">{index + 1}</td>
                          <td className="p-2 border">
                            <Input
                              value={entry.author}
                              onChange={(e) =>
                                updateBookEntry(
                                  entry.id,
                                  "author",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="p-2 border">
                            <Input
                              value={entry.title}
                              onChange={(e) =>
                                updateBookEntry(
                                  entry.id,
                                  "title",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="p-2 border">
                            <Input
                              value={entry.publisher}
                              onChange={(e) =>
                                updateBookEntry(
                                  entry.id,
                                  "publisher",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="p-2 border">
                            <Input
                              type="number"
                              value={entry.price}
                              onChange={(e) =>
                                updateBookEntry(
                                  entry.id,
                                  "price",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="p-2 border">
                            <Input
                              type="number"
                              value={entry.quantity}
                              onChange={(e) =>
                                updateBookEntry(
                                  entry.id,
                                  "quantity",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="p-2 border">
                            <RoundedButton
                              onClick={() => removeBookEntry(entry.id)}
                              disabled={bookEntries.length === 1}
                              variant="secondary"
                            >
                              Remove
                            </RoundedButton>
                          </td>
                        </motion.tr>
                      ))}
                    </AnimatePresence>
                  </tbody>
                </table>
              </div>

              {/* Add Book Button */}
              <div className="flex items-center justify-between mb-8">
                <RoundedButton
                  onClick={addBookEntry}
                  variant="outline"
                  className="flex items-center gap-2"
                >
                  {/* <CirclePlus className="w-4 h-4" /> */}
                  Add Book
                </RoundedButton>
                <div className="text-right">
                  <p className="font-semibold">
                    Total amount of Tk. {calculateTotal()}
                  </p>
                </div>
              </div>
              {/* Form */}
              {/* <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
                <FormField
                  id="name"
                  label="Name"
                  type="text"
                  placeholder="Your name"
                  autoFocus={true}
                />
                <FormField
                  label="Email"
                  type="email"
                  placeholder="your@email.com"
                />
                <FormField
                  label="Student ID"
                  type="number"
                  placeholder="18103363"
                />
                <FormField
                  label="Phone Number"
                  type="text"
                  placeholder="016XXXXXXXXX"
                />

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full px-6 py-3 text-lg font-semibold text-white transition-all duration-300 rounded-md bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  Send Message
                </motion.button>
              </form> */}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Library;
function FormField({ id, label, type, placeholder, autoFocus }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <label
        htmlFor={id}
        className="block mb-1 text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      {type === "textarea" ? (
        <textarea
          id={id}
          className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
          rows={4}
          placeholder={placeholder}
          autoFocus={autoFocus}
        ></textarea>
      ) : (
        <input
          id={id}
          type={type}
          className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      )}
    </motion.div>
  );
}
