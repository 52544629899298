import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import image1 from "../../../assets/paper1.jpeg";
import image2 from "../../../assets/paper2.jpeg";
import image3 from "../../../assets/paper3.jpeg";
const focusData = [
  {
    id: 1,
    slug: "technology",
    title: "Technology",
    short_description:
      "Explore cutting-edge innovations and advancements in the world of technology.",
    country: {
      image: image1,
      flag: image2,
    },
  },
  {
    id: 2,
    slug: "sustainability",
    title: "Sustainability",
    short_description:
      "Discover practices and ideas that contribute to a greener and more sustainable future.",
    country: {
      image: image2,
      flag: image1,
    },
  },
  {
    id: 3,
    slug: "agriculture",
    title: "Agriculture",
    short_description:
      "Learn about innovative methods and practices that are shaping modern agriculture.",
    country: {
      image: image1,
      flag: image3,
    },
  },
];

gsap.registerPlugin(ScrollTrigger);

const FocusCard = () => {
  return (
    <>
      <div className="grid items-center lg:w-[70vw] mx-auto justify-start grid-cols-1 gap-10 py-10 sm:grid-cols-2 lg:grid-cols-3 md:gap-9 ">
        {focusData?.map((data) => (
          <Link
            to={`/visa/${data?.id}/${data?.slug}`}
            key={data.id}
            className="relative p-0 m-0 space-y-16 overflow-hidden bg-white cursor-pointer w-[300px] mx-auto h-[375px] rounded-xl group"
          >
            <div>
              <div className="relative">
                <img
                  src={`${data?.country?.image}`}
                  alt=""
                  className="-z-10 w-full h-[220px]"
                />
                <img
                  className="absolute z-10 border-[6px] bg-white border-white bottom-3 left-[42%] w-[60px] h-[60px] rounded-full object-cover"
                  src={`${data?.country?.flag}`}
                  alt=""
                />
              </div>
            </div>
            <div className="relative z-10 space-y-3 text-center bottom-14">
              <h1 className="text-2xl font-medium">{data?.title}</h1>
              <p className="text-gray-600 max-w-[88%] mx-auto line-clamp-3">
                {data?.short_description}
              </p>
            </div>
            <div className="absolute z-0 w-[400px] h-24 bg-white -rotate-[11deg] transition-all duration-300 group-hover:rotate-0 bottom-[113px] ease-linear"></div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default FocusCard;
