import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import colorLogo from "../../assets/logoBlack.png";
import blackLogo from "../../assets/logoWhite.png";
import { AnimatedHamburgerButton } from "../ui/AnimatedHamburgerButton";
import RoundedButton from "../ui/RoundedButton";
import { navigation } from "../../data/menuData";

export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const toggleSubmenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      style={{
        zIndex: 1001,
      }}
    >
      {/* Main Header */}
      <motion.header
        initial={{ opacity: 1, y: 0 }}
        animate={
          isScrolled
            ? { y: 0, boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }
            : { y: 0 }
        }
        className={`fixed top-0 left-0 w-full z-[60] transition-all duration-300 ${
          isScrolled ? "bg-white shadow-md" : "bg-black/20"
        }`}
      >
        <div className="container px-4 mx-auto sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 md:h-20">
            {/* Logo */}
            <Link to="/" className="flex items-center space-x-2 md:space-x-4">
              <img
                src={isScrolled ? colorLogo : blackLogo}
                alt="ZUMS Logo"
                className="w-auto h-8 md:h-14"
              />
              <div className="flex-col hidden text-black sr-only sm:flex">
                <span className="text-base font-semibold leading-none md:text-xl font-domine">
                  ZUMS
                </span>
                <span className="text-xs md:text-sm text-black/80 font-manrope">
                  All for the Future Good
                </span>
              </div>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden lg:flex lg:items-center lg:gap-6">
              <RoundedButton
                href="https://103.60.173.170/zumsadmission/Admission/Home.aspx"
                external={true}
                className={`px-4 text-sm md:text-base md:px-6 ${
                  isScrolled ? "text-black" : "text-white"
                }`}
              >
                Apply Now
              </RoundedButton>
              {/* <button
                type="button"
                className="p-2 text-black transition-colors hover:text-black/80"
                aria-label="Search"
              >
                <svg
                  className="w-5 h-5 md:w-6 md:h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button> */}
              <AnimatedHamburgerButton
                active={isMobileMenuOpen}
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="w-5 h-5 md:w-6 md:h-6"
              />
            </div>

            {/* Mobile buttons */}
            <div className="flex items-center gap-1.5 md:gap-3 lg:hidden">
              <RoundedButton
                href="/apply-now"
                className={`text-xs md:text-sm px-1 md:px-3 py-1.5 ${
                  isScrolled ? "text-black" : "text-white"
                }`}
              >
                Apply Now
              </RoundedButton>
              {/* <button
                type="button"
                className="p-1.5 text-black hover:text-black/80 transition-colors"
                aria-label="Search"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button> */}
              <AnimatedHamburgerButton
                active={isMobileMenuOpen}
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="w-5 h-5"
              />
            </div>
          </div>
        </div>
      </motion.header>

      {/* Full-screen Navigation Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <>
            {/* Backdrop */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed inset-0 z-40 bg-black bg-opacity-50"
              onClick={() => setIsMobileMenuOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, x: "100%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "100%" }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              className="fixed top-0 bottom-0 h-screen overflow-y-scroll right-0 z-50 w-full bg-white shadow-lg md:w-1/3"
            >
              <div className="flex flex-col min-h-full pt-16 pb-6">
                <div className="flex-1 px-4 overflow-y-auto sm:px-6 lg:px-8">
                  <nav className="flex flex-col justify-between h-full">
                    <div className="py-8 space-y-1">
                      {navigation.map((item) => (
                        <motion.div
                          key={item.key}
                          initial={{ opacity: 0, x: 20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: 20 }}
                          transition={{ duration: 0.3 }}
                        >
                          <div>
                            <div
                              className="flex items-center justify-between py-4 text-2xl font-semibold border-b border-gray-200 cursor-pointer text-secondary hover:text-primary font-domine"
                              onClick={() => toggleSubmenu(item.name)}
                            >
                              <Link
                                onClick={() => {
                                  if (!item.submenu) setIsMobileMenuOpen(false);
                                }}
                                to={item.href}
                              >
                                {item.name}
                              </Link>

                              {item.submenu && (
                                <svg
                                  className={`w-5 h-5 transform ${
                                    activeMenu === item.name ? "rotate-90" : ""
                                  } transition-transform`}
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5l7 7-7 7"
                                  />
                                </svg>
                              )}
                            </div>
                            {item.submenu && activeMenu === item.name && (
                              <motion.div
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: "auto" }}
                                exit={{ opacity: 0, height: 0 }}
                                className="pl-4 space-y-2"
                              >
                                {item.submenu.map((sub) => (
                                  <Link
                                    key={sub.name}
                                    to={sub.href}
                                    className="block text-lg text-gray-600 hover:text-primary"
                                    onClick={() => setIsMobileMenuOpen(false)}
                                  >
                                    {sub.name}
                                  </Link>
                                ))}
                              </motion.div>
                            )}
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  </nav>
                </div>

                {/* Bottom Buttons */}
                <div className="flex justify-around gap-4 px-4 mt-auto">
                  <RoundedButton href={"/academics/course-catalog"}>
                    Course Catalog
                  </RoundedButton>
                  <RoundedButton
                    href="https://ums.zums.edu.bd"
                    external={true}
                    className="flex-1 py-2 text-sm text-center"
                  >
                    Student Portal
                  </RoundedButton>
                  <RoundedButton
                    href="https://zums.edu.bd/webmail"
                    external={true}
                    className="flex-1 py-2 text-sm text-center"
                  >
                    Webmail
                  </RoundedButton>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}
