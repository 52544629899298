"use client";
import { motion } from "framer-motion";

const gradeData = [
  { score: "80% and above", letter: "A+", point: "4.00" },
  { score: "75% to less than 80%", letter: "A", point: "3.75" },
  { score: "70% to less than 75%", letter: "A-", point: "3.50" },
  { score: "65% to less than 70%", letter: "B+", point: "3.25" },
  { score: "60% to less than 65%", letter: "B", point: "3.00" },
  { score: "55% to less than 60%", letter: "B-", point: "2.75" },
  { score: "50% to less than 55%", letter: "C+", point: "2.50" },
  { score: "45% to less than 50%", letter: "C", point: "2.25" },
  { score: "40% to less than 45%", letter: "D", point: "2.00" },
  { score: "Less than 40%", letter: "F", point: "0.00" },
];

const GradeTable = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div className="overflow-x-auto">
      <motion.table
        className="min-w-full border-collapse"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <thead>
          <tr className="bg-primary text-white">
            <th className="py-3 px-4 text-left border border-gray-300">
              Numerical Scores
            </th>
            <th className="py-3 px-4 text-left border border-gray-300">
              Letter Grade
            </th>
            <th className="py-3 px-4 text-left border border-gray-300">
              Grade Point
            </th>
          </tr>
        </thead>
        <motion.tbody>
          {gradeData.map((grade, index) => (
            <motion.tr
              key={index}
              variants={item}
              className="hover:bg-gray-50 transition-colors"
            >
              <td className="py-2 px-4 border border-gray-300">
                {grade.score}
              </td>
              <td className="py-2 px-4 border border-gray-300">
                {grade.letter}
              </td>
              <td className="py-2 px-4 border border-gray-300">
                {grade.point}
              </td>
            </motion.tr>
          ))}
        </motion.tbody>
      </motion.table>
    </div>
  );
};

export default GradeTable;
