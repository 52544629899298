import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import Header from "./Header";
import Footer from "../shared/Footer";
import SmoothScroll from "./SmoothScrolling";

export default function Layout() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />

      {/* Main content with padding for fixed header */}
      <main className="pt-0 overflow-hidden lg:pt-0">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className=""
        >
          <SmoothScroll>
            <Outlet />
            <Footer />
          </SmoothScroll>
        </motion.div>
      </main>
    </div>
  );
}
