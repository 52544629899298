/* eslint-disable react/prop-types */
import { useState } from "react";
import { ChevronDown } from "lucide-react";

export default function IQACMission({ data, title, details }) {
  return (
    <div className="py-8 md:py-12">
      <div className="mb-8 text-center">
        <h2 className="mb-4 text-4xl font-bold font-domine text-primary-900">
          {title}
        </h2>
        <p className="text-sm text-gray-600 md:text-base">{details}</p>
      </div>

      <div className="max-w-3xl mx-auto space-y-4">
        {data.map((mission) => (
          <MissionItem
            key={mission.id}
            title={mission.title}
            description={mission.description}
          />
        ))}
      </div>
    </div>
  );
}

const MissionItem = ({ title, description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={`overflow-hidden border-l-4 border-primary rounded-lg shadow-sm transition-all duration-700 ease-linear ${
        isExpanded ? "bg-gray-50" : "bg-white"
      }`}
    >
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center justify-between w-full px-4 py-4 transition-all duration-700 hover:bg-gray-50 sm:px-6"
        aria-expanded={isExpanded}
      >
        <h3 className="text-base font-semibold text-gray-800 sm:text-lg">
          {title}
        </h3>
        <div
          className={`flex-shrink-0 ml-2 text-gray-500 transition-transform duration-700 ${
            isExpanded ? "rotate-180" : "rotate-0"
          }`}
        >
          <ChevronDown className="w-5 h-5" />
        </div>
      </button>

      <div
        className={`transition-all duration-700 overflow-hidden ${
          isExpanded ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="px-6 py-4 bg-gray-50">
          <p className="text-sm text-gray-700">{description}</p>
        </div>
      </div>
    </div>
  );
};
