import { motion } from "framer-motion";
import { useRef } from "react";
import { useInView } from "framer-motion";
import logo from "../../assets/ZUIC-logo.png";
import bannerImage from "../../assets/facutlyBg.jpg";
import vision from "../../assets/business.jpg";
import RoundedButton from "../../components/ui/RoundedButton";

import JournalCarousel from "../../components/ui/JurnalCarousel";
import ResearchCTA from "../../components/ui/ResearchCTA";

import FocusCard from "../../components/home/Research/FocusCard";

import WhatWeOffer2 from "../../components/home/Research/WhatWeOffer2";

const ResearchPage3 = () => {
  const missionRef = useRef(null);
  const visionRef = useRef(null);

  const isMissionInView = useInView(missionRef, {
    once: false,
    margin: "-10%",
  });
  const isVisionInView = useInView(visionRef, { once: false, margin: "-10%" });

  return (
    <div className="min-h-screen">
      {/* Banner Section */}
      <div className="relative w-full h-[70vh] overflow-hidden">
        <motion.img
          className="absolute inset-0 object-cover w-full h-full"
          src={bannerImage}
          alt="Research Banner"
          initial={{ scale: 1 }}
          animate={{ scale: 1.25 }}
          transition={{
            duration: 15,
            ease: "linear",
          }}
        />
        <div className="absolute bottom-0 z-20 flex flex-col items-center justify-center w-full h-full px-8 pb-10 bg-black bg-opacity-50 lg:items-start lg:justify-end"></div>
        <div className="absolute bottom-0 z-20 flex flex-col items-center justify-center w-full px-8 pb-10 bg-gradient-to-t from-50%  from-[#F9FAFB] via-[#F9FAFB/50 to-transparent h-fit lg:items-start  lg:justify-end">
          <div className="container mx-auto ">
            <motion.h1
              initial={{ x: "-100%" }}
              animate={{ x: "0%" }}
              transition={{ duration: 1, ease: "linear" }}
              className="text-xl font-bold text-gray-300 lg:text-2xl"
            >
              Research to
            </motion.h1>
            <motion.p
              initial={{ x: "-100%" }}
              animate={{ x: "0%" }}
              transition={{ duration: 1, delay: 0.5, ease: "linear" }}
              className="mt-2 text-3xl font-bold text-gray-700 md:text-4xl lg:text-5xl"
            >
              Serve the world
            </motion.p>
          </div>
        </div>
        {/* logo */}
        <div className="absolute z-50 flex items-center justify-center w-full bottom-1/2 lg:bottom-0">
          <img className="w-48 md:w-72" src={logo} alt="" />
        </div>
      </div>

      {/* Content Section */}
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <p className="text-2xl text-center text-gray-800">
          The ZNRF University Incubation Center (ZUIC) at ZUMS fosters
          innovation, empowering start-ups and researchers with workspaces,
          training, mentorship, and collaboration to scale impactful ventures
          globally.
        </p>

        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
          {/* Mission Section */}
          <div className="relative min-h-[60vh] my-12 flex justify-start items-center">
            <div
              className="absolute inset-0 rounded-xl"
              style={{
                backgroundImage: `url(${bannerImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "16px",
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 rounded-xl"></div>
            </div>
            <motion.div
              ref={missionRef}
              initial={{ x: "-80%" }}
              animate={isMissionInView ? { x: "0%" } : {}}
              transition={{ duration: 1, ease: "easeIn" }}
              id="mission"
              style={{
                clipPath: "polygon(0 0, 30% 0, 100% 100%, 0% 100%)",
                borderRadius: "10px",
              }}
              className="relative flex justify-start items-center z-10 min-h-[60vh] p-4 lg:p-10 h-full w-[100vw] lg:max-w-[64vw] text-black bg-white rounded-2xl"
            >
              <div className="max-w-[50vw] lg:max-w-xs">
                <h1 className="text-xl font-semibold lg:text-3xl">Ambition</h1>
                <p className="mt-2 text-sm md:text-base">
                  To provide comprehensive support and resources to innovators,
                  enabling them to develop sustainable solutions that address
                  real-world challenges.
                </p>
                <RoundedButton
                  className={`mt-2`}
                  spanClassName={`text-primary`}
                >
                  Learn More
                </RoundedButton>
              </div>
            </motion.div>
          </div>

          {/* Vision Section */}
          <div className="relative min-h-[60vh] my-12 flex justify-end items-center">
            <div
              className="absolute inset-0 rounded-xl"
              style={{
                backgroundImage: `url(${vision})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px", // Add rounded corners for the image section
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 rounded-2xl"></div>
            </div>
            <motion.div
              ref={visionRef}
              initial={{ x: "80%" }}
              animate={isVisionInView ? { x: "0%" } : {}}
              transition={{ duration: 1, ease: "easeIn" }}
              id="vision"
              style={{
                clipPath: "polygon(70% 0, 100% 0, 100% 100%, 0% 100%)",
                borderRadius: "10px",
              }}
              className="relative flex justify-start items-center z-10 min-h-[60vh] p-4 lg:p-10 h-full w-[100vw] lg:w-[70vw] text-black bg-white rounded-xl"
            >
              <div className="max-w-[100vw] absolute right-0 p-4 lg:p-10 lg:max-w-xl">
                <h1 className="text-xl font-semibold lg:text-3xl text-end">
                  Commitment
                </h1>
                <p className="mt-2 text-sm max-w-[50vw] md:max-w-sm lg:max-w-xs text-end md:text-base">
                  To establish a thriving ecosystem where academia, industry,
                  and startups converge, promoting economic growth and social
                  development.
                </p>
                <div className="flex justify-end ">
                  <RoundedButton
                    className={`mt-2`}
                    spanClassName={`text-primary`}
                  >
                    Learn More
                  </RoundedButton>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <JournalCarousel />

      {/* <JournalCarousel2 /> */}
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div>
          <div className="w-full">
            <h2 className="mb-4 text-4xl font-bold text-center font-domine">
              Focus Area
            </h2>
            <div className="w-16 h-1 mx-auto my-4 bg-primary"></div>
          </div>
          {/* <FocusArea /> */}
          <FocusCard />
          {/* <div className="w-full">
            <h2 className="mb-4 text-4xl font-bold text-center font-domine">
              Focus Area
            </h2>
            <div className="w-16 h-1 mx-auto my-4 bg-primary"></div>
          </div>
          <FocusArea2 /> */}
        </div>
        <div className="w-full mt-12">
          <h2 className="mb-4 text-4xl font-bold text-center font-domine">
            What We Offer
          </h2>
          <div className="w-16 h-1 mx-auto my-4 bg-primary"></div>
        </div>
        <WhatWeOffer2 />
      </div>

      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <ResearchCTA />
      </div>
      {/* <ResearchContactForm /> */}
    </div>
  );
};

export default ResearchPage3;
