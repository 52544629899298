import { useEffect, useRef } from "react";
import gsap from "gsap";
import "./style.css";
import image from "../../assets/logoBlack.png";

const Loader = () => {
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const img = imageRef.current;

    if (!container || !img) return;

    const tl = gsap.timeline();
    tl.to(img, {
      opacity: 1,
      scale: 0.5,
      duration: 1,
      ease: "power2.out",
    });
    tl.to(img, {
      clipPath: "inset(0 0% 0 0)",
      duration: 1,
      left: "-20%",
      ease: "power2.inOut",
      //   left: "50%",
      //   xPercent: -50,
    }).to(".main-container", {
      y: "-100%",
      duration: 1,
      ease: "power1.out",
    });

    // Center the image container
    // tl.to(container, {
    //   left: "50%",
    //   xPercent: -50,
    //   duration: 0.5,
    //   ease: "power2.inOut",
    // });
  }, []);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen bg-white main-container">
      <div ref={containerRef} className="image-container">
        <img ref={imageRef} className="image" src={image} alt="Loading icon" />
      </div>
    </div>
  );
};

export default Loader;
