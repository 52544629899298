/* eslint-disable react/prop-types */
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { FaRegEnvelope } from "react-icons/fa";
// import { LuPhone } from "react-icons/lu";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { FaCircle } from "react-icons/fa";
import { Download } from "lucide-react";

const DepartmentFacultyCard = ({
  name,
  title,
  department,
  // expertise = [],
  email,
  // phone,
  // bio,
  image,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="relative h-full max-w-md mx-auto w-full overflow-hidden border shadow-lg rounded-2xl bg-gradient-to-br from-white via-gray-50 to-white border-white/20 backdrop-blur-sm"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{
        boxShadow:
          "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Background gradient animation */}
      <motion.div
        className="absolute inset-0 bg-gradient-to-br from-primary-100/40 via-transparent to-primary-50/40"
        animate={{
          background: isHovered
            ? [
                "linear-gradient(135deg, rgba(var(--primary-100), 0.4) 0%, transparent 50%, rgba(var(--primary-50), 0.4) 100%)",
                "linear-gradient(225deg, rgba(var(--primary-100), 0.4) 0%, transparent 50%, rgba(var(--primary-50), 0.4) 100%)",
              ]
            : "linear-gradient(135deg, rgba(var(--primary-100), 0.4) 0%, transparent 50%, rgba(var(--primary-50), 0.4) 100%)",
        }}
        transition={{ duration: 3, repeat: Infinity, repeatType: "reverse" }}
      />

      {/* Floating particles */}
      <AnimatePresence>
        {isHovered &&
          [...Array(3).keys()].map((i) => (
            <motion.div
              key={i}
              initial={{ opacity: 0, scale: 0 }}
              animate={{
                opacity: [0, 0.5, 0],
                scale: [0, 1, 0],
                y: [-20, -60],
              }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 2, delay: i * 0.2, repeat: Infinity }}
              className="absolute z-10"
              style={{
                left: `${30 + i * 30}%`,
                top: "50%",
              }}
            >
              <FaCircle className="w-2 h-2 text-primary-400/30" />
            </motion.div>
          ))}
      </AnimatePresence>

      {/* Header Section */}
      <div className="relative overflow-hidden">
        <motion.div
          className="w-full overflow-hidden aspect-square"
          whileHover={{ scale: 1.03 }}
          transition={{ duration: 0.4, ease: "easeOut" }}
        >
          <motion.div
            className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent"
            initial={{ opacity: 0 }}
            animate={{ opacity: isHovered ? 1 : 0.3 }}
            transition={{ duration: 0.4 }}
          />
          <motion.img
            src={image}
            alt={name}
            className="object-cover object-top w-full h-full transition-all duration-700"
            initial={{ scale: 1.1 }}
            animate={{
              scale: isHovered ? 1.15 : 1.1,
              filter: isHovered
                ? "brightness(0.9) contrast(1.1)"
                : "brightness(1) contrast(1)",
            }}
            transition={{ duration: 0.7 }}
          />
        </motion.div>
      </div>

      {/* Content Section */}
      <motion.div
        className="relative z-10 p-6 space-y-4"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <motion.div className="space-y-1">
          <motion.h2
            className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary-600 to-primary-400"
            whileHover={{ scale: 1.01 }}
            transition={{ type: "spring", stiffness: 400 }}
          >
            {name}
          </motion.h2>
          <motion.p
            className="font-medium text-gray-600"
            initial={{ x: -10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {title}
          </motion.p>
        </motion.div>

        <motion.div
          className="flex items-center p-2 space-x-2 rounded-lg bg-gradient-to-r from-primary-50 to-transparent"
          whileHover={{ x: 5, backgroundColor: "rgba(var(--primary-50), 0.3)" }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <HiOutlineAcademicCap className="w-5 h-5 text-primary-600" />
          <span className="text-gray-700">{department}</span>
        </motion.div>

        {/* {expertise.length > 0 && (
          <motion.div
            className="space-y-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <h3 className="text-sm font-semibold text-gray-800">Expertise</h3>
            <div className="flex flex-wrap gap-2">
              {expertise.map((area, index) => (
                <motion.span
                  key={index}
                  className="relative px-3 py-1 overflow-hidden text-sm rounded-full group"
                  style={{
                    background: "linear-gradient(135deg, rgba(var(--primary-100), 0.5), rgba(var(--primary-50), 0.5))",
                    border: "1px solid rgba(var(--primary-200), 0.3)",
                  }}
                  whileHover={{ scale: 1.05, y: -2 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <motion.span
                    className="absolute inset-0 opacity-0 bg-gradient-to-r from-primary-100/50 to-primary-200/50 group-hover:opacity-100"
                    transition={{ duration: 0.3 }}
                  />
                  <span className="relative z-10 text-primary-700">{area}</span>
                </motion.span>
              ))}
            </div>
          </motion.div>
        )} */}

        <motion.div
          className="pt-4 space-y-2 border-t border-primary-100/30"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <motion.a
            href={`mailto:${email}`}
            className="relative flex items-center px-3 py-2 space-x-2 overflow-hidden text-gray-600 rounded-lg group"
            whileHover={{ x: 5 }}
          >
            <motion.div
              className="absolute inset-0 transition-all duration-300 bg-primary-50/0 group-hover:bg-primary-50/50"
              style={{ borderRadius: "inherit" }}
            />
            <motion.div
              whileHover={{ rotate: 15 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <FaRegEnvelope className="w-5 h-5 transition-colors text-primary-500 group-hover:text-primary-600" />
            </motion.div>
            <span className="relative z-10 text-sm transition-colors group-hover:text-primary-600">
              {email}
            </span>
          </motion.a>

          {/* {phone && (
            <motion.a
              href={`tel:${phone}`}
              className="relative flex items-center px-3 py-2 space-x-2 overflow-hidden text-gray-600 rounded-lg group"
              whileHover={{ x: 5 }}
            >
              <motion.div
                className="absolute inset-0 transition-all duration-300 bg-primary-50/0 group-hover:bg-primary-50/50"
                style={{ borderRadius: "inherit" }}
              />
              <motion.div
                whileHover={{ rotate: 15 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <LuPhone className="w-5 h-5 transition-colors text-primary-500 group-hover:text-primary-600" />
              </motion.div>
              <span className="relative z-10 text-sm transition-colors group-hover:text-primary-600">
                {phone}
              </span>
            </motion.a>
          )} */}
        </motion.div>
        <motion.div
          className="flex items-center justify-center w-full pt-4 space-y-2 border-t border-primary-100/30"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          {/* <motion.a
            
            className="relative flex items-center px-3 py-2 space-x-2 overflow-hidden text-gray-600 rounded-lg group"
            whileHover={{ x: 5 }}
          >
            <motion.div
              className="absolute inset-0 transition-all duration-300 bg-primary-50/0 group-hover:bg-primary-50/50"
              style={{ borderRadius: "inherit" }}
            />
            <motion.div
              whileHover={{ rotate: 15 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Download className="w-5 h-5 transition-colors text-primary-500 group-hover:text-primary-600" />
            </motion.div>
            <span className="relative z-10 text-sm transition-colors group-hover:text-primary-600">
              Download Cv
            </span>
          </motion.a> */}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default DepartmentFacultyCard;
