/* eslint-disable react/prop-types */

import { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { useLocation } from "react-router-dom";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import ScrollSmoother from "gsap/dist/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

export default function SmoothScroll({ children, disableSmoothScroll }) {
  const wrapper = useRef(null);
  const content = useRef(null);
  const location = useLocation();
  const [isDesktop, setIsDesktop] = useState(false);

  // Ensure proper client-side rendering
  useEffect(() => {
    const checkIsDesktop = () => {
      setIsDesktop(window.innerWidth > 1024); // Adjust as needed for desktop size
    };

    // Check initial size and add resize listener
    checkIsDesktop();
    window.addEventListener("resize", checkIsDesktop);

    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, []);

  useEffect(() => {
    if (
      isDesktop &&
      wrapper.current &&
      content.current &&
      !disableSmoothScroll
    ) {
      const smoother = ScrollSmoother.create({
        wrapper: wrapper.current,
        content: content.current,
        smooth: 0,
        effects: true,
      });

      ScrollTrigger.refresh();

      if (location.hash) {
        setTimeout(() => {
          smoother.scrollTo(location.hash, true);
        }, 400);
      } else {
        smoother.scrollTo(0, true);
      }

      return () => {
        smoother.kill();
      };
    }
  }, [location, isDesktop, disableSmoothScroll]); // Trigger when location or disableSmoothScroll changes

  return (
    <div id="smooth-wrapper" ref={wrapper}>
      <div id="smooth-content" ref={content}>
        {children}
      </div>
    </div>
  );
}
