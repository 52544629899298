import { timelineData } from "../../data/timelineData";
import { Timeline } from "../ui/timeline";

export function VcBiography() {
  return (
    <div className="w-full">
      <Timeline
        data={timelineData}
        title="Meet Dr. M. Zubaidur Rahman"
        subtitle="Dr. Rahman's distinguished career reflects his dedication to education, innovation, and fostering global partnerships, embodying the mission and values of ZUMS."
      />
    </div>
  );
}
