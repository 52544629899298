import PodCastEpisodes from "../components/podcast/PodCastEpisodes";
import PodCTA from "../components/podcast/PodCTA";
import PodExperience from "../components/podcast/PodExperience";
import PodHero from "../components/podcast/PodHero";
import PodStats from "../components/podcast/PodStats";

const ZUMSVoices = () => {
  return (
    <div className="container px-4 py-6 mx-auto sm:py-12 md:pt-24 lg:pt-32 sm:px-6 lg:px-8">
      <PodHero />
      <PodStats />
      <PodExperience />
      <PodCastEpisodes />
      <PodCTA />
    </div>
  );
};

export default ZUMSVoices;
