import vc from "../assets/vcBanner.png";
import vcPhone from "../assets/vcBannerM.png";
import { VcBiography } from "../components/shared/VcBiography";
// import OfficeVcDetails from "../components/office/OfficeVcDetails";

const OfficeOfTheVc = () => {
  return (
    <div>
      {/* banner */}
      <div className="relative w-full z-10  h-screen  lg:h-[95vh]">
        <img
          src={vc || "/placeholder.svg"}
          alt="VC Banner"
          className="absolute inset-0 hidden object-cover w-full h-full md:block"
        />
        <img
          src={vcPhone || "/placeholder.svg"}
          alt="VC Banner"
          className="absolute inset-0 block object-cover w-full h-full md:hidden"
        />
        <div className="absolute inset-0 z-10 w-full h-full bg-black/20"></div>
        <div className="container relative z-20 flex items-center justify-start w-full h-full p-8 mx-auto">
          {/* Text content */}
          <div className="text-white">
            <h1 className="max-w-2xl text-4xl font-bold font-domine md:text-5xl lg:text-7xl">
              Let&#39;s do big things,{" "}
              <span className=" bg-primary">together</span>
            </h1>
            <p className="mt-4 text-2xl font-semibold lg:mt-5">
              — Vice Chancellor, ZNRF University of Management Sciences (ZUMS)
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <VcBiography />
      </div>
    </div>
  );
};

export default OfficeOfTheVc;
