import bannerImage from "../assets/centers/clll_11zon.jpg";
import ImageHeading from "../components/ui/ImageHeading";
import { termsData } from "../data/termsData";

const PrivacyPolicy = () => {
  // Helper function to render content based on type
  const renderContent = (content) => {
    return content.map((item, index) => {
      if (item.type === "paragraph") {
        return (
          <p key={index} className="mb-4">
            {item.text}
          </p>
        );
      } else if (item.type === "list") {
        return (
          <ul key={index} className="pl-6 mb-4 space-y-2 list-none">
            {item.items.map((listItem, listIndex) => (
              <li key={listIndex}>
                {listItem.id && (
                  <span className="font-medium">{listItem.id}.</span>
                )}{" "}
                {listItem.text}
                {listItem.subItems && (
                  <ul className="pl-12 mt-2 list-disc">
                    {listItem.subItems.map((subItem, subIndex) => (
                      <li key={subIndex}>{subItem}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        );
      }
      return null;
    });
  };

  // Helper function to render subsections
  const renderSubsections = (subsections) => {
    return subsections.map((subsection) => (
      <div key={subsection.id}>
        <h3 className="mb-2 text-xl font-semibold font-domine text-primary">
          {subsection.id} {subsection.title}
        </h3>
        {subsection.content && renderContent(subsection.content)}
        {subsection.subsections && renderSubsections(subsection.subsections)}
      </div>
    ));
  };

  return (
    <div className="bg-gray-50">
      <ImageHeading heading="Terms & Conditions" imageUrl={bannerImage} />
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="space-y-8">
          {termsData.sections.map((section) => (
            <section key={section.id}>
              <h2 className="mb-4 text-2xl font-bold font-domine text-primary">
                {section.id}. {section.title}
              </h2>
              {section.content && renderContent(section.content)}
              {section.subsections && renderSubsections(section.subsections)}
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
