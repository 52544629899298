import BarristerMdShafiurRahman from "../assets/bot/BarristerMdShafiurRahman.jpg";
import BarristerQuamrunNaharMahmud from "../assets/bot/BarristerQuamrunNaharMahmud.jpg";
import MdZulfikarAliSikder from "../assets/bot/MdZulfikarAli Sikder.jpg";
import MrEkramAhmed from "../assets/bot/MrEkramAhmed.jpg";
import MrMdObaidurRahman from "../assets/bot/MrMdObaidurRahman.jpg";
import MrZahedulIslamMahmud from "../assets/bot/MrZahedulIslamMahmud.jpg";
import NargisRafiqaRahman from "../assets/bot/NargisRafiqaRahman.jpg";
import ProfDrMZubaidurRahman from "../assets/bot/ProfDrMZubaidurRahman.jpg";
import RabeyaZahed from "../assets/bot/RabeyaZahed.jpeg";
import SaimaKhatun from "../assets/bot/SaimaKhatun.jpg";
import ZakirAhmedKhan from "../assets/bot/ZakirAhmedKhan.jpg";

export const boardOfTrusteesData = [
  {
    image: ZakirAhmedKhan,
    title: "Chairman",
    name: "Zakir Ahmed Khan",
    bio: "Ph.D. in Business Administration from Harvard University",
    details: {
      email: "president@zums.edu",
      experience: "25+ years in Higher Education",
      expertise: ["Educational Leadership", "Strategic Planning", "Innovation"],
      achievements: [
        "Led multiple successful university accreditations",
        "Published author in educational leadership",
        "Awarded Excellence in Education Leadership 2023",
      ],
    },
  },
  {
    image: NargisRafiqaRahman,
    title: "Vice Chairman",
    name: "Nargis Rafiqa Rahman",
    bio: "Ph.D. in Educational Management from Stanford University",
    details: {
      email: "vicepresident@zums.edu",
      experience: "20+ years in Academic Administration",
      expertise: ["Academic Affairs", "Curriculum Development", "Research"],
      achievements: [
        "Established 3 new academic programs",
        "Led international academic partnerships",
        "Research excellence award recipient",
      ],
    },
  },
  {
    image: ProfDrMZubaidurRahman,
    title: "Member",
    name: "Prof. Dr. M. Zubaidur Rahman",
    bio: "Ph.D. in Educational Policy from Yale University",
    details: {
      email: "secretary@zums.edu",
      experience: "18+ years in Academic Administration",
      expertise: [
        "Policy Development",
        "Administrative Leadership",
        "Governance",
      ],
      achievements: [
        "Streamlined administrative processes",
        "Implemented digital transformation initiatives",
        "Excellence in Administration Award 2022",
      ],
    },
  },
  {
    image: MrEkramAhmed,
    title: "Member",
    name: "Mr. Ekram Ahmed",
    bio: "MBA in Finance from MIT",
    details: {
      email: "treasurer@zums.edu",
      experience: "15+ years in Educational Finance",
      expertise: ["Financial Management", "Budget Planning", "Risk Management"],
      achievements: [
        "Optimized university financial operations",
        "Secured major educational grants",
        "Financial Excellence Award 2023",
      ],
    },
  },
  {
    image: MdZulfikarAliSikder,
    title: "Member",
    name: "Md. Zulfikar Ali Sikder",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: [
        "Program Management",
        "Student Affairs",
        "Educational Technology",
      ],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
  {
    image: BarristerMdShafiurRahman,
    title: "Member",
    name: "Barrister Md. Shafiqur Rahman",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: [
        "Program Management",
        "Student Affairs",
        "Educational Technology",
      ],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
  {
    image: BarristerQuamrunNaharMahmud,
    title: "Member",
    name: "Barrister Quamrun Nahar Mahmud",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: [
        "Program Management",
        "Student Affairs",
        "Educational Technology",
      ],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
  {
    image: MrMdObaidurRahman,
    title: "Member",
    name: "Mr. Md. Obaidur Rahman",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: [
        "Program Management",
        "Student Affairs",
        "Educational Technology",
      ],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
  {
    image: MrZahedulIslamMahmud,
    title: "Member",
    name: "Mr. Zahedul Islam Mahmud",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: [
        "Program Management",
        "Student Affairs",
        "Educational Technology",
      ],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
  {
    image: RabeyaZahed,
    title: "Member",
    name: "Rabeya Zahed",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: [
        "Program Management",
        "Student Affairs",
        "Educational Technology",
      ],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
  {
    image: SaimaKhatun,
    title: "Member",
    name: "Saima Khatun",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: [
        "Program Management",
        "Student Affairs",
        "Educational Technology",
      ],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
];
