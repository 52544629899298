/* eslint-disable react/prop-types */
"use client";

import { useScroll, useTransform, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export const Timeline = ({ data, title, subtitle }) => {
  const ref = useRef(null);
  const containerRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setHeight(rect.height);
    }
  }, []);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start 10%", "end 50%"],
  });

  const heightTransform = useTransform(scrollYProgress, [0, 1], [0, height]);
  const opacityTransform = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  return (
    <div className="w-full font-sans md:px-10" ref={containerRef}>
      <div className="px-4 py-20 mx-auto max-w-7xl md:px-8 lg:px-10">
        <h2 className="max-w-4xl mb-4 text-4xl font-semibold text-primary font-domine md:text-5xl lg:text-6xl">
          {title}
        </h2>
        <p className="max-w-2xl text-sm md:text-base lg:text-lg text-neutral-700">
          {subtitle}
        </p>
      </div>

      <div ref={ref} className="relative pb-20 mx-auto max-w-7xl">
        {data.map((item, index) => (
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            viewport={{ once: true, amount: 0.2 }}
            key={index}
            className="flex justify-start pt-10 md:pt-20 md:gap-10"
          >
            <div className="sticky z-40 flex flex-col items-center self-start max-w-xs md:flex-row top-40 lg:max-w-sm md:w-full">
              <div className="absolute flex items-center justify-center w-10 h-10 bg-white rounded-full left-3 md:left-3">
                <div className="w-4 h-4 p-2 border rounded-full bg-primary-500 border-primary" />
              </div>
              <h3 className="hidden text-xl font-bold font-domine md:block md:pl-20 md:text-5xl text-neutral-500">
                {item.year}
              </h3>
            </div>

            <div className="relative w-full pl-20 pr-4 md:pl-4">
              <h3 className="block mb-4 text-2xl font-bold text-left md:hidden text-neutral-500">
                {item.year}
              </h3>
              <h3 className="pb-3 text-xl font-semibold md:text-2xl lg:text-3xl font-domine xl:text-4xl text-primary">
                {item.title}
              </h3>
              <p className="mb-4 text-base font-normal text-neutral-800 md:text-lg lg:text-xl">
                {item.description}
              </p>
              {item.details && (
                <ul className="pl-5 text-base font-normal list-disc text-neutral-800 md:text-lg lg:text-xl">
                  {item.details.map((detail, idx) => (
                    <li key={idx}>{detail}</li>
                  ))}
                </ul>
              )}
            </div>
          </motion.div>
        ))}
        <div
          style={{
            height: height + "px",
          }}
          className="absolute md:left-8 left-8 top-0 overflow-hidden w-[2px] bg-[linear-gradient(to_bottom,var(--tw-gradient-stops))] from-transparent from-[0%] via-neutral-200 dark:via-neutral-700 to-transparent to-[99%]  [mask-image:linear-gradient(to_bottom,transparent_0%,black_10%,black_90%,transparent_100%)]"
        >
          <motion.div
            style={{
              height: heightTransform,
              opacity: opacityTransform,
            }}
            className="absolute inset-x-0 top-0  w-[2px] bg-gradient-to-t from-purple-500 via-blue-500 to-transparent from-[0%] via-[10%] rounded-full"
          />
        </div>
      </div>
    </div>
  );
};
