/* eslint-disable react/prop-types */
import { motion, AnimatePresence } from "framer-motion";

export default function KeyObjectives({ objectives }) {
  const ObjectiveCard = ({ objective }) => (
    <motion.div
      key={objective.id}
      whileInView={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 20 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
      className={`relative overflow-hidden rounded-xl
        bg-white shadow-lg
        basis-full md:basis-[calc(50%-12px)] lg:basis-[calc(33.333%-16px)]
        
        ${
          objective.important
            ? "border-l-4 border-primary-500 ring-1 ring-primary-100"
            : "border-l-4 border-transparent"
        }
        p-6 transform transition-all duration-300
        hover:shadow-xl hover:shadow-primary-100
        group`}
    >
      <div
        className={`absolute inset-0 bg-gradient-to-br 
        ${
          objective.important
            ? "from-primary-50/50 to-transparent"
            : "from-primary-50/30 to-transparent"
        }
        opacity-0 group-hover:opacity-100 
        transition-opacity duration-300`}
      />
      <div className="relative z-10">
        <div
          className={`${
            objective.important ? "text-primary-500" : "text-blue-400"
          } mb-4 transform transition-transform duration-300
          group-hover:scale-110 group-hover:rotate-3`}
        >
          {objective.icon}
        </div>
        <h3 className="mb-2 text-xl font-semibold text-gray-900">
          {objective.title}
        </h3>
        <p className="text-sm leading-relaxed text-gray-600">
          {objective.description}
        </p>
      </div>
    </motion.div>
  );

  return (
    <div className="py-6 md:py-12">
      <div className="mb-12 text-center">
        <h2 className="mb-4 text-4xl font-bold font-domine text-primary-900">
          Key Objectives
        </h2>
      </div>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ staggerChildren: 0.1 }}
          className="flex flex-wrap justify-center gap-6 px-4 md:px-6 lg:px-8"
        >
          {objectives?.map((objective) => (
            <ObjectiveCard key={objective.id} objective={objective} />
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
