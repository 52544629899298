import DrChristineIWallich from "../assets/advisoryBoard/DrChristineIWallich.jpg";
import DrAlaknandapatel from "../assets/advisoryBoard/DrAlaknandapatel.jpg";
import DrMohammedFarashuddin from "../assets/advisoryBoard/DrMohammedFarashuddin.jpg";
import ProfDrSerajulIslamChowdhury from "../assets/advisoryBoard/ProfDrSerajulIslamChowdhury.jpg";
import ProfessorBernadineVanGramberg from "../assets/advisoryBoard/ProfessorBernadineVanGramberg.jpg";
import ProfessorJulianTeicher from "../assets/advisoryBoard/ProfessorJulianTeicher.jpg";
import ProfessorRobertScapens from "../assets/advisoryBoard/ProfessorRobertScapens.jpg";
import QuentinPeel from "../assets/advisoryBoard/QuentinPeel.jpg";


export const advisoryBoardData = [
  {
    image: ProfDrSerajulIslamChowdhury,
    title: "Member",
    name: "Prof Dr Serajul Islam Chowdhury",
    bio: "Ph.D. in Business Administration from Harvard University",
    details: {
      email: "president@zums.edu",
      experience: "25+ years in Higher Education",
      expertise: ["Educational Leadership", "Strategic Planning", "Innovation"],
      achievements: [
        "Led multiple successful university accreditations",
        "Published author in educational leadership",
        "Awarded Excellence in Education Leadership 2023",
      ],
    },
  },
  {
    image: DrMohammedFarashuddin,
    title: "Member",
    name: "Dr Mohammed Farashuddin",
    bio: "Ph.D. in Educational Management from Stanford University",
    details: {
      email: "vicepresident@zums.edu",
      experience: "20+ years in Academic Administration",
      expertise: ["Academic Affairs", "Curriculum Development", "Research"],
      achievements: [
        "Established 3 new academic programs",
        "Led international academic partnerships",
        "Research excellence award recipient",
      ],
    },
  },
  {
    image: ProfessorRobertScapens,
    title: "Member",
    name: "Professor Dr. Robert Scapens",
    bio: "Ph.D. in Educational Policy from Yale University",
    details: {
      email: "secretary@zums.edu",
      experience: "18+ years in Academic Administration",
      expertise: ["Policy Development", "Administrative Leadership", "Governance"],
      achievements: [
        "Streamlined administrative processes",
        "Implemented digital transformation initiatives",
        "Excellence in Administration Award 2022",
      ],
    },
  },
  {
    image: ProfessorJulianTeicher,
    title: "Member",
    name: "Professor Dr. Julian Teicher",
    bio: "MBA in Finance from MIT",
    details: {
      email: "treasurer@zums.edu",
      experience: "15+ years in Educational Finance",
      expertise: ["Financial Management", "Budget Planning", "Risk Management"],
      achievements: [
        "Optimized university financial operations",
        "Secured major educational grants",
        "Financial Excellence Award 2023",
      ],
    },
  },
  {
    image: ProfessorBernadineVanGramberg,
    title: "Member",
    name: "Professor Dr. Bernadine Van Gramberg",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: ["Program Management", "Student Affairs", "Educational Technology"],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
  {
    image: DrChristineIWallich,
    title: "Member",
    name: "Dr. Christine I Wallich",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: ["Program Management", "Student Affairs", "Educational Technology"],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
  {
    image: QuentinPeel,
    title: "Member",
    name: "Dr. Quentin Peel",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: ["Program Management", "Student Affairs", "Educational Technology"],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
  {
    image: DrAlaknandapatel,
    title: "Member",
    name: "Dr. Alaknanda Patel",
    bio: "Ph.D. in Educational Technology from UC Berkeley",
    details: {
      email: "coordinator@zums.edu",
      experience: "12+ years in Academic Coordination",
      expertise: ["Program Management", "Student Affairs", "Educational Technology"],
      achievements: [
        "Launched innovative student support programs",
        "Improved student satisfaction rates by 40%",
        "Best Coordinator Award 2023",
      ],
    },
  },
];
