/* eslint-disable react/prop-types */
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// book image
import member1 from "../../assets/rPaper1.webp";
import member2 from "../../assets/rPaper2.jpg";
import member3 from "../../assets/rPaper3.jpg";
import member4 from "../../assets/rPaper4.jpg";
import member5 from "../../assets/rPaper6.jpg";
import member6 from "../../assets/rPaper8.webp";
import BookFlipCard from "../ui/Card/BookFlipCard";

const iqacMembers = [
  {
    id: 1,
    title: "Dr. Ahsan Rahman",
    author: "Head of IQAC",
    image: member1, // Replace with actual image import
  },
  {
    id: 2,
    title: "Prof. Nafisa Karim",
    author: "Deputy Head",
    image: member2,
  },
  {
    id: 3,
    title: "Dr. Tanvir Hasan",
    author: "Senior Quality Assurance Officer",
    image: member3,
  },
  {
    id: 4,
    title: "Ms. Farzana Ahmed",
    author: "Training & Development Coordinator",
    image: member4,
  },
  {
    id: 5,
    title: "Engr. Shakib Hossain",
    author: "Technology & Innovation Lead",
    image: member5,
  },
  {
    id: 6,
    title: "Md. Faisal Alam",
    author: "Research & Assessment Officer",
    image: member6,
  },
];

const PublicationCarousel = ({ title }) => {
  return (
    <div className="w-full ">
      <h2 className="mb-4 text-4xl font-bold text-center font-domine text-primary-900">
        {title}
      </h2>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{ clickable: true }}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
          1280: {
            slidesPerView: 5,
          },
        }}
        className="mySwiper"
      >
        {iqacMembers.map((book) => (
          <SwiperSlide key={book.id}>
            <BookFlipCard book={book} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PublicationCarousel;
