/* eslint-disable react/prop-types */
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";
import DepartmentFacultyCard from "../../ui/Card/DepartmentFacultyCard";

const FacultyModal = ({ faculty, onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 mt-16"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        onClick={(e) => e.stopPropagation()}
        className="bg-white rounded-xl shadow-2xl max-w-2xl w-full max-h-[80vh] overflow-y-auto"
      >
        <div className="relative">
          <button
            onClick={onClose}
            className="absolute p-2 transition-colors rounded-full right-4 top-4 bg-white/80 backdrop-blur-sm hover:bg-white"
          >
            <X className="w-5 h-5" />
          </button>
          <div className="w-full aspect-square overflow-hidden">
            <img
              src={faculty.image}
              alt={faculty.name}
              className="object-cover w-full h-full"
            />
          </div>
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-800">{faculty.name}</h2>
            <p className="font-medium text-primary-600">{faculty.title}</p>
            <p className="mb-4 text-gray-600">{faculty.department}</p>

            <div className="space-y-6">
              <div>
                <h3 className="mb-2 text-lg font-semibold text-gray-800">
                  Contact
                </h3>
                <p className="text-gray-600">Email: {faculty.email}</p>
                <p className="text-gray-600">Phone: {faculty.phone}</p>
              </div>

              <div>
                <h3 className="mb-2 text-lg font-semibold text-gray-800">
                  Bio
                </h3>
                <p className="text-gray-600">{faculty.bio}</p>
              </div>

              <div>
                <h3 className="mb-2 text-lg font-semibold text-gray-800">
                  Areas of Expertise
                </h3>
                <ul className="space-y-1 text-gray-600 list-disc list-inside">
                  {faculty.expertise.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const FacultyCSE = ({ data, onFacultySelect }) => {
  return (
    <div className="p-8">
      <h2 className="mb-6 text-4xl font-bold text-center font-domine">
        Faculty
      </h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <AnimatePresence>
          {data?.map((faculty) => (
            <div
              key={faculty.id}
              onClick={() => onFacultySelect(faculty)}
              className="cursor-pointer"
            >
              <DepartmentFacultyCard
                name={faculty?.name}
                title={faculty?.title}
                department={faculty?.department}
                expertise={faculty?.expertise}
                email={faculty?.email}
                phone={faculty?.phone}
                bio={faculty?.bio}
                image={faculty?.image}
              />
            </div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

// Export FacultyModal separately so it can be used in the parent component
export { FacultyModal };
export default FacultyCSE;
