import React from "react";
import { motion } from "framer-motion";
const CareerHeader = () => {
  return (
    <section className=" py-12">
      <div className="container mx-auto px-4">
        <motion.div
          className="space-y-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
        >
          <div className="max-w-4xl">
            <h2 className="text-4xl font-bold font-domine mb-6">
              Grow With us
            </h2>
            <p className="text-lg mb-8">
              Here you will find career connections and resources related to
              University of Houston professional staff and faculty opportunities
              through ZUMS Human Resources and information about University
              student and alumni employment through Career Services.
            </p>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default CareerHeader;
