import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  BookOpen,
  Clock,
  VolumeX,
  Smartphone,
  Coffee,
  Users,
  Trash2,
  BookMarked,
} from "lucide-react";

const rules = [
  {
    id: 1,
    icon: <BookOpen className="w-6 h-6" />,
    title: "Handle Books with Care",
    description: "Please use bookmarks and keep books away from water or food.",
    important: true,
  },
  {
    id: 2,
    icon: <Clock className="w-6 h-6" />,
    title: "Return on Time",
    description: "Books must be returned or renewed by their due date.",
    important: true,
  },
  {
    id: 3,
    icon: <VolumeX className="w-6 h-6" />,
    title: "Maintain Silence",
    description: "Keep noise levels to a minimum to respect other readers.",
    important: true,
  },
  {
    id: 4,
    icon: <Smartphone className="w-6 h-6" />,
    title: "Phone Policy",
    description: "Phones should be on silent mode. Take calls outside.",
    important: true,
  },
  {
    id: 5,
    icon: <Coffee className="w-6 h-6" />,
    title: "No Food or Drinks",
    description: "Food and beverages are not allowed near books.",
    important: true,
  },
  {
    id: 6,
    icon: <Users className="w-6 h-6" />,
    title: "Respect Others",
    description: "Be considerate of other library users and staff.",
    important: true,
  },
  {
    id: 7,
    icon: <Trash2 className="w-6 h-6" />,
    title: "Keep Clean",
    description: "Help maintain a clean and tidy environment.",
    important: true,
  },
  {
    id: 8,
    icon: <BookMarked className="w-6 h-6" />,
    title: "Book Reservations",
    description: "Reserve books in advance for guaranteed availability.",
    important: true,
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 24,
    },
  },
};

export default function LibraryRules() {
  return (
    <div className="  py-6 md:py-12">
      <div className="">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold font-domine text-primary-900 mb-4">
            Library Rules & Guidelines
          </h2>
        </div>

        <AnimatePresence>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="show"
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
          >
            {rules.map((rule) => (
              <motion.div
                key={rule.id}
                variants={itemVariants}
                whileHover={{
                  scale: 1.02,
                  transition: { duration: 0.2 },
                }}
                className={`
                  relative overflow-hidden rounded-xl
                  bg-white shadow-lg
                  ${
                    rule.important
                      ? "border-l-4 border-primary-500 ring-1 ring-primary-100"
                      : "border-l-4 border-transparent"
                  }
                  p-6 transform transition-all duration-300
                  hover:shadow-xl hover:shadow-primary-100
                  group
                `}
              >
                <div
                  className={`
                  absolute inset-0 bg-gradient-to-br 
                  ${
                    rule.important
                      ? "from-primary-50/50 to-transparent"
                      : "from-primary-50/30 to-transparent"
                  }
                  opacity-0 group-hover:opacity-100 
                  transition-opacity duration-300
                `}
                />

                <div className="relative z-10">
                  <div
                    className={`
                    ${rule.important ? "text-primary-500" : "text-blue-400"}
                    mb-4 transform transition-transform duration-300
                    group-hover:scale-110 group-hover:rotate-3
                  `}
                  >
                    {rule.icon}
                  </div>

                  <h3 className="text-gray-900 text-xl font-semibold mb-2">
                    {rule.title}
                  </h3>

                  <p className="text-gray-600 text-sm leading-relaxed">
                    {rule.description}
                  </p>

                  {/* {rule.important && (
                    <div className="absolute top-4 right-4">
                      <span className="px-2 py-1 text-xs font-medium text-amber-600 bg-amber-100 rounded-full">
                        Important
                      </span>
                    </div>
                  )} */}
                </div>
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}
