import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import BookFlipCard from "../ui/Card/BookFlipCard";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// book image
import book1 from "../../assets/rPaper2.jpg";
import book2 from "../../assets/rPaper3.jpg";
import book3 from "../../assets/rPaper4.jpg";
import book4 from "../../assets/paper1.jpeg";
import book5 from "../../assets/paper2.jpeg";
import book6 from "../../assets/paper3.jpeg";

const books = [
  {
    id: 1,
    title: "The Great Gatsby",
    author: "F. Scott Fitzgerald",
    image: book1,
  },
  {
    id: 2,
    title: "To Kill a Mockingbird",
    author: "Harper Lee",
    image: book2,
  },
  {
    id: 3,
    title: "1984",
    author: "George Orwell",
    image: book3,
  },
  {
    id: 4,
    title: "Pride and Prejudice",
    author: "Jane Austen",
    image: book4,
  },
  {
    id: 5,
    title: "The Catcher in the Rye",
    author: "J.D. Salinger",
    image: book5,
  },
  {
    id: 6,
    title: "The Catcher in the Rye",
    author: "J.D. Salinger",
    image: book6,
  },
  // Add more books as needed
];

const NewArrivalSlide = () => {
  return (
    <div className="w-full py-10">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{ clickable: true }}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
          1280: {
            slidesPerView: 5,
          },
        }}
        className="mySwiper"
      >
        {books.map((book) => (
          <SwiperSlide key={book.id}>
            <BookFlipCard book={book} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default NewArrivalSlide;
