import { useRef } from "react";
import whyBanner from "../assets/centers/iqac_11zon.jpg";
import containerBanner from "../assets/wh3.jpg";
import WhyCard from "../components/ui/Card/WhyCard";
import ImageHeading from "../components/ui/ImageHeading";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ExpandableContent } from "../components/shared/ExpandableContent";
const cardData = [
  {
    title: "Unique Single-Benefactor Model",
    details:
      "ZUMS is uniquely supported by the Zubaidur & Nargis Rahman Foundation (ZNRF), ensuring long-term stability and a focus on educational excellence rather than profit. This distinctive model sets ZUMS apart from many other private universities in Bangladesh.",
  },
  {
    title: "Commitment to Social Impact",
    details:
      "As a not-for-profit institution with a social-service oriented character, ZUMS is dedicated to making a positive impact on the community. This is demonstrated by their commitment to providing free education to 10% of their student body from disadvantaged backgrounds.",
  },
  {
    title: "Focus on Global Readiness",
    details:
      "ZUMS offers meticulously designed programs and attracts internationally recognized academics and researchers to prepare students for the complexities of today's interconnected world.",
  },
  {
    title: "Emphasis on Ethical Leadership and Service",
    details:
      "ZUMS fosters a culture of service, social justice, and ethical leadership, shaping graduates who are not only academically proficient but also responsible and engaged citizens.",
  },
  {
    title: "Student-Centered Approach",
    details:
      "ZUMS prioritizes the student experience, providing comprehensive support for academic, cultural, and personal development.",
  },
  {
    title: "Inclusive and Welcoming Environment",
    details:
      "ZUMS embraces diversity and fosters a welcoming and inclusive campus environment where everyone feels a sense of belonging.",
  },
  {
    title: "Core Values Driving Excellence",
    details:
      "Our institutional values—integrity, diversity, responsibility, service, and excellence—foster a nurturing environment that empowers students to achieve personal and professional success.",
  },
];

gsap.registerPlugin(ScrollTrigger);

const WhyChooseZums2 = () => {
  const headerRef = useRef(null);

  const firstHalf = cardData.slice(0, Math.ceil(cardData.length / 2));
  const secondHalf = cardData.slice(Math.ceil(cardData.length / 2));

  useGSAP(() => {
    gsap.to(headerRef.current, {
      scrollTrigger: {
        trigger: headerRef.current,
        start: "top 15%",
        end: "bottom -120%",
        pin: true,
        // markers: true,
        pinSpacing: true,
        scrub: 1,
      },
    });

    // Refresh ScrollTrigger after mounting
    ScrollTrigger.refresh();
  }, []);

  return (
    <div>
      <ImageHeading heading="Why Choose ZUMS" imageUrl={whyBanner} />
      <div
        style={{
          backgroundImage: `url(${containerBanner})`,
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
        className="relative"
      >
        {/* Dark Overlay */}
        <div className="absolute inset-0 z-0 bg-black opacity-70"></div>

        <div className="container relative z-10 px-6 py-10 mx-auto lg:px-8">
          <div className="grid items-start grid-cols-1 gap-16 lg:grid-cols-6 ">
            <div
              ref={headerRef}
              className="col-span-6 text-xl text-white lg:col-span-2"
            >
              <h1 className="text-3xl font-bold font-domine lg:text-4xl ">
                Why Choose ZUMS
              </h1>
              <ExpandableContent
                content={
                  <div>
                    <p>
                      At ZUMS, we don&apos;t just educate—we empower. Our
                      mission is to transform students into leaders, equipping
                      them with the knowledge, skills, and confidence to shape
                      the future. Education at ZUMS is not just about learning;
                      it&apos;s about growth, innovation, and impact. <br />{" "}
                      <br /> We cultivate a dynamic environment where students
                      excel, lead, and inspire. Through cutting-edge programs,
                      world-class faculty, and hands-on experiences, we prepare
                      individuals to tackle real-world challenges with vision
                      and determination. <br /> <br /> Choosing ZUMS means
                      choosing a path of excellence, leadership, and
                      transformation. Your future starts here!
                    </p>
                  </div>
                }
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 col-span-6 lg:col-span-4 border-[#AC0D30] gap-8 lg:gap-16 lg:border-l-2 xl:px-24 lg:px-16 ">
              {/* First Column */}
              <div className="flex flex-col gap-8 lg:gap-16">
                {firstHalf.map((card, index) => (
                  <WhyCard
                    key={index}
                    title={card.title}
                    details={card.details}
                  />
                ))}
              </div>
              {/* Second Column */}
              <div className="flex flex-col gap-8 mt-16 lg:gap-16">
                {secondHalf.map((card, index) => (
                  <WhyCard
                    key={index}
                    title={card.title}
                    details={card.details}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseZums2;
