import { motion } from "framer-motion";

import ImageHeading from "../components/ui/ImageHeading";
import tuitionFeesImage from "../assets/header/header.jpeg";

const programs = [
  {
    program: "Bachelor of Business Administration (BBA)",
    admissionFee: "15,000",
    tuitionFee: "3,90,000",
    computerLabFee: "8,000",
    studentActivityFee: "8,000",
    libraryFee: "8,000",
    total: "4,29,000",
    totalCredits: 130,
    tuitionFeePerCredit: "3,000",
  },
  {
    program: "Bachelor of Social Science in Economics",
    admissionFee: "15,000",
    tuitionFee: "3,90,000",
    computerLabFee: "8,000",
    studentActivityFee: "8,000",
    libraryFee: "8,000",
    total: "4,29,000",
    totalCredits: 130,
    tuitionFeePerCredit: "3,000",
  },
  {
    program: "Bachelor of Science in Computer Science and Engineering (CSE)",
    admissionFee: "15,000",
    tuitionFee: "4,35,000",
    computerLabFee: "24,000",
    studentActivityFee: "8,000",
    libraryFee: "8,000",
    total: "4,90,000",
    totalCredits: 145,
    tuitionFeePerCredit: "3,000",
  },
  {
    program: "Bachelor of Science in Information Technology Management (ITM)",
    admissionFee: "15,000",
    tuitionFee: "4,41,000",
    computerLabFee: "24,000",
    studentActivityFee: "8,000",
    libraryFee: "8,000",
    total: "4,96,000",
    totalCredits: 147,
    tuitionFeePerCredit: "3,000",
  },
  {
    program: "Bachelor of Arts in English",
    admissionFee: "15,000",
    tuitionFee: "3,90,000",
    computerLabFee: "8,000",
    studentActivityFee: "8,000",
    libraryFee: "8,000",
    total: "4,29,000",
    totalCredits: 130,
    tuitionFeePerCredit: "3,000",
  },
  {
    program: "Bachelor of Law (Hon’s)",
    admissionFee: "15,000",
    tuitionFee: "5,28,000",
    computerLabFee: "24,000",
    studentActivityFee: "8,000",
    libraryFee: "8,000",
    total: "5,83,000",
    totalCredits: 132,
    tuitionFeePerCredit: "4,000",
  },
  {
    program: "Master of Business Administration (MBA)",
    admissionFee: "15,000",
    tuitionFee: "2,20,500",
    computerLabFee: "4,000",
    studentActivityFee: "4,000",
    libraryFee: "4,000",
    total: "2,47,500",
    totalCredits: 63,
    tuitionFeePerCredit: "3,500",
  },
];

// const creditFees = [
//   {
//     program: "Bachelor of Business Administration (BBA)",
//     totalCredits: 130,
//     tuitionFeePerCredit: "3,000",
//   },
//   {
//     program: "Bachelor of Social Science in Economics",
//     totalCredits: 130,
//     tuitionFeePerCredit: "3,000",
//   },
//   {
//     program: "Bachelor of Science in Computer Science and Engineering (CSE)",
//     totalCredits: 145,
//     tuitionFeePerCredit: "3,000",
//   },
//   {
//     program: "Bachelor of Science in Information Technology Management (ITM)",
//     totalCredits: 147,
//     tuitionFeePerCredit: "3,000",
//   },
//   {
//     program: "Bachelor of Arts in English",
//     totalCredits: 130,
//     tuitionFeePerCredit: "3,000",
//   },
//   {
//     program: "Bachelor of Law (Hon’s)",
//     totalCredits: 132,
//     tuitionFeePerCredit: "4,000",
//   },
//   {
//     program: "Master of Business Administration (MBA)",
//     totalCredits: 63,
//     tuitionFeePerCredit: "3,500",
//   },
// ];
// const otherFees = [
//   {
//     program: "Bachelor of Business Administration (BBA)",
//     admissionFee: "15,000",
//     computerLabFee: "1,000",
//     studentActivityFee: "1,000",
//     libraryFee: "1,000",
//   },
//   {
//     program: "Bachelor of Social Science in Economics",
//     admissionFee: "15,000",
//     computerLabFee: "1,000",
//     studentActivityFee: "1,000",
//     libraryFee: "1,000",
//   },
//   {
//     program: "Bachelor of Science in Computer Science and Engineering (CSE)",
//     admissionFee: "15,000",
//     computerLabFee: "3,000",
//     studentActivityFee: "1,000",
//     libraryFee: "1,000",
//   },
//   {
//     program: "Bachelor of Science in Information Technology Management (ITM)",
//     admissionFee: "15,000",
//     computerLabFee: "3,000",
//     studentActivityFee: "1,000",
//     libraryFee: "1,000",
//   },
//   {
//     program: "Bachelor of Arts in English",
//     admissionFee: "15,000",
//     computerLabFee: "1,000",
//     studentActivityFee: "1,000",
//     libraryFee: "1,000",
//   },
//   {
//     program: "Bachelor of Law (Hon’s)",
//     admissionFee: "15,000",
//     computerLabFee: "3,000",
//     studentActivityFee: "1,000",
//     libraryFee: "1,000",
//   },
//   {
//     program: "Master of Business Administration (MBA)",
//     admissionFee: "15,000",
//     computerLabFee: "1,000",
//     studentActivityFee: "1,000",
//     libraryFee: "1,000",
//   },
// ];

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { staggerChildren: 0.1, delayChildren: 0.3 },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, ease: "easeOut" },
  },
};

const TuitionFees = () => {
  return (
    <div>
      <ImageHeading
        heading="Affordable Education, Exceptional Value"
        imageUrl={tuitionFeesImage}
      />
      {/* total tuition fees */}
      <div>
        <motion.h2
          className="px-4 mt-12 text-3xl font-bold text-center text-gray-800 lg:text-4xl md:px-8 font-domine"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Your Investment in Success: Tuition & Fees
        </motion.h2>
        `
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
          className="container grid grid-cols-1 gap-6 px-4 py-12 mx-auto md:grid-cols-2 lg:grid-cols-3"
        >
          {programs.map((program, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="relative p-6 overflow-hidden transition-all duration-300 transform bg-white border-l-4 shadow-lg cursor-pointer rounded-xl border-primary-500 ring-1 hover:bg-gradient-to-r from-primary-100 via-primary-50 to-transparent ring-primary-100 hover:shadow-xl hover:shadow-primary-100 group"
            >
              <div className="relative z-10">
                <h3 className="mb-2 text-xl font-semibold text-gray-900 font-domine">
                  {program.program}
                </h3>
                <div className="space-y-1">
                  <p className="text-sm leading-relaxed text-gray-600">
                    <strong>Admission Fee:</strong> {program.admissionFee} BDT
                  </p>
                  <p className="text-sm leading-relaxed text-gray-600">
                    <strong>Tuition Fee:</strong> {program.tuitionFee} BDT
                  </p>
                  <p className="text-sm leading-relaxed text-gray-600">
                    <strong>Computer Lab Fee:</strong> {program.computerLabFee}{" "}
                    BDT
                  </p>
                  <p className="text-sm leading-relaxed text-gray-600">
                    <strong>Student Activity Fee:</strong>{" "}
                    {program.studentActivityFee} BDT
                  </p>
                  <p className="text-sm leading-relaxed text-gray-600">
                    <strong>Library Fee:</strong> {program.libraryFee} BDT
                  </p>
                  <p className="text-sm leading-relaxed text-gray-600">
                    <strong>Total Credits:</strong> {program.totalCredits}
                  </p>
                  <p className="text-sm leading-relaxed text-gray-600">
                    <strong>Tuition Fee per Credit:</strong>{" "}
                    {program.tuitionFeePerCredit} BDT
                  </p>
                  <p className="mt-2 text-lg font-semibold text-gray-900">
                    <strong>Total Fee:</strong> {program.total} BDT
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
        `
      </div>
      {/* credit fees */}
      {/* <div className="container mx-auto my-12 overflow-x-auto">
        <motion.h2
          className="mb-12 text-4xl font-bold text-center text-gray-800 font-domine"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Credit Fees
        </motion.h2>
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
          <thead className="bg-gray-100">
            <tr>
              <th className="hidden px-4 py-2 text-sm font-medium text-left text-gray-700 md:block">
                Sl. No.
              </th>
              <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                Program/Degree
              </th>
              <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                Total Credits
              </th>
              <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                Tuition Fees (Per Credit)
              </th>
            </tr>
          </thead>
          <tbody>
            {creditFees.map((program, index) => (
              <motion.tr
                // whileInView={{ opacity: 1, y: 0 }}
                // initial={{ opacity: 0, y: 50 }}
                // viewport={{ once: true, amount: 0.2 }}
                // transition={{ duration: 0.5, ease: "easeOut" }}
                key={index}
                className="border-t border-gray-200"
              >
                <td className="hidden px-4 py-5 text-sm text-gray-600 md:block">
                  {index + 1}
                </td>
                <td className="px-4 py-5 text-sm text-gray-600">
                  {program.program}
                </td>
                <td className="px-4 py-5 text-sm text-gray-600">
                  {program.totalCredits}
                </td>
                <td className="px-4 py-5 text-sm text-gray-600">
                  {program.tuitionFeePerCredit} BDT
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div> */}
      {/* other fees */}
      {/* <div className="container mx-auto mb-12 ">
        <motion.h2
          className="mt-12 mb-12 text-4xl font-bold text-center text-gray-800 font-domine"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Other Fees
        </motion.h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead className="bg-gray-100">
              <tr>
                <th className="hidden px-4 py-2 text-sm font-medium text-left text-gray-700 md:block">
                  Sl. No.
                </th>
                <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                  Program/Degree
                </th>
                <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                  Admission Fee <br /> (One Time Payment)
                </th>
                <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                  Computer Lab Fee <br /> (Per Semester)
                </th>
                <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                  Student Activity Fee <br /> (Per Semester)
                </th>
                <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                  Library Fee <br /> (Per Semester)
                </th>
              </tr>
            </thead>
            <tbody>
              {otherFees.map((program, index) => (
                <motion.tr
                  key={index}
                  className="border-t border-gray-200"
                  // whileInView={{ opacity: 1, y: 0 }}
                  // initial={{ opacity: 0, y: 50 }}
                  // viewport={{ once: true, amount: 0.2 }}
                  // transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  <td className="hidden px-4 py-2 text-sm text-gray-600 md:block">
                    {index + 1}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {program.program}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {program.admissionFee} BDT
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {program.computerLabFee} BDT
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {program.studentActivityFee} BDT
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {program.libraryFee} BDT
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
};

export default TuitionFees;
