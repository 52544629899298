import { useRef, useState, useEffect } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import { MoveLeft, MoveRight } from "lucide-react";
import { Link } from "react-router-dom";
import { cn } from "../../../utils/utils";
import image1 from "../../../assets/rPaper1.webp";
import image2 from "../../../assets/rPaper2.jpg";
import image3 from "../../../assets/rPaper3.jpg";
import image4 from "../../../assets/rPaper4.jpg";
import image5 from "../../../assets/rPaper6.jpg";
import image7 from "../../../assets/rPaper8.webp";

const FALLBACK_WIDTH = 509;
const AUTOPLAY_INTERVAL = 5000;

const articles = [
  {
    title:
      "Optimizing Machine Learning Algorithms for Environmental Data Analysis",
    url: image1,
    id: 1,
  },
  {
    title: "Blockchain-based Solutions for Environmental Sustainability",
    url: image2,
    id: 2,
  },
  {
    title: "Data-Driven Approaches for Predicting Climate Change Impact",
    url: image3,
    id: 3,
  },
  {
    title: "AI-powered Models for Sustainable Resource Management",
    url: image4,
    id: 4,
  },
  {
    title: "Edge Computing for Real-Time Environmental Monitoring Systems",
    url: image5,
    id: 5,
  },
  {
    title: "Optimizing Renewable Energy Grid Management using AI",
    url: image7,
    id: 6,
  },
  {
    title: "Environmental Impact Assessment using Big Data Analytics",
    url: image2,
    id: 7,
  },
  {
    title: "IoT Solutions for Smart Cities and Environmental Conservation",
    url: image4,
    id: 8,
  },
  {
    title: "Machine Learning Techniques for Predicting Environmental Disasters",
    url: image2,
    id: 9,
  },
];

export default function JournalCarousel2() {
  const containerRef = useRef(null);
  const itemsRef = useRef([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(3);
  const canScrollPrev = activeSlide > 0;
  const canScrollNext = activeSlide < articles.length - itemsPerView;
  const offsetX = useMotionValue(0);
  const animatedX = useSpring(offsetX, {
    damping: 20,
    stiffness: 150,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setItemsPerView(1);
      } else if (window.innerWidth < 768) {
        setItemsPerView(2);
      } else {
        setItemsPerView(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const autoplayTimer = setInterval(() => {
      if (canScrollNext) {
        scrollNext();
      } else {
        offsetX.set(0);
        setActiveSlide(0);
      }
    }, AUTOPLAY_INTERVAL);

    return () => clearInterval(autoplayTimer);
  }, [canScrollNext, activeSlide]);

  const getWidth = () => {
    const baseWidth = 100 / itemsPerView;
    return `${baseWidth}%`;
  };

  function scrollPrev() {
    if (!canScrollPrev) return;
    const itemWidth = itemsRef.current[0]?.offsetWidth ?? FALLBACK_WIDTH;
    offsetX.set(offsetX.get() + itemWidth);
    setActiveSlide((prev) => prev - 1);
  }

  function scrollNext() {
    if (!canScrollNext) return;
    const itemWidth = itemsRef.current[0]?.offsetWidth ?? FALLBACK_WIDTH;
    offsetX.set(offsetX.get() - itemWidth);
    setActiveSlide((prev) => prev + 1);
  }

  const handleDragEnd = (_, info) => {
    const dragThreshold = 100;
    if (info.offset.x > dragThreshold && canScrollPrev) {
      scrollPrev();
    } else if (info.offset.x < -dragThreshold && canScrollNext) {
      scrollNext();
    }
  };

  return (
    <div className="container px-4 mx-auto overflow-hidden md:px-6 lg:px-20">
      <div className="text-center">
        <h1 className="mb-4 text-4xl font-bold text-center font-domine">
          Journals
        </h1>
        <p className="pb-5 text-sm text-gray-400">
          ZUIC has been instrumental in nurturing groundbreaking ventures that
          have achieved remarkable success.
        </p>
      </div>
      <div className="container mx-6 group">
        <div className="relative overflow-hidden">
          <motion.ul
            ref={containerRef}
            className="flex items-start"
            style={{
              x: animatedX,
            }}
            drag="x"
            dragConstraints={{
              left: -(
                (articles.length - itemsPerView) *
                (itemsRef.current[0]?.offsetWidth ?? FALLBACK_WIDTH)
              ),
              right: 0,
            }}
            dragElastic={0.1}
            onDragEnd={handleDragEnd}
          >
            {articles.map((article, index) => {
              const active =
                index >= activeSlide && index < activeSlide + itemsPerView;
              return (
                <motion.li
                  key={article.id}
                  ref={(el) => (itemsRef.current[index] = el)}
                  className={cn(
                    "group relative shrink-0 select-none md:px-3 transition-opacity duration-300"
                  )}
                  style={{
                    flexBasis: getWidth(),
                  }}
                >
                  <Link
                    className="block pointer-events-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <motion.div className="grid place-content-center overflow-hidden rounded-lg h-[400px] lg:max-h-[500px]">
                      <img
                        className="object-cover w-full h-full pointer-events-none"
                        src={article.url}
                        alt={article.title}
                      />
                    </motion.div>
                  </Link>
                  <div
                    className={cn(
                      "mt-4 flex justify-center",
                      !active && "hidden"
                    )}
                  >
                    <Link
                      href={article.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-xl font-bold leading-tight text-black transition-colors group-hover:text-primary"
                    >
                      {article.title}
                    </Link>
                  </div>
                </motion.li>
              );
            })}
          </motion.ul>
          <button
            type="button"
            className="absolute z-20 left-10 top-1/2"
            onClick={scrollPrev}
            disabled={!canScrollPrev}
          >
            <MoveLeft className="p-3 text-white rounded-full size-12 bg-black/70" />
          </button>
          <button
            type="button"
            className="absolute z-20 right-10 top-1/2"
            onClick={scrollNext}
            disabled={!canScrollNext}
          >
            <MoveRight className="p-3 text-white rounded-full size-12 bg-black/70" />
          </button>
        </div>
      </div>
    </div>
  );
}
