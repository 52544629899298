/* eslint-disable react/prop-types */

import { motion, AnimatePresence } from "framer-motion";
import {
  Search,
  // Calendar as CalendarIcon,
  X,
  Filter,
  // ChevronLeft,
  // ChevronRight,
} from "lucide-react";
// import {
//   format,
//   startOfMonth,
//   endOfMonth,
//   eachDayOfInterval,
//   isSameMonth,
//   isToday,
//   isSameDay,
//   addMonths,
//   subMonths,
// } from "date-fns";

export function FilterPanel({
  filters,
  onFilterChange,
  categories,
  audiences,
  itemCounts,
}) {
  // const [currentMonth, setCurrentMonth] = React.useState(new Date());
  // const [hoveredDate, setHoveredDate] = React.useState(null);

  const clearFilters = () => {
    onFilterChange({
      search: "",
      dateRange: { start: null, end: null },
      categories: [],
      audiences: [],
    });
  };

  // const days = eachDayOfInterval({
  //   start: startOfMonth(currentMonth),
  //   end: endOfMonth(currentMonth),
  // });

  // const handleDateClick = (date) => {
  //   if (!filters.dateRange.start) {
  //     onFilterChange({
  //       ...filters,
  //       dateRange: { start: date, end: null },
  //     });
  //   } else if (!filters.dateRange.end && date > filters.dateRange.start) {
  //     onFilterChange({
  //       ...filters,
  //       dateRange: { ...filters.dateRange, end: date },
  //     });
  //   } else {
  //     onFilterChange({
  //       ...filters,
  //       dateRange: { start: date, end: null },
  //     });
  //   }
  // };

  // const isInRange = (date) => {
  //   if (filters.dateRange.start && filters.dateRange.end) {
  //     return date >= filters.dateRange.start && date <= filters.dateRange.end;
  //   }
  //   if (filters.dateRange.start && hoveredDate && !filters.dateRange.end) {
  //     return date >= filters.dateRange.start && date <= hoveredDate;
  //   }
  //   return false;
  // };

  return (
    <div className="sticky p-6 border shadow-xl bg-white/80 backdrop-blur-lg rounded-2xl top-4 border-white/20">
      <div className="mb-8">
        <div className="relative group">
          <Search
            className="absolute text-gray-400 transition-colors transform -translate-y-1/2 left-4 top-1/2 group-focus-within:text-primary-500"
            size={20}
          />
          <input
            type="text"
            placeholder="Search news and events..."
            value={filters.search}
            onChange={(e) =>
              onFilterChange({ ...filters, search: e.target.value })
            }
            className="w-full py-3 pl-12 pr-4 transition-all duration-300 border-2 border-transparent bg-gray-50/50 rounded-xl focus:border-primary-500 focus:ring-0 focus:bg-white"
          />
        </div>
      </div>

      {/* <div className="mb-8">
        <h3 className="flex items-center gap-2 mb-4 text-lg font-semibold">
          <CalendarIcon size={20} className="text-primary-500" />
          Date Range
        </h3>
        <div className="relative">
          <div className="flex items-center justify-between mb-4">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <ChevronLeft size={20} />
            </motion.button>
            <h4 className="text-lg font-medium">
              {format(currentMonth, "MMMM yyyy")}
            </h4>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <ChevronRight size={20} />
            </motion.button>
          </div>
          <div className="grid grid-cols-7 gap-1 mb-2 text-sm font-medium text-center text-gray-500">
            {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
              <div key={day}>{day}</div>
            ))}
          </div>
          <div className="grid grid-cols-7 gap-1">
            {Array.from({ length: startOfMonth(currentMonth).getDay() }).map(
              (_, i) => (
                <div key={`empty-${i}`} className="aspect-square" />
              )
            )}
            {days.map((day) => {
              const isSelected =
                filters.dateRange.start &&
                (isSameDay(day, filters.dateRange.start) ||
                  (filters.dateRange.end &&
                    isSameDay(day, filters.dateRange.end)));
              const inRange = isInRange(day);

              return (
                <motion.button
                  key={day.toISOString()}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleDateClick(day)}
                  onMouseEnter={() => setHoveredDate(day)}
                  onMouseLeave={() => setHoveredDate(null)}
                  className={`
                    relative aspect-square rounded-lg text-sm font-medium
                    ${isSelected ? "bg-primary-500 text-white" : ""}
                    ${inRange ? "bg-primary-100" : ""}
                    ${!isSelected && !inRange ? "hover:bg-gray-100" : ""}
                    ${!isSameMonth(day, currentMonth) ? "text-gray-300" : ""}
                    ${isToday(day) ? "ring-2 ring-primary-500" : ""}
                  `}
                >
                  {format(day, "d")}
                  {isSelected && (
                    <motion.div
                      layoutId="dateSelection"
                      className="absolute inset-0 rounded-lg bg-primary-500 -z-10"
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 30,
                      }}
                    />
                  )}
                </motion.button>
              );
            })}
          </div>
        </div>
      </div> */}

      <div className="mb-8">
        <h3 className="flex items-center gap-2 mb-4 text-lg font-semibold">
          <Filter size={20} className="text-primary-500" />
          Categories
        </h3>
        <div className="space-y-3">
          {categories.map((category) => (
            <motion.label
              key={category}
              className="flex items-center gap-3 cursor-pointer group"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="relative">
                <input
                  type="checkbox"
                  checked={filters.categories.includes(category)}
                  onChange={(e) => {
                    const newCategories = e.target.checked
                      ? [...filters.categories, category]
                      : filters.categories.filter((c) => c !== category);
                    onFilterChange({ ...filters, categories: newCategories });
                  }}
                  className="sr-only peer"
                />
                <div className="w-5 h-5 transition-all border-2 border-gray-300 rounded-md peer-checked:border-primary-500 peer-checked:bg-primary-500">
                  <AnimatePresence>
                    {filters.categories.includes(category) && (
                      <motion.svg
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                        className="w-full h-full text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12" />
                      </motion.svg>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              <span className="flex-1 text-gray-700 group-hover:text-gray-900">
                {category}
              </span>
              <motion.span
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                className="px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full"
              >
                {itemCounts.categories[category] || 0}
              </motion.span>
            </motion.label>
          ))}
        </div>
      </div>

      <div className="mb-8">
        <h3 className="mb-4 text-lg font-semibold">Target Audience</h3>
        <div className="flex flex-wrap gap-2">
          {audiences.map((audience) => {
            const isSelected = filters.audiences.includes(audience);
            return (
              <motion.button
                key={audience}
                onClick={() => {
                  const newAudiences = isSelected
                    ? filters.audiences.filter((a) => a !== audience)
                    : [...filters.audiences, audience];
                  onFilterChange({ ...filters, audiences: newAudiences });
                }}
                className={`relative px-4 py-2 rounded-xl ${
                  isSelected
                    ? "text-white"
                    : "text-gray-700 hover:text-gray-900"
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="relative z-10">{audience}</span>
                <span className="relative z-10 ml-2">
                  ({itemCounts.audiences[audience] || 0})
                </span>
                <AnimatePresence>
                  {isSelected && (
                    <motion.div
                      layoutId={`audience-${audience}`}
                      className="absolute inset-0 bg-gradient-to-r from-primary-500 to-primary-600 rounded-xl"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    />
                  )}
                </AnimatePresence>
              </motion.button>
            );
          })}
        </div>
      </div>

      {(filters.search ||
        filters.dateRange.start ||
        filters.dateRange.end ||
        filters.categories.length > 0 ||
        filters.audiences.length > 0) && (
        <motion.button
          onClick={clearFilters}
          className="flex items-center justify-center w-full gap-2 py-3 text-white transition-all duration-300 bg-gradient-to-r from-red-500 to-red-600 rounded-xl hover:from-red-600 hover:to-red-700"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <X size={16} />
          Clear all filters
        </motion.button>
      )}
    </div>
  );
}
