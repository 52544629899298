/* eslint-disable react/prop-types */
"use client";
import { motion } from "framer-motion";

const specialGradeData = [
  {
    code: "I",
    name: "Incomplete",
    description: "Student has not completed all required coursework.",
  },
  {
    code: "W",
    name: "Withdrawal",
    description: "Student officially dropped the course.",
  },
  {
    code: "R",
    name: "Repeat/Retake",
    description: "Student is retaking the course to improve grade.",
  },
  {
    code: "CA",
    name: "Course Abandonment",
    description: "Student registered but did not participate in the course.",
  },
];

const SpecialGrades = ({ onGradeSelect, selectedGrade }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {specialGradeData.map((grade, index) => (
        <motion.div
          key={grade.code}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className={`
            p-4 rounded-lg border cursor-pointer transition-all
            ${
              selectedGrade === `${grade.code}** ${grade.name}`
                ? "border-primary bg-primary bg-opacity-10"
                : "border-gray-200 hover:border-primary hover:shadow-md"
            }
          `}
          onClick={() => onGradeSelect(`${grade.code}** ${grade.name}`)}
        >
          <div className="flex items-center space-x-3">
            <div className="text-2xl font-bold text-primary">{grade.code}</div>
            <div>
              <h3 className="font-semibold">{grade.name}</h3>
              <p className="text-sm text-gray-600">{grade.description}</p>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default SpecialGrades;
