import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import RoundedButton from "../ui/RoundedButton";
import careerSuccessImage from "../../assets/Labs/labs2.webp";

const CareerSuccess = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "50%"]);

  return (
    <section
      ref={ref}
      className="relative py-24 overflow-hidden bg-[#1a1a1a] text-white"
    >
      <motion.div
        className="absolute inset-0 w-full h-[120%] -top-[10%]"
        style={{
          y: backgroundY,
          backgroundImage: `url(${careerSuccessImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.2,
        }}
      />

      <div className="container mx-auto px-4 relative">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl md:text-5xl font-domine font-bold mb-6">
              SUCCESS THROUGH PEOPLE
            </h2>
            <p className="text-lg mb-8">
              Join a community of innovators, educators, and professionals
              committed to excellence. At the University of Houston, we believe
              our success is built on the dedication and talent of our people.
            </p>
            <RoundedButton className="text-white">
              View Current Openings
            </RoundedButton>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="relative h-[400px]"
          >
            <img
              src={careerSuccessImage} // Use the imported image here as well
              alt="Success at UH"
              className="w-full h-full object-cover rounded-lg"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default CareerSuccess;
