import bannerImage from "../../assets/centers/clll_11zon.jpg";
import IQACMission from "../../components/CenterOfExcellence/IQACMission";
import PublicationCarousel from "../../components/CenterOfExcellence/PublicationCarousel";
// import TeamCarousel from "../../components/CenterOfExcellence/TeamCarousel";
import AnimatedQuote from "../../components/ui/Card/AnimatedQuoteCard";
import FeaturedEvents from "../../components/ui/FeaturedEvents";
import ImageHeading from "../../components/ui/ImageHeading";
import ResearchCTA from "../../components/ui/ResearchCTA";

const whatWeOfferData = [
  {
    id: 1,
    title: "Professional Development Courses",
    description:
      "Specialized skill-based workshops and certification programs in emerging fields like Artificial Intelligence, Climate Change, and Entrepreneurship.",
  },
  {
    id: 2,
    title: "Personal Enrichment Programs",
    description:
      "Creative arts, language classes, and personal wellness programs offering opportunities for exploring new hobbies and interests.",
  },
  {
    id: 3,
    title: "Online Learning Opportunities",
    description:
      "Self-paced and instructor-led courses accessible anytime, anywhere, with partnerships for internationally recognized certifications.",
  },
  {
    id: 4,
    title: "Customized Corporate Training",
    description:
      "Tailored solutions for businesses and organizations to upskill their workforce and address industry challenges.",
  },
  {
    id: 5,
    title: "Community Engagement Initiatives",
    description:
      "Outreach programs to promote education in underserved communities, including free workshops and seminars for local learners.",
  },
];

const CLLL = () => {
  return (
    <div>
      <ImageHeading
        heading="Center for Life-Long Learning (CLLL)"
        imageUrl={bannerImage}
      />
      <div className="container px-4 mx-auto md:px-8 lg:px-0 ">
        {/* quote */}
        <div className="">
          <AnimatedQuote
            author={" Alvin Toffler"}
            quote="The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn."
          />
        </div>
        <div className="gap-8 my-12 ">
          <div>
            <div>
              <h3 className="text-2xl font-bold text-black lg:text-4xl font-domine">
                Welcome to the Center for Life-Long Learning (CLLL)
              </h3>
              <p className="py-6 leading-loose text-gray-700 ">
                The Center for Life-Long Learning (CLLL) at ZNRF University, a
                hub for continuous education, professional growth, and personal
                development. CLLL is dedicated to fostering a culture of
                lifelong learning by providing innovative, accessible, and
                flexible learning opportunities to individuals of all ages and
                backgrounds.
                <br />
              </p>
            </div>

            <IQACMission
              title={"What We Offer"}
              details={
                " The CLLL provides a wide array of programs and resources tailored to the needs of students, professionals, and lifelong learners."
              }
              data={whatWeOfferData}
            />

            <div className="py-12 space-y-20">
              <PublicationCarousel title={"Publication & Report"} />
              {/* <TeamCarousel title={"   CLLL Team"} /> */}
              <FeaturedEvents />
              <ResearchCTA
                title={"Contact EDRC"}
                mail={"edrc@gmail.com"}
                number={"+880187863784"}
                description={
                  " Are you ready to bring your ideas to life? EDRC welcomes students, faculty members, and external entrepreneurs who are passionate about making a difference. Join us to access the  resources, mentorship, and community you need to turn your vision into reality."
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CLLL;
