// Business Administration faculty imports
import DrMasudIsa from "../assets/Faculty/BusinessAdministration/DrMasudIsa.jpg";
import FarhanaHussain from "../assets/Faculty/BusinessAdministration/FarhanaHussain.jpg";
import IshfaqueWaseek from "../assets/Faculty/BusinessAdministration/IshfaqueWaseek.jpg";
import JamalUddinAhmedFCA from "../assets/Faculty/BusinessAdministration/JamalUddinAhmedFCA.jpg";
import MdNurulHasan from "../assets/Faculty/BusinessAdministration/MdNurulHasan.jpg";
import MohammadHares from "../assets/Faculty/BusinessAdministration/MohammadHares.jpg";
import mohiuddin from "../assets/Adminstration/adminHeads/ProVC/ProfMohammadMohiuddin.jpg";
import MoshahidurRahman from "../assets/Faculty/BusinessAdministration/MoshahidurRahman.webp";
import NurulAfserChowdhury from "../assets/Faculty/BusinessAdministration/NurulAfserChowdhury.jpg";
import RayhanAmin from "../assets/Faculty/BusinessAdministration/RayhanAmin.jpg";
import UmmeySalma from "../assets/Faculty/BusinessAdministration/UmmeySalma.jpg";
import ZakirAhmedKhan from "../assets/Faculty/BusinessAdministration/ZakirAhmedKhan.jpg";

// Economics faculty imports
import AfrinJannat from "../assets/Faculty/Economics/AfrinJannat.jpeg";
import BrishtiBhowmik from "../assets/Faculty/Economics/BrishtiBhowmik.jpg";
import DrBernardWasow from "../assets/Faculty/Economics/DrBernardWasow.jpg";
import DrMohiuddinAhmad from "../assets/Faculty/Economics/DrMohiuddinAhmad.jpeg";
import DrMohammadOsmanGani from "../assets/Faculty/Economics/DrMohammadOsmanGani.jpg";
import LorraineRuffing from "../assets/Faculty/Economics/LorraineRuffing.jpg";
import DrRezaKibria from "../assets/Faculty/Economics/DrRezaKibria.webp";

// Humanities and Social Sciences faculty imports
import FaiyazAhmed from "../assets/Faculty/HumanitiesandSocialSciences/FaiyazAhmed.jpg";
import MdShahadatHossain from "../assets/Faculty/HumanitiesandSocialSciences/MdShahadatHossain.jpg";
import ProfMustafizurRahman from "../assets/Faculty/HumanitiesandSocialSciences/ProfMustafizurRahman.jpeg";
import RownakJahanOishi from "../assets/Faculty/HumanitiesandSocialSciences/RownakJahanOishi.jpg";
import SeemaHaydarChawdhuri from "../assets/Faculty/HumanitiesandSocialSciences/SeemaHaydarChawdhuri.jpg";

// Science and Engineering faculty imports
import DrSheikhAbdulLatif from "../assets/Faculty/ScienceandEngineering/DrSheikhAbdulLatif.jpg";
import DrRiasatIslam from "../assets/Faculty/ScienceandEngineering/DrRiasatIslam.png";
import IsratJahanEasha from "../assets/Faculty/ScienceandEngineering/IsratJahanEasha.jpeg";
import KhairulEFahim from "../assets/Faculty/ScienceandEngineering/KhairulEFahim.jpeg";
import MdEnamulHaque from "../assets/Faculty/ScienceandEngineering/MdEnamulHaque.jpeg";
import MohammedShamsulArifin from "../assets/Faculty/ScienceandEngineering/MohammedShamsulArifin.jpg";
import ProfDrMdFaruqueAhmedHaolader from "../assets/Faculty/ScienceandEngineering/ProfDrMdFaruqueAhmedHaolader.jpg";
import ProfFokhruzZaman from "../assets/Faculty/ScienceandEngineering/ProfFokhruzZaman.jpg";
import ProfSyedAbdulAziz from "../assets/Faculty/ScienceandEngineering/ProfSyedAbdulAziz.jpg";
import RahatUddin from "../assets/Faculty/ScienceandEngineering/RahatUddin.jpg";
import RajibulAnam from "../assets/Faculty/ScienceandEngineering/RajibulAnam.jpeg";
import RubaiyaAkterDipti from "../assets/Faculty/ScienceandEngineering/RubaiyaAkterDipti.jpg";
import ShaikhMohammadMuntasir from "../assets/Faculty/ScienceandEngineering/ShaikhMohammadMuntasir.jpg";

export const facultyData = {
  0: {
    name: "School of Business Administration",
    faculty: [
      {
        id: 1,
        name: "Dr. Masud Isa",
        title: "Adjunct Professor",
        department: "School of Business Administration",
        expertise: [
          "Management of marketing and industrial ventures",
          "Designing programmes for disadvantaged communities",
          "ICT for poverty alleviation strategies",
          "Management of organisations for sustainable development",
          "Design and management of microfinance operations",
          "MIS, Planning and Budgeting in microfinance organisations",
          "ICT strategies for marginalised communities",
          "Designing social and inclusive business enterprises",
        ],
        email: "masud.isa@zums.edu",
        phone: "+880 1234567890",
        image: DrMasudIsa,
        bio: "Leading expert in corporate finance with over 15 years of experience in academia and industry",
      },
      {
        id: 2,
        name: "Zakir Ahmed Khan",
        title: "Adjunct Professor",
        department: "School of Business Administration",
        expertise: ["Taxation", "Public Finance", "Fiscal Policy"],
        email: "zakir.khan@zums.edu",
        phone: "+880 1234567901",
        image: ZakirAhmedKhan,
        bio: "Expert in taxation systems and public finance management",
      },
      {
        id: 3,
        name: "Dr. Jamaluddin Ahmed, FCA",
        title: "Professor",
        department: "School of Business Administration",
        expertise: [
          "Financial Accounting",
          "Auditing",
          "Chartered Accountancy",
        ],
        email: "jamal.ahmed@zums.edu",
        phone: "+880 1234567893",
        image: JamalUddinAhmedFCA,
        bio: "Expert in financial accounting and chartered accountancy",
      },
      {
        id: 4,
        name: "Md Mohiuddin",
        title: "Professor",
        department: "School of Business Administration",
        expertise: [
          "Supply Chain Management",
          "Operations Research",
          "Quality Management",
        ],
        email: "mohiuddin@zums.edu",
        phone: "+880 1234567896",
        image: mohiuddin,
        bio: "Researcher in logistics optimization and supply chain practices",
      },
      {
        id: 5,
        name: "Dr. Moshahid ur Rahman",
        title: "Professor",
        department: "School of Business Administration",
        expertise: [
          "Entrepreneurship",
          "Small Business Management",
          "Innovation Management",
        ],
        email: "moshahidur.rahman@zums.edu",
        phone: "+880 1234567897",
        image: MoshahidurRahman,
        bio: "Researcher in entrepreneurship development and innovation ecosystems",
      },
      {
        id: 6,
        name: "Raihan Amin",
        title: "Adjunct Assistant Professor",
        department: "School of Business Administration",
        expertise: [
          "International Trade",
          "Economic Policy",
          "Development Economics",
        ],
        email: "rayhan.amin@zums.edu",
        phone: "+880 1234567899",
        image: RayhanAmin,
        bio: "Specialist in international trade policies and economic development",
      },
      {
        id: 7,
        name: "Farhana Hussain",
        title: "Assistant Professor",
        department: "School of Business Administration",
        expertise: [
          "Digital Marketing",
          "Consumer Behavior",
          "Brand Management",
        ],
        email: "farhana.hussain@zums.edu",
        phone: "+880 1234567891",
        image: FarhanaHussain,
        bio: "Specialist in digital marketing transformation and consumer psychology",
      },
      {
        id: 8,
        name: "Ummey Salma",
        title: "Assistant Professor",
        department: "School of Business Administration",
        expertise: [
          "Foreign Direct Investment (FDI)",
          "Capital Market Efficiency",
          "Stock Market Behavior and Sentiment",
          "Government Policies and FDI Attraction",
          "Capital Structure Decisions",
          "Financial Risk Assessment",
        ],
        email: "ummey.salma@zums.edu",
        phone: "+880 1234567900",
        image: UmmeySalma,
        bio: "Researcher in business ethics and sustainable business practices",
      },
      {
        id: 9,
        name: "Ishfaque Waseek",
        title: "Assistant Professor",
        department: "School of Business Administration",
        expertise: [
          "Investment Banking",
          "Financial Markets",
          "Risk Management",
        ],
        email: "ishfaque.waseek@zums.edu",
        phone: "+880 1234567892",
        image: IshfaqueWaseek,
        bio: "Former investment banker with expertise in financial markets",
      },
      {
        id: 10,
        name: "Mohammad Hares",
        title: "Assistant Professor",
        department: "School of Business Administration",
        expertise: [
          "Financial Management",
          "Corporate Governance",
          "Business Strategy",
        ],
        email: "mohammad.hares@zums.edu",
        phone: "+880 1234567895",
        image: MohammadHares,
        bio: "Expert in financial management and corporate strategy",
      },
      {
        id: 11,
        name: "Nurul Afsar Chowdhury",
        title: "Adjunct Assistant Professor",
        department: "School of Business Administration",
        expertise: [
          "Business Analytics",
          "Decision Science",
          "Information Systems",
        ],
        email: "nurul.afser@zums.edu",
        phone: "+880 1234567898",
        image: NurulAfserChowdhury,
        bio: "Expert in business analytics and data-driven decision making",
      },
      {
        id: 12,
        name: "Md Nurul Hasan",
        title: "Lecturer",
        department: "School of Business Administration",
        expertise: [
          "Human Resource Management",
          "Organizational Development",
          "Leadership Studies",
        ],
        email: "nurul.hasan@zums.edu",
        phone: "+880 1234567894",
        image: MdNurulHasan,
        bio: "Specialized in organizational leadership and human capital development",
      },
    ],
  },
  1: {
    name: "School of Economics",
    faculty: [
      {
        id: 1,
        name: "Dr. Mohammad Osman Gani",
        title: "Professor",
        department: "School of Economics",
        expertise: ["Microeconomics", "Labor Economics", "Econometrics"],
        email: "osman.gani@zums.edu",
        phone: "+880 1934567890",
        image: DrMohammadOsmanGani,
        bio: "Experienced researcher in microeconomic theory and labor market dynamics",
      },
      {
        id: 2,
        name: "Dr. Mohiuddin Ahmad",
        title: "Professor",
        department: "School of Economics",
        expertise: [
          "Macroeconomics",
          "Development Economics",
          "Economic Policy",
        ],
        email: "mohiuddin.ahmad@zums.edu",
        phone: "+880 1712345678",
        image: DrMohiuddinAhmad,
        bio: "Leading researcher in development economics with over 20 years of experience",
      },
      {
        id: 3,
        name: "Dr. Reza Kibria",
        title: "Professor",
        department: "School of Economics",
        expertise: [
          "Public Economics",
          "Fiscal Policy",
          "International Finance",
        ],
        email: "reza.kibria@zums.edu",
        phone: "+880 1823456789",
        image: DrRezaKibria,
        bio: "Expert in public economics and international financial systems",
      },
      {
        id: 4,
        name: "Dr. Bernard Wasow",
        title: "Adjunct Professor",
        department: "School of Economics",
        expertise: [
          "International Economics",
          "Economic Theory",
          "Public Policy",
        ],
        email: "bernard.wasow@zums.edu",
        phone: "+880 1823456789",
        image: DrBernardWasow,
        bio: "Distinguished economist with expertise in international economic relations",
      },
      {
        id: 5,
        name: "Dr. Lorraine Ruffing",
        title: "Adjunct Professor",
        department: "School of Economics",
        expertise: [
          "International Trade",
          "Development Economics",
          "Global Finance",
        ],
        email: "lorraine.ruffing@zums.edu",
        phone: "+880 1745678901",
        image: LorraineRuffing,
        bio: "International trade expert with experience at major global institutions",
      },
      {
        id: 6,
        name: "Brishti Bhowmik",
        title: "Assistant Professor",
        department: "School of Economics",
        expertise: [
          "Environmental Economics",
          "Sustainable Development",
          "Resource Economics",
        ],
        email: "brishti.bhowmik@zums.edu",
        phone: "+880 1567890123",
        image: BrishtiBhowmik,
        bio: "Researcher focused on environmental economics and sustainability (On Study Leave)",
      },
      {
        id: 7,
        name: "Afrin Jannat",
        title: "Lecturer",
        department: "School of Economics",
        expertise: [
          "Financial Economics",
          "Monetary Policy",
          "Banking Systems",
        ],
        email: "afrin.jannat@zums.edu",
        phone: "+880 1656789012",
        image: AfrinJannat,
        bio: "Specialist in financial economics and monetary policy analysis",
      },
    ],
  },
  2: {
    name: "School of Science & Engineering",
    faculty: [
      {
        id: 1,
        name: "Prof. Dr. Md. Faruque Ahmed Haolader",
        title: "Professor",
        department: "School of Science & Engineering",
        expertise: [
          "Computer Science",
          "Engineering Education",
          "Information Systems",
        ],
        email: "faruque.ahmed@zums.edu",
        phone: "+880 1712345670",
        image: ProfDrMdFaruqueAhmedHaolader,
        bio: "Distinguished professor with extensive research in computer science and engineering education",
      },
      // {
      //   id: 2,
      //   name: "Dr. Sheikh Abdul Latif",
      //   title: "Professor",
      //   department: "School of Science & Engineering",
      //   expertise: ["Mechatronics", "Robotics", "Automation Systems"],
      //   email: "abdul.latif@zums.edu",
      //   phone: "+880 1745678903",
      //   image: DrSheikhAbdulLatif,
      //   bio: "Researcher in robotics and automated control systems",
      // },
      {
        id: 3,
        name: "Prof. Fokhruz Zaman",
        title: "Professor",
        department: "School of Science & Engineering",
        expertise: [
          "Electrical Engineering",
          "Power Systems",
          "Renewable Energy",
        ],
        email: "fokhruz.zaman@zums.edu",
        phone: "+880 1723456781",
        image: ProfFokhruzZaman,
        bio: "Expert in electrical engineering and renewable energy systems",
      },
      {
        id: 4,
        name: "Dr. Riasat Islam",
        title: "Adjunct Assistant Professor",
        department: "School of Science & Engineering",
        expertise: [
          "Computer Networks",
          "Data Communications",
          "Network Security",
        ],
        email: "riasat.islam@zums.edu",
        phone: "+880 1756789014",
        image: DrRiasatIslam,
        bio: "Expert in network systems and data communication technologies",
      },
      {
        id: 5,
        name: "Khairul E. Fahim, PhD (Candidate)",
        title: "Associate Professor",
        department: "School of Science & Engineering",
        expertise: [
          "Internet of Things (IoT)",
          "Wireless Networks",
          "Sensor Technologies",
        ],
        email: "khairul.fahim@zums.edu",
        phone: "+880 1801234569",
        image: KhairulEFahim,
        bio: "Researcher in IoT applications and wireless sensor networks",
      },
      {
        id: 6,
        name: "Md Enamul Haque",
        title: "Adjunct Assistant Professor & Director Entrepreneur Development Research Center (EDRC)",
        department: "School of Science & Engineering",
        expertise: [
          "Electronic Engineering",
          "Embedded Systems",
          "Digital Design",
        ],
        email: "enamul@zums.edu.bd",
        phone: "+880 1778901236",
        image: MdEnamulHaque,
        bio: "Expert in electronic circuit design and embedded system development",
      },
      {
        id: 7,
        name: "Mohammed Shamsul Arifin",
        title: "Associate Professor",
        department: "School of Science & Engineering",
        expertise: [
          "Software Engineering",
          "Database Systems",
          "Web Technologies",
        ],
        email: "shamsul.arifin@zums.edu",
        phone: "+880 1767890125",
        image: MohammedShamsulArifin,
        bio: "Specialist in software development and database management systems",
      },
      {
        id: 8,
        name: "Rajibul Anam",
        title: "Assistant Professor",
        department: "School of Science & Engineering",
        expertise: [
          "Artificial Intelligence",
          "Machine Learning",
          "Data Science",
        ],
        email: "rajibul.anam@zums.edu",
        phone: "+880 1789012347",
        image: RajibulAnam,
        bio: "Researcher in AI algorithms and machine learning applications",
      },
      {
        id: 9,
        name: "Israt Jahan Easha",
        title: "Lecturer",
        department: "School of Science & Engineering",
        expertise: ["Information Security", "Cryptography", "Cyber Security"],
        email: "israt.easha@zums.edu",
        phone: "+880 1812345670",
        image: IsratJahanEasha,
        bio: "Specialist in information security and cryptographic systems",
      },
      {
        id: 10,
        name: "Rahat Uddin",
        title: "Lecturer",
        department: "School of Science & Engineering",
        expertise: ["Web Development", "Cloud Computing", "DevOps"],
        email: "rahatuddin@zums.edu.bd",
        phone: "+880 1823456781",
        image: RahatUddin,
        bio: "Expert in web technologies and cloud deployment systems",
      },
      {
        id: 11,
        name: "Rubaiya Rahman Dipti",
        title: "Lecturer",
        department: "Computer Science and Engineering",
        expertise: [
          "Big Data Analytics",
          "Data Visualization",
          "Business Intelligence",
        ],
        email: "rubaiya.dipti@zums.edu",
        phone: "+880 1834567892",
        image: RubaiyaAkterDipti,
        bio: "Specialist in data analytics and visualization techniques",
      },
      {
        id: 12,
        name: "Shaikh Mohammad Muntasir",
        title: "Lecturer",
        department: "School of Science & Engineering",
        expertise: [
          "Mobile Application Development",
          "Computer Graphics",
          "Human-Computer Interaction",
        ],
        email: "mohammad.muntasir@zums.edu",
        phone: "+880 1790123458",
        image: ShaikhMohammadMuntasir,
        bio: "Specialist in mobile computing and interactive system design",
      },
    ],
  },
  3: {
    name: "School of Humanities & Social Science",
    faculty: [
      {
        id: 1,
        name: "Prof. Mustafizur Rahman",
        title: "Professor",
        department: "School of Humanities and Social Sciences",
        expertise: [
          "Language and Literature",
          "Cultural Studies",
          "Literary Criticism",
        ],
        email: "mustafizur.rahman@zums.edu",
        phone: "+880 1234567898",
        image: ProfMustafizurRahman,
        bio: "Leading researcher in Bengali literature and cultural studies",
      },
      {
        id: 2,
        name: "Seema Haider Chaudhury",
        title: "Associate Professor",
        department: "School of Humanities and Social Sciences",
        expertise: ["Philosophy", "Ethics", "Critical Thinking"],
        email: "seema.haydar@zums.edu",
        phone: "+880 1234567903",
        image: SeemaHaydarChawdhuri,
        bio: "Philosophy scholar specializing in ethics and moral reasoning",
      },
      {
        id: 4,
        name: "Mohammad Shahadat Hossain",
        title: "Associate Professor",
        department: "School of Humanities and Social Sciences",
        expertise: ["Sociology", "Anthropology", "Social Development"],
        email: "shahadat.hossain@zums.edu",
        phone: "+880 1234567899",
        image: MdShahadatHossain,
        bio: "Expert in sociology with focus on social structures in developing countries",
      },
      {
        id: 5,
        name: "Rownak Jahan Oishi",
        title: "Lecturer",
        department: "School of Humanities and Social Sciences",
        expertise: [
          "English Language Teaching",
          "Communication Studies",
          "Linguistics",
        ],
        email: "rownak.jahan@zums.edu",
        phone: "+880 1234567901",
        image: RownakJahanOishi,
        bio: "Language specialist with expertise in English language teaching methodologies",
      },
      {
        id: 6,
        name: "Faiyaz Ahmed",
        title: "Lecturer",
        department: "School of Humanities and Social Sciences",
        expertise: [
          "International Relations",
          "Political Science",
          "Diplomacy",
        ],
        email: "faiyaz.ahmed@zums.edu",
        phone: "+880 1234567900",
        image: FaiyazAhmed,
        bio: "Specialist in international relations and political development",
      },
    ],
  },
};
