/* eslint-disable react/prop-types */

const GlobalTitle = ({ title }) => {
  return (
    <div>
      <h2 className="text-3xl font-bold text-primary font-domine ">{title}</h2>
      <div className="w-16 h-1 my-4 bg-primary"></div>
    </div>
  );
};

export default GlobalTitle;
