import { PhoneCall } from "lucide-react";
import bannerImage from "../assets/infoBg.jpg";
import GlobalTitle from "../components/shared/GlobalTitle";
import RoundedButton from "../components/ui/RoundedButton";
// import policyBg from "../assets/GraduationHat2.svg";
import { motion } from "framer-motion";
const InfoCenter = () => {
  // Dynamic data for info sections
  const infoSections = [
    {
      title: "Talk to Us",
      description: (
        <div>
          <div className="grid justify-between grid-cols-2 mt-2">
            <div>
              <h4 className="font-medium text-gray-800 ">
                ZUMS Admission Office:
              </h4>
              <ul className="pt-2 text-primary">
                {[
                  "01877 700201",
                  "01877 700202",
                  "01877 700203",
                  "01877 700206",
                ].map((number) => (
                  <li key={number} className="flex items-center gap-2">
                    <PhoneCall className="size-4" />
                    <a
                      href={`tel:${number}`}
                      className="text-gray-700 hover:underline"
                    >
                      {number}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="font-medium text-gray-800 ">
                ZUMS Accounts Office
              </h4>
              <div className="flex items-center gap-2 text-primary">
                <PhoneCall className="size-4" />
                <a
                  href="tel:01314553095"
                  className="text-gray-700 hover:underline"
                >
                  01314553095
                </a>
              </div>
            </div>
          </div>
        </div>
      ),
      button: {
        text: "Campus Maps and Directions",
        link: "https://maps.app.goo.gl/Ngp6QDBBa21b7rmo6",
      },
    },
    {
      title: "Academic Calendars",
      description:
        "Stay updated with the academic schedules, important dates, and other related events.",
      button: {
        text: "View Academic Calendars",
        link: "/academics/academic-calender",
      },
    },
    {
      title: "News & Events",
      description:
        "The Newsroom is the source for breaking news and feature stories about St. Thomas and its community. You can also view a list of St. Thomas publications.",
      button: { text: "Go to the Newsroom", link: "/news-and-events" },
    },
    {
      title: "Policies & Compliance",
      description:
        "Get direct access to the university policy you're looking for.",
      customContent: (
        <div className="grid gap-10 md:grid-cols-2 ">
          <RoundedButton
            href={"/terms-and-condition"}
            className="w-full text-black sm:w-44 md:w-52"
          >
            Terms & Conditions
          </RoundedButton>
          <RoundedButton
            href={"/grading-system"}
            className="w-full text-black sm:w-44 md:w-52"
          >
            Grading System
          </RoundedButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      {/* banner */}
      <div className="relative w-full z-10 h-[70vh]  lg:h-[50vh]">
        <img
          src={bannerImage || "/placeholder.svg"}
          alt="VC Banner"
          className="absolute inset-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 z-20 w-full h-full bg-gradient-to-r from-primary/90 via-primary/40 to-primary/10"></div>
        <div className="container relative z-20 flex items-center justify-start w-full h-full p-8 mx-auto">
          {/* Text content */}
          <div className="text-white">
            <p className="my-4 text-2xl font-semibold">— Info Center</p>
            <h1 className="text-4xl font-bold md:text-5xl lg:text-6xl font-domine">
              Looking for something?
            </h1>
          </div>
        </div>
      </div>

      {/* info */}
      <div className="container px-4 py-12 mx-auto lg:py-20 md:px-8 ">
        <div className="space-y-20 lg:space-y-40">
          {Array.from(
            { length: Math.ceil(infoSections.length / 2) },
            (_, i) => (
              <motion.div
                key={i}
                className="grid grid-cols-1 gap-20 lg:gap-10 lg:grid-cols-2"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.1 }}
                transition={{
                  staggerChildren: 0.2,
                  duration: 0.6,
                  ease: "easeInOut",
                }}
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: { opacity: 1, x: 0 },
                }}
              >
                {infoSections.slice(i * 2, i * 2 + 2).map((section, index) => (
                  <motion.div
                    key={index}
                    className={`${
                      index === 0
                        ? "lg:border-r-2 lg:border-dashed lg:border-primary lg:pr-8"
                        : "lg:pl-8"
                    }`}
                    variants={{
                      hidden: { opacity: 0, y: 50 },
                      visible: { opacity: 1, y: 0 },
                    }}
                  >
                    <GlobalTitle title={section.title} />
                    <p className="pb-5 lg:pb-10">{section.description}</p>
                    {section.customContent ? (
                      section.customContent
                    ) : (
                      <RoundedButton href={section?.button?.link}>
                        {section.button.text}
                      </RoundedButton>
                    )}
                  </motion.div>
                ))}
              </motion.div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoCenter;
