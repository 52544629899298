import headerImage from "../../assets/schools/schoolOfBusinessAdministration2.jpeg";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import ImageHeading from "../../components/ui/ImageHeading";
import RoundedButton from "../../components/ui/RoundedButton";
import DepartmentFacultyCard from "../../components/ui/Card/DepartmentFacultyCard";
import FeaturedEvents from "../../components/ui/FeaturedEvents";
import DrMasudIsa from "../../assets/Faculty/BusinessAdministration/DrMasudIsa.jpg";
import ZakirAhmedKhan from "../../assets/Faculty/BusinessAdministration/ZakirAhmedKhan.jpg";
import mohiuddin from "../../assets/Faculty/BusinessAdministration/mohiuddin.jpg";
import JamalUddinAhmedFCA from "../../assets/Faculty/BusinessAdministration/JamalUddinAhmedFCA.jpg";
const Academics = () => {
  const facultyMembers = [
    {
      id: 1,
      name: "Dr. Masud Isa",
      title: "Adjunct Professor",
      department: "School of Business Administration",
      expertise: [
        "Management of marketing and industrial ventures",
        "Designing programmes for disadvantaged communities",
        "ICT for poverty alleviation strategies",
        "Management of organisations for sustainable development",
        "Design and management of microfinance operations",
        "MIS, Planning and Budgeting in microfinance organisations",
        "ICT strategies for marginalised communities",
        "Designing social and inclusive business enterprises",
      ],
      email: "masud.isa@zums.edu",
      phone: "+880 1234567890",
      image: DrMasudIsa,
      bio: "Leading expert in corporate finance with over 15 years of experience in academia and industry",
    },
    {
      id: 2,
      name: "Zakir Ahmed Khan",
      title: "Adjunct Professor",
      department: "School of Business Administration",
      expertise: ["Taxation", "Public Finance", "Fiscal Policy"],
      email: "zakir.khan@zums.edu",
      phone: "+880 1234567901",
      image: ZakirAhmedKhan,
      bio: "Expert in taxation systems and public finance management",
    },
    {
      id: 3,
      name: "Dr. Jamaluddin Ahmed, FCA",
      title: "Professor",
      department: "School of Business Administration",
      expertise: ["Financial Accounting", "Auditing", "Chartered Accountancy"],
      email: "jamal.ahmed@zums.edu",
      phone: "+880 1234567893",
      image: JamalUddinAhmedFCA,
      bio: "Expert in financial accounting and chartered accountancy",
    },
    {
      id: 4,
      name: "Md Mohiuddin",
      title: "Professor",
      department: "School of Business Administration",
      expertise: [
        "Supply Chain Management",
        "Operations Research",
        "Quality Management",
      ],
      email: "mohiuddin@zums.edu",
      phone: "+880 1234567896",
      image: mohiuddin,
      bio: "Researcher in logistics optimization and supply chain practices",
    },
  ];

  return (
    <div className="min-h-screen ">
      <ImageHeading
        heading="School Of Business Administration"
        imageUrl={headerImage}
        subHeading="Lorem ipsum dolor sit amet consectetur adipisicing elit."
      />
      {/* <div
        style={{
          backgroundImage: `url(${headerImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="flex justify-start relative items-center h-[50vh] text-white"
      >
        <div className="absolute inset-0 w-full h-full bg-black/40"></div>
        <div className="container absolute inset-0 left-0 mx-auto h-fit top-1/3">
          <div className="px-5 py-5 w-fit bg-primary">
            <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl">
              School Of Business <br /> Administration
            </h1>
          </div>
        </div>
      </div> */}

      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <section className="grid grid-cols-1 gap-12 md:grid-cols-3">
          <div className="col-span-2 space-y-4 text-gray-700 ">
            <p>
              The School of Business Administration at ZUMS prepares future
              leaders to navigate the complexities of the modern business world.
              We offer a dynamic curriculum blending theory and practice,
              fostering innovation, ethical decision-making, and a global
              perspective. Develop the skills to lead, manage, and succeed in
              today&#39;s competitive business landscape.
            </p>
          </div>
          <div className="flex flex-col items-center space-y-4">
            {/* <div className="w-full pb-2 border-b border-primary">
              <Link className="flex items-center justify-between" to="/Schools">
                <h3 className="text-lg font-semibold text-primary">
                  Explore All Schools
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div> */}
            <div className="w-full pb-2 border-b border-primary">
              <Link
                className="flex items-center justify-between"
                to="/academics/academic-Calendar"
              >
                <h3 className="text-lg font-semibold text-primary">
                  Academic Calendar
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div>
            <div className="w-full pb-2 border-b border-primary">
              <Link
                className="flex items-center justify-between"
                to="https://103.60.173.170/zumsadmission/Admission/Home.aspx"
              >
                <h3 className="text-lg font-semibold text-primary">
                  Apply Now
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div>
          </div>
        </section>
      </div>
      <div className=" relative text-gray-200 bg-[#AC0D30] overflow-hidden">
        {/* Background Pattern */}
        <div
          className="absolute inset-0 opacity-20"
          style={{
            backgroundImage:
              "radial-gradient(circle at 25px 25px, rgba(255, 255, 255, 0.2) 2%, transparent 0%), " +
              "radial-gradient(circle at 75px 75px, rgba(255, 255, 255, 0.2) 2%, transparent 0%)",
            backgroundSize: "100px 100px",
          }}
        />

        {/* Gradient Overlays */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#AC0D30]/50 to-[#AC0D30]" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />

        <div className="container grid grid-cols-1 gap-10 px-4 py-12 mx-auto lg:gap-0 sm:px-6 lg:px-8 md:grid-cols-2">
          <div className="z-20 border-gray-200 lg:border-r lg:pr-24">
            <h3 className="text-3xl font-bold font-domine lg:text-4xl">
              BBA Program
            </h3>
            <p className="mt-8 text-gray-200">
              The BBA program at ZNRF University provides students with a strong
              foundation in business principles, leadership, and management. The
              curriculum is designed to develop critical thinking,
              decision-making, and entrepreneurial skills.
            </p>

            <div className="flex items-center justify-center ">
              <RoundedButton
                className={
                  "mt-8 py-4 font-bold font-display px-6 w-fit text-center border-white text-white"
                }
                spanClassName={"text-white"}
                href={
                  "/academics/school-of-science-and-engineering/business-administration"
                }
              >
                Explore BBA Program
              </RoundedButton>
            </div>
          </div>
          <div className="z-20 lg:pl-24">
            <h3 className="text-3xl font-bold font-domine lg:text-4xl">
              MBA Program
            </h3>
            <p className="mt-8 text-gray-200">
              The MBA program at ZNRF University is designed for aspiring
              business leaders and professionals. It offers advanced knowledge
              in business strategy, leadership, and innovation, preparing
              graduates for executive roles in global organizations.
            </p>

            <div className="flex items-center justify-center ">
              <RoundedButton
                className={
                  "mt-8 py-4 font-bold font-display px-6 w-fit text-center border-white text-white"
                }
                spanClassName={"text-white"}
                href={
                  "/academics/school-of-science-and-engineering/master-business-administration"
                }
              >
                Explore MBA Program
              </RoundedButton>
            </div>
          </div>
        </div>
      </div>

      {/* Faculty */}
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8 ">
        <h2 className="text-4xl font-bold text-center font-domine">
          Faculty members
        </h2>
        <div className="grid grid-cols-1 gap-5 my-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 lg:gap-6">
          {facultyMembers.map((faculty, index) => (
            <DepartmentFacultyCard key={index} {...faculty} />
          ))}
        </div>
        <div className="flex justify-center w-full">
          <RoundedButton href={"/discover-zums/faculty"}>
            View All Faculty Members
          </RoundedButton>
        </div>
      </div>

      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <FeaturedEvents />
      </div>
    </div>
  );
};

export default Academics;
