import admin1 from "../assets/advisory.jpg";
import admin2 from "../assets/advisory.jpg";
import admin3 from "../assets/advisory.jpg";
import admin4 from "../assets/advisory.jpg";
import admin5 from "../assets/advisory.jpg";
import admin6 from "../assets/advisory.jpg";

// Import VC images from Administration folder
import ProfDrMZubaidurRahman from "../assets/Adminstration/adminHeads/VC/ProfDrMZubaidurRahman.jpeg";
import ProfessorMZubaidurRahman from "../assets/Adminstration/adminHeads/VC/ProfessorMZubaidurRahman.jpg";
import VCSir from "../assets/Adminstration/adminHeads/VC/VCSir.jpeg";

// Import Pro VC images from Administration folder
import ProfMohammadMohiuddin from "../assets/Adminstration/adminHeads/ProVC/ProfMohammadMohiuddin.jpg";

// Import Registrar images from Administration folder
import DrMohammadAli from "../assets/Adminstration/adminHeads/Registrar/DrMohammadAli.jpg";

// Import administration staff images
import AbdullahAlAraf from "../assets/Adminstration/adminstrationData/AbdullahAlAraf(ResearchandDevelopmentOfficer).jpg";
import RumaHaldar from "../assets/Faculty/HumanitiesandSocialSciences/RumaHaldar.jpg";
import MohammadHares from "../assets/Faculty/BusinessAdministration/MohammadHares.jpg";
import AkhiAkter from "../assets/Adminstration/adminstrationData/AkhiAkter(StudentCounselor).jpg";
import AlexBarai from "../assets/Adminstration/adminstrationData/AlexBarai(AdministrativeOfficer).jpg";
import JannatulFerdousMim from "../assets/Adminstration/adminstrationData/JannatulFerdousMim(Librarian).jpg";
import MdMojnu from "../assets/Adminstration/adminstrationData/MdMojnu(SecurityIncherge).jpg";
import MdMolamSarwararMorshed from "../assets/Adminstration/adminstrationData/MdMolamSarwararMorshed(AssistantRegistrar).jpeg";
import MdRahat from "../assets/Adminstration/adminstrationData/MdRahat(StudentCounselor).jpg";
import MdRakibHasan from "../assets/Adminstration/adminstrationData/MdRakibHasan(ITOfficer).jpg";
import NasimBegumSimi from "../assets/Adminstration/adminstrationData/NasimBegumSimi(StudentCounselor).jpg";
import Ramkumar from "../assets/Adminstration/adminstrationData/Ramkumar(Driver).jpg";
import SabrinaMohi from "../assets/Adminstration/adminstrationData/SabrinaMohi(StudentCounselor).jpg";
import SharovMondolJony from "../assets/Adminstration/adminstrationData/SharovMondolJony(HeadofAccountOffice).jpg";
import ShumiBala from "../assets/Adminstration/adminstrationData/ShumiBala(Messenger).jpg";
import StefenSoran from "../assets/Adminstration/adminstrationData/StefenSoran(Messenger).jpg";
import SujonDatta from "../assets/Adminstration/adminstrationData/SujonDatta(StudentRelationsOfficer).jpg";

export const adminHeads = {
  members: [
    {
      id: 1,
      name: "Professor Dr. M Zubaidur Rahman",
      title: "Vice Chancellor",
      department: "Office of the Vice Chancellor",
      responsibilities: [
        "University Administration",
        "Strategic Leadership",
        "Academic Excellence",
      ],
      email: "vc@zums.edu",
      phone: "+880 1234567001",
      image: ProfDrMZubaidurRahman,
      bio: "Distinguished academic leader with extensive experience in higher education administration",
    },
    {
      id: 2,
      name: "Professor Mohammad Mohiuddin",
      title: "Pro-Vice Chancellor",
      department: "Office of the Pro-Vice Chancellor",
      responsibilities: [
        "Academic Affairs",
        "Faculty Development",
        "Research Initiatives",
      ],
      email: "provc@zums.edu",
      phone: "+880 1234567002",
      image: ProfMohammadMohiuddin,
      bio: "Dedicated to advancing academic programs and research excellence",
    },
    {
      id: 3,
      name: "Dr. Mohammad Ali",
      title: "Registrar",
      department: "Office of the Registrar",
      responsibilities: [
        "Administrative Operations",
        "Student Records",
        "Institutional Compliance",
      ],
      email: "registrar@zums.edu",
      phone: "+880 1234567003",
      image: DrMohammadAli,
      bio: "Experienced administrator overseeing university operations and records management",
    },
  ],
};

export const administrationData = {
  0: {
    name: "Registrar's Office",
    members: [
      {
        id: 1,
        name: "Md Golam Sorwer Morshed",
        title: "Assistant Registrar",
        department: "Registrar's Office",
        responsibilities: [
          "Administrative Support",
          "Records Management",
          "Student Services",
        ],
        email: "morshed@zums.edu.bd",
        phone: "+880 1723456781",
        image: MdMolamSarwararMorshed,
        bio: "Dedicated administrator responsible for day-to-day operations of the registrar's office",
      },
      {
        id: 2,
        name: "Ruma Haldar",
        title: "Controller of Examinations",
        department: "Examination Controll",
        expertise: ["Gender Studies", "Social Justice", "Development Studies"],
        email: "ruma.haldar@zums.edu",
        phone: "+880 1234567902",
        image: RumaHaldar,
        bio: "Researcher in gender studies and social equality initiatives",
      },
      {
        id: 4,
        name: "Mohammad Hares",
        title: "Proctor",
        department: "Proctor",
        expertise: [
          "Financial Management",
          "Corporate Governance",
          "Business Strategy",
        ],
        email: "mohammad.hares@zums.edu",
        phone: "+880 1234567895",
        image: MohammadHares,
        bio: "Expert in financial management and corporate strategy",
      },
      {
        id: 5,
        name: "Sourav Mondol Jony",
        title: "Head of Accounts Department",
        department: "Finance",
        responsibilities: [
          "Financial Management",
          "Budget Planning",
          "Accounting Operations",
        ],
        email: "sharov.jony@zums.edu",
        phone: "+880 1812345670",
        image: SharovMondolJony,
        bio: "Overseeing university financial operations and accounting procedures",
      },
      {
        id: 6,
        name: "Alex Barai",
        title: "Administrative Officer",
        department: "Administration",
        responsibilities: [
          "Office Management",
          "Administrative Support",
          "Staff Coordination",
        ],
        email: "alex.barai@zums.edu",
        phone: "+880 1789012347",
        image: AlexBarai,
        bio: "Managing day-to-day administrative operations of the university",
      },
      {
        id: 7,
        name: "Jannatul Mawa Mim",
        title: "Librarian",
        department: "Library",
        responsibilities: [
          "Library Management",
          "Resource Acquisition",
          "Research Support",
        ],
        email: "jannatul@zums.edu.bd",
        phone: "+880 1823456781",
        image: JannatulFerdousMim,
        bio: "Managing the university's library resources and supporting academic research",
      },
      {
        id: 8,
        name: "Abdullah Al Araf",
        title: "Research and Development Officer",
        department: "ZNRF University Incubation Center",
        responsibilities: [
          "Research Support",
          "Project Coordination",
          "Grant Management",
        ],
        email: "araf@zums.edu.bd",
        phone: "+880 1801234569",
        image: AbdullahAlAraf,
        bio: "Supporting faculty and student research initiatives and development projects",
      },
      {
        id: 9,
        name: "Md. Rakib Hasan",
        title: "IT Officer",
        department: "Information Technology",
        responsibilities: [
          "IT Infrastructure",
          "Technical Support",
          "Systems Administration",
        ],
        email: "rakib.hasan@zums.edu",
        phone: "+880 1790123458",
        image: MdRakibHasan,
        bio: "Ensuring smooth operation of university IT systems and technical infrastructure",
      },
      {
        id: 10,
        name: "Sujon Datta",
        title: "Student Relations Officer",
        department: "Student Affairs",
        responsibilities: [
          "Student Support",
          "Event Coordination",
          "Campus Activities",
        ],
        email: "sujon.datta@zums.edu",
        phone: "+880 1734567892",
        image: SujonDatta,
        bio: "Committed to enhancing student experience and campus community engagement",
      },
      {
        id: 11,
        name: "Sabrina Mohi",
        title: "Student Counselor",
        department: "Student Affairs",
        responsibilities: [
          "Student Support",
          "Crisis Intervention",
          "Personal Development",
        ],
        email: "sabrina.mohi@zums.edu",
        phone: "+880 1767890125",
        image: SabrinaMohi,
        bio: "Providing comprehensive support for student wellbeing and success",
      },
      {
        id: 12,
        name: "Mofazzel Hossain Rahat",
        title: "Student Counselor",
        department: "Student Affairs",
        responsibilities: [
          "Academic Counseling",
          "Career Guidance",
          "Student Development",
        ],
        email: "md.rahat@zums.edu",
        phone: "+880 1756789014",
        image: MdRahat,
        bio: "Helping students navigate academic challenges and career decisions",
      },
      {
        id: 13,
        name: "Nasim Begum Simi",
        title: "Student Counselor",
        department: "Student Affairs",
        responsibilities: [
          "Personal Counseling",
          "Group Facilitation",
          "Wellness Workshops",
        ],
        email: "nasim.simi@zums.edu",
        phone: "+880 1778901236",
        image: NasimBegumSimi,
        bio: "Experienced counselor focused on holistic student development",
      },
      {
        id: 14,
        name: "Akhi Akter",
        title: "Front Desk Executive",
        department: "Student Affairs",
        responsibilities: [
          "Student Assistance",
          "Information Services",
          "Administrative Support",
        ],
        email: "akhi.akter@zums.edu",
        phone: "+880 1745678903",
        image: AkhiAkter,
        bio: "First point of contact for university visitors and student inquiries",
      },
      {
        id: 15,
        name: "Stefen Soran",
        title: "Senior Office Attendant",
        department: "Administrative Services",
        responsibilities: [
          "Campus Deliveries",
          "Administrative Support",
          "Office Assistance",
        ],
        email: "stefen.soran@zums.edu",
        phone: "+880 1867890125",
        image: StefenSoran,
        bio: "Supporting university communications and administrative functions",
      },
      {
        id: 16,
        name: "Shumi Bala",
        title: "Service Staff",
        department: "Administrative Services",
        responsibilities: [
          "Document Delivery",
          "Office Support",
          "Mail Services",
        ],
        email: "shumi.bala@zums.edu",
        phone: "+880 1856789014",
        image: ShumiBala,
        bio: "Facilitating internal communications and document delivery",
      },
      {
        id: 17,
        name: "Md. Mojnu",
        title: "Security Incharge",
        department: "Security",
        responsibilities: [
          "Campus Security",
          "Safety Protocols",
          "Emergency Response",
        ],
        email: "md.mojnu@zums.edu",
        phone: "+880 1834567892",
        image: MdMojnu,
        bio: "Ensuring security and safety across campus facilities",
      },
      {
        id: 18,
        name: "Ram Kumar",
        title: "Driver",
        department: "Transportation",
        responsibilities: [
          "University Transport",
          "Vehicle Maintenance",
          "Transportation Services",
        ],
        email: "ramkumar@zums.edu",
        phone: "+880 1845678903",
        image: Ramkumar,
        bio: "Providing transportation services for university operations",
      },
    ],
  },
};
