import image1 from "../../assets/podcast/femaleVoice.webp";
import image2 from "../../assets/podcast/voice.png";
import image3 from "../../assets/podcast/maleVoice.webp";
import music from "../../assets/podcast/podcast.png";
import profile from "../../assets/Faculty/faculty4.jpg";
import profile2 from "../../assets/Faculty/faculty1.jpg";
import profile3 from "../../assets/Faculty/faculty2.jpg";
import profile4 from "../../assets/Faculty/faculty3.jpg";
import { Music } from "lucide-react";
import HoverButton from "../shared/HoverButton";

const PodHero = () => {
  return (
    <div>
      <h1 className="max-w-4xl mx-auto text-3xl font-bold text-center md:text-5xl lg:text-6xl font-manrope text-primary">
        Empowering conversations for every listener
      </h1>
      <div className="grid items-center justify-between grid-cols-1 gap-10 py-12 md:gap-16 lg:gap-10 md:grid-cols-2 xl:grid-cols-3 ">
        <div className=" order-1 relative cursor-pointer group h-[300px] lg:h-[450px] w-full">
          <img
            src={image1}
            className="absolute inset-0 z-10 object-cover object-center w-full h-full rounded-2xl "
            alt=""
          />
          <div className=" w-[300px] h-fit overflow-hidden z-20 absolute left-[10%] md:left-[6%] lg:left-[15%] -bottom-14 bg-[#F3F6F8] rounded-xl shadow-xl lg:-rotate-[20deg] group-hover:rotate-0 flex justify-center flex-col items-center  cursor-pointer transition-all transform duration-500">
            <div className="flex items-center justify-between w-full px-6 py-4 ">
              <h1 className="text-xl font-semibold md:text-2xl font-domine">
                Listen Now
              </h1>
              <Music className=" text-primary" />
            </div>
            <img className="pb-5 max-w-48" src={music} alt="" />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center order-2 mt-14 ">
          <HoverButton title={"Explore Episodes"} />

          <img
            src={image2}
            className="w-full h-[250px] object-contain lg:h-full p-10 "
            alt=""
          />
        </div>
        <div className=" order-3 relative cursor-pointer group h-[300px] lg:h-[450px] w-full">
          <img
            src={image3}
            className="absolute inset-0 z-10 object-cover object-center w-full h-full rounded-2xl "
            alt=""
          />
          <div className=" w-[300px] h-ful overflow-hidden z-20 absolute left-[10%] md:left-[6%] lg:left-[15%] -bottom-14 bg-[#F3F6F8] rounded-xl shadow-xl lg:-rotate-[20deg] group-hover:rotate-0 flex justify-center flex-col items-center  cursor-pointer transition-all transform duration-500">
            <div className="flex items-center justify-between w-full px-6 py-4 ">
              <h1 className="text-xl font-bold md:text-2xl font-domine">
                20k +
              </h1>
              <p className="text-lg text-primary md:text-xl">Active Listener</p>
            </div>
            <div className="flex pb-4 -space-x-4 rtl:space-x-reverse">
              <img
                className="object-cover w-10 h-10 border-4 border-white rounded-full "
                src={profile}
                alt=""
              />
              <img
                className="object-cover w-10 h-10 border-4 border-white rounded-full "
                src={profile2}
                alt=""
              />
              <img
                className="object-cover w-10 h-10 border-4 border-white rounded-full "
                src={profile3}
                alt=""
              />
              <img
                className="object-cover w-10 h-10 border-4 border-white rounded-full "
                src={profile4}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodHero;
