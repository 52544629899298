/* eslint-disable react/prop-types */

import RoundedButton from "../../../components/ui/RoundedButton";

import { motion } from "framer-motion";
import WhyChooseCSE from "./WhyChooseCSE";

const Card = ({ title, description, icon, delay }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    whileHover={{ scale: 1.05, translateY: -10 }}
    className="flex flex-col items-center p-8 space-y-4 text-center transition-all duration-300 bg-white border border-gray-100 shadow-lg rounded-2xl hover:shadow-2xl"
  >
    <motion.div
      whileHover={{ rotate: 360 }}
      transition={{ duration: 0.5 }}
      className="flex items-center justify-center w-16 h-16 rounded-full bg-primary/10 text-primary"
    >
      {icon}
    </motion.div>
    <h4 className="text-2xl font-bold text-gray-800">{title}</h4>
    <p className="leading-relaxed text-gray-600">{description}</p>
  </motion.div>
);
const OverviewCSE = ({ data }) => {
  return (
    <div className="py-8">
      {/* description */}
      <div className="flex flex-col w-full gap-8 px-8 lg:flex-row">
        <div className="w-full space-y-8 lg:w-3/5">
          <h3 className="w-full text-xl font-bold leading-snug lg:w-4/5 md:text-2xl lg:text-4xl font-domine">
            Welcome to the <span className="text-primary">{data?.title}</span>
          </h3>
          <p className="leading-relaxed text-gray-700">{data?.description}</p>
          <RoundedButton href="https://103.60.173.170/zumsadmission/Admission/Home.aspx" external={true} className="mt-8">Apply Now</RoundedButton>
        </div>
      </div>

      {/* Mission, Vision, and Values section */}
      <div className="px-8 mt-16 space-y-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {data?.missionVision?.map((item, idx) => (
            <Card
              key={idx}
              title={item?.title}
              description={item?.description}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              }
              delay={0.2}
            />
          ))}
        </div>
      </div>

      {/* why choose us */}
      <div className="">
        <WhyChooseCSE data={data?.features} />
      </div>
    </div>
  );
};

export default OverviewCSE;
