import React from "react";
import { motion } from "framer-motion";
import RoundedButton from "../ui/RoundedButton";
import labs1 from "../../assets/Labs/labs1.webp";
import labs2 from "../../assets/Labs/labs2.webp";
import labs3 from "../../assets/Labs/labs3.webp";

const FeaturedOpportunities = () => {
  const opportunities = [
    {
      title: "Faculty Positions",
      description:
        "Join our distinguished faculty and contribute to academic excellence.",
      image: labs1,
    },
    {
      title: "Staff Positions",
      description:
        "Be part of our dedicated administrative and support team. and more",
      image: labs2,
    },
    {
      title: "Research Opportunities",
      description:
        "Engage in groundbreaking research across multiple disciplines.",
      image: labs3,
    },
  ];

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold mb-12 text-center font-domine"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          Featured Opportunities
        </motion.h2>

        <div className="grid md:grid-cols-3 gap-8">
          {opportunities.map((opportunity, index) => (
            <motion.div
              key={opportunity.title}
              className="bg-gray-100 rounded-lg overflow-hidden shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
            >
              <img
                src={opportunity.image || "/placeholder.svg"}
                alt={opportunity.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">
                  {opportunity.title}
                </h3>
                <p className="text-gray-600 mb-4">{opportunity.description}</p>
                <RoundedButton>Learn More</RoundedButton>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedOpportunities;
