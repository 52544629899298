import { useEffect, useState } from "react";
import { useDebounce } from "react-use";

export function useNewsFilter(items) {
  const [filters, setFilters] = useState({
    search: "",
    dateRange: { start: null, end: null },
    categories: [],
    audiences: [],
  });

  const [debouncedSearch, setDebouncedSearch] = useState(filters.search);
  useDebounce(() => setDebouncedSearch(filters.search), 300, [filters.search]);

  const filteredItems = items.filter((item) => {
    // Search filter
    if (
      debouncedSearch &&
      !item.title.toLowerCase().includes(debouncedSearch.toLowerCase())
    ) {
      return false;
    }

    // Date range filter
    if (filters.dateRange.start && filters.dateRange.end) {
      const itemDate = new Date(item.date);
      if (
        itemDate < filters.dateRange.start ||
        itemDate > filters.dateRange.end
      ) {
        return false;
      }
    }

    // Category filter
    if (
      filters.categories.length > 0 &&
      !filters.categories.includes(item.category)
    ) {
      return false;
    }

    // Audience filter
    if (
      filters.audiences.length > 0 &&
      !item.audience.some((a) => filters.audiences.includes(a))
    ) {
      return false;
    }

    return true;
  });

  return {
    filters,
    setFilters,
    filteredItems,
  };
}
