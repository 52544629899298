import React, { useState } from "react";
import { ChevronDown } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const CourseSection = ({ title, subtitles, courses }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="overflow-hidden border-b border-gray-200"
    >
      <motion.button
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center justify-between w-full px-4 py-4 transition-all duration-300 sm:px-6 bg-gradient-to-r from-white to-gray-50 hover:from-gray-50 hover:to-gray-100"
        aria-expanded={isExpanded}
      >
        <div className="text-left">
          <motion.h2
            className="text-base font-semibold text-transparent sm:text-lg bg-clip-text bg-gradient-to-r from-primary-600 to-primary-400"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
          >
            {title}
          </motion.h2>
          {subtitles && subtitles.length > 0 && (
            <motion.p
              className="mt-1 text-xs text-gray-600 sm:text-sm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              {subtitles.join(" • ")}
            </motion.p>
          )}
        </div>
        <motion.div
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ type: "spring", stiffness: 200, damping: 15 }}
          className="flex-shrink-0 ml-2 text-gray-500"
        >
          <ChevronDown className="w-4 h-4 sm:w-5 sm:h-5" />
        </motion.div>
      </motion.button>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 20 }}
            className="overflow-hidden"
          >
            <CourseTable courses={courses} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const CourseTable = ({ courses }) => {
  // Step 1: Dynamically determine headers
  const headers = courses.reduce((acc, course) => {
    Object.keys(course).forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, []);
  const headerNames = {
    sno: "Serial No",
    area: "Area",
    noOfCourse: "Number Of Course",
    code: "Course Code",
    name: "Course Name",
    credits: "Credits",
  };
  // Optional mapping for field labels
  const fieldLabels = {
    area: "Area",
    noOfCourse: "Number Of Course",
    code: "Course Code",
    name: "Course Name",
    credits: "Credits",
  };

  // Mobile view for small screens
  const renderMobileView = () => (
    <div className="p-4 space-y-4">
      {courses.map((course, index) => (
        <motion.div
          key={course.code}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="p-4 bg-white rounded-lg shadow-sm"
        >
          <div className="flex items-start justify-between mb-2">
            <motion.span
              className="text-sm font-medium text-blue-600"
              whileHover={{ scale: 1.05 }}
            >
              {course.code}
            </motion.span>
            <span className="text-sm text-gray-500">
              {course.credits} credits
            </span>
          </div>
          <h3 className="mb-2 text-sm font-medium text-gray-900">
            {course.name}
          </h3>
          <div className="space-y-1 text-xs text-gray-500">
            <p>
              <span className="font-medium">Instructor:</span>{" "}
              {course.instructor}
            </p>
            <p>
              <span className="font-medium">Schedule:</span> {course.schedule}
            </p>
          </div>
        </motion.div>
      ))}
    </div>
  );

  // Desktop view for larger screens
  const renderDesktopView = () => (
    <div className="overflow-x-auto">
      <motion.table
        className="min-w-full divide-y divide-gray-200"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1 }}
      >
        <thead className="bg-gradient-to-r from-gray-50 to-gray-100">
          <tr>
            {headers.map((header) => (
              <th
                key={header}
                className="px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase sm:px-6"
              >
                {headerNames[header] || header}
              </th>
            ))}

            {/* <th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase sm:px-6">
              Serial No
            </th>
            
            <th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase sm:px-6">
              Course Code
            </th>
            <th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase sm:px-6">
              Course Name
            </th>
            <th className="px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase sm:px-6">
              Credits
            </th> */}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {courses.map((course, index) => (
            <motion.tr
              key={course.sno || index} // Fallback for keys
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{
                scale: 1.01,
                backgroundColor: "rgba(249, 250, 251, 0.5)",
              }}
              className="transition-all duration-200 transform"
            >
              {headers.map((header) => (
                <td
                  key={header}
                  className="px-4 py-4 text-sm text-gray-900 sm:px-6 whitespace-nowrap"
                >
                  {course[header] || "-"} {/* Display data or fallback */}
                </td>
              ))}
            </motion.tr>
          ))}
          {/* {courses.map((course, index) => (
            <motion.tr
              key={course.code}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{
                scale: 1.01,
                backgroundColor: "rgba(249, 250, 251, 0.5)",
              }}
              className="transition-all duration-200 transform"
            >
              <td className="px-4 py-4 sm:px-6 whitespace-nowrap">
                <motion.span
                  className="text-sm font-medium text-primary-600"
                  whileHover={{ scale: 1.05 }}
                >
                  {course.sno}
                </motion.span>
              </td>
              <td className="px-4 py-4 sm:px-6 whitespace-nowrap">
                <motion.span
                  className="text-sm font-medium text-primary-600"
                  whileHover={{ scale: 1.05 }}
                >
                  {course.code}
                </motion.span>
              </td>
              <td className="px-4 py-4 text-sm text-gray-900 sm:px-6 whitespace-nowrap">
                {course.name}
              </td>
              <td className="px-4 py-4 text-sm text-gray-500 sm:px-6 whitespace-nowrap">
                {course.credits}
              </td>
              <td className="px-4 py-4 text-sm text-gray-500 sm:px-6 whitespace-nowrap">
                {course.instructor}
              </td>
              <td className="px-4 py-4 text-sm text-gray-500 sm:px-6 whitespace-nowrap">
                {course.schedule}
              </td>
            </motion.tr>
          ))} */}
        </tbody>
      </motion.table>
    </div>
  );

  return (
    <>
      <div className="hidden md:block">{renderDesktopView()}</div>
      <div className="md:hidden">{renderMobileView()}</div>
    </>
  );
};

export default CourseSection;
