"use client";

import { motion, AnimatePresence } from "framer-motion";
import { Plus } from "lucide-react";
import { useState } from "react";
import image1 from "../../assets/campusLife/campusLife1.jpg";
import image2 from "../../assets/campusLife/campusLife2.jpg";
import image3 from "../../assets/campusLife/campusLife3.jpg";
import image4 from "../../assets/campusLife/campusLife4.jpg";
import image5 from "../../assets/campusLife/campusLife5.jpg";

const events = [
  {
    title: "2025 Mellon Research Scholars Call for Applications",
    dateRange: "Monday, January 20, 2025 - Monday, January 27, 2025",
    time: "12:00 pm - 11:30 pm",
    image: image1,
  },
  {
    title: "Guest Workshop: Stephen Nachmanovitch, improv",
    date: "Wednesday, January 22, 2025",
    time: "5:00 pm - 8:00 pm",
    image: image2,
  },
  {
    title: "Ed.D. in Professional Leadership: Learn More",
    date: "Thursday, January 23, 2025",
    time: "6:00 pm - 7:30 pm",
    image: image3,
  },
  {
    title: "A.I. Lack Visiting Artist Series: Hal Robinson",
    date: "Sunday, February 2, 2025",
    time: "6:00 pm - 7:00 pm",
    image: image4,
  },
  {
    title: "Emotional Management During Difficult Conversations",
    date: "Thursday, February 13, 2025",
    time: "12:00 pm - 1:00 pm",
    image: image5,
  },
];

export default function FeaturedEvents() {
  const [activeImage, setActiveImage] = useState(events[0].image);
  const [activeTitle, setActiveTitle] = useState(events[0].title);

  return (
    <div className="px-4 pb-12">
      <motion.h1
        className="mb-16 text-3xl font-semibold text-center lg:text-5xl font-domine "
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Featured
        <br />
        Events
      </motion.h1>

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        {/* Image Section */}
        <div className=" md:relative mb-40 md:mb-0  max-h-[400px] top-4">
          <AnimatePresence mode="wait">
            <motion.h3
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.1 }}
              transition={{ type: "spring", stiffness: 200, damping: 20 }}
              key={activeTitle}
              className="mb-6 text-xl font-bold lg:text-3xl"
            >
              {activeTitle}
            </motion.h3>
            <motion.img
              key={activeImage}
              src={activeImage}
              alt="Event preview"
              className="object-cover w-full h-full rounded-lg shadow-lg"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.1 }}
              transition={{ type: "spring", stiffness: 200, damping: 20 }}
            />
          </AnimatePresence>
        </div>

        {/* Timeline Section */}
        <div className="relative">
          {/* Vertical line */}
          <div className="absolute top-0 bottom-0 w-px bg-gray-200 left-2" />

          {/* Events */}
          <div className="space-y-12">
            {events.map((event, index) => (
              <motion.div
                key={index}
                className="relative flex items-center justify-start"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                {/* Timeline node */}
                <motion.div
                  className={`absolute left-2 transform -translate-x-1/2 w-4 h-4 bg-white border-2 
                    ${
                      activeImage === event.image
                        ? "border-red-600"
                        : "border-gray-200"
                    } 
                    rounded-full`}
                  whileHover={{ scale: 1.2 }}
                  transition={{ type: "spring", stiffness: 300 }}
                />

                {/* Event content */}
                <div className="ml-8">
                  <a
                    // href="#"
                    className="block group"
                    onMouseEnter={(e) => {
                      e.preventDefault();
                      setActiveImage(event.image);
                      setActiveTitle(event.title);
                    }}
                  >
                    <h2 className="text-xl font-medium text-red-600 transition-colors group-hover:text-red-700">
                      {event.title}
                    </h2>
                    <p className="mt-1 text-gray-600">
                      {event.dateRange || event.date}
                    </p>
                    <p className="text-gray-600">{event.time}</p>
                  </a>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Add event button */}
          <motion.button
            className="absolute flex items-center justify-center w-12 h-12 mt-8 text-white transition-colors transform -translate-x-1/2 bg-red-700 rounded-full left-2 md:-left-3 hover:bg-red-800"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: events.length * 0.2 }}
          >
            <Plus className="w-6 h-6" />
          </motion.button>
        </div>
      </div>
    </div>
  );
}
