/* eslint-disable react/prop-types */
import { Mic } from "lucide-react";

const HoverButton = ({ title }) => {
  return (
    <button className="relative h-12 px-8 overflow-hidden text-white bg-black border-none rounded-full cursor-pointer group">
      <span className="relative z-10 flex items-center justify-between gap-3">
        {" "}
        <Mic /> {title}
      </span>
      <span className="absolute top-0 left-0 w-full h-full transition-transform duration-500 origin-center scale-x-0 rounded-full bg-primary group-hover:scale-x-100"></span>
    </button>
  );
};

export default HoverButton;
