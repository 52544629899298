import { motion } from "framer-motion";
import { useRef } from "react";
import { useInView } from "framer-motion";
import { Clock, Mic, Music2, Users } from "lucide-react";
import statsImage from "../../assets/lifeAtZums/lifeatzums.webp";
import HoverButton from "../shared/HoverButton";

const PodStats = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const cardData = [
    { id: 1, title: "Episodes Published", number: "250+", icon: <Mic /> },
    { id: 2, title: "Listeners Worldwide", number: "5000+", icon: <Music2 /> },
    { id: 3, title: "Podcast Duration", number: "1000+ Hr", icon: <Clock /> },
    { id: 4, title: "Guests Hosted", number: "150+", icon: <Users /> },
  ];

  return (
    <div
      ref={ref}
      className="grid items-center justify-between grid-cols-1 gap-10 py-20 xl:grid-cols-5"
    >
      <motion.div
        initial={{ opacity: 0, x: -150 }}
        animate={isInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 1 }}
        className="col-span-1 lg:col-span-2"
      >
        <h1 className="pb-8 text-2xl font-bold text-start md:text-4xl lg:text-5xl font-manrope text-primary">
          Passionate about inspiring through audio
        </h1>
        <HoverButton title={"See More About"} />
        <div className="grid grid-cols-2 gap-8 mt-10 lg:gap-5">
          {cardData.map((card) => (
            <div
              key={card.id}
              className="bg-[#e0e2e4]/30 space-y-4 cursor-pointer hover:scale-105 transition-all transform duration-300 p-6 rounded-2xl shadow-lg"
            >
              <div className="flex items-center gap-2">
                {card.icon}
                <p className="text-base font-semibold md:text-2xl font-domine text-primary">
                  {card.number}
                </p>
              </div>
              <p className="text-sm text-gray-700 md:text-lg">{card.title}</p>
            </div>
          ))}
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={isInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.6 }}
        className="col-span-1 lg:col-span-3"
      >
        <img
          src={statsImage}
          className="object-cover w-full h-full rounded-2xl"
          alt="Stats"
        />
      </motion.div>
    </div>
  );
};

export default PodStats;
