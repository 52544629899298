/* eslint-disable react/prop-types */
import cm1Image from "../../assets/hero2.jpg";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import engineering from "../../assets/schools/schoolOfScience1.webp";
import business from "../../assets/schools/schoolOfBusinessAdministration2.jpeg";
import humanities from "../../assets/schools/schoolOfHum.webp";
import Economics from "../../assets/schools/schoolOfEcon2.jpeg";
import { Link } from "react-router-dom";

const ResearchHome = () => {
  const researchData = [
    {
      id: 1,
      title: "School of Business Administration",
      description:
        "School of Business Administration prepares future leaders with practical skills, ethical values, and a global mindset for success.",
      image: business,
      link: "/academics/school-of-business-administration",
    },
    {
      id: 2,
      title: "School of Science and Engineering",
      description:
        "School of Science & Engineering fosters innovation, problem-solving, and discovery to shape the future with real-world impact.",
      image: engineering,
      link: "/academics/school-of-science-and-engineering",
    },
    {
      id: 3,
      title: "School of Humanities and Social Sciences",
      description:
        "School of Humanities & Social Sciences fosters critical thinking, communication, and cultural awareness to navigate today’s challenges and impact society.",
      image: humanities,
      link: "/academics/school-of-humanities-and-social-sciences",
    },
    {
      id: 4,
      title: "School of Economics",
      description:
        "School of Economics equips students with analytical skills to understand markets, influence policy, and drive positive change.",
      image: Economics,
      link: "/academics/school-of-economics",
    },
  ];

  const [selectedHover, setSelectedHover] = useState(0);

  return (
    <motion.div
      id="service"
      // initial={{ opacity: 0, y: 200 }}
      // transition={{ duration: 0.75 }}
      // whileInView={{ opacity: 1, y: 0 }}
      className=""
    >
      {/* Desktop Section */}
      <div className="hidden w-full gap-1 mt-8 lg:flex">
        {researchData.map((info) => (
          <motion.div
            key={info.id}
            className={`relative w-1/4 overflow-hidden ${
              selectedHover === info.id ? "w-1/4" : ""
            }`}
            initial={{ width: "25%" }}
            onHoverStart={() => setSelectedHover(info.id)}
            onHoverEnd={() => setSelectedHover(0)}
            whileHover={{ width: "50%" }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <ComprehensiveMaritimeCard info={info} />
          </motion.div>
        ))}
      </div>

      {/* Mobile Section */}
      <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-2 lg:hidden">
        {researchData.map((info, index) => (
          <ComprehensiveMaritimeCard key={index} info={info} isMobile />
        ))}
      </div>
    </motion.div>
  );
};

export default ResearchHome;

const ComprehensiveMaritimeCard = ({ info, isMobile }) => {
  const container = useRef(null);

  return (
    <motion.div
      ref={container}
      className="h-96 md:h-[500px] relative group/card"
      initial={{ opacity: 0, scale: 0.95 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      whileTap={{ scale: isMobile ? 1.05 : 1 }} // Adds tap animation for mobile
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      {/* Background Image */}
      <div className="absolute top-0 bottom-0 left-0 right-0 z-10 h-full overflow-hidden">
        <img
          src={info.image || cm1Image}
          alt="ComprehensiveMaritime"
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 top-0 left-0 w-full bg-black/30 "></div>
      </div>

      {/* Content */}
      <div className="absolute left-0 right-0 z-20 text-white bottom-10">
        <h1 className="text-xl font-bold text-center font-domine md:text-2xl lg:text-3xl">
          <Link to={info?.link}>{info.title}</Link>
        </h1>

        <p className="px-5 mt-5 text-center">{info.description}</p>
      </div>
    </motion.div>
  );
};
