import React from "react";
import { motion } from "framer-motion";
import { Book, Star, GraduationCap, DollarSign } from "lucide-react";  // Add DollarSign import
import { format } from "date-fns";

export default function CalenderEventCard({ event }) {
  const getIcon = () => {
    switch (event.type) {
      case "academic":
        return <Book className="h-5 w-5" />;
      case "holiday":
        return <Star className="h-5 w-5" />;
      case "exam":
        return <GraduationCap className="h-5 w-5" />;
      case "financial":
        return <DollarSign className="h-5 w-5" />;
      default:
        return <Book className="h-5 w-5" />;
    }
  };

  const getColor = () => {
    switch (event.type) {
      case "academic":
        return "border-gray-300 hover:bg-gray-100";
      case "exam":
        return "border-blue-400 hover:bg-blue-50";
      case "holiday":
        return "border-red-400 hover:bg-red-50";
      case "financial":
        return "border-green-400 hover:bg-green-50";
      default:
        return "border-gray-300 hover:bg-gray-100";
    }
  };

  const formatDate = (date) => {
    if (date.start && date.end) {
      return `${format(date.start, "MMMM dd")} - ${format(date.end, "MMMM dd, yyyy")}`;
    }
    return format(date, "MMMM dd, yyyy");
  };

  const getMonthDisplay = (date) => {
    if (date.start && date.end) {
      return format(date.start, "MMM").toUpperCase();
    }
    return format(date, "MMM").toUpperCase();
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      whileHover={{ scale: 1.02 }}
      className={`p-4 rounded-lg border-2 bg-gray-50 ${getColor()} cursor-pointer relative flex justify-between items-start`}
    >
      <div className="flex-1">
        <div className="flex items-start space-x-3">
          <div
            className={`p-2 rounded-full ${
              event.type === "holiday"
                ? "bg-red-100"
                : event.type === "exam"
                ? "bg-blue-100"
                : event.type === "financial"
                ? "bg-green-100"
                : "bg-gray-200"
            }`}
          >
            {getIcon()}
          </div>
          <div>
            <h3 className="font-semibold text-gray-800">{event.title}</h3>
            <p className="text-sm text-gray-600">
              {formatDate(event.date)}
            </p>
            {event.description && (
              <p className="mt-2 text-sm text-gray-600">{event.description}</p>
            )}
          </div>
        </div>
      </div>

      <div className="flex-shrink-0 ml-4">
        <div className="w-16 h-16 rounded-lg overflow-hidden shadow-sm">
          <div className="bg-primary-500 h-3"></div>
          <div className="bg-white text-center py-2">
            <span className="text-2xl font-bold text-gray-800">
              {getMonthDisplay(event.date)}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
