export const navigation = [
  {
    key: "discover-zums",
    name: "Discover ZUMS",
    // href: "/discover-zums",
    submenu: [
      // {
      //   key: "quick-facts",
      //   name: "Quick Facts",
      //   href: "/discover-zums/quick-facts",
      // },
      {
        key: "ambition-commitment-values",
        name: "Ambition, Commitment & Values",
        href: "/discover-zums/ambition-commitment-values",
      },
      {
        key: "office-of-the-vc",
        name: "Office of the VC",
        href: "/discover-zums/office-of-the-vc",
      },
      {
        key: "board-of-trustees",
        name: "Board of Trustees",
        href: "/discover-zums/board-of-trustees",
      },
      {
        key: "advisory-board",
        name: "Advisory Board",
        href: "/discover-zums/advisory-board",
      },

      {
        key: "faculty",
        name: "Faculty ",
        href: "/discover-zums/faculty",
      },
      {
        key: "administration",
        name: "Administration ",
        href: "/discover-zums/administration",
      },
      {
        key: "why-choose-zums",
        name: "Why Choose ZUMS",
        href: "/discover-zums/why-choose-zums",
      },
    ],
  },
  {
    key: "academics",
    name: "Academics",
    href: "/academics",
    submenu: [
      {
        key: "school-of-business-administration",
        name: "School of Business Administration",
        href: "/academics/school-of-business-administration",
      },
      {
        key: "school-of-economics",
        name: "School of Economics",
        href: "/academics/school-of-economics",
      },
      {
        key: "school-of-science-and-engineering",
        name: "School of Science & Engineering",
        href: "/academics/school-of-science-and-engineering",
      },
      {
        key: "school-of-humanities-and-social-sciences",
        name: "School of Humanities & Social Sciences",
        href: "/academics/school-of-humanities-and-social-sciences",
      },
    ],
  },
  {
    key: "admission",
    name: "Admission",
    href: "/admission",
    submenu: [
      // {
      //   key: "undergraduate",
      //   name: "Undergraduate",
      //   href: "/admission/undergraduate",
      // },
      // { key: "graduate", name: "Graduate", href: "/admission/graduate" },
      // {
      //   key: "international-admissions",
      //   name: "International Admissions",
      //   href: "/admission/international-admissions",
      // },
      //have to add another route name scholarship and financial aid
      // {
      //   key: "tuition-scholarship-and-financial-aid",
      //   name: "Tuition, Scholarship & Financial Aid",
      //   href: "/admission/tuition-scholarship-and-financial-aid",
      // },
      {
        key: "tuition-fees",
        name: "Tuition Fees",
        href: "/admission/tuition-fees",
      },
      {
        key: "scholarship",
        name: "Scholarship",
        href: "/admission/scholarship",
      },
      // {
      //   key: "advising-center",
      //   name: "Advising Center",
      //   href: "/admission/advising-center",
      // },
    ],
  },
  {
    key: "centers-of-excellence",
    name: "Centers of Excellence",
    // href: "/beyond-books",
    submenu: [
      {
        key: "iqac",
        name: "Institutional Quality Assurance Cell (IQAC)",
        href: "/centers-of-excellence/iqac",
      },
      {
        key: "edrc",
        name: "Entrepreneur Development Research Center (EDRC)",
        href: "/centers-of-excellence/edrc",
      },
      {
        key: "zuic",
        name: "ZNRF University Incubation Center (ZUIC)",
        href: "/academics/zums-incubation-center",
      },
      {
        key: "clll",
        name: "Center for Life-Long Learning (CLLL)",
        href: "/centers-of-excellence/clll",
      },
      {
        key: "zums-technology-innovation-lab",
        name: "ZUMS Technology Innovation Lab",
        href: "/academics/zums-technology-innovation-lab",
      },
      // { key: "library", name: "Library", href: "/academics/library" },
    ],
  },
  {
    key: "library",
    name: "Library",
    href: "/library",
  },
  {
    key: "life-at-zums",
    name: "Life at ZUMS",
    href: "/life-at-zums",
  },
  // {
  //   key: "zums-voices",
  //   name: "ZUMS Voices",
  //   href: "/zums-voices",
  // },
  {
    key: "info-center",
    name: "Info Center",
    href: "/info",
  },
];
