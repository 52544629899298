import headerImage from "../../assets/schools/schoolOfHum.webp";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import ImageHeading from "../../components/ui/ImageHeading";
import RoundedButton from "../../components/ui/RoundedButton";
import DepartmentFacultyCard from "../../components/ui/Card/DepartmentFacultyCard";
import FeaturedEvents from "../../components/ui/FeaturedEvents";

import MdShahadatHossain from "../../assets/Faculty/HumanitiesandSocialSciences/MdShahadatHossain.jpg";
import ProfMustafizurRahman from "../../assets/Faculty/HumanitiesandSocialSciences/ProfMustafizurRahman.jpeg";

import RumaHaldar from "../../assets/Faculty/HumanitiesandSocialSciences/RumaHaldar.jpg";
import SeemaHaydarChawdhuri from "../../assets/Faculty/HumanitiesandSocialSciences/SeemaHaydarChawdhuri.jpg";
const SchoolOfHumanities = () => {
  const facultyMembers = [
    {
      id: 1,
      name: "Prof. Mustafizur Rahman",
      title: "Professor",
      department: "School of Humanities and Social Sciences",
      expertise: [
        "Language and Literature",
        "Cultural Studies",
        "Literary Criticism",
      ],
      email: "mustafizur.rahman@zums.edu",
      phone: "+880 1234567898",
      image: ProfMustafizurRahman,
      bio: "Leading researcher in Bengali literature and cultural studies",
    },
    {
      id: 2,
      name: "Seema Haydar Chawdhuri",
      title: "Associate Professor",
      department: "School of Humanities and Social Sciences",
      expertise: ["Philosophy", "Ethics", "Critical Thinking"],
      email: "seema.haydar@zums.edu",
      phone: "+880 1234567903",
      image: SeemaHaydarChawdhuri,
      bio: "Philosophy scholar specializing in ethics and moral reasoning",
    },
    {
      id: 3,
      name: "Ruma Haldar",
      title: "Assistant Professor",
      department: "School of Humanities and Social Sciences",
      expertise: ["Gender Studies", "Social Justice", "Development Studies"],
      email: "ruma.haldar@zums.edu",
      phone: "+880 1234567902",
      image: RumaHaldar,
      bio: "Researcher in gender studies and social equality initiatives",
    },
    {
      id: 4,
      name: "Md. Shahadat Hossain",
      title: "Assistant Professor",
      department: "School of Humanities and Social Sciences",
      expertise: ["Sociology", "Anthropology", "Social Development"],
      email: "shahadat.hossain@zums.edu",
      phone: "+880 1234567899",
      image: MdShahadatHossain,
      bio: "Expert in sociology with focus on social structures in developing countries",
    },
  ];

  return (
    <div className="min-h-screen ">
      <ImageHeading
        heading="School Of Humanities & Social Science"
        imageUrl={headerImage}
        subHeading="Lorem ipsum dolor sit amet consectetur adipisicing elit."
      />
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <section className="grid grid-cols-1 gap-12 md:grid-cols-3">
          <div className="col-span-2 space-y-4 text-gray-700 ">
            <p>
              The School of Humanities & Social Sciences at ZUMS explores the
              human experience in all its richness and complexity. We cultivate
              critical thinking, effective communication, and a deep
              understanding of diverse perspectives. Develop the skills to
              navigate the social, cultural, and ethical challenges of our time
              and contribute meaningfully to society.
            </p>
          </div>
          <div className="flex flex-col items-center space-y-4">
            {/* <div className="w-full pb-2 border-b border-primary">
              <Link className="flex items-center justify-between" to="/Schools">
                <h3 className="text-lg font-semibold text-primary">
                  Explore All Schools
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div> */}
            <div className="w-full pb-2 border-b border-primary">
              <Link
                className="flex items-center justify-between"
                to="/academics/academic-Calendar"
              >
                <h3 className="text-lg font-semibold text-primary">
                  Academic Calendar
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div>
            <div className="w-full pb-2 border-b border-primary">
              <Link
                className="flex items-center justify-between"
                to="https://103.60.173.170/zumsadmission/Admission/Home.aspx"
              >
                <h3 className="text-lg font-semibold text-primary">
                  Apply Now
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div>
          </div>
        </section>
      </div>
      <div className=" relative text-gray-200 bg-[#AC0D30] overflow-hidden">
        {/* Background Pattern */}
        <div
          className="absolute inset-0 opacity-20"
          style={{
            backgroundImage:
              "radial-gradient(circle at 25px 25px, rgba(255, 255, 255, 0.2) 2%, transparent 0%), " +
              "radial-gradient(circle at 75px 75px, rgba(255, 255, 255, 0.2) 2%, transparent 0%)",
            backgroundSize: "100px 100px",
          }}
        />

        {/* Gradient Overlays */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#AC0D30]/50 to-[#AC0D30]" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />

        <div className="container grid grid-cols-1 gap-10 px-4 py-12 mx-auto lg:gap-0 sm:px-6 lg:px-8 md:grid-cols-2">
          <div className="z-20 border-gray-200 lg:border-r lg:pr-24">
            <h3 className="text-3xl font-bold font-domine lg:text-4xl">
              English Program
            </h3>
            <p className="mt-8 text-gray-200">
              The English program at ZNRF University provides students with a
              solid foundation in literature, linguistics, writing, and critical
              thinking. Students will explore diverse literary traditions, hone
              their writing and communication skills, and develop a deep
              understanding of language. Graduates are well-equipped for careers
              in education, writing, publishing, media, and beyond.
            </p>

            <div className="flex items-center justify-center ">
              <RoundedButton
                className={
                  "mt-8 py-4 font-bold font-display px-6 w-fit text-center border-white text-white"
                }
                href={"/academics/school-of-science-and-engineering/english"}
                spanClassName={"text-white"}
              >
                Explore English Program
              </RoundedButton>
            </div>
          </div>
          <div className="z-20 lg:pl-24">
            <h3 className="text-3xl font-bold font-domine lg:text-4xl">
              LLB Program
            </h3>
            <p className="mt-8 text-gray-200">
              The LLB program at ZNRF University provides a comprehensive
              foundation in legal theory, ethics, and practice. Students will
              gain essential knowledge of the law, legal writing, and advocacy
              skills, preparing them for careers as legal professionals,
              advocates, and policy makers. The curriculum emphasizes practical
              experience, critical thinking, and the application of legal
              principles to real-world issues.
            </p>

            <div className="flex items-center justify-center ">
              <RoundedButton
                className={
                  "mt-8 py-4 font-bold font-display px-6 w-fit text-center border-white text-white"
                }
                spanClassName={"text-white"}
                href={"/academics/school-of-science-and-engineering/law"}
              >
                Explore LLB Program
              </RoundedButton>
            </div>
          </div>
        </div>
      </div>

      {/* Faculty */}
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8 ">
        <h2 className="text-4xl font-bold text-center font-domine">
          Faculty members
        </h2>
        <div className="grid grid-cols-1 gap-5 my-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 lg:gap-6">
          {facultyMembers.map((faculty, index) => (
            <DepartmentFacultyCard key={index} {...faculty} />
          ))}
        </div>
        <div className="flex justify-center w-full">
          <RoundedButton href={"/discover-zums/faculty"}>
            View All Faculty Members
          </RoundedButton>
        </div>
      </div>

      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <FeaturedEvents />
      </div>
    </div>
  );
};

export default SchoolOfHumanities;
