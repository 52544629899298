import ImageHeading from "../components/ui/ImageHeading";
import tuitionFeesImage from "../assets/header/header.jpeg";
import { motion } from "framer-motion";
import IQACMission from "../components/CenterOfExcellence/IQACMission";
import { CircleCheckBig } from "lucide-react";
const specialScholarshipsData = [
  {
    id: 1,
    title: "Freedom Fighters",
    description:
      "The University pursues a policy of awarding up to 100% waiver of tuition fees to the children/grandchildren of freedom fighters of the Liberation War of Bangladesh, with provision for higher levels of award for those from modest economic backgrounds. In order to continue with this scholarship, a student requires to maintain a minimum CGPA of 3.25 in every semester.",
  },
  {
    id: 2,
    title: "Donor’s Scholarship",
    description:
      "If a donor provides a scholarship to any student, that scholarship will be governed by the policies adopted by the donor.",
  },
  {
    id: 3,
    title: "Sibling Scholarship",
    description:
      "If one sibling has been studying at ZUMS, other sibling(s) who enrolled later will be eligible to apply for a tuition waiver up to 30%. In order to continue with this scholarship, a student requires to maintain a minimum CGPA of 3.0 in every semester.",
  },
  {
    id: 4,
    title: "Female Students",
    description:
      "If a female student does not qualify for a scholarship under any other category, she is entitled to a 10% tuition fee waiver.",
  },
  {
    id: 5,
    title: "Graduate Students",
    description:
      "ZUMS makes financial assistance available to graduate students in various forms. These include scholarships, teaching and research assistantships, and on-campus employment. The above-mentioned Special Scholarships for undergraduate students are equally applicable for the graduate students at ZUMS. We make arrangements with organizations in both public and private sectors of Bangladesh for providing group tuition fee waiver to the prospective graduate students from those organizations.",
  },
  {
    id: 6,
    title:
      "ZUMS Scholarship for Meritorious Students of Disadvantaged Families",
    description:
      "100% tuition fee waiver (excluding other fees) plus a stipend for defraying the cost of food and accommodation is awarded to selected meritorious students coming from economically disadvantaged families. In order to continue receiving this scholarship up to the end of the undergraduate program, a minimum CGPA of 3.5 on a scale of 4 is required to be maintained in all the semesters. If a student fails to maintain the required CGPA, she/he will receive tuition and other fees waiver for the subsequent two consecutive semesters during which she/he will need to regain the required CGPA, otherwise the scholarship will be discontinued.",
  },
];

const Scholarship = () => {
  return (
    <div>
      <ImageHeading
        heading="Scholarships and Financial Aid "
        imageUrl={tuitionFeesImage}
      />

      <div className="container px-4 mx-auto md:px-8">
        <div className="mb-12 lg:mb-16 ">
          <h3 className="text-2xl font-bold text-black lg:text-4xl font-domine">
            Scholarships and Financial Aid
          </h3>
          <div className="py-6 space-y-4 leading-loose text-gray-700 ">
            <p>
              {" "}
              The Board of Trustees of ZUMS has made a decision to bring higher
              education within reasonable reach for students and their families
              from different socio-economic backgrounds. Many factors contribute
              to the ability to earn a university degree, and the leadership of
              ZUMS management are committed to address them all in order to
              increase access to higher education, especially for disadvantaged
              people in the society who have historically faced barriers to
              entry.
            </p>

            <p>
              Given the large number of students who need financial aid, and the
              socioeconomic challenges in communities throughout Bangladesh, our
              ability to provide financial assistance to students is very
              important. ZUMS’s public-service oriented approach will be helpful
              in enabling potential students to have access to higher education,
              both flexibly and at reasonable cost.
            </p>

            <p>
              ZUMS provides a range of scholarships and financial aid for its
              undergraduate and graduate students. This University offers
              comprehensive, need-based financial aid in the form of
              scholarships to the students who qualify for assistance. At ZUMS,
              we are committed to providing as much financial assistance as
              possible within the limits of its available resources.
            </p>
          </div>
          <div className="ml-5 lg:ml-8">
            <div className="">
              <p className="flex items-center gap-3">
                <CircleCheckBig className="hidden lg:block size-9 text-primary" />
                <span className="text-gray-700 ">
                  The students should keep in mind that full-time enrollment for
                  scholarships and financial aid purposes is considered 15
                  credits or more in a semester. Since there are two semesters
                  in a year, the full time undergraduate students are expected
                  to complete degree requirements in 8 semesters.{" "}
                </span>
              </p>
            </div>
            <div className="mt-3">
              <p className="flex items-center gap-3">
                <CircleCheckBig className="hidden lg:block size-fit text-primary" />
                <span className="text-gray-700 ">
                  The Scholarships and Financial Aid Policy is subject to change
                  at the discretion of the University authority.
                </span>
              </p>
            </div>
          </div>
        </div>
        {/* table */}
        <div className="max-w-3xl pb-5 mx-auto">
          <div className="pb-8 space-y-3">
            <motion.h2
              className="text-4xl font-bold text-center text-gray-800 font-domine"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              Merit Scholarship Based on ZUMS Academic Results
            </motion.h2>
            <p className="max-w-3xl mx-auto text-sm text-center text-gray-600 md:text-base">
              In order to continue with the awarded merit scholarship in
              subsequent semesters, a student is required to score the following
              CGPA in every semester.
            </p>
          </div>
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                  CGPA
                </th>
                <th className="px-4 py-2 text-sm font-medium text-left text-gray-700">
                  Tuition Waiver (%)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t border-gray-200">
                <td className="px-4 py-5 text-sm text-gray-600">3.85 – 3.89</td>
                <td className="px-4 py-5 text-sm text-gray-600">70%</td>
              </tr>
              <tr className="border-t border-gray-200">
                <td className="px-4 py-5 text-sm text-gray-600">3.90 – 3.94</td>
                <td className="px-4 py-5 text-sm text-gray-600">80%</td>
              </tr>
              <tr className="border-t border-gray-200">
                <td className="px-4 py-5 text-sm text-gray-600">3.95 – 4.00</td>
                <td className="px-4 py-5 text-sm text-gray-600">100%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <IQACMission
          title={"Special Scholarship"}
          details={" "}
          data={specialScholarshipsData}
        />
      </div>
    </div>
  );
};

export default Scholarship;
