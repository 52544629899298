import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import CalenderEventCard from "../components/ui/Card/CalenderEventCard";
import { academicCalenderData } from "../data/academicCalenderData";
import AcademicCalenderFilter from "./AcademicCalenderFilter";
import ImageHeading from "../components/ui/ImageHeading";
import headerImage from "../assets/academicsHeader.jpg";

const AcademicCalender = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentFilter, setCurrentFilter] = useState("all");
  const [showHolidays, setShowHolidays] = useState(true);

  const filteredEvents = academicCalenderData.filter((event) => {
    const matchesSearch =
      event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.description?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter =
      currentFilter === "all" || event.type === currentFilter;
    const matchesHolidayToggle = event.type !== "holiday" || showHolidays;

    return matchesSearch && matchesFilter && matchesHolidayToggle;
  });

  const getEventDate = (event) => {
    return event.date.start || event.date;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <ImageHeading heading="Academic Calendar" imageUrl={headerImage} />
      <AcademicCalenderFilter
        onSearch={setSearchTerm}
        onFilter={setCurrentFilter}
        currentFilter={currentFilter}
      />

      <main className="max-w-7xl mx-auto px-4 py-8">
        <div className="space-y-4">
          <AnimatePresence>
            {filteredEvents
              .sort((a, b) => getEventDate(a) - getEventDate(b))
              .map((event) => (
                <CalenderEventCard key={event.id} event={event} />
              ))}
          </AnimatePresence>
        </div>
      </main>
    </div>
  );
};

export default AcademicCalender;
