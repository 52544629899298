import React, { useState } from "react";
import ImageHeading from "../components/ui/ImageHeading";
import headerImage from "../assets/header/header.jpeg";
import { Plus, Minus } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
// import { boardOfTrusteesData } from "../data/boardOfTrusteesData";
import { advisoryBoardData } from "../data/advisoryBoardData";
const AdvisoryBoard = () => {
  const [expandedId, setExpandedId] = useState(null);

  const toggleExpand = (index) => {
    setExpandedId(expandedId === index ? null : index);
  };

  return (
    <div className="min-h-screen">
      <ImageHeading heading={"Advisory Board"} imageUrl={headerImage} />
      <div className="container px-6 py-10 mx-auto lg:px-8">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-start justify-start gap-10">
            <div className="w-full">
              <div>
                {advisoryBoardData.map((faculty, index) => (
                  <motion.div
                    key={index}
                    className="flex flex-col gap-4"
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: false }}
                    transition={{ delay: index * 0.2, duration: 0.6 }}
                  >
                    <div className="flex justify-start gap-10 pt-10">
                      <div className="overflow-hidden bg-red-600 aspect-square rounded-full w-[120px]">
                        <img
                          src={faculty.image}
                          className="object-cover object-center w-full h-full"
                          alt={faculty.name}
                        />
                      </div>
                      <div className="flex items-center justify-between w-full pb-4 border-b-2 border-primary-600">
                        <div>
                          <h3 className="text-3xl font-semibold lg:text-4xl">
                            {faculty.name}
                          </h3>
                          <p className="text-lg font-medium lg:text-xl">
                            {faculty.title}
                          </p>
                        </div>
                        {/* <button
                          onClick={() => toggleExpand(index)}
                          className="p-1.5 rounded-full bg-primary-500 w-fit hover:bg-primary-600 transition-colors"
                        >
                          {expandedId === index ? (
                            <Minus className="w-6 h-6 text-white" />
                          ) : (
                            <Plus className="w-6 h-6 text-white" />
                          )}
                        </button> */}
                      </div>
                    </div>
                    <AnimatePresence>
                      {expandedId === index && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: "auto", opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3, ease: "easeInOut" }}
                          className="ml-32 overflow-hidden"
                        >
                          <div className="p-6 rounded-lg shadow-sm bg-gray-50">
                            <p className="mb-4 text-lg text-gray-700">
                              {faculty.bio}
                            </p>
                            <div className="space-y-4">
                              <p className="text-gray-600">
                                <span className="font-semibold">Email:</span>{" "}
                                {faculty.details.email}
                              </p>
                              <p className="text-gray-600">
                                <span className="font-semibold">
                                  Experience:
                                </span>{" "}
                                {faculty.details.experience}
                              </p>
                              <div>
                                <p className="mb-2 font-semibold text-gray-700">
                                  Expertise:
                                </p>
                                <ul className="ml-4 text-gray-600 list-disc list-inside">
                                  {faculty.details.expertise.map((item, i) => (
                                    <li key={i}>{item}</li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <p className="mb-2 font-semibold text-gray-700">
                                  Key Achievements:
                                </p>
                                <ul className="ml-4 text-gray-600 list-disc list-inside">
                                  {faculty.details.achievements.map(
                                    (item, i) => (
                                      <li key={i}>{item}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisoryBoard;
