import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  EffectCoverflow,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

import bgImage from "../../assets/researchBg2.jpg";

// Import your images
import image1 from "../../assets/paper1.jpeg";
import image2 from "../../assets/paper2.jpeg";
import image3 from "../../assets/paper3.jpeg";
import RoundedButton from "./RoundedButton";

const slidesData = [
  { title: "Beautiful Landscape", image: image1 },
  { title: "Serene Lake", image: image2 },
  { title: "Majestic Mountains", image: image3 },
  { title: "Beautiful Landscape", image: image1 },
  { title: "Serene Lake", image: image2 },
  { title: "Majestic Mountains", image: image3 },
];

const SwiperComponent = () => {
  return (
    <div
      className="relative flex flex-col items-center justify-center w-full h-[80vh] my-10 bg-center bg-cover"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute top-0 left-0 z-10 w-full h-full bg-black opacity-70"></div>

      <div className="relative z-20">
        <h2 className="pt-10 text-3xl font-semibold text-center text-white">
          Success Stories
        </h2>
        <p className="py-5 text-sm text-gray-100">
          ZUIC has been instrumental in nurturing groundbreaking ventures that
          have achieved remarkable success.
        </p>
      </div>

      <Swiper
        modules={[Navigation, Pagination, EffectCoverflow, Autoplay]}
        slidesPerView={4}
        loop={true}
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        spaceBetween={-80}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 300,
          modifier: 3,
          slideShadows: false,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        // pagination={{ clickable: true }}
        className="w-full h-[60%] z-20"
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative w-full h-full overflow-hidden rounded-lg shadow-lg">
              <img
                src={slide.image}
                alt={slide.title}
                className="object-cover w-full h-full"
              />
              <div className="absolute bottom-0 left-0 w-full py-2 font-semibold text-center text-white bg-black bg-opacity-50 lg:text-3xl">
                {slide.title}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <RoundedButton
        className="relative z-30 mt-5 border-white"
        spanClassName="text-white border-white"
      >
        See More
      </RoundedButton>
    </div>
  );
};

export default SwiperComponent;
