import { useState } from "react";
import { motion } from "framer-motion";
import GradeTable from "../components/GradingSystem/GradingTable";
import SpecialGrades from "../components/GradingSystem/SpecialGrades";
import GradeInfo from "../components/GradingSystem/GradingInfo";

import headerImage from "../assets/Academics/academics.webp";
function GradingSystem() {
  const [selectedGrade, setSelectedGrade] = useState(null);

  const handleGradeSelect = (grade) => {
    setSelectedGrade(grade);
  };

  return (
    <div>
      <div className="relative w-full z-10 h-[70vh]  lg:h-[50vh]">
        <img
          src={headerImage || "/placeholder.svg"}
          alt="VC Banner"
          className="absolute inset-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 z-20 w-full h-full bg-gradient-to-r from-primary/90 via-primary/40 to-primary/10"></div>
        <div className="container relative z-20 flex items-center justify-start w-full h-full p-8 mx-auto">
          {/* Text content */}
          <div className="text-white">
            {/* <p className="my-4 text-2xl font-semibold">— Grading System</p> */}
            <h1 className="text-4xl font-bold md:text-5xl lg:text-6xl font-domine">
              ZUMS Grading System?
            </h1>
          </div>
        </div>
      </div>
      <main className="container px-4 py-12 mx-auto md:px-8 ">
        <div>
          {/* Grading Table Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="lg:col-span-2 bg-white rounded-lg shadow-md p-6"
          >
            <h2 className="text-2xl font-semibold mb-4 text-primary border-b pb-2">
              Grading Scale
            </h2>
            <GradeTable />
          </motion.div>

          {/* Special Grades Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7, duration: 0.5 }}
            className="bg-white rounded-lg shadow-md p-6"
          >
            <h2 className="text-2xl font-semibold mb-4 text-primary border-b pb-2">
              Special Grade Notations
            </h2>
            <SpecialGrades
              onGradeSelect={handleGradeSelect}
              selectedGrade={selectedGrade}
            />
          </motion.div>

          {/* Grade Information Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.5 }}
            className="lg:col-span-3 bg-white rounded-lg shadow-md p-6"
          >
            <h2 className="text-2xl font-semibold mb-4 text-primary border-b pb-2">
              Special Grade Details
            </h2>
            <GradeInfo
              selectedGrade={selectedGrade}
              onGradeSelect={handleGradeSelect}
            />
          </motion.div>
        </div>
      </main>
    </div>
  );
}

export default GradingSystem;
