import { Award, Globe, Handshake, Sparkles, Users } from "lucide-react";

import FeaturedEvents from "../../components/ui/FeaturedEvents";
import ResearchCTA from "../../components/ui/ResearchCTA";

import CoreActivities from "../../components/CenterOfExcellence/CoreActivities";
import IQACMission from "../../components/CenterOfExcellence/IQACMission";
import ImageHeading from "../../components/ui/ImageHeading";

import bannerImage from "../../assets/centers/edrc_11zon.jpg";
import PublicationCarousel from "../../components/CenterOfExcellence/PublicationCarousel";
// import TeamCarousel from "../../components/CenterOfExcellence/TeamCarousel";
const whatWeDoData = [
  {
    id: 1,
    title: "Research and Innovation",
    description:
      "We support cutting-edge research projects across various domains, including technology integration in education, policy development, and social innovation.",
  },
  {
    id: 2,
    title: "Training and Capacity Building",
    description:
      "EDRC organizes workshops, seminars, and training programs to equip educators, students, and professionals with the skills needed to thrive in an ever-changing world.",
  },
  {
    id: 3,
    title: "Collaboration and Partnerships",
    description:
      "We partner with academic institutions, industry leaders, government bodies, and international organizations to create impactful research and implement sustainable development practices.",
  },
  {
    id: 4,
    title: "Knowledge Sharing",
    description:
      "EDRC publishes research papers, reports, and journals to disseminate findings and insights to a global audience.",
  },
  {
    id: 5,
    title: "Student Engagement",
    description:
      "By integrating students into research activities, EDRC fosters critical thinking, innovation, and leadership skills among the next generation.",
  },
];
const values = [
  {
    id: 1,
    icon: <Sparkles className="w-6 h-6" />,
    title: "Innovation",
    description:
      "Encouraging creativity and new ideas to solve complex problems.",
    important: true,
  },
  {
    id: 2,
    icon: <Award className="w-6 h-6" />,
    title: "Excellence",
    description: "Maintaining the highest standards in research and education.",
    important: true,
  },
  {
    id: 3,
    icon: <Handshake className="w-6 h-6" />,
    title: "Collaboration",
    description:
      "Building bridges across disciplines, institutions, and countries.",
    important: true,
  },
  {
    id: 4,
    icon: <Users className="w-6 h-6" />,
    title: "Inclusivity",
    description: "Ensuring equitable access to opportunities for all.",
    important: true,
  },
  {
    id: 5,
    icon: <Globe className="w-6 h-6" />,
    title: "Sustainability",
    description:
      "Promoting research and practices that align with global sustainability goals.",
    important: true,
  },
];

const EDRC = () => {
  return (
    <div>
      <ImageHeading
        heading="Entrepreneur Development Research Center (EDRC)"
        imageUrl={bannerImage}
      />
      <div className="container px-4 mx-auto md:px-8 lg:px-0 ">
        <div className="gap-8 my-12 ">
          <div>
            <div>
              <h3 className="text-2xl font-bold text-black lg:text-4xl font-domine">
                Welcome to the Entrepreneur Development Research Center (EDRC)
              </h3>
              <p className="py-6 leading-loose text-gray-700 ">
                The Entrepreneur and Development Research Council (EDRC) is a
                dedicated wing of ZNRF University of Management Sciences (ZUMS),
                established to foster innovative research and promote impactful
                educational initiatives. EDRC is committed to driving excellence
                in education and development by bridging academic theory with
                real-world applications.
                <br />
              </p>
            </div>

            <IQACMission
              title={"What We Do"}
              details={
                " We are committed to academic excellence, research, and societal impact."
              }
              data={whatWeDoData}
            />
            <CoreActivities data={values} title={"Core Values"} />

            <div className="py-12 space-y-20">
              <PublicationCarousel title={"Publication & Report"} />

              {/* <TeamCarousel title={"   EDRC Team"} /> */}
              <FeaturedEvents />
              <ResearchCTA
                title={"Contact EDRC"}
                mail={"edrc@gmail.com"}
                number={"+880187863784"}
                description={
                  " Are you ready to bring your ideas to life? EDRC welcomes students, faculty members, and external entrepreneurs who are passionate about making a difference. Join us to access the  resources, mentorship, and community you need to turn your vision into reality."
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EDRC;
