/* eslint-disable react/prop-types */

import { motion } from "framer-motion";

const AnimatedQuote = ({ quote, author }) => {
  return (
    <div className="flex items-center justify-center p-4">
      <motion.div
        initial={{ rotateY: -90, opacity: 0 }}
        animate={{ rotateY: 0, opacity: 1 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
        className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-2xl perspective-1000"
      >
        <motion.div
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 1, delay: 1 }}
          className="w-16 h-1 mb-6 bg-primary-500"
        />
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.5 }}
          className="mb-4 font-serif text-xl text-gray-800 lg:text-2xl"
        >
          <i>&#34;{quote}&#34;</i>
        </motion.h2>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 2 }}
          className="text-base text-right text-gray-600  lg:text-xl"
        >
          - {author}
        </motion.p>
      </motion.div>
    </div>
  );
};

export default AnimatedQuote;
