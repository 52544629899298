import { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Clock, Mic, Play } from "lucide-react";
import podBg from "../../assets/podcast/podBg.svg";
import image1 from "../../assets/podcast/femaleVoice.webp";
import image2 from "../../assets/podcast/maleVoice.webp";
import image3 from "../../assets/campusLife/campusLife5.jpg";
import image4 from "../../assets/campusLife/campusLife3.jpg";

const podcastEpisodes = [
  {
    id: 1,
    episode: "Episode 1",
    title: "Turning Obstacles Into Opportunities For Growth",
    host: "Sarah Johnson",
    duration: "1 hour, 40 minutes",
    category: "Motivation",
    image: image1,
  },
  {
    id: 2,
    episode: "Episode 2",
    title: "How AI is Changing the Future of Innovation",
    host: "James Carter",
    duration: "50 minutes",
    category: "Innovation",
    image: image2,
  },
  {
    id: 3,
    episode: "Episode 3",
    title: "Studying Abroad: Challenges and Benefits",
    host: "Emma Wilson",
    duration: "1 hour, 20 minutes",
    category: "Study Abroad",
    image: image3,
  },
  {
    id: 4,
    episode: "Episode 4",
    title: "Building a Career in the Tech Industry",
    host: "Daniel Lee",
    duration: "45 minutes",
    category: "Career",
    image: image4,
  },
];

const categories = [
  "All",
  "Motivation",
  "Innovation",
  "Study Abroad",
  "Career",
];

const PodCastEpisodes = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const filteredEpisodes =
    selectedCategory === "All"
      ? podcastEpisodes
      : podcastEpisodes.filter(
          (podcast) => podcast.category === selectedCategory
        );

  return (
    <div className="py-12 lg:py-20">
      <h1 className="max-w-4xl mx-auto text-3xl font-bold text-center md:text-4xl lg:text-5xl font-manrope text-primary">
        Explore the most recent podcast episodes
      </h1>
      <div className="flex flex-wrap justify-center gap-4 mt-8">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`px-4 py-2 font-semibold rounded-lg text-sm md:text-base transition-all duration-300 ${
              selectedCategory === category
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            {category}
          </button>
        ))}
      </div>
      <motion.div
        layout
        className="grid grid-cols-1 gap-6 pt-12 md:gap-10 xl:grid-cols-2"
      >
        <AnimatePresence>
          {filteredEpisodes.map((podcast) => (
            <motion.div
              key={podcast.id}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.4 }}
            >
              <Link
                to={`/podcast/${podcast.id}`}
                className="grid grid-cols-6 transition-all duration-500 border border-gray-100 shadow-md min-h-64 group rounded-2xl lg:hover:shadow-2xl xl:hover:scale-105"
              >
                <img
                  className="object-cover w-full h-full col-span-6 rounded-t-lg md:col-span-2 md:rounded-none md:rounded-l-2xl"
                  src={podcast.image}
                  alt={podcast.title}
                />
                <div
                  style={{
                    backgroundImage: `url(${podBg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                  className="col-span-6 md:col-span-4 flex flex-col justify-between bg-[#F3F6F8] rounded-r-2xl p-4 md:p-6"
                >
                  <div className="flex items-center justify-between gap-0">
                    <button className="w-full px-4 py-1.5 font-domine bg-white text-gray-600 shadow-lg rounded-lg text-xs md:text-sm">
                      {podcast.episode}
                    </button>
                    <button className="w-full whitespace-nowrap text-gray-700 px-4 py-1.5 font-domine flex items-center justify-center gap-2 rounded-xl text-xs md:text-sm">
                      <Mic className="text-primary" /> {podcast.host}
                    </button>
                    <button className="w-full px-4 py-1.5 font-domine text-gray-600 bg-white rounded-lg shadow-lg text-xs md:text-sm">
                      {podcast.category}
                    </button>
                  </div>
                  <h1 className="py-4 pb-8 text-base font-bold text-gray-800 text-start md:text-lg lg:text-xl font-manrope">
                    {podcast.title}
                  </h1>
                  <div className="flex items-center justify-between w-full">
                    <button className="flex items-center justify-center gap-2 text-gray-700">
                      <Play className="p-2 transition-all duration-500 transform bg-white rounded-full shadow-md group-hover:rotate-[360deg] group-hover:bg-primary group-hover:text-white divide-neutral-500 size-9 text-primary" />
                      Listen Now
                    </button>
                    <div className="flex items-center justify-center gap-1 text-xs text-gray-600 md:text-sm">
                      <Clock className="size-4 text-primary" />{" "}
                      {podcast.duration}
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default PodCastEpisodes;
