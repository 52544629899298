import React, { useEffect, useState } from "react";
import ImageHeading from "../../components/ui/ImageHeading";
import headerImage from "../../assets/header/header.jpeg";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/utils";
import OverviewCSE from "../../components/Departments/CSE/OverviewCSE";
import FacultyCSE, { FacultyModal } from "../../components/Departments/CSE/FacultyCSE";
import CurriculumCSE from "../../components/Departments/CSE/CurriculumCSE";
import axios from "axios";
import { useParams } from "react-router-dom";

const tabData = {
  overview: {
    name: "Overview",
    component: OverviewCSE,
    dataKey: "DepartmentOverview",
  },
  faculty: {
    name: "Faculty",
    component: FacultyCSE,
    dataKey: "Faculty",
  },
  curriculum: {
    name: "Curriculum",
    component: CurriculumCSE,
    dataKey: "curriculum",
  },
  // message: {
  //   name: "Message From Chairman",
  //   component: MessageFromChairmanCSE,
  //   dataKey: "messageFromChairman",
  // },
};

const DepartmentCSE = () => {
  const [active, setActive] = useState("overview");
  const { slug } = useParams();
  const [departmentData, setDepartmentData] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState(null);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await axios.get(
          `https://zums-backend.vercel.app/api/v1/departments/${slug}`
        );
        setDepartmentData(response?.data || []);
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    };

    fetchDepartmentData();
  }, [slug]);

  console.log(departmentData, "data.....");

  return (
    <div className="min-h-screen">
      <ImageHeading heading={departmentData?.title2} imageUrl={headerImage} />
      <div className="container px-4 mx-auto my-12 md:px-8">
        <h2 className="flex flex-col space-y-3 font-bold font-domine">
          <span className="text-base lg:text-xl font-display">
            {departmentData?.title1}
          </span>{" "}
          <span className="text-xl md:text-2xls lg:text-4xl">
            {departmentData?.title2}
          </span>
        </h2>
        <p className="mt-4 font-display">{departmentData?.subTitle}</p>

        {/* Tab Filter Section */}
        <div
          className={cn(
            "mb-8 flex flex-row items-center justify-left overflow-scroll [perspective:1000px] relative sm:overflow-visible h-full no-visible-scrollbar max-w-full w-full mt-8"
          )}
        >
          <div className="px-8 py-4 bg-white rounded-md shadow-xl lg:rounded-full w-fit">
            {Object.entries(tabData).map(([key, value]) => (
              <button
                key={key}
                onClick={() => setActive(key)}
                className={cn("relative px-4 py-2 rounded-full")}
                style={{
                  transformStyle: "preserve-3d",
                }}
              >
                {active === key && (
                  <motion.div
                    layoutId="clickedbutton"
                    transition={{ type: "spring", bounce: 0.3, duration: 0.6 }}
                    className={cn(
                      "absolute inset-0 bg-primary text-white rounded-full"
                    )}
                  />
                )}
                <span
                  className={`relative block ${
                    active === key ? "text-white" : "text-black"
                  }`}
                >
                  {value.name}
                </span>
              </button>
            ))}
          </div>
        </div>

        {/* Content Section */}
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            key={active}
            className="overflow-hidden shadow-xl bg-white/80 backdrop-blur-sm rounded-xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {React.createElement(tabData[active]?.component, {
              data: departmentData[tabData[active]?.dataKey],
              title2: departmentData?.title2,
              onFacultySelect: setSelectedFaculty, // Add this prop for FacultyCSE
            })}
          </motion.div>
        </AnimatePresence>

        {/* Faculty Modal */}
        {/* <AnimatePresence>
          {selectedFaculty && (
            <FacultyModal
              faculty={selectedFaculty}
              onClose={() => setSelectedFaculty(null)}
            />
          )}
        </AnimatePresence> */}
      </div>
    </div>
  );
};

export default DepartmentCSE;
