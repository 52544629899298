/* eslint-disable react/prop-types */
import { Routes, Route } from "react-router-dom";
import { lazy, useState, useEffect } from "react";
import Layout from "../components/Layout";
import Loader from "../components/Layout/Loader";
import DiscoverZums from "../pages/DiscoverZums";
// import Admission from "../pages/Admission.page";
import LifeAtZums from "../pages/LifeAtZums.page";

import Research from "../pages/Research.page";
import { AdmissionPage2 } from "../pages/Admission2.page";
import Academics from "../pages/Academics/Academics.page";
import SchoolOfEconomics from "../pages/Academics/Economics.page";
import SchoolOfHumanities from "../pages/Academics/Humanities.page";
import SchoolOfScience from "../pages/Academics/Scince.page";
import ResearchPage2 from "../pages/Academics/Research2.page";
import CourseCatalog from "../pages/CourseCatalog.page";
import ResearchPage3 from "../pages/Academics/Research3.page";
import Lab2 from "../pages/Lab2.page";
import Library from "../pages/Library.page";
// import WhyChooseZums from "../pages/WhyChooseZums";

import WhyChooseZums2 from "../pages/WhyChooseZums2.page";
import AcademicCalender from "../pages/AcademicCalender.page";
import OfficeOfTheVc from "../pages/OfficeOfTheVc";
import BoardOfTrustees from "../pages/BoardOfTrustees";
import InfoCenter from "../pages/InfoCenter.page";
import Career from "../pages/Career.page";
import NewsAndEvents from "../pages/NewsAndEvents.page";
import AdvisoryBoard from "../pages/AdvisoryBoard.page";
import Faculty from "../pages/Faculty.page";
import TuitionFees from "../pages/TuitionFees.page";

import Scholarship from "../pages/Scholarship.page";
import DepartmentCSE from "../pages/Department/DepartmentCSE.page";
import IQAC from "../pages/centers/IQAC.page";
import EDRC from "../pages/centers/EDRC.page";
import CLLL from "../pages/centers/CLLL.page";
import School from "../pages/Schools.page";
import Administration from "../pages/Administration.page";
import PrivacyPolicy from "../pages/PrivacyPolicy.page";
import ZUMSVoices from "../pages/ZUMSVoices.page";
import GradingSystem from "../pages/GradingSystem.page";

// Lazy load pages
const Home = lazy(() => import("../pages/Home"));
const NotFound = lazy(() => import("../pages/NotFound"));

const DelayedSuspense = ({ children, delay = 3000 }) => {
  const [isDelayComplete, setIsDelayComplete] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelayComplete(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return isDelayComplete ? children : <Loader />;
};

export default function AppRoutes() {
  return (
    <DelayedSuspense>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" index element={<Home />} />
          <Route path="*" element={<NotFound />} />
          {/* discover zums */}
          <Route
            path="/discover-zums/ambition-commitment-values"
            element={<DiscoverZums />}
          />

          {/* Admission */}
          <Route path="/admission" element={<AdmissionPage2 />} />
          <Route path="/admission/tuition-fees" element={<TuitionFees />} />
          <Route path="/admission/scholarship" element={<Scholarship />} />
          <Route
            path="/discover-zums/office-of-the-vc"
            element={<OfficeOfTheVc />}
          />

          {/* Student Life */}
          <Route path="/life-at-zums" element={<LifeAtZums />} />

          {/* Academics */}
          <Route path="/academics" element={<School />} />
          <Route
            path="/academics/school-of-business-administration"
            element={<Academics />}
          />
          <Route
            path="/academics/school-of-economics"
            element={<SchoolOfEconomics />}
          />
          <Route
            path="/academics/school-of-science-and-engineering"
            element={<SchoolOfScience />}
          />
          <Route
            path="/academics/school-of-humanities-and-social-sciences"
            element={<SchoolOfHumanities />}
          />
          <Route
            path="/academics/zums-incubation-center"
            element={<ResearchPage2 />}
          />
          <Route
            path="/academics/academic-Calendar"
            element={<AcademicCalender />}
          />
          <Route path="/academics/research2" element={<Research />} />
          <Route path="/academics/research3" element={<ResearchPage3 />} />
          <Route
            path="/academics/zums-technology-innovation-lab"
            element={<Lab2 />}
          />
          <Route path="/centers-of-excellence/iqac" element={<IQAC />} />
          <Route path="/centers-of-excellence/edrc" element={<EDRC />} />
          <Route path="/centers-of-excellence/clll" element={<CLLL />} />
          {/* <Route path="/academics/zums-technology-innovation-lab2" element={<Lab2 />} /> */}
          <Route path="/academics/course-catalog" element={<CourseCatalog />} />
          <Route path="/library" element={<Library />} />
          <Route path="/grading-system" element={<GradingSystem />} />
          <Route
            path="/discover-zums/why-choose-zums"
            element={<WhyChooseZums2 />}
          />
          <Route
            path="/discover-zums/board-of-trustees"
            element={<BoardOfTrustees />}
          />
          <Route path="/info" element={<InfoCenter />} />
          <Route path="/career" element={<Career />} />
          <Route path="/news-and-events" element={<NewsAndEvents />} />
          <Route path="/terms-and-condition" element={<PrivacyPolicy />} />
          <Route path="/zums-voices" element={<ZUMSVoices />} />
          <Route
            path="/discover-zums/advisory-board"
            element={<AdvisoryBoard />}
          />
          <Route path="/discover-zums/faculty" element={<Faculty />} />
          <Route
            path="/discover-zums/administration"
            element={<Administration />}
          />
          {/* Dynamic route for school */}
          <Route
            path="academics/school-of-science-and-engineering/:slug"
            element={<DepartmentCSE />}
          />
        </Route>
      </Routes>
    </DelayedSuspense>
  );
}
