import headerImage from "../../assets/schools/schoolOfScience1.webp";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import ImageHeading from "../../components/ui/ImageHeading";
import RoundedButton from "../../components/ui/RoundedButton";
import DepartmentFacultyCard from "../../components/ui/Card/DepartmentFacultyCard";
import FeaturedEvents from "../../components/ui/FeaturedEvents";
import DrSheikhAbdulLatif from "../../assets/Faculty/ScienceandEngineering/DrSheikhAbdulLatif.jpg";
import DrRiasatIslam from "../../assets/Faculty/ScienceandEngineering/DrRiasatIslam.png";

import ProfDrMdFaruqueAhmedHaolader from "../../assets/Faculty/ScienceandEngineering/ProfDrMdFaruqueAhmedHaolader.jpg";
import ProfFokhruzZaman from "../../assets/Faculty/ScienceandEngineering/ProfFokhruzZaman.jpg";

const SchoolOfScience = () => {
  const facultyMembers = [
    {
      id: 1,
      name: "Prof. Dr. Md. Faruque Ahmed Haolader",
      title: "Professor",
      department: "School of Science & Engineering",
      expertise: [
        "Computer Science",
        "Engineering Education",
        "Information Systems",
      ],
      email: "faruque.ahmed@zums.edu",
      phone: "+880 1712345670",
      image: ProfDrMdFaruqueAhmedHaolader,
      bio: "Distinguished professor with extensive research in computer science and engineering education",
    },
    {
      id: 2,
      name: "Dr. Sheikh Abdul Latif",
      title: "Professor",
      department: "School of Science & Engineering",
      expertise: ["Mechatronics", "Robotics", "Automation Systems"],
      email: "abdul.latif@zums.edu",
      phone: "+880 1745678903",
      image: DrSheikhAbdulLatif,
      bio: "Researcher in robotics and automated control systems",
    },
    {
      id: 3,
      name: "Prof. Fokhruz Zaman",
      title: "Professor",
      department: "School of Science & Engineering",
      expertise: [
        "Electrical Engineering",
        "Power Systems",
        "Renewable Energy",
      ],
      email: "fokhruz.zaman@zums.edu",
      phone: "+880 1723456781",
      image: ProfFokhruzZaman,
      bio: "Expert in electrical engineering and renewable energy systems",
    },
    {
      id: 4,
      name: "Dr. Riasat Islam",
      title: "Adjunct Assistant Professor",
      department: "School of Science & Engineering",
      expertise: [
        "Computer Networks",
        "Data Communications",
        "Network Security",
      ],
      email: "riasat.islam@zums.edu",
      phone: "+880 1756789014",
      image: DrRiasatIslam,
      bio: "Expert in network systems and data communication technologies",
    },
  ];

  return (
    <div className="min-h-screen ">
      <ImageHeading
        heading="School Of Science & Engineering"
        imageUrl={headerImage}
        subHeading="Lorem ipsum dolor sit amet consectetur adipisicing elit."
      />
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <section className="grid grid-cols-1 gap-12 md:grid-cols-3">
          <div className="col-span-2 space-y-4 text-gray-700 ">
            <p>
              The School of Science & Engineering at ZUMS is where innovation
              meets practical application. We provide a rigorous education in
              cutting-edge fields, fostering creativity, problem-solving, and a
              passion for discovery. Prepare to engineer the future and make a
              real-world impact.
            </p>
          </div>
          <div className="flex flex-col items-center space-y-4">
            {/* <div className="w-full pb-2 border-b border-primary">
              <Link className="flex items-center justify-between" to="/Schools">
                <h3 className="text-lg font-semibold text-primary">
                  Explore All Schools
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div> */}
            <div className="w-full pb-2 border-b border-primary">
              <Link
                className="flex items-center justify-between"
                to="/academics/academic-Calendar"
              >
                <h3 className="text-lg font-semibold text-primary">
                  Academic Calendar
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div>
            <div className="w-full pb-2 border-b border-primary">
              <Link
                className="flex items-center justify-between"
                to="https://103.60.173.170/zumsadmission/Admission/Home.aspx"
              >
                <h3 className="text-lg font-semibold text-primary">
                  Apply Now
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div>
            <div className="w-full pb-2 border-b border-primary">
              <Link
                className="flex items-center justify-between"
                to="/academics/zums-technology-innovation-lab"
              >
                <h3 className="text-lg font-semibold text-primary">
                  Innovation Lab
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div>
          </div>
        </section>
      </div>
      <div className=" relative text-gray-200 bg-[#AC0D30] overflow-hidden">
        {/* Background Pattern */}
        <div
          className="absolute inset-0 opacity-20"
          style={{
            backgroundImage:
              "radial-gradient(circle at 25px 25px, rgba(255, 255, 255, 0.2) 2%, transparent 0%), " +
              "radial-gradient(circle at 75px 75px, rgba(255, 255, 255, 0.2) 2%, transparent 0%)",
            backgroundSize: "100px 100px",
          }}
        />

        {/* Gradient Overlays */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#AC0D30]/50 to-[#AC0D30]" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />

        <div className="container grid grid-cols-1 gap-10 px-4 py-12 mx-auto sm:px-6 lg:px-8 md:grid-cols-2">
          <div className="z-20 border-gray-200 lg:border-r lg:pr-24">
            <h3 className="text-3xl font-bold font-domine lg:text-4xl">
              ITM Program
            </h3>
            <p className="mt-8 text-gray-200">
              The Information Technology Management program at ZNRF University
              is designed to bridge the gap between technology and business.
              Students gain expertise in IT systems, data management, and
              business strategies, preparing them for leadership roles in the
              tech-driven business world. The curriculum emphasizes practical
              skills, innovation, and ethical decision-making.
            </p>

            <div className="flex items-center justify-center ">
              <RoundedButton
                className={
                  "mt-8 py-4 font-bold font-display px-6 w-fit text-center border-white text-white"
                }
                spanClassName={"text-white"}
                href={
                  "/academics/school-of-science-and-engineering/information-technology-management"
                }
              >
                Explore ITM Program
              </RoundedButton>
            </div>
          </div>
          <div className="z-20 lg:pl-24">
            <h3 className="text-3xl font-bold font-domine lg:text-4xl">
              CSE Program
            </h3>
            <p className="mt-8 text-gray-200">
              The Computer Science & Engineering program at ZNRF University
              equips students with cutting-edge knowledge in computing, software
              development, and artificial intelligence. The curriculum
              emphasizes programming, problem-solving, and system design to
              prepare students for dynamic careers in the tech industry.
            </p>

            <div className="flex items-center justify-center ">
              <RoundedButton
                className={
                  "mt-8 py-4 font-bold font-display px-6 w-fit text-center border-white text-white"
                }
                spanClassName={"text-white"}
                href={
                  "/academics/school-of-science-and-engineering/computer-science-engineering"
                }
              >
                Explore CSE Program
              </RoundedButton>
            </div>
          </div>
        </div>
      </div>

      {/* Faculty */}
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8 ">
        <h2 className="text-4xl font-bold text-center font-domine">
          Faculty members
        </h2>
        <div className="grid grid-cols-1 gap-5 my-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 lg:gap-6">
          {facultyMembers.map((faculty, index) => (
            <DepartmentFacultyCard key={index} {...faculty} />
          ))}
        </div>
        <div className="flex justify-center w-full">
          <RoundedButton href={"/discover-zums/faculty"}>
            View All Faculty Members
          </RoundedButton>
        </div>
      </div>

      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <FeaturedEvents />
      </div>
    </div>
  );
};

export default SchoolOfScience;
