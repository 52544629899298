import React, { useState } from "react";
import { Search } from "lucide-react";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

export default function AcademicCalenderFilter({
  onSearch,
  onFilter,
  currentFilter,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const filterOptions = [
    { value: "all", label: "All Events" },
    { value: "academic", label: "Academic" },
    { value: "exam", label: "Exams" },
    { value: "holiday", label: "Holidays" },
    { value: "financial", label: "Payments" },
  ];

  return (
    <motion.header
      className=""
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
    >
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex flex-col items-center space-y-6">
          {/* Title */}
          <h1 className="text-3xl lg:text-5xl font-bold text-gray-800 font-domine">
            Fall 2025 Academic Calendar
          </h1>

          {/* Filter Tabs */}
          <div
            className={cn(
              "mb-8 flex flex-row items-center justify-center overflow-scroll [perspective:1000px] relative sm:overflow-visible h-full no-visible-scrollbar max-w-full w-full"
            )}
          >
            <div className="px-8 py-4 bg-white rounded-md shadow-xl lg:rounded-full w-fit">
              {filterOptions.map((filter) => (
                <button
                  key={filter.value}
                  onClick={() => onFilter(filter.value)}
                  className={cn("relative px-4 py-2 rounded-full")}
                  style={{
                    transformStyle: "preserve-3d",
                  }}
                >
                  {currentFilter === filter.value && (
                    <motion.div
                      layoutId="clickedbutton"
                      transition={{
                        type: "spring",
                        bounce: 0.3,
                        duration: 0.6,
                      }}
                      className={cn(
                        "absolute inset-0 bg-primary text-white rounded-full"
                      )}
                    />
                  )}
                  <span
                    className={`relative block ${
                      currentFilter === filter.value
                        ? "text-white"
                        : "text-black"
                    }`}
                  >
                    {filter.label}
                  </span>
                </button>
              ))}
            </div>
          </div>

          {/* Search Bar */}
          <div className="relative w-full max-w-md">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Search events..."
              className="w-full pl-12 pr-4 py-3 rounded-full border border-gray-200 
                       focus:outline-none focus:ring-2 focus:ring-blue-500 
                       shadow-sm transition-all duration-200 
                       hover:shadow-md"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                onSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </motion.header>
  );
}
