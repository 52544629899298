import RoundedButton from "../../ui/RoundedButton";
import { motion } from "framer-motion";
import agri from "../../../assets/agriBg.jpg";
import tech from "../../../assets/techBg.jpg";
import sustain from "../../../assets/sustainBg.jpg";
const FocusArea3 = () => {
  return (
    <div className="grid grid-cols-1 py-20 md:grid-cols-2 lg:grid-cols-3">
      {/* First Column */}
      <div className="flex flex-col items-center justify-start gap-20">
        <motion.div
          initial={{
            y: "200px",
          }}
          transition={{
            duration: 2,
          }}
          whileInView={{
            y: 0,
          }}
          className=" space-y-4 overflow-hidden drop-shadow-lg text-center bg-gray-100 max-w-[300px] size-fit rounded-xl z-10"
        >
          <img className="object-cover w-fit h-fit " src={tech} alt="" />
          <div className="p-5 ">
            <h2 className="text-2xl font-medium">Technology</h2>
            <p>
              Explore cutting-edge innovations and advancements in the world of
              technology.
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{
            y: "-200px",
          }}
          transition={{
            duration: 2,
          }}
          whileInView={{
            y: 0,
          }}
          className="h-[300px] bg-[#910C28] w-[3px] z-0"
        ></motion.div>
      </div>

      {/* Second Column */}
      <div className="flex flex-col-reverse items-center justify-start gap-16 md:flex-col">
        <motion.div
          initial={{
            y: "200px",
          }}
          transition={{
            duration: 2,
          }}
          whileInView={{
            y: 0,
          }}
          className="h-[300px] bg-[#910C28] w-[3px] z-0"
        ></motion.div>
        <motion.div
          initial={{
            y: "200px",
          }}
          transition={{
            duration: 2,
          }}
          whileInView={{
            y: 0,
          }}
          className=" space-y-4 overflow-hidden drop-shadow-lg text-center bg-gray-100 max-w-[300px] size-fit rounded-xl z-10"
        >
          <img className="object-cover w-fit h-fit " src={sustain} alt="" />
          <div className="p-5 ">
            <h2 className="text-2xl font-medium">Sustainability</h2>
            <p>
              Discover practices and ideas that contribute to a greener and more
              sustainable future.
            </p>
          </div>
        </motion.div>
      </div>

      {/* Third Column */}
      <div className="flex flex-col items-center justify-start gap-20">
        <motion.div
          initial={{
            y: "200px",
          }}
          transition={{
            duration: 2,
          }}
          whileInView={{
            y: 0,
          }}
          className=" space-y-4 overflow-hidden drop-shadow-lg text-center bg-gray-100 max-w-[300px] size-fit rounded-xl z-10"
        >
          <img className="object-cover w-fit h-fit " src={agri} alt="" />
          <div className="p-5 ">
            <h2 className="text-2xl font-medium">Agriculture</h2>
            <p>
              Learn about innovative methods and practices that are shaping
              modern agriculture.
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{
            y: "-200px",
          }}
          transition={{
            duration: 2,
          }}
          whileInView={{
            y: 0,
          }}
          className="h-[300px] hidden lg:block bg-[#910C28] w-[3px] z-0"
        ></motion.div>
      </div>
    </div>
  );
};

export default FocusArea3;
