import React from "react";
import { motion } from "framer-motion";
import { Rocket, Shield, Users, Code2, Zap } from "lucide-react";
import FeatureCard from "../components/ui/Card/FeatureCard";
// import ParticleBackground from './ParticleBackground';
// import { colors } from '../theme/colors';

const features = [
  {
    icon: Rocket,
    title: "Startup Support and Mentorship",
    points: [
      "Guidance from mentors and industry leaders",
      "Support for idea validation and business models",
      "End-to-end startup success assistance",
    ],
  },
  {
    icon: Shield,
    title: "State-of-the-Art Facilities",
    points: [
      "Co-working spaces and innovation labs",
      "Resources for R&D across domains",
      "Prototyping tools and equipment",
    ],
  },
  {
    icon: Users,
    title: "Workshops and Training",
    points: [
      "Workshops, training, and hackathons",
      "Focus on strategy, design, and marketing",
      "Skills development for members",
    ],
  },
  {
    icon: Code2,
    title: "Access to Funding and Networking",
    points: [
      "Grants, seed funding, and venture capital",
      "Networking with investors and partners",
    ],
  },
  {
    icon: Zap,
    title: "Collaboration with Industry and Academia",
    points: [
      "Partnerships with organizations and government",
      "Access to research and technical expertise",
    ],
  },
];

const WhatWeOffer = () => {
  const firstRow = features.slice(0, 3);
  const secondRow = features.slice(3);

  return (
    <section className="relative pt-6">
      {/* <ParticleBackground /> */}

      {/* Animated gradient orbs */}
      {/* <div
        className="absolute w-1/2 rounded-full top-1/4 -left-1/4 h-1/2 blur-3xl animate-pulse"
        style={{
          background: `radial-gradient(circle, #FBD1D5/30, #ED1C2E/10)`,
        }}
      />
      <div
        className="absolute w-1/2 delay-1000 rounded-full bottom-1/4 -right-1/4 h-1/2 blur-3xl animate-pulse"
        style={{
          background: `radial-gradient(circle, #ED1C2E/30, #910F1B/10)`,
        }}
      /> */}

      <div className="relative ">
        {/* <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="mb-24 text-center"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="inline-block"
          >
            <h2
              className="mb-6 text-6xl font-bold"
              style={{
                background: `linear-gradient(135deg, #FBD1D5, #ED1C2E, #910F1B)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Beyond Tomorrow
            </h2>
          </motion.div>
          <p className="max-w-2xl mx-auto text-xl text-gray-400">
            Step into the future with our quantum-powered solutions
          </p>
        </motion.div> */}

        {/* First row - 3 cards */}
        <div className="grid grid-cols-1 gap-8 mb-8 md:grid-cols-3 lg:gap-12">
          {firstRow.map((feature, index) => (
            <FeatureCard
              key={index}
              {...feature}
              index={index}
              primaryColor="#ED1C2E"
            />
          ))}
        </div>

        {/* Second row - 2 centered cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12 max-w-[800px] mx-auto">
          {secondRow.map((feature, index) => (
            <FeatureCard
              key={index + 3}
              {...feature}
              index={index + 3}
              primaryColor="#ED1C2E"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffer;
