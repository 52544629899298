export const labCards = [
  {
    id: 1,
    title: "Circuit Lab",
    description:
      "The Circuit Lab is dedicated to exploring the fundamentals of electrical circuits and electronics. Equipped with advanced testing and measurement tools, this lab allows students to design, analyze, and troubleshoot electronic circuits. Hands-on projects provide practical experience in circuit theory, component analysis, and system integration.",
    image:
      "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&q=80&w=800",
    category: "engineering-applied-sciences",
  },
  {
    id: 2,
    title: "IC & Logic Loom Lab",
    description:
      "This lab introduces students to the world of digital electronics and integrated circuits. Here, they study the design and implementation of logic gates, combinational and sequential circuits, and microcontrollers. The lab supports hands-on learning in digital logic design and VLSI systems.",
    image:
      "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?auto=format&fit=crop&q=80&w=800",
    category: "engineering-applied-sciences",
  },
  {
    id: 3,
    title: "Automation Lab",
    description:
      "The Automation Lab provides hands-on training in industrial automation technologies. Students learn about programmable logic controllers (PLCs), sensors, actuators, and human-machine interfaces (HMIs). This lab is a stepping stone for careers in automation and control engineering.",
    image:
      "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&q=80&w=800",
    category: "engineering-applied-sciences",
  },
  {
    id: 4,
    title: "SysSphere Lab",
    description:
      "The SysSphere Lab focuses on systems engineering and integration. Students work on projects involving embedded systems, real-time operating systems, and hardware-software integration. This lab prepares students to tackle challenges in system design and optimization.",
    image:
      "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?auto=format&fit=crop&q=80&w=800",
    category: "engineering-applied-sciences",
  },
  {
    id: 5,
    title: "Physics Lab",
    description:
      "The Physics Lab is designed to bridge theoretical physics concepts with practical applications. Students perform experiments related to mechanics, thermodynamics, optics, and electromagnetism. The lab fosters a deeper understanding of the physical principles underlying engineering innovations.",
    image:
      "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&q=80&w=800",
    category: "engineering-applied-sciences",
  },
  {
    id: 6,
    title: "IoT & Robotics Lab",
    description:
      "This cutting-edge lab empowers students to delve into the Internet of Things (IoT) and robotics. Students work on projects involving sensors, microcontrollers, and actuators, gaining experience in automation, smart devices, and robotics programming. The lab nurtures innovation in smart systems and industrial automation.",
    image:
      "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?auto=format&fit=crop&q=80&w=800",
    category: "engineering-applied-sciences",
  },
  {
    id: 7,
    title: "Connect Tech Lab",
    description:
      "The Connect Tech Lab focuses on networking and communication technologies. Students learn about network design, configuration, and troubleshooting. With simulators and real-world equipment, the lab prepares students for careers in IT infrastructure, cybersecurity, and telecommunications.",
    image:
      "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?auto=format&fit=crop&q=80&w=800",
    category: "engineering-applied-sciences",
  },
  {
    id: 8,
    title: "Code Craft Lab",
    description:
      "The Code Craft Lab is an inspiring space for budding programmers to refine their coding skills. With state-of-the-art systems and a collaborative environment, this lab is ideal for learning programming languages, algorithm design, and software development. Students engage in coding competitions, hackathons, and team projects.",
    image:
      "https://images.unsplash.com/photo-1472214103451-9374bd1c798e?auto=format&fit=crop&q=80&w=800",
    category: "software-computing",
  },
  {
    id: 9,
    title: "Code Forge Lab",
    description:
      "The Code Forge Lab is where ideas are transformed into software solutions. Focused on application development, this lab supports students in creating web, mobile, and desktop applications. It provides tools for software engineering, debugging, and version control, fostering innovation in coding.",
    image:
      "https://images.unsplash.com/photo-1449824913935-59a10b8d2000?auto=format&fit=crop&q=80&w=800",
    category: "software-computing",
  },
  {
    id: 10,
    title: "Computer Programming Lab",
    description:
      "This lab serves as a hub for mastering computational problem-solving. Students practice programming logic, data structures, and algorithm optimization. Equipped with industry-standard software, the lab facilitates learning in areas such as competitive programming, software testing, and code performance analysis.",
    image:
      "https://images.unsplash.com/photo-1449824913935-59a10b8d2000?auto=format&fit=crop&q=80&w=800",
    category: "software-computing",
  },
  {
    id: 11,
    title: "Computing Center ",
    description:
      "This lab serves as a hub for mastering computational problem-solving. Students practice programming logic, data structures, and algorithm optimization. Equipped with industry-standard software, the lab facilitates learning in areas such as competitive programming, software testing, and code performance analysis.",
    image:
      "https://images.unsplash.com/photo-1449824913935-59a10b8d2000?auto=format&fit=crop&q=80&w=800",
    category: "software-computing",
  },
];
