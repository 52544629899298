import image1 from "../../assets/podcast/femaleVoice.webp";
import { useInView, motion } from "framer-motion";
import { useRef } from "react";
const expertCards = [
  {
    id: 1,
    title: "Expert Insights",
    description:
      "Gain exclusive insights from industry experts sharing their knowledge and experiences.",
    image: image1,
  },
  {
    id: 2,
    title: "Podcast Trends",
    description:
      "Stay updated with the latest trends shaping the podcasting industry.",
    image: image1,
  },
  {
    id: 3,
    title: "Production Tips",
    description:
      "Learn the best techniques to enhance your podcast production quality.",
    image: image1,
  },
  {
    id: 4,
    title: "Audience Engagement",
    description:
      "Discover effective strategies to grow and engage your podcast audience.",
    image: image1,
  },
];

const PodExperience = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <div>
      <h1 className="max-w-3xl pb-8 text-3xl font-bold text-start md:text-4xl lg:text-5xl font-manrope text-primary">
        The ultimate podcasting experience explained
      </h1>
      <div
        ref={ref}
        className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4"
      >
        {expertCards.map((card, index) => (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, delay: index * 0.3 }}
            key={card.id}
            className="relative cursor-pointer h-[350px] group"
          >
            <div className="z-50">
              <img
                className="absolute top-0 z-10 group-hover:h-[220px] transition-all transform duration-500 left-0 object-cover object-center w-full h-full rounded-xl"
                src={card.image}
                alt={card.title}
              />
              <div className="absolute inset-0 top-0 left-0 z-20 group-hover:h-[220px] w-full h-full transition-all duration-500 transform bg-black/30 rounded-xl"></div>
              <h1 className="absolute z-30 text-xl font-semibold text-gray-200 transition-all duration-500 transform group-hover:text-transparent bottom-4 left-4 md:text-2xl font-domine">
                {card.title}
              </h1>
            </div>
            <div className="absolute bottom-0 left-0 hidden p-4 transition-all duration-500 transform -z-0 group-hover:block ">
              <h1 className="text-lg font-medium md:text-xl font-domine text-primary">
                {card.title}
              </h1>
              <p className="text-gray-700 line-clamp-4">{card.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default PodExperience;
