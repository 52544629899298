/* eslint-disable react/prop-types */
"use client";

import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Plus } from "lucide-react";

export function ExpandableContent({ content, styles = "" }) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Convert JSX content to plain text for word count
  const getTextContent = (node) => {
    if (typeof node === "string") return node;
    if (Array.isArray(node)) return node.map(getTextContent).join(" ");
    if (node?.props?.children) return getTextContent(node.props.children);
    return "";
  };

  const fullText = getTextContent(content);
  const words = fullText.split(/\s+/);
  const previewText =
    words.slice(0, 20).join(" ") + (words.length > 20 ? "..." : "");

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  return (
    <div className="mt-4 space-y-6">
      <div className="relative">
        <AnimatePresence>
          <motion.div
            key={isExpanded ? "expanded" : "collapsed"}
            className={`pl-4 space-y-4 text-sm leading-relaxed border-l-4 sm:text-base border-primary ${styles}`}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            {!isExpanded ? <span>{previewText}</span> : <div>{content}</div>}
          </motion.div>
        </AnimatePresence>

        {words.length > 20 && (
          <motion.button
            className="p-1 -ml-3 rounded-full bg-primary group hover:bg-black/30"
            onClick={toggleExpand}
            initial={false}
            animate={{ rotate: isExpanded ? 45 : 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <Plus className="text-white size-3 md:size-4 lg:size-5 group-hover:text-white" />
          </motion.button>
        )}
      </div>
    </div>
  );
}
