/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import * as LucideIcons from "lucide-react"; // Import everything from lucide-react

const WhyChooseCSE = ({ data }) => {
  const [activeFeature, setActiveFeature] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const features = data || [];

  // Utility function to get the correct icon dynamically
  const getIconComponent = (iconName) => {
    const IconComponent = LucideIcons[iconName];
    return IconComponent ? <IconComponent className="w-6 h-6 mr-3" /> : null;
  };

  useEffect(() => {
    if (!isHovering) {
      const interval = setInterval(() => {
        setActiveFeature((prev) => (prev + 1) % features.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isHovering, features.length]);

  return (
    <section className="relative px-4 py-20 overflow-hidden text-gray-800 sm:px-6 lg:px-8">
      <div className="mx-auto ">
        <div className="relative">
          {/* Animated background */}
          <div className="absolute inset-0 flex items-center justify-center opacity-30">
            <div className="rounded-full w-96 h-96 bg-primary-200 mix-blend-multiply filter blur-xl animate-blob"></div>
            <div className="bg-purple-200 rounded-full w-96 h-96 mix-blend-multiply filter blur-xl animate-blob animation-delay-2000"></div>
            <div className="bg-pink-200 rounded-full w-96 h-96 mix-blend-multiply filter blur-xl animate-blob animation-delay-4000"></div>
          </div>

          <div className="relative z-10 grid grid-cols-1 gap-8 md:grid-cols-3">
            {/* Interactive feature selector */}
            <div className="p-6 bg-white shadow-lg rounded-xl">
              <h4 className="mb-4 text-2xl font-bold text-gray-800">
                Objectives of the Program
              </h4>

              <ul>
                {features.map((feature, index) => (
                  <motion.li
                    key={index}
                    className={`cursor-pointer p-3 rounded-lg transition-colors ${
                      index === activeFeature
                        ? "bg-gray-100"
                        : "hover:bg-gray-50"
                    }`}
                    onHoverStart={() => {
                      setIsHovering(true);
                      setActiveFeature(index);
                    }}
                    onHoverEnd={() => setIsHovering(false)}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <div className="flex items-center">
                      {getIconComponent(feature.icon)}{" "}
                      {/* Use the dynamic icon */}
                      <span className="text-gray-700">{feature.title}</span>
                    </div>
                  </motion.li>
                ))}
              </ul>
            </div>
            {/* Feature showcase */}
            <div className="p-8 bg-white shadow-lg md:col-span-2 rounded-xl">
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeFeature}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className="flex flex-col justify-center h-full"
                >
                  {/* Ensure activeFeature is within bounds and data exists */}
                  {features[activeFeature] ? (
                    <>
                      <div
                        className="mb-6 text-5xl"
                        style={{
                          color: features[activeFeature].color || "#000", // Default to black if no color is provided
                        }}
                      >
                        {getIconComponent(features[activeFeature].icon, 64)}{" "}
                        {/* Use the dynamic icon */}
                      </div>
                      <h3
                        className="mb-4 text-2xl font-bold"
                        style={{
                          color: features[activeFeature].color || "#000",
                        }}
                      >
                        {features[activeFeature].title}
                      </h3>
                      <p className="text-gray-600">
                        {features[activeFeature].description}
                      </p>
                    </>
                  ) : (
                    <p>No feature data available</p>
                  )}
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseCSE;
