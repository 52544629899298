export const timelineData = [
  {
    year: "1975",
    title: "Academic Foundations",
    description:
      "Started as Lecturer, Department of Finance, University of Dhaka.",
  },
  {
    year: "1982",
    title: "Ph.D. Achievement",
    description:
      "Earned Ph.D. in Finance from the University of Manchester, UK.",
  },
  {
    year: "1985–1991",
    title: "International Academic Contributions",
    description: "Held various academic positions internationally.",
    details: [
      "Professor, State University of New York.",
      "Visiting Professor at the University of Bocconi, Italy.",
      "Visiting Scholar at the University of Illinois, Urbana-Champaign, USA",
      "Visiting Scholar at the Moscow State University, Russia.",
    ],
  },
  {
    year: "2000–2015",
    title: "Leadership at the World Bank",
    description: "Senior Professional Staff, Washington DC.",
    details: [
      "Led initiatives in financial transparency, accountability, and governance.",
      "Spearheaded global financial system reforms and capacity-building projects.",
    ],
  },
  {
    year: "1991 Onwards",
    title: "Private Sector Leadership",
    description: "Established and managed international offices.",
    details: [
      "Established Deloitte & Touche in Moscow, Russia.",
      "Founded and managed Deloitte offices in Kazakhstan and Uzbekistan.",
      "Championed privatization and financial reforms in Central Asia.",
    ],
  },
  {
    year: "2018",
    title: "ZUMS Leadership",
    description:
      "Appointed Founding Vice Chancellor of ZNRF University of Management Sciences.",
  },
];
