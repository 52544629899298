import headerImage from "../../assets/schools/schoolOfEcon2.jpeg";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import ImageHeading from "../../components/ui/ImageHeading";
import RoundedButton from "../../components/ui/RoundedButton";
import DepartmentFacultyCard from "../../components/ui/Card/DepartmentFacultyCard";
import FeaturedEvents from "../../components/ui/FeaturedEvents";
import DrBernardWasow from "../../assets/Faculty/Economics/DrBernardWasow.jpg";
import DrMohiuddinAhmad from "../../assets/Faculty/Economics/DrMohiuddinAhmad.jpeg";
import DrMohammadOsmanGani from "../../assets/Faculty/Economics/DrMohammadOsmanGani.jpg";
import DrRezaKibria from "../../assets/Faculty/Economics/DrRezaKibria.webp";
const SchoolOfEconomics = () => {
  const facultyMembers = [
    {
      id: 1,
      name: "Dr. Mohammad Osman Gani",
      title: "Professor",
      department: "School of Economics",
      expertise: ["Microeconomics", "Labor Economics", "Econometrics"],
      email: "osman.gani@zums.edu",
      phone: "+880 1934567890",
      image: DrMohammadOsmanGani,
      bio: "Experienced researcher in microeconomic theory and labor market dynamics",
    },
    {
      id: 2,
      name: "Dr. Mohiuddin Ahmad",
      title: "Professor",
      department: "School of Economics",
      expertise: ["Macroeconomics", "Development Economics", "Economic Policy"],
      email: "mohiuddin.ahmad@zums.edu",
      phone: "+880 1712345678",
      image: DrMohiuddinAhmad,
      bio: "Leading researcher in development economics with over 20 years of experience",
    },
    {
      id: 3,
      name: "Dr. Reza Kibria",
      title: "Professor",
      department: "School of Economics",
      expertise: ["Public Economics", "Fiscal Policy", "International Finance"],
      email: "reza.kibria@zums.edu",
      phone: "+880 1823456789",
      image: DrRezaKibria,
      bio: "Expert in public economics and international financial systems",
    },
    {
      id: 4,
      name: "Dr. Bernard Wasow",
      title: "Adjunct Professor",
      department: "School of Economics",
      expertise: [
        "International Economics",
        "Economic Theory",
        "Public Policy",
      ],
      email: "bernard.wasow@zums.edu",
      phone: "+880 1823456789",
      image: DrBernardWasow,
      bio: "Distinguished economist with expertise in international economic relations",
    },
  ];

  return (
    <div className="min-h-screen ">
      <ImageHeading
        heading="School Of Economics"
        imageUrl={headerImage}
        subHeading="Lorem ipsum dolor sit amet consectetur adipisicing elit."
      />
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <section className="grid grid-cols-1 gap-12 md:grid-cols-3">
          <div className="col-span-2 space-y-4 text-gray-700 ">
            <p>
              The School of Economics at ZUMS explores the forces that shape our
              world. We equip students with the analytical tools and critical
              thinking skills to understand economic systems, analyze market
              trends, and contribute to informed policy-making. Discover how
              economics can drive positive change and shape a better future.
            </p>
          </div>
          <div className="flex flex-col items-center space-y-4">
            <div className="w-full pb-2 border-b border-primary">
              <Link
                className="flex items-center justify-between"
                to="/academics/academic-Calendar"
              >
                <h3 className="text-lg font-semibold text-primary">
                  Academic Calendar
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div>
            <div className="w-full pb-2 border-b border-primary">
              <Link
                className="flex items-center justify-between"
                to="https://103.60.173.170/zumsadmission/Admission/Home.aspx"
              >
                <h3 className="text-lg font-semibold text-primary">
                  Apply Now
                </h3>
                <FaRegArrowAltCircleRight className="text-primary" size={24} />
              </Link>
            </div>
          </div>
        </section>
      </div>
      <div className=" relative text-gray-200 bg-[#AC0D30] overflow-hidden">
        {/* Background Pattern */}
        <div
          className="absolute inset-0 opacity-20"
          style={{
            backgroundImage:
              "radial-gradient(circle at 25px 25px, rgba(255, 255, 255, 0.2) 2%, transparent 0%), " +
              "radial-gradient(circle at 75px 75px, rgba(255, 255, 255, 0.2) 2%, transparent 0%)",
            backgroundSize: "100px 100px",
          }}
        />

        {/* Gradient Overlays */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#AC0D30]/50 to-[#AC0D30]" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />

        <div className="container grid max-w-4xl grid-cols-1 px-4 py-12 mx-auto sm:px-6 lg:px-8 ">
          <div className="z-20 border-gray-200 ">
            <h3 className="text-3xl font-bold font-domine lg:text-4xl">
              Economics Program
            </h3>
            <p className="mt-8 text-gray-200">
              The Economics program at ZNRF University provides students with a
              strong foundation in economic theory, quantitative analysis, and
              policy. Our graduates are well-prepared to pursue careers in
              government, finance, and business. The program emphasizes both the
              theoretical and practical aspects of economics, with a focus on
              addressing global economic challenges.
            </p>

            <div className="flex items-center justify-center ">
              <RoundedButton
                className={
                  "mt-8 py-4 font-bold font-display px-6 w-fit text-center border-white text-white"
                }
                spanClassName={"text-white"}
                href={"/academics/school-of-science-and-engineering/economics"}
              >
                Explore Economics Program
              </RoundedButton>
            </div>
          </div>
        </div>
      </div>

      {/* Faculty */}
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8 ">
        <h2 className="text-4xl font-bold text-center font-domine">
          Faculty members
        </h2>
        <div className="grid grid-cols-1 gap-5 my-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 lg:gap-6">
          {facultyMembers.map((faculty, index) => (
            <DepartmentFacultyCard key={index} {...faculty} />
          ))}
        </div>
        <div className="flex justify-center w-full">
          <RoundedButton href={"/discover-zums/faculty"}>
            View All Faculty Members
          </RoundedButton>
        </div>
      </div>

      <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <FeaturedEvents />
      </div>
    </div>
  );
};

export default SchoolOfEconomics;
