import React from "react";
import ImageHeading from "../components/ui/ImageHeading";
import discoverImage from "../assets/Labs/labs1.webp";
import CareerHeader from "../components/Career/CareerHeader";
import FeaturedOpportunities from "../components/Career/FeaturedOpportunities";
import EmployeeBenefits from "../components/Career/EmployeeBenefits";
import CareerSuccess from "../components/Career/CareerSuccess";

const Career = () => {
  return (
    <div className="min-h-screen">
      <ImageHeading heading="Career Opportunities" imageUrl={discoverImage} />
      <CareerHeader />
      <FeaturedOpportunities />
      <CareerSuccess />
      <EmployeeBenefits />
    </div>
  );
};

export default Career;
