export const tabData = {
  0: {
    name: "BBA",
    // icon: GraduationCap,
    sections: [
      {
        id: "course-map-bba",
        title: "Course Map",
        subtitles: [
          "List of Course Category to Complete BBA",
          "Total Credits: 130",
        ],
        courses: [
          {
            sno: 1,
            area: "General Education Compulsory Courses",
            noOfCourse: "8",
            credits: 24,
          },
          {
            sno: 2,
            area: "General Education Elective Courses",
            noOfCourse: "3",
            credits: 9,
          },
          {
            sno: 3,
            area: "Business Core Courses",
            noOfCourse: "22",
            credits: 66,
          },
          {
            sno: 4,
            area: "Marketing major Compulsory Courses",
            noOfCourse: "4",
            credits: 12,
          },
        ],
      },
      {
        id: "general-education-compulsory-courses-bba",
        title: "General Education Compulsory Courses",
        subtitles: ["8 Courses", "24 Credits"],
        courses: [
          {
            sno: 1,
            code: "ENG 101",
            name: "Basic English",
            credits: 3,
          },
          {
            sno: 2,
            code: "HIS 101",
            name: "History of the Emergence of Bangladesh",
            credits: 3,
          },
          {
            sno: 3,
            code: "MAT 101",
            name: "Introductory Mathematics",
            credits: 3,
          },
          {
            sno: 4,
            code: "CSE 101",
            name: "Introduction to Computing",
            credits: 3,
          },
          {
            sno: 5,
            code: "STA 101",
            name: "Introduction to Statistics",
            credits: 3,
          },
          {
            sno: 6,
            code: "ENG 222",
            name: "English Writing and Reasoning",
            credits: 3,
          },
          {
            sno: 7,
            code: "GED 260",
            name: "Ethics and Social Responsibility",
            credits: 3,
          },
          {
            sno: 8,
            code: "GED 265",
            name: "Analytical Skills and Creative Thinking",
            credits: 3,
          },
        ],
      },
      {
        id: "general-education-elective-courses-bba",
        title: "General Education Elective Courses",
        subtitles: ["3 Courses", "9 Credits"],
        courses: [
          {
            sno: 1,
            code: "PSY 101",
            name: "Introduction to Psychology",
            credits: 3,
          },
          {
            sno: 2,
            code: "PHI 101",
            name: "Introduction to Philosophy",
            credits: 3,
          },
          {
            sno: 3,
            code: "POL 101",
            name: "Introduction to Political Science",
            credits: 3,
          },
          {
            sno: 4,
            code: "PAD 101",
            name: "Introduction to Public Administration",
            credits: 3,
          },
          {
            sno: 5,
            code: "SOC 101",
            name: "Introduction to Sociology",
            credits: 3,
          },
          {
            sno: 6,
            code: "BIO 101",
            name: "Introduction to Biology",
            credits: 3,
          },
          { sno: 7, code: "CHE 101", name: "General Chemistry", credits: 3 },
          { sno: 8, code: "PHY 101", name: "General Physics I", credits: 3 },
          {
            sno: 9,
            code: "ENV 101",
            name: "Introduction to Environmental Management",
            credits: 3,
          },
          {
            sno: 10,
            code: "BNG 201",
            name: "Bengali Language and Literature",
            credits: 3,
          },
          {
            sno: 11,
            code: "IRD 201",
            name: "Foundations of International Relations",
            credits: 3,
          },
          {
            sno: 12,
            code: "GED 220",
            name: "Fundamentals of Communication",
            credits: 3,
          },
          { sno: 13, code: "GED 225", name: "Bangladesh Studies", credits: 3 },
          {
            sno: 14,
            code: "HIS 235",
            name: "Introduction to Global History",
            credits: 3,
          },
          { sno: 15, code: "GED 308", name: "Public Speaking", credits: 3 },
          {
            sno: 16,
            code: "GED 312",
            name: "Human Rights and Governance",
            credits: 3,
          },
          {
            sno: 17,
            code: "GED 361",
            name: "Teamwork and Team Building",
            credits: 3,
          },
          {
            sno: 18,
            code: "GED 410",
            name: "Language (Foreign language other than English – at least intermediate level proficiency)",
            credits: 3,
          },
        ],
      },
      {
        id: "business-core-courses-bba",
        title: "Business Core Courses",
        subtitles: ["22 Courses", "66 Credits"],
        courses: [
          {
            sno: 1,
            code: "BUS 201",
            name: "Business Communication",
            credits: 3,
          },
          {
            sno: 2,
            code: "FIN 201",
            name: "Principles of Finance",
            credits: 3,
          },
          {
            sno: 3,
            code: "MGT 201",
            name: "Principles of Management",
            credits: 3,
          },
          {
            sno: 4,
            code: "MKT 201",
            name: "Principles of Marketing",
            credits: 3,
          },
          {
            sno: 5,
            code: "ACC 202",
            name: "Principles of Financial Accounting",
            credits: 3,
          },
          {
            sno: 6,
            code: "ACC 204",
            name: "Principles of Management Accounting",
            credits: 3,
          },
          {
            sno: 7,
            code: "BUS 210",
            name: "Mathematics for Business and Economics",
            credits: 3,
          },
          {
            sno: 8,
            code: "ENT 222",
            name: "Introduction to Entrepreneurship",
            credits: 3,
          },
          {
            sno: 9,
            code: "ECO 225",
            name: "Principles of Microeconomics",
            credits: 3,
          },
          {
            sno: 10,
            code: "ECO 245",
            name: "Principles of Macroeconomics",
            credits: 3,
          },
          {
            sno: 11,
            code: "HRM 301",
            name: "Human Resource Management",
            credits: 3,
          },
          {
            sno: 12,
            code: "FIN 302",
            name: "Financial Management",
            credits: 3,
          },
          {
            sno: 13,
            code: "MKT 305",
            name: "Marketing Management",
            credits: 3,
          },
          { sno: 14, code: "STA 305", name: "Applied Statistics", credits: 3 },
          {
            sno: 15,
            code: "MIS 308",
            name: "Management Information Systems",
            credits: 3,
          },
          {
            sno: 16,
            code: "BUS 310",
            name: "Business Ethics and Sustainability",
            credits: 3,
          },
          { sno: 17, code: "BUS 312", name: "E-Business", credits: 3 },
          {
            sno: 18,
            code: "MGT 315",
            name: "Organizational Behaviour",
            credits: 3,
          },
          { sno: 19, code: "BUS 320", name: "Business Law", credits: 3 },
          {
            sno: 20,
            code: "MGT 325",
            name: "Operations and Supply Chain Management",
            credits: 3,
          },
          {
            sno: 21,
            code: "BUS 370",
            name: "International Business",
            credits: 3,
          },
          {
            sno: 22,
            code: "MGT 401",
            name: "Strategic Management (Capstone Course)",
            credits: 3,
          },
        ],
      },
      {
        id: "marketing-major-compulsory-courses-bba",
        title: "Marketing Major Compulsory Courses",
        subtitles: ["4 Courses", "12 Credits"],
        courses: [
          { sno: 1, code: "MKT 317", name: "Consumer Behaviour", credits: 3 },
          {
            sno: 2,
            code: "MKT 412",
            name: "Integrated Marketing Communications",
            credits: 3,
          },
          { sno: 3, code: "MKT 455", name: "Marketing Research", credits: 3 },
          { sno: 4, code: "MKT 465", name: "Strategic Marketing", credits: 3 },
        ],
      },
      {
        id: "marketing-major-elective-courses-bba",
        title: "Marketing Major Elective Courses",
        subtitles: ["2 Courses", "6 Credits"],
        courses: [
          {
            sno: 1,
            code: "MKT 312",
            name: "Agriculture Marketing",
            credits: 3,
          },
          {
            sno: 2,
            code: "MKT 333",
            name: "Introduction to Advertising",
            credits: 3,
          },
          { sno: 3, code: "MKT 338", name: "Digital Marketing", credits: 3 },
          {
            sno: 4,
            code: "MKT 372",
            name: "Business to Business Marketing",
            credits: 3,
          },
          {
            sno: 5,
            code: "MKT 375",
            name: "New Product Development",
            credits: 3,
          },
          {
            sno: 6,
            code: "MKT 378",
            name: "International Marketing",
            credits: 3,
          },
          { sno: 7, code: "MKT 415", name: "Services Marketing", credits: 3 },
          { sno: 8, code: "MKT 417", name: "Brand Management", credits: 3 },
          { sno: 9, code: "MKT 427", name: "Sales Management", credits: 3 },
          {
            sno: 10,
            code: "MKT 431",
            name: "Marketing and Society",
            credits: 3,
          },
          {
            sno: 11,
            code: "MKT 439",
            name: "Export Import Management",
            credits: 3,
          },
          { sno: 12, code: "MKT 467", name: "Marketing Insights", credits: 3 },
          {
            sno: 13,
            code: "BUS 477",
            name: "Business Research Methodology",
            credits: 3,
          },
          { sno: 14, code: "MKT 489", name: "Marketing Analytics", credits: 3 },
        ],
      },
    ],
  },
  2: {
    name: "CSE",
    // icon: Code,
    sections: [
      {
        id: "course-map-cse",
        title: "Course Map",
        subtitles: [
          "List of Course Category to Complete CSE",
          "Total Credits: 145",
        ],
        courses: [
          {
            sno: 1,
            area: "General Education Compulsory Courses",
            noOfCourse: "9",
            credits: 27,
          },
          {
            sno: 2,
            area: "General Education Elective Courses",
            noOfCourse: "3",
            credits: 9,
          },
          {
            sno: 3,
            area: "School Required Courses",
            noOfCourse: "9",
            credits: 27,
          },
          {
            sno: 4,
            area: "Program Requirements (Compulsory Courses)",
            noOfCourse: "17",
            credits: 63,
          },
          {
            sno: 5,
            area: "Program Requirements (Elective Courses)",
            noOfCourse: "5",
            credits: 15,
          },
          {
            sno: 6,
            area: "Internship / Project",
            noOfCourse: "1",
            credits: 4,
          },
        ],
      },
      {
        id: "general-education-compulsory-courses-cse",
        title: "General Education Compulsory Courses",
        subtitles: ["9 Courses", "27 Credits"],
        courses: [
          { code: "MAT 101", name: "Introductory Mathematics", credits: 3 },
          { code: "ENG 101", name: "Basic English", credits: 3 },
          { code: "ECO 101", name: "Introductory Economics", credits: 3 },
          { code: "BUS 110", name: "Introduction to Business", credits: 3 },
          {
            code: "HIS 101",
            name: "History of the Emergence of Bangladesh",
            credits: 3,
          },
          {
            code: "GED 260",
            name: "Ethics and Social Responsibility",
            credits: 3,
          },
          {
            code: "GED 265",
            name: "Analytical Skills and Creative Thinking",
            credits: 3,
          },
          { code: "STA 101", name: "Introduction to Statistics", credits: 3 },
          {
            code: "ENG 222",
            name: "English Writing and Reasoning",
            credits: 3,
          },
        ],
      },
      {
        id: "general-education-elective-courses-cse",
        title: "General Education Elective Courses",
        subtitles: ["3 Courses", "9 Credits"],
        courses: [
          {
            sno: 1,
            code: "GED 220",
            name: "Fundamentals of Communication",
            credits: 3,
          },
          { sno: 2, code: "GED 225", name: "Bangladesh Studies", credits: 3 },
          {
            sno: 3,
            code: "ECO 225",
            name: "Principles of Microeconomics",
            credits: 3,
          },
          {
            sno: 4,
            code: "HIS 235",
            name: "Introduction to Global History",
            credits: 3,
          },
          {
            sno: 5,
            code: "ECO 245",
            name: "Principles of Macroeconomics",
            credits: 3,
          },
          {
            sno: 6,
            code: "BNG 201",
            name: "Bengali Language and Literature",
            credits: 3,
          },
          {
            sno: 7,
            code: "POL 101",
            name: "Introduction to Political Science",
            credits: 3,
          },
          {
            sno: 8,
            code: "SOC 101",
            name: "Introduction to Sociology",
            credits: 3,
          },
          {
            sno: 9,
            code: "PSY 101",
            name: "Introduction to Psychology",
            credits: 3,
          },
          { sno: 10, code: "GED 308", name: "Public Speaking", credits: 3 },
          {
            sno: 11,
            code: "ENV 101",
            name: "Introduction to Environmental Management",
            credits: 3,
          },
          { sno: 12, code: "CHE 101", name: "General Chemistry", credits: 3 },
          {
            sno: 13,
            code: "BIO 101",
            name: "Introduction to Biology",
            credits: 3,
          },
          {
            sno: 14,
            code: "GED 361",
            name: "Teamwork and Team Building",
            credits: 3,
          },
          {
            sno: 15,
            code: "GED 410",
            name: "Language (Foreign language other than English – at least intermediate level proficiency)",
            credits: 3,
          },
        ],
      },
      {
        id: "school-required-courses",
        title: "School Required Courses",
        subtitles: ["9 Courses", "27 Credits"],
        courses: [
          {
            sno: 1,
            code: "MAT 211",
            name: "Linear Algebra",
            credits: 3,
          },
          {
            sno: 2,
            code: "MAT 251",
            name: "Methods of Integration and Differential Equations",
            credits: 3,
          },
          {
            sno: 3,
            code: "MAT 271",
            name: "Multivariable Calculus",
            credits: 3,
          },
          {
            sno: 4,
            code: "MAT 185",
            name: "Discrete Mathematics",
            credits: 3,
          },
          {
            sno: 5,
            code: "MAT 331",
            name: "Introduction to Numerical Methods",
            credits: 3,
          },
          {
            sno: 6,
            code: "PHY 101",
            name: "General Physics 1",
            credits: 3,
          },
          {
            sno: 7,
            code: "PHY 102",
            name: "General Physics 2",
            credits: 3,
          },
          {
            sno: "8.a",
            code: "EEE 101",
            name: "Introduction to Circuits and Electronics",
            credits: 3,
          },
          {
            sno: "8.b",
            code: "EEE 102",
            name: "Introduction to Circuits and Electronics Lab",
            credits: 3,
          },
          {
            sno: 10,
            code: "STA 207",
            name: "Probability and Statistics for Science and Engineering",
            credits: 3,
          },
        ],
      },
      {
        id: "cse-major-requirements-compulsory-courses",
        title: "CSE Major Requirements (Compulsory): ",
        subtitles: ["17 Courses", "63 Credits"],
        courses: [
          {
            serial: "1",
            code: "CSE 111",
            title: "Introduction to Computer Science",
            credit: 3,
          },
          {
            serial: "1.a",
            code: "CSE 112",
            title: "Introduction to Computer Science Lab",
            credit: 1,
          },
          {
            serial: "2",
            code: "EEE 215",
            title: "Electronic Devices and Circuits",
            credit: 3,
          },
          {
            serial: "2.a",
            code: "EEE 216",
            title: "Electronic Devices and Circuits Lab",
            credit: 1,
          },
          {
            serial: "3",
            code: "EEE 241",
            title: "Digital Logic Design",
            credit: 2,
          },
          {
            serial: "3.a",
            code: "EEE 242",
            title: "Digital Logic Design Lab",
            credit: 1,
          },
          {
            serial: "4",
            code: "CSE 235",
            title: "Data Structures and Algorithms",
            credit: 3,
          },
          {
            serial: "4.a",
            code: "CSE 236",
            title: "Data Structures and Algorithms Lab",
            credit: 1,
          },
          {
            serial: "5",
            code: "CSE 155",
            title: "Introduction to Programming",
            credit: 3,
          },
          {
            serial: "5.a",
            code: "CSE 156",
            title: "Introduction to Programming Lab",
            credit: 1,
          },
          {
            serial: "6",
            code: "CSE 255",
            title: "Object Oriented Programming",
            credit: 3,
          },
          {
            serial: "6.a",
            code: "CSE 256",
            title: "Object Oriented Programming Lab",
            credit: 1,
          },
          {
            serial: "7",
            code: "CSE 281",
            title: "Computer System Architecture",
            credit: 3,
          },
          {
            serial: "8",
            code: "CSE 267",
            title: "Database Management Systems",
            credit: 3,
          },
          {
            serial: "8.a",
            code: "CSE 268",
            title: "Database Management Systems Lab",
            credit: 1,
          },
          {
            serial: "9",
            code: "CSE 301",
            title: "Design and Analysis of Algorithms",
            credit: 3,
          },
          {
            serial: "9.a",
            code: "CSE 302",
            title: "Design and Analysis of Algorithms Lab",
            credit: 1,
          },
          {
            serial: "10",
            code: "CSE 321",
            title: "Data Communication",
            credit: 3,
          },
          {
            serial: "11.a",
            code: "ITM 345",
            title: "System Analysis and Design",
            credit: 3,
          },
          {
            serial: "11.b",
            code: "ITM 346",
            title: "System Analysis and Design Lab",
            credit: 1,
          },
          {
            serial: "12.a",
            code: "ITM 427",
            title: "Software Engineering",
            credit: 3,
          },
          {
            serial: "12.b",
            code: "ITM 428",
            title: "Software Engineering Lab",
            credit: 1,
          },
          {
            serial: "13",
            code: "CSE 351",
            title: "Automata, Computability, and Complexity",
            credit: 3,
          },
          {
            serial: "14.a",
            code: "CSE 381",
            title: "Operating System",
            credit: 3,
          },
          {
            serial: "14.b",
            code: "CSE 382",
            title: "Operating System Lab",
            credit: 1,
          },
          {
            serial: "15.a",
            code: "CSE 333",
            title: "Computer Networks",
            credit: 3,
          },
          {
            serial: "15.b",
            code: "CSE 334",
            title: "Computer Networks Lab",
            credit: 1,
          },
          {
            serial: "16.a",
            code: "ROB 401",
            title: "Introduction to Mechatronics",
            credit: 2,
          },
          {
            serial: "16.b",
            code: "ROB 402",
            title: "Introduction to Mechatronics Lab",
            credit: 1,
          },
          {
            serial: "17",
            code: "CSE 457",
            title: "Artificial Intelligence",
            credit: 4,
          },
        ],
      },
      {
        id: "cse-major-requirements- elective-courses",
        title: "CSE Major Requirements (Elective): ",
        subtitles: ["5 Courses", "15 Credits"],
        courses: [
          {
            serial: "1.a",
            code: "CSE 331",
            title: "Computer Graphics",
            credit: 2,
          },
          {
            serial: "1.b",
            code: "CSE 332",
            title: "Computer Graphics Lab",
            credit: 1,
          },
          {
            serial: "2",
            code: "CSE 341",
            title: "Human Computer Interaction",
            credit: 3,
          },
          {
            serial: "3.a",
            code: "CSE 365",
            title: "Mobile Application Development",
            credit: 2,
          },
          {
            serial: "3.b",
            code: "CSE 366",
            title: "Mobile Application Development Lab",
            credit: 1,
          },
          {
            serial: "4.a",
            code: "ITM 405",
            title: "Cyber Security",
            credit: 2,
          },
          {
            serial: "4.b",
            code: "ITM 406",
            title: "Cyber Security Lab",
            credit: 1,
          },
          {
            serial: "5.a",
            code: "CSE 401",
            title: "Signals and Systems",
            credit: 2,
          },
          {
            serial: "5.b",
            code: "CSE 402",
            title: "Signals and Systems Lab",
            credit: 1,
          },
          {
            serial: "6.a",
            code: "CSE 405",
            title: "Cloud Computing",
            credit: 2,
          },
          {
            serial: "6.b",
            code: "CSE 406",
            title: "Cloud Computing Lab",
            credit: 1,
          },
          {
            serial: "7.a",
            code: "CSE 361",
            title: "Microprocessors",
            credit: 2,
          },
          {
            serial: "7.b",
            code: "CSE 362",
            title: "Microprocessors Lab",
            credit: 1,
          },
          {
            serial: "8.a",
            code: "CSE 427",
            title: "Pattern Recognition",
            credit: 2,
          },
          {
            serial: "8.b",
            code: "CSE 428",
            title: "Pattern Recognition Lab",
            credit: 1,
          },
          {
            serial: "9",
            code: "CSE 431",
            title: "Computer Interfacing",
            credit: 2,
          },
          {
            serial: "10.a",
            code: "CSE 315",
            title: "Advanced Database Management",
            credit: 2,
          },
          {
            serial: "10.b",
            code: "CSE 316",
            title: "Advanced Database Management Lab",
            credit: 1,
          },
          {
            serial: "11.a",
            code: "CSE 447",
            title: "Compiler Design",
            credit: 2,
          },
          {
            serial: "11.b",
            code: "CSE 448",
            title: "Compiler Design Lab",
            credit: 1,
          },
          {
            serial: "12.a",
            code: "CSE 451",
            title: "Image Processing",
            credit: 2,
          },
          {
            serial: "12.b",
            code: "CSE 452",
            title: "Image Processing Lab",
            credit: 1,
          },
          {
            serial: "13.a",
            code: "CSE 481",
            title: "Introduction to Data Mining",
            credit: 2,
          },
          {
            serial: "13.b",
            code: "CSE 482",
            title: "Introduction to Data Mining Lab",
            credit: 1,
          },
          {
            serial: "14",
            code: "CSE 437",
            title: "Internet of Things",
            credit: 3,
          },
          {
            serial: "15",
            code: "MGT 475",
            title: "Project Management",
            credit: 3,
          },
          {
            serial: "16",
            code: "CSE 419",
            title: "Machine Learning",
            credit: 3,
          },
        ],
      },
      {
        id: "cse-internship-project-courses",
        title: "Internship & Project",
        subtitles: ["4 Credits"],
        courses: [
          {
            serial: "1",
            code: "CSE-499",
            title: "Project and Internship",
            credit: 4,
          },
          {
            serial: "2",
            code: "CSE 501",
            title: "Community Service (Compulsory)",
            credit: "0",
          },
          {
            serial: "3",
            code: "CSE 502",
            title: "Career Preparation & Enrichment Workshops (Compulsory)",
            credit: "0",
          },
        ],
      },
    ],
  },
  3: {
    name: "ITM",
    // icon: Microscope,
    sections: [
      {
        id: "course-map-itm",
        title: "Course Map",
        subtitles: [
          "List of Course Category to Complete ITM",
          "Total Credits: 147",
        ],
        courses: [
          {
            sno: 1,
            area: "General Education Compulsory Courses",
            noOfCourse: "9",
            credits: 27,
          },
          {
            sno: 2,
            area: "General Education Elective Courses",
            noOfCourse: "3",
            credits: 9,
          },
          {
            sno: 3,
            area: "School Required Courses",
            noOfCourse: "8",
            credits: 24,
          },
          {
            sno: 4,
            area: "Program Requirements (Compulsory Courses)",
            noOfCourse: "18",
            credits: 63,
          },
          {
            sno: 5,
            area: "Program Requirements (Elective Courses)",
            noOfCourse: "5",
            credits: 15,
          },
          {
            sno: 6,
            area: "Project and Dissertation",
            noOfCourse: "1",
            credits: 6,
          },
          {
            sno: 7,
            area: "Internship",
            noOfCourse: "1",
            credits: 3,
          },
          {
            sno: 8,
            area: "Community Service (Compulsory)",
            noOfCourse: "-",
            credits: "Non Credit",
          },
          {
            sno: 9,
            area: "Career Preparation & Enrichment Workshops (Compulsory)",
            noOfCourse: "-",
            credits: "Non Credit",
          },
        ],
      },
      {
        id: "general-education-compulsory-courses-itm",
        title: "General Education Compulsory Courses",
        subtitles: ["9 Courses", "27 Credits"],
        courses: [
          { code: "MAT 101", name: "Introductory Mathematics", credits: 3 },
          { code: "ENG 101", name: "Basic English", credits: 3 },
          { code: "ECO 101", name: "Introductory Economics", credits: 3 },
          { code: "BUS 110", name: "Introduction to Business", credits: 3 },
          {
            code: "HIS 101",
            name: "History of the Emergence of Bangladesh",
            credits: 3,
          },
          {
            code: "GED 260",
            name: "Ethics and Social Responsibility",
            credits: 3,
          },
          {
            code: "GED 265",
            name: "Analytical Skills and Creative Thinking",
            credits: 3,
          },
          { code: "STA 101", name: "Introduction to Statistics", credits: 3 },
          {
            code: "ENG 222",
            name: "English Writing and Reasoning",
            credits: 3,
          },
        ],
      },
      {
        id: "general-education-elective-courses-itm",
        title: "General Education Elective Courses",
        subtitles: ["3 Courses", "9 Credits"],
        courses: [
          {
            sno: 1,
            code: "GED 220",
            name: "Fundamentals of Communication",
            credits: 3,
          },
          { sno: 2, code: "GED 225", name: "Bangladesh Studies", credits: 3 },
          {
            sno: 3,
            code: "ECO 225",
            name: "Principles of Microeconomics",
            credits: 3,
          },
          {
            sno: 4,
            code: "HIS 235",
            name: "Introduction to Global History",
            credits: 3,
          },
          {
            sno: 5,
            code: "ECO 245",
            name: "Principles of Macroeconomics",
            credits: 3,
          },
          {
            sno: 6,
            code: "BNG 201",
            name: "Bengali Language and Literature",
            credits: 3,
          },
          {
            sno: 7,
            code: "POL 101",
            name: "Introduction to Political Science",
            credits: 3,
          },
          {
            sno: 8,
            code: "SOC 101",
            name: "Introduction to Sociology",
            credits: 3,
          },
          {
            sno: 9,
            code: "PSY 101",
            name: "Introduction to Psychology",
            credits: 3,
          },
          { sno: 10, code: "GED 308", name: "Public Speaking", credits: 3 },
          {
            sno: 11,
            code: "ENV 101",
            name: "Introduction to Environmental Management",
            credits: 3,
          },
          { sno: 12, code: "CHE 101", name: "General Chemistry", credits: 3 },
          {
            sno: 13,
            code: "BIO 101",
            name: "Introduction to Biology",
            credits: 3,
          },
          {
            sno: 14,
            code: "GED 361",
            name: "Teamwork and Team Building",
            credits: 3,
          },
          {
            sno: 15,
            code: "GED 410",
            name: "Language (Foreign language other than English – at least intermediate level proficiency)",
            credits: 3,
          },
        ],
      },
      {
        id: "school-required-courses-itm",
        title: "School Required Courses",
        subtitles: ["9 Courses", "27 Credits"],
        courses: [
          {
            sno: 1,
            code: "MAT 211",
            name: "Linear Algebra",
            credits: 3,
          },
          {
            sno: 2,
            code: "MAT 251",
            name: "Methods of Integration and Differential Equations",
            credits: 3,
          },
          {
            sno: 3,
            code: "MAT 271",
            name: "Multivariable Calculus",
            credits: 3,
          },
          {
            sno: 4,
            code: "MAT 185",
            name: "Discrete Mathematics",
            credits: 3,
          },
          {
            sno: 5,
            code: "MAT 331",
            name: "Introduction to Numerical Methods",
            credits: 3,
          },
          {
            sno: 6,
            code: "PHY 101",
            name: "General Physics 1",
            credits: 3,
          },
          {
            sno: 7,
            code: "PHY 102",
            name: "General Physics 2",
            credits: 3,
          },
          {
            sno: "8.a",
            code: "EEE 101",
            name: "Introduction to Circuits and Electronics",
            credits: 3,
          },
          {
            sno: "8.b",
            code: "EEE 102",
            name: "Introduction to Circuits and Electronics Lab",
            credits: 3,
          },
          {
            sno: 10,
            code: "STA 207",
            name: "Probability and Statistics for Science and Engineering",
            credits: 3,
          },
        ],
      },
      {
        id: "itm-major-requirements-compulsory-courses",
        title: "ITM Major Requirements (Compulsory Courses)",
        subtitles: ["18 Courses", "63 Credits"],
        courses: [
          {
            serial: 1,
            code: "CSE 111",
            name: "Introduction to Computer Science",
            credits: 3,
          },
          {
            serial: 1.1,
            code: "CSE 112",
            name: "Introduction to Computer Science Lab",
            credits: 1,
          },
          {
            serial: 2,
            code: "CSE 155",
            name: "Introduction to Programming",
            credits: 3,
          },
          {
            serial: 2.1,
            code: "CSE 156",
            name: "Introduction to Programming Lab",
            credits: 1,
          },
          {
            serial: 3,
            code: "CSE 235",
            name: "Data Structures and Algorithms",
            credits: 3,
          },
          {
            serial: 3.1,
            code: "CSE 236",
            name: "Data Structures and Algorithms Lab",
            credits: 1,
          },
          {
            serial: 4,
            code: "CSE 301",
            name: "Design and Analysis of Algorithms",
            credits: 3,
          },
          {
            serial: 4.1,
            code: "CSE 302",
            name: "Design and Analysis of Algorithms Lab",
            credits: 1,
          },
          {
            serial: 5,
            code: "CSE 255",
            name: "Object Oriented Programming",
            credits: 3,
          },
          {
            serial: 5.1,
            code: "CSE 256",
            name: "Object-Oriented Programming Lab",
            credits: 1,
          },
          {
            serial: 6,
            code: "ITM 261",
            name: "Fundamentals of Web Development",
            credits: 3,
          },
          {
            serial: 6.1,
            code: "ITM 262",
            name: "Fundamentals of Web Development Lab",
            credits: 1,
          },
          {
            serial: 7,
            code: "FIN 201",
            name: "Principle of Finance",
            credits: 3,
          },
          {
            serial: 8,
            code: "CSE 267",
            name: "Database Management Systems",
            credits: 3,
          },
          {
            serial: 8.1,
            code: "CSE 268",
            name: "Database Management Systems Lab",
            credits: 1,
          },
          {
            serial: 9,
            code: "EEE 241",
            name: "Digital Logic Design",
            credits: 2,
          },
          {
            serial: 9.1,
            code: "EEE 242",
            name: "Digital Logic Design Lab",
            credits: 1,
          },
          {
            serial: 10,
            code: "CSE 333",
            name: "Computer Networks",
            credits: 3,
          },
          {
            serial: 10.1,
            code: "CSE 334",
            name: "Computer Networks Lab",
            credits: 1,
          },
          {
            serial: 11,
            code: "ITM 345",
            name: "System Analysis and Design",
            credits: 3,
          },
          {
            serial: 11.1,
            code: "ITM 346",
            name: "System Analysis and Design Lab",
            credits: 1,
          },
          {
            serial: 12,
            code: "ITM 427",
            name: "Software Engineering",
            credits: 3,
          },
          {
            serial: 12.1,
            code: "ITM 428",
            name: "Software Engineering Lab",
            credits: 1,
          },
          {
            serial: 13,
            code: "ENT 222",
            name: "Introduction to Entrepreneurship",
            credits: 3,
          },
          {
            serial: 14,
            code: "MGT 331",
            name: "Introduction to Management Information System",
            credits: 3,
          },
          {
            serial: 15,
            code: "MGT 475",
            name: "Project Management",
            credits: 3,
          },
          {
            serial: 16,
            code: "CSE 381",
            name: "Operating System",
            credits: 3,
          },
          {
            serial: 16.1,
            code: "CSE 382",
            name: "Operating System Lab",
            credits: 1,
          },
          {
            serial: 17,
            code: "CSE 457",
            name: "Artificial Intelligence",
            credits: 4,
          },
        ],
      },
      {
        id: "itm-major-requirements-elective-courses",
        title: "ITM Major Requirements (Elective Courses)",
        subtitles: ["5 Courses", "15 Credits"],
        courses: [
          {
            serial: 1,
            code: "ITM 491",
            name: "IT Project Management & Delivery",
            credits: 3,
          },
          {
            serial: 2,
            code: "CSE 315",
            name: "Advanced Database Management",
            credits: 2,
          },
          {
            serial: 2.1,
            code: "CSE 316",
            name: "Advanced Database Management Lab",
            credits: 1,
          },
          {
            serial: 3,
            code: "ITM 405",
            name: "Cyber Security",
            credits: 2,
          },
          {
            serial: 3.1,
            code: "ITM 406",
            name: "Cyber Security Lab",
            credits: 1,
          },
          {
            serial: 4,
            code: "ITM 361",
            name: "Database Security",
            credits: 2,
          },
          {
            serial: 4.1,
            code: "ITM 362",
            name: "Database Security Lab",
            credits: 1,
          },
          {
            serial: 5,
            code: "ITM 411",
            name: "Operating System Security",
            credits: 2,
          },
          {
            serial: 5.1,
            code: "ITM 412",
            name: "Operating System Security Lab",
            credits: 1,
          },
          {
            serial: 6,
            code: "CSE 321",
            name: "Data Communication",
            credits: 3,
          },
          {
            serial: 7,
            code: "ITM 461",
            name: "Enterprise Server Administration",
            credits: 2,
          },
          {
            serial: 7.1,
            code: "ITM 462",
            name: "Enterprise Server Administration Lab",
            credits: 1,
          },
          {
            serial: 8,
            code: "ITM 463",
            name: "Enterprise End-User System Administration",
            credits: 2,
          },
          {
            serial: 8.1,
            code: "ITM 464",
            name: "Enterprise End-User System Administration Lab",
            credits: 1,
          },
          {
            serial: 9,
            code: "CSE 481",
            name: "Introduction to Data Mining",
            credits: 2,
          },
          {
            serial: 9.1,
            code: "CSE 482",
            name: "Introduction to Data Mining Lab",
            credits: 1,
          },
          {
            serial: 10,
            code: "ITM 267",
            name: "Human-Computer Interaction",
            credits: 3,
          },
          {
            serial: 11,
            code: "MKT 338",
            name: "Digital Marketing",
            credits: 3,
          },
          {
            serial: 12,
            code: "CSE 437",
            name: "Internet of Things",
            credits: 3,
          },
          {
            serial: 13,
            code: "CSE 365",
            name: "Mobile Application Development",
            credits: 3,
          },
          {
            serial: 14,
            code: "ACC 201",
            name: "Principles of Accounting",
            credits: 3,
          },
          {
            serial: 15,
            code: "ITM 331",
            name: "Network Management",
            credits: 2,
          },
          {
            serial: 15.1,
            code: "ITM 332",
            name: "Network Management Lab",
            credits: 1,
          },
          {
            serial: 16,
            code: "BUS 448",
            name: "Enterprise Resource Planning",
            credits: 3,
          },
          {
            serial: 17,
            code: "BUS 445",
            name: "Business Analytics",
            credits: 3,
          },
          {
            serial: 18,
            code: "MGT 427",
            name: "Enterprise Risk Management",
            credits: 3,
          },
          {
            serial: 19,
            code: "SCM 310",
            name: "Introduction to Logistics & Supply Chain Management",
            credits: 3,
          },
          {
            serial: 20,
            code: "ITM 407",
            name: "Cloud Computing & System Integration",
            credits: 2,
          },
          {
            serial: 20.1,
            code: "ITM 408",
            name: "Cloud Computing & System Integration Lab",
            credits: 1,
          },
          {
            serial: 21,
            code: "CSE 419",
            name: "Machine Learning",
            credits: 3,
          },
          {
            serial: 22,
            code: "CSE 351",
            name: "Automata, Computability, and Complexity",
            credits: 3,
          },
        ],
      },
      {
        id: "itm-internship-project-courses",
        title: "Internship & Project",
        subtitles: ["4 Courses", "9 Credits"],
        courses: [
          {
            serial: 1,
            code: "ITM 491",
            name: "Project and Dissertation",
            credits: 6,
          },
          {
            serial: 2,
            code: "ITM 499",
            name: "Internship",
            credits: 3,
          },
          {
            serial: 3,
            code: "ITM 501",
            name: "Community Service (Compulsory)",
            credits: 0,
          },
          {
            serial: 4,
            code: "ITM 502",
            name: "ITM 501 Non-Credit",
            credits: 0,
          },
        ],
      },
    ],
  },
  4: {
    name: "English",
    // icon: Palette,
    sections: [
      {
        id: "course-map-eng",
        title: "Course Map",
        subtitles: [
          "List of Course Category to Complete English",
          "Total Credits: 130",
        ],
        courses: [
          {
            sno: 1,
            area: "GED/ Interdisciplinary Courses ",
            noOfCourse: "12",
            credits: 36,
          },
          {
            sno: 2,
            area: "Core Courses ",
            noOfCourse: "22",
            credits: 9,
          },
          {
            sno: 3,
            area: "Courses for Specialization (Language/ Literature) ",
            noOfCourse: "8",
            credits: 24,
          },
          {
            sno: 4,
            area: "Capstone Course/ Internship/ Thesis/ Projects/ Portfolio ",
            noOfCourse: "1",
            credits: 4,
          },
        ],
      },
      {
        id: "general-education-compulsory-courses-eng",
        title: "General Education Compulsory Courses",
        subtitles: ["5 Courses", "15 Credits"],
        courses: [
          {
            sno: 1,
            code: "GED 101",
            name: "History of the Emergence of Bangladesh",
            credits: 3,
          },
          {
            sno: 2,
            code: "CSE 101",
            name: "Introduction to Computing",
            credits: 3,
          },
          {
            sno: 3,
            code: "GED 110",
            name: "Ethics and Social Responsibility",
            credits: 3,
          },
          {
            sno: 4,
            code: "GED 220",
            name: "Fundamentals of Communication",
            credits: 3,
          },
          {
            sno: 5,
            code: "GED 302",
            name: "Bengali Language and Literature",
            credits: 3,
          },
        ],
      },
      {
        id: "general-education-elective-courses-eng",
        title: "General Education Elective Courses",
        subtitles: ["7 Courses", "28 Credits"],
        courses: [
          {
            sno: 1,
            code: "ECO 101",
            name: "Introductory Economics",
            credits: 3,
          },
          {
            sno: 2,
            code: "BUS 110",
            name: "Introduction to Business",
            credits: 3,
          },
          {
            sno: 3,
            code: "GED 120",
            name: "Analytical Skills and Creative Thinking",
            credits: 3,
          },
          {
            sno: 4,
            code: "STA 201",
            name: "Introduction to Statistics and Data Analysis",
            credits: 3,
          },
          {
            sno: 5,
            code: "MKT 201",
            name: "Principles of Marketing",
            credits: 3,
          },
          {
            sno: 6,
            code: "IRD 201",
            name: "Foundations of International Relations",
            credits: 3,
          },
          {
            sno: 7,
            code: "GED 202",
            name: "Introduction to Gender Studies",
            credits: 3,
          },
          {
            sno: 8,
            code: "GED 206",
            name: "Introduction to Philosophy",
            credits: 3,
          },
          {
            sno: 9,
            code: "ENG 210",
            name: "History of English Literature",
            credits: 3,
          },
          {
            sno: 10,
            code: "GED 212",
            name: "Socio-Political History of England",
            credits: 3,
          },
          {
            sno: 11,
            code: "ENT 222",
            name: "Introduction to Entrepreneurship",
            credits: 3,
          },
          { sno: 12, code: "GED 225", name: "Bangladesh Studies", credits: 3 },
          {
            sno: 13,
            code: "GED 235",
            name: "Introduction to Global History",
            credits: 3,
          },
          {
            sno: 14,
            code: "GED 305",
            name: "Introduction to Politics and Government",
            credits: 3,
          },
          {
            sno: 15,
            code: "GED 306",
            name: "Introduction to Sociology",
            credits: 3,
          },
          {
            sno: 16,
            code: "GED 307",
            name: "Introduction to Psychology",
            credits: 3,
          },
          { sno: 17, code: "GED 308", name: "Public Speaking", credits: 3 },
          {
            sno: 18,
            code: "GED 310",
            name: "Introduction to Environmental Management",
            credits: 3,
          },
          {
            sno: 19,
            code: "GED 311",
            name: "Introduction to Public Administration",
            credits: 3,
          },
          {
            sno: 20,
            code: "ECO 378",
            name: "Introduction to Development Studies",
            credits: 3,
          },
          {
            sno: 21,
            code: "GED 410",
            name: "Language (Foreign language other than English)",
            credits: 3,
          },
          {
            sno: 22,
            code: "FIN 430",
            name: "Public Financial Management",
            credits: 3,
          },
        ],
      },
      {
        id: "core-courses-eng",
        title: "Core Courses",
        subtitles: ["9 Courses", "27 Credits"],
        courses: [
          {
            sno: 1,
            code: "ENG 105",
            name: "Developing English Language Skills",
            credits: 3,
          },
          {
            sno: 2,
            code: "ENG 111",
            name: "Introduction to Literary Genres",
            credits: 3,
          },
          { sno: 3, code: "ENG 116", name: "Academic Writing – I", credits: 3 },
          {
            sno: 4,
            code: "ENG 121",
            name: "Academic Writing – II",
            credits: 3,
          },
          {
            sno: 5,
            code: "ENG 125",
            name: "Introduction to Poetry and Drama",
            credits: 3,
          },
          {
            sno: 6,
            code: "ENG 131",
            name: "Introduction to Prose",
            credits: 3,
          },
          {
            sno: 7,
            code: "ENG 205",
            name: "Introduction to Linguistics",
            credits: 3,
          },
          {
            sno: 8,
            code: "ENG 211",
            name: "Elizabethan and Jacobean Drama",
            credits: 3,
          },
          {
            sno: 9,
            code: "ENG 216",
            name: "Restoration and 18th Century Literature",
            credits: 3,
          },
          {
            sno: 10,
            code: "ENG 221",
            name: "Introduction to Romantic Poetry",
            credits: 3,
          },
          {
            sno: 11,
            code: "ENG 225",
            name: "Introduction to Victorian Poetry",
            credits: 3,
          },
          {
            sno: 12,
            code: "ENG 231",
            name: "English for Professional Purposes",
            credits: 3,
          },
          {
            sno: 13,
            code: "ENG 301",
            name: "English for the Media",
            credits: 3,
          },
          {
            sno: 14,
            code: "ENG 305",
            name: "Introduction to Victorian Novel",
            credits: 3,
          },
          {
            sno: 15,
            code: "ENG 311",
            name: "17th Century Poetry and Prose",
            credits: 3,
          },
          {
            sno: 16,
            code: "ENG 316",
            name: "Critical Theory and Literary Criticism",
            credits: 3,
          },
          {
            sno: 17,
            code: "ENG 325",
            name: "20th Century Poetry and Drama",
            credits: 3,
          },
          { sno: 18, code: "ENG 331", name: "American Literature", credits: 3 },
          {
            sno: 19,
            code: "ENG 411",
            name: "Classics in Translation",
            credits: 3,
          },
          {
            sno: 20,
            code: "ENG 416",
            name: "20th Century Fiction",
            credits: 3,
          },
          {
            sno: 21,
            code: "ENG 431",
            name: "Old and Middle English",
            credits: 3,
          },
          {
            sno: 22,
            code: "ENG 451",
            name: "Research Methodology in English Literature and ELT",
            credits: 3,
          },
        ],
      },
      {
        id: "elective-courses-for-specialization-in-literature",
        title: "Elective Courses for Specialization in Literature",
        subtitles: ["6 Courses", "24 Credits"],
        courses: [
          { sno: 1, code: "ENG 320", name: "Modern Poetry", credits: 4 },
          {
            sno: 2,
            code: "ENG 410",
            name: "Literature and the Environment",
            credits: 4,
          },
          {
            sno: 3,
            code: "ENG 422",
            name: "South Asian Literature in English",
            credits: 4,
          },
          {
            sno: 4,
            code: "ENG 423",
            name: "Contemporary Literature",
            credits: 4,
          },
          {
            sno: 5,
            code: "ENG 424",
            name: "Postcolonial Literature",
            credits: 4,
          },
          {
            sno: 6,
            code: "ENG 426",
            name: "Continental Literature",
            credits: 4,
          },
          { sno: 7, code: "ENG 428", name: "Women and Literature", credits: 4 },
          { sno: 8, code: "ENG 435", name: "Shakespeare", credits: 4 },
          { sno: 9, code: "ENG 442", name: "Literature and Film", credits: 4 },
          {
            sno: 10,
            code: "ENG 448",
            name: "Migration Literature",
            credits: 4,
          },
        ],
      },
      {
        id: "elective-courses-for-specialization-in-language",
        title: "Elective Courses for Specialization in Language",
        subtitles: ["6 Courses", "24 Credits"],
        courses: [
          {
            sno: 1,
            code: "ENG 321",
            name: "Introduction to Phonetics and Phonology",
            credits: 4,
          },
          {
            sno: 2,
            code: "ENG 335",
            name: "English Language Teaching",
            credits: 4,
          },
          {
            sno: 3,
            code: "ENG 405",
            name: "Sociolinguistics and Psycholinguistics",
            credits: 4,
          },
          {
            sno: 4,
            code: "ENG 421",
            name: "Methodology in Language Teaching",
            credits: 4,
          },
          {
            sno: 5,
            code: "ENG 425",
            name: "Second Language Acquisition",
            credits: 4,
          },
          {
            sno: 6,
            code: "ENG 440",
            name: "Syllabus and Materials Design",
            credits: 4,
          },
          { sno: 7, code: "ENG 441", name: "Discourse Analysis", credits: 4 },
          { sno: 8, code: "ENG 445", name: "Teaching Practicum", credits: 4 },
          {
            sno: 9,
            code: "ENG 446",
            name: "Language Testing, Evaluation and Assessment",
            credits: 4,
          },
          {
            sno: 10,
            code: "ENG 447",
            name: "English in Diverse World Contexts",
            credits: 4,
          },
        ],
      },
      {
        id: "Capstone-Course-Thesis-Internship-Projects",
        title: "Capstone Course / Thesis / Internship / Projects",
        subtitles: ["1 Courses", "4 Credits"],
        courses: [
          {
            sno: 1,
            code: "ENG 455",
            name: "Dissertation",
            credits: 4,
          },
        ],
      },
    ],
  },
  5: {
    name: "Economics",
    // icon: Palette,
    sections: [
      {
        id: "course-map-econ",
        title: "Course Map",
        subtitles: [
          "List of Course Category to Complete Economics",
          "Total Credits: 130",
        ],
        courses: [
          {
            sno: 1,
            area: "General Education (Compulsory) Courses ",
            noOfCourse: "10",
            credits: 30,
          },
          {
            sno: 2,
            area: "General Education (Elective) Courses ",
            noOfCourse: "6",
            credits: 18,
          },
          {
            sno: 3,
            area: "Core (Compulsory) Courses ",
            noOfCourse: "10",
            credits: 30,
          },
          {
            sno: 4,
            area: "Elective   Courses ",
            noOfCourse: "16",
            credits: 48,
          },
          {
            sno: 5,
            area: "Internship (Compulsory) ",
            noOfCourse: "1",
            credits: 4,
          },
          {
            sno: 6,
            area: "Community Service (Compulsory) ",
            noOfCourse: "-",
            credits: "Non Credit",
          },
          {
            sno: 6,
            area: "Career Preparation & Enrichment Workshops (Compulsory)",
            noOfCourse: "-",
            credits: "Non Credit",
          },
        ],
      },
      {
        id: "general-education-compulsory-courses-econ",
        title: "General Education Compulsory Courses",
        subtitles: ["10 Courses", "30 Credits"],
        courses: [
          {
            sno: 1,
            code: "HIS 101",
            name: "History of the Emergence of Bangladesh",
            credits: 3,
          },
          { sno: 2, code: "ENG 101", name: "Basic English", credits: 3 },
          {
            sno: 3,
            code: "ECO 101",
            name: "Introductory Economics",
            credits: 3,
          },
          {
            sno: 4,
            code: "CSE 101",
            name: "Introduction to Computing",
            credits: 3,
          },
          {
            sno: 5,
            code: "MAT 101",
            name: "Introductory Mathematics",
            credits: 3,
          },
          {
            sno: 6,
            code: "STA 101",
            name: "Introduction to Statistics",
            credits: 3,
          },
          {
            sno: 7,
            code: "GED 220",
            name: "Fundamentals of Communication",
            credits: 3,
          },
          {
            sno: 8,
            code: "ENG 222",
            name: "English Writing and Reasoning",
            credits: 3,
          },
          {
            sno: 9,
            code: "GED 260",
            name: "Ethics and Social Responsibility",
            credits: 3,
          },
          {
            sno: 10,
            code: "GED 265",
            name: "Analytical Skills and Creative Thinking",
            credits: 3,
          },
        ],
      },
      {
        id: "general-education-elective-courses-econ",
        title: "General Education Elective Courses",
        subtitles: ["6 Courses", "18 Credits"],
        courses: [
          {
            sno: 1,
            code: "PHI 101",
            name: "Introduction to Philosophy",
            credits: 3,
          },
          {
            sno: 2,
            code: "ENV 101",
            name: "Introduction to Environmental Management",
            credits: 3,
          },
          {
            sno: 3,
            code: "SOC 101",
            name: "Introduction to Sociology",
            credits: 3,
          },
          {
            sno: 4,
            code: "PSY 101",
            name: "Introduction to Psychology",
            credits: 3,
          },
          {
            sno: 5,
            code: "POL 101",
            name: "Introduction to Political Science",
            credits: 3,
          },
          {
            sno: 6,
            code: "BNG 201",
            name: "Bengali Language and Literature",
            credits: 3,
          },
          {
            sno: 7,
            code: "BUS 210",
            name: "Mathematics for Business and Economics",
            credits: 3,
          },
          {
            sno: 8,
            code: "MAT 211",
            name: "Introduction to Linear Algebra",
            credits: 3,
          },
          { sno: 9, code: "GED 225", name: "Bangladesh Studies", credits: 3 },
          {
            sno: 10,
            code: "BUS 301",
            name: "Business, Society, and Government",
            credits: 3,
          },
          {
            sno: 11,
            code: "BUS 305",
            name: "Introduction to Information Systems and Technology",
            credits: 3,
          },
          { sno: 12, code: "STA 305", name: "Applied Statistics", credits: 3 },
          { sno: 13, code: "GED 308", name: "Public Speaking", credits: 3 },
          { sno: 14, code: "BUS 312", name: "E-Business", credits: 3 },
          {
            sno: 15,
            code: "GED 361",
            name: "Team Work and Team Building",
            credits: 3,
          },
        ],
      },
      {
        id: "core-courses-compulsory-econ",
        title: "Core Courses (Compulsory)",
        subtitles: ["10 Courses", "30 Credits"],
        courses: [
          {
            sno: 1,
            code: "ECO 225",
            name: "Principles of Microeconomics",
            credits: 3,
          },
          {
            sno: 2,
            code: "ECO 245",
            name: "Principles of Macroeconomics",
            credits: 3,
          },
          {
            sno: 3,
            code: "ECO 325",
            name: "Intermediate Microeconomic Theory",
            credits: 3,
          },
          {
            sno: 4,
            code: "ECO 335",
            name: "Intermediate Macroeconomic Theory",
            credits: 3,
          },
          {
            sno: 5,
            code: "ECO 355",
            name: "Introduction to Mathematical Economics",
            credits: 3,
          },
          { sno: 6, code: "ECO 375", name: "Public Finance", credits: 3 },
          { sno: 7, code: "ECO 377", name: "Money and Banking", credits: 3 },
          {
            sno: 8,
            code: "ECO 412",
            name: "International Economics",
            credits: 3,
          },
          {
            sno: 9,
            code: "ECO 413",
            name: "Time Series Econometrics",
            credits: 3,
          },
          {
            sno: 10,
            code: "ECO 435",
            name: "History of Economic Thought",
            credits: 3,
          },
        ],
      },
      {
        id: " elective-courses-econ",
        title: "Elective Courses",
        subtitles: ["16 Courses", "48 Credits"],
        courses: [
          { sno: 1, code: "ECO 330", name: "Welfare Economics", credits: 3 },
          {
            sno: 2,
            code: "ECO 331",
            name: "Contemporary Issues in International Economics",
            credits: 3,
          },
          { sno: 3, code: "ECO 337", name: "Labor Economics", credits: 3 },
          { sno: 4, code: "ECO 338", name: "Blue Economics", credits: 3 },
          {
            sno: 5,
            code: "ECO 341",
            name: "Agricultural Economics",
            credits: 3,
          },
          {
            sno: 6,
            code: "ECO 378",
            name: "Introduction to Development Studies",
            credits: 3,
          },
          { sno: 7, code: "ECO 401", name: "Behavioral Economics", credits: 3 },
          { sno: 8, code: "ECO 402", name: "Law and Economics", credits: 3 },
          { sno: 9, code: "ECO 425", name: "International Trade", credits: 3 },
          {
            sno: 10,
            code: "ECO 437",
            name: "Economics of Developing Countries",
            credits: 3,
          },
          {
            sno: 11,
            code: "ECO 445",
            name: "Environmental Economics",
            credits: 3,
          },
          {
            sno: 12,
            code: "ECO 449",
            name: "Asian and Emerging Economies",
            credits: 3,
          },
          {
            sno: 13,
            code: "ECO 453",
            name: "Game Theory and Applications",
            credits: 3,
          },
          {
            sno: 14,
            code: "ECO 461",
            name: "Research Methodology",
            credits: 3,
          },
          {
            sno: 15,
            code: "ECO 465",
            name: "Political Economy Analysis",
            credits: 3,
          },
          { sno: 16, code: "ECO 469", name: "Monetary Economics", credits: 3 },
          {
            sno: 17,
            code: "ECO 474",
            name: "Business Cycles and Economic Growth",
            credits: 3,
          },
          {
            sno: 18,
            code: "ECO 476",
            name: "Market Structure and Performance",
            credits: 3,
          },
          {
            sno: 19,
            code: "ECO 477",
            name: "Current Issues in Macroeconomic Policy",
            credits: 3,
          },
          {
            sno: 20,
            code: "ECO 478",
            name: "Energy Economics and Policy",
            credits: 3,
          },
          {
            sno: 21,
            code: "ECO 479",
            name: "Gender and Development",
            credits: 3,
          },
          {
            sno: 22,
            code: "ECO 484",
            name: "Project Analysis and Evaluation",
            credits: 3,
          },
          { sno: 23, code: "ECO 486", name: "Basic Econometrics", credits: 3 },
          { sno: 24, code: "ECO 491", name: "Applied Economics", credits: 3 },
          {
            sno: 25,
            code: "FIN 201",
            name: "Principles of Finance",
            credits: 3,
          },
          { sno: 26, code: "FIN 303", name: "Financial Systems", credits: 3 },
          {
            sno: 27,
            code: "FIN 304",
            name: "Financial Markets and Institutions",
            credits: 3,
          },
          { sno: 28, code: "FIN 308", name: "Behavioral Finance", credits: 3 },
          {
            sno: 29,
            code: "FIN 405",
            name: "International Financial Management",
            credits: 3,
          },
          { sno: 30, code: "FIN 412", name: "Risk and Insurance", credits: 3 },
          {
            sno: 31,
            code: "FIN 415",
            name: "Foundations in Financial Economics",
            credits: 3,
          },
          {
            sno: 32,
            code: "FIN 430",
            name: "Public Financial Management",
            credits: 3,
          },
          {
            sno: 33,
            code: "BUS 110",
            name: "Introduction to Business",
            credits: 3,
          },
          { sno: 34, code: "BUS 320", name: "Business Law", credits: 3 },
          {
            sno: 35,
            code: "MGT 315",
            name: "Organizational Behaviour",
            credits: 3,
          },
          {
            sno: 36,
            code: "MGT 417",
            name: "Managing Change and Organization Development",
            credits: 3,
          },
          {
            sno: 37,
            code: "MGT 427",
            name: "Enterprise Risk Management",
            credits: 3,
          },
          {
            sno: 38,
            code: "MGT 430",
            name: "Management of Financial Institutions",
            credits: 3,
          },
          {
            sno: 39,
            code: "MGT 342",
            name: "Small Business Management",
            credits: 3,
          },
        ],
      },

      {
        id: "internship-econ",
        title: "Internship ",
        subtitles: ["1 Courses", "4 Credits"],
        courses: [
          {
            sno: 1,
            code: "ECO 455",
            name: "Dissertation",
            credits: 4,
          },
        ],
      },
    ],
  },
  6: {
    name: "LLB",
    // icon: Palette,
    sections: [
      {
        id: "course-map-llb",
        title: "Course Map",
        subtitles: [
          "List of Course Category to Complete Economics",
          "Total Credits: 132",
        ],
        courses: [
          {
            sno: 1,
            area: "General Education Compulsory Courses",
            noOfCourse: "8",
            credits: 24,
          },
          {
            sno: 2,
            area: "General Education Elective Courses",
            noOfCourse: "11",
            credits: 33,
          },
          {
            sno: 3,
            area: "Major / Compulsory Courses",
            noOfCourse: "25",
            credits: 75,
          },
          {
            sno: 4,
            area: "Internship (Compulsory) ",
            noOfCourse: "1",
            credits: 4,
          },
          {
            sno: 5,
            area: "Community Service (Compulsory) ",
            noOfCourse: "-",
            credits: "Non Credit",
          },
          {
            sno: 6,
            area: "Career Preparation & Enrichment Workshops (Compulsory)",
            noOfCourse: "-",
            credits: "Non Credit",
          },
        ],
      },
      {
        id: "general-education-compulsory-courses-llb",
        title: "General Education Compulsory Courses",
        subtitles: ["8 Courses", "24 Credits"],
        courses: [
          {
            sno: 1,
            code: "ENG 101",
            name: "Basic English",
            credits: 3,
          },
          {
            sno: 2,
            code: "HIS 101",
            name: "History of the Emergence of Bangladesh",
            credits: 3,
          },
          {
            sno: 3,
            code: "CSE 101",
            name: "Introduction to Computing",
            credits: 3,
          },
          {
            sno: 4,
            code: "ENG 105",
            name: "Developing English Language Skills",
            credits: 3,
          },
          {
            sno: 5,
            code: "LAW 101",
            name: "The Jurisprudence of Legal Concepts and the legal system of Bangladesh",
            credits: 3,
          },
          {
            sno: 6,
            code: "LAW 102",
            name: "Law of Contract",
            credits: 3,
          },
          {
            sno: 7,
            code: "LAW 103",
            name: "Law of Torts",
            credits: 3,
          },
          {
            sno: 8,
            code: "LAW 201",
            name: "Equity, Trusts and Specific Relief",
            credits: 3,
          },
        ],
      },
      {
        id: "general-education-elective-courses-llb",
        title: "General Education Elective Courses",
        subtitles: ["11 Courses", "33 Credits"],
        courses: [
          {
            sno: 1,
            code: "MAT-101",
            name: "Introductory Mathematics",
            credits: 3,
          },
          {
            sno: 2,
            code: "ECO 101",
            name: "Introductory Economics",
            credits: 3,
          },
          {
            sno: 3,
            code: "PHY 101",
            name: "General Physics",
            credits: 3,
          },
          {
            sno: 4,
            code: "GED 260",
            name: "Ethics and Social Responsibility",
            credits: 3,
          },
          {
            sno: 5,
            code: "GED 265",
            name: "Analytical Skills and Creative Thinking",
            credits: 3,
          },
          {
            sno: 6,
            code: "STA 101",
            name: "Introduction to Statistics",
            credits: 3,
          },
          {
            sno: 7,
            code: "GED 220",
            name: "Fundamentals of Communication",
            credits: 3,
          },
          {
            sno: 8,
            code: "ENG 222",
            name: "English Writing and Reasoning",
            credits: 3,
          },
          {
            sno: 9,
            code: "GED 225",
            name: "Bangladesh Studies",
            credits: 3,
          },
          {
            sno: 10,
            code: "HIS 235",
            name: "Introduction to Global History",
            credits: 3,
          },
          {
            sno: 11,
            code: "BNG 201",
            name: "Bengali Language and Literature",
            credits: 3,
          },
          {
            sno: 12,
            code: "BUS 301",
            name: "Business, Society and Government",
            credits: 3,
          },
          {
            sno: 13,
            code: "MIS 308",
            name: "Management Information Systems",
            credits: 3,
          },
          {
            sno: 14,
            code: "POL 101",
            name: "Introduction to Political Science",
            credits: 3,
          },
          {
            sno: 15,
            code: "SOC 101",
            name: "Introduction to Sociology",
            credits: 3,
          },
          {
            sno: 16,
            code: "PSY 101",
            name: "Introduction to Psychology",
            credits: 3,
          },
          {
            sno: 17,
            code: "GED 308",
            name: "Public Speaking",
            credits: 3,
          },
          {
            sno: 18,
            code: "IRD-201",
            name: "Foundations of International Relations",
            credits: 3,
          },
          {
            sno: 19,
            code: "ENV 101",
            name: "Introduction to Environmental Management",
            credits: 3,
          },
          {
            sno: 20,
            code: "LAW 332",
            name: "Laws on Foreign Exchange Investment and Anti-money Laundering",
            credits: 3,
          },
          {
            sno: 21,
            code: "LAW 343",
            name: "Criminology and Penology",
            credits: 3,
          },
          {
            sno: 22,
            code: "LAW 346",
            name: "Environmental Law",
            credits: 3,
          },
          {
            sno: 23,
            code: "LAW 447",
            name: "Human Rights",
            credits: 3,
          },
          {
            sno: 24,
            code: "LAW 448",
            name: "Administrative Law",
            credits: 3,
          },
          {
            sno: 25,
            code: "LAW 351",
            name: "Hindu Law and Succession",
            credits: 3,
          },
          {
            sno: 26,
            code: "FIN 430",
            name: "Public Financial Management",
            credits: 3,
          },
        ],
      },
      {
        id: "core-courses-llb",
        title: "Major/ Compulsory Courses",
        subtitles: ["25 Courses", "75 Credits"],
        courses: [
          {
            sno: 1,
            code: "LAW 202",
            name: "Muslim Law",
            credits: 3,
          },
          {
            sno: 2,
            code: "LAW 203",
            name: "Property Law and Transfers",
            credits: 3,
          },
          {
            sno: 3,
            code: "LAW 204",
            name: "Law of Registration and Limitation",
            credits: 3,
          },
          {
            sno: 4,
            code: "LAW 307",
            name: "Law of Crimes",
            credits: 3,
          },
          {
            sno: 5,
            code: "LAW 309",
            name: "Corporate Law",
            credits: 3,
          },
          {
            sno: 6,
            code: "LAW 315",
            name: "Principles of Civil Procedure",
            credits: 3,
          },
          {
            sno: 7,
            code: "LAW 312",
            name: "Public International Law",
            credits: 3,
          },
          {
            sno: 8,
            code: "LAW 321",
            name: "Law of Evidence",
            credits: 3,
          },
          {
            sno: 9,
            code: "LAW 325",
            name: "Intellectual Property Law",
            credits: 3,
          },
          {
            sno: 10,
            code: "LAW 342",
            name: "Legal System and Institutions",
            credits: 3,
          },
          {
            sno: 11,
            code: "LAW 352",
            name: "Succession, Social Policy and Law Reforms",
            credits: 3,
          },
          {
            sno: 12,
            code: "LAW 353",
            name: "Law of Town Planning and Environment",
            credits: 3,
          },
          {
            sno: 13,
            code: "LAW 327",
            name: "Laws on Banking, Insurance and Securities Industry",
            credits: 3,
          },
          {
            sno: 14,
            code: "LAW 338",
            name: "Law of Criminal Procedure",
            credits: 3,
          },
          {
            sno: 15,
            code: "LAW 341",
            name: "Constitutional Law",
            credits: 3,
          },
          {
            sno: 16,
            code: "LAW 401",
            name: "Legal Writing",
            credits: 3,
          },
          {
            sno: 17,
            code: "LAW 408",
            name: "Alternative Dispute Resolution and Arbitration",
            credits: 3,
          },
          {
            sno: 18,
            code: "LAW 411",
            name: "Labor and Industrial Law",
            credits: 3,
          },
          {
            sno: 19,
            code: "LAW 421",
            name: "Legal Ethics and Professionalism",
            credits: 3,
          },
          {
            sno: 20,
            code: "LAW 425",
            name: "Legal Research and Methodology [Dissertation Paper]",
            credits: 3,
          },
          {
            sno: 21,
            code: "LAW 433",
            name: "Moot-Court Sessions",
            credits: 3,
          },
          {
            sno: 22,
            code: "LAW 429",
            name: "Maritime Law and Carriers",
            credits: null, // Assuming no credits provided
          },
          {
            sno: 23,
            code: "LAW 431",
            name: "Inland Shipping Law",
            credits: 3,
          },
          {
            sno: 24,
            code: "LAW 481",
            name: "Cyber Law",
            credits: 3,
          },
          {
            sno: 25,
            code: "LAW 453",
            name: "Construction Laws",
            credits: 3,
          },
        ],
      },

      {
        id: "internship-econ",
        title: "Internship ",
        subtitles: ["1 Courses", "4 Credits"],
        courses: [
          {
            sno: 1,
            code: "ECO 455",
            name: "Dissertation",
            credits: 4,
          },
        ],
      },
    ],
  },
  7: {
    name: "MBA",
    // icon: Palette,
    sections: [
      {
        id: "course-map-mba",
        title: "Course Map",
        subtitles: [
          "List of Course Category to Complete Economics",
          "Total Credits: 130",
        ],
        courses: [
          {
            sno: 1,
            area: "Non-credit Prerequisite Courses",
            noOfCourse: 3,
            credits: "0",
          },
          {
            sno: 2,
            area: "Foundation Courses",
            noOfCourse: 5,
            credits: 15,
          },
          {
            sno: 3,
            area: "Core Courses (Compulsory)",
            noOfCourse: 8,
            credits: 24,
          },
          {
            sno: 4,
            area: "Core Courses (Elective)",
            noOfCourse: 2,
            credits: 6,
          },
          {
            sno: 5,
            area: "Capstone Course",
            noOfCourse: 1,
            credits: 3,
          },
          {
            sno: 6,
            area: "Concentration Area Courses (Elective)",
            noOfCourse: 4,
            credits: 12,
          },
          {
            sno: 7,
            area: "Internship",
            noOfCourse: 1,
            credits: 3,
          },
        ],
      },
      {
        id: "non-credit-prerequisite-courses-mba",
        title: "Non-credit Prerequisite Courses",
        subtitles: ["3 Courses", "0 Credits"],
        courses: [
          {
            sno: 1,
            code: "BUS 491",
            name: "Introduction to Business",
            credits: "0",
          },
          {
            sno: 2,
            code: "BUS 492",
            name: "Managerial Communication",
            credits: "0",
          },
          {
            sno: 3,
            code: "BUS 493",
            name: "Computer as an Analytical Tool",
            credits: "0",
          },
        ],
      },
      {
        id: "foundation-courses-mba",
        title: "Foundation Courses",
        subtitles: ["5 Courses", "15 Credits"],
        courses: [
          { sno: 1, code: "ECO 511", name: "Managerial Economics", credits: 3 },
          {
            sno: 2,
            code: "ACC 511",
            name: "Financial Accounting and Reporting",
            credits: 3,
          },
          {
            sno: 3,
            code: "BUS 511",
            name: "Mathematics for Managerial Decision Making",
            credits: 3,
          },
          {
            sno: 4,
            code: "BUS 521",
            name: "Legal Environment of Business",
            credits: 3,
          },
          {
            sno: 5,
            code: "MGT 521",
            name: "Management and Organizational Behavior",
            credits: 3,
          },
        ],
      },
      {
        id: "core-courses-compulsory-mba",
        title: "Core Courses (Compulsory)",
        subtitles: ["8 Courses", "24 Credits"],
        courses: [
          { sno: 1, code: "FIN 532", name: "Managerial Finance", credits: 3 },
          {
            sno: 2,
            code: "ACC 522",
            name: "Managerial Accounting and Control",
            credits: 3,
          },
          { sno: 3, code: "MKT 532", name: "Marketing Management", credits: 3 },
          {
            sno: 4,
            code: "BUS 532",
            name: "Statistical Analysis for Decision Making",
            credits: 3,
          },
          {
            sno: 5,
            code: "MGT 522",
            name: "International Business Theory and Practice",
            credits: 3,
          },
          {
            sno: 6,
            code: "MGT 533",
            name: "Management Information Systems",
            credits: 3,
          },
          { sno: 7, code: "MGT 543", name: "Corporate Governance", credits: 3 },
          {
            sno: 8,
            code: "MGT 553",
            name: "Operations Management",
            credits: 3,
          },
        ],
      },
      {
        id: " elective-courses-mba",
        title: "Elective Courses",
        subtitles: ["2 Courses", "6 Credits"],
        courses: [
          {
            sno: 1,
            code: "ECO 543",
            name: "Macroeconomics and Business Forecasting",
            credits: 3,
          },
          {
            sno: 2,
            code: "MGT 523",
            name: "Entrepreneurship and SME Development",
            credits: 3,
          },
          { sno: 3, code: "BUS 544", name: "Research Methodology", credits: 3 },
          { sno: 4, code: "MGT 564", name: "Change Management", credits: 3 },
          {
            sno: 5,
            code: "FIN 524",
            name: "Law and Practice of Banking",
            credits: 3,
          },
        ],
      },

      {
        id: "capstone-course-mba",
        title: "Capstone Course ",
        subtitles: ["1 Courses", "3 Credits"],
        courses: [
          {
            sno: 1,
            code: "MGT 690 ",
            name: "Strategic Management",
            credits: 3,
          },
        ],
      },
      {
        id: "capstone-course-mba-fin",
        title: "Concentration courses - Finance",
        subtitles: ["4 Courses", "12 Credits"],
        courses: [
          {
            sno: 1,
            code: "FIN 630",
            name: "Financial Econometrics",
            credits: 3,
          },
          { sno: 2, code: "FIN 635", name: "Corporate Finance", credits: 3 },
          {
            sno: 3,
            code: "FIN 640",
            name: "Capital Budgeting and Financing Decisions",
            credits: 3,
          },
          {
            sno: 4,
            code: "FIN 645",
            name: "Financial Institutions and Marketing of Financial Services",
            credits: 3,
          },
          {
            sno: 5,
            code: "FIN 650",
            name: "Investment and Portfolio Management",
            credits: 3,
          },
          {
            sno: 6,
            code: "FIN 655",
            name: "International Financial Management",
            credits: 3,
          },
          {
            sno: 7,
            code: "FIN 660",
            name: "Financial Statement Analysis",
            credits: 3,
          },
          {
            sno: 8,
            code: "FIN 665",
            name: "Financial Derivatives",
            credits: 3,
          },
          {
            sno: 9,
            code: "FIN 670",
            name: "Treasury and Risk Management",
            credits: 3,
          },
        ],
      },
      {
        id: "capstone-course-mba-hrm",
        title: "Concentration courses - Human Resource Management",
        subtitles: ["4 Courses", "12 Credits"],
        courses: [
          {
            sno: 1,
            code: "HRM 630",
            name: "Manpower Planning and Personnel Policy",
            credits: 3,
          },
          {
            sno: 2,
            code: "HRM 635",
            name: "Training and Development",
            credits: 3,
          },
          {
            sno: 3,
            code: "HRM 640",
            name: "Leadership and Teamwork",
            credits: 3,
          },
          {
            sno: 4,
            code: "HRM 645",
            name: "Negotiation and Conflict Resolution",
            credits: 3,
          },
          { sno: 5, code: "HRM 650", name: "Industrial Relation", credits: 3 },
          {
            sno: 6,
            code: "HRM 655",
            name: "Strategic Human Resource Management",
            credits: 3,
          },
          {
            sno: 7,
            code: "HRM 660",
            name: "Labor Market and Public Policy",
            credits: 3,
          },
          {
            sno: 8,
            code: "HRM 665",
            name: "Human Resource Systems and Technology",
            credits: 3,
          },
          {
            sno: 9,
            code: "HRM 670",
            name: "Managing People and Organizations",
            credits: 3,
          },
        ],
      },
      {
        id: "capstone-course-mba-mkt",
        title: "Concentration courses - Marketing",
        subtitles: ["4 Courses", "12 Credits"],
        courses: [
          { sno: 1, code: "MKT 630", name: "Brand Management", credits: 3 },
          { sno: 2, code: "MKT 635", name: "Services Marketing", credits: 3 },
          { sno: 3, code: "MKT 640", name: "Marketing Research", credits: 3 },
          {
            sno: 4,
            code: "MKT 645",
            name: "International Marketing",
            credits: 3,
          },
          { sno: 5, code: "MKT 650", name: "Consumer Behavior", credits: 3 },
          {
            sno: 6,
            code: "MKT 655",
            name: "Marketing Strategy and Implementation",
            credits: 3,
          },
          {
            sno: 7,
            code: "MKT 660",
            name: "Sales Force Management",
            credits: 3,
          },
          {
            sno: 8,
            code: "MKT 665",
            name: "Design and Marketing New Products",
            credits: 3,
          },
          { sno: 9, code: "MKT 670", name: "Digital Marketing", credits: 3 },
        ],
      },
      {
        id: "capstone-course-mba-scm",
        title: "Concentration courses - Supply Chain Management",
        subtitles: ["4 Courses", "12 Credits"],
        courses: [
          {
            sno: 1,
            code: "SCM 630",
            name: "Supply Chain Analysis",
            credits: 3,
          },
          { sno: 2, code: "SCM 635", name: "Managing Innovation", credits: 3 },
          {
            sno: 3,
            code: "SCM 640",
            name: "Supply Chain Management",
            credits: 3,
          },
          {
            sno: 4,
            code: "SCM 645",
            name: "Services and Operations Management",
            credits: 3,
          },
          {
            sno: 5,
            code: "SCM 650",
            name: "Sustainable Operations and Supply Chain Management",
            credits: 3,
          },
          {
            sno: 6,
            code: "SCM 655",
            name: "Advanced Supply Chain Management",
            credits: 3,
          },
          {
            sno: 7,
            code: "SCM 660",
            name: "Procurement and Logistics",
            credits: 3,
          },
          {
            sno: 8,
            code: "SCM 665",
            name: "Emerging Topics in Supply Chain Management",
            credits: 3,
          },
          {
            sno: 9,
            code: "SCM 670",
            name: "Decision Analysis and Project Management",
            credits: 3,
          },
        ],
      },
      {
        id: "capstone-course-mba-mgt",
        title: "Concentration courses - Management",
        subtitles: ["4 Courses", "12 Credits"],
        courses: [
          {
            sno: 1,
            code: "MGT 630",
            name: "Business Ethics and Leadership",
            credits: 3,
          },
          {
            sno: 2,
            code: "MGT 635",
            name: "Managerial Problem Solving and Decision Making",
            credits: 3,
          },
          { sno: 3, code: "MGT 640", name: "Project Management", credits: 3 },
          {
            sno: 4,
            code: "MGT 645",
            name: "Services and Operations Management",
            credits: 3,
          },
          {
            sno: 5,
            code: "MGT 650",
            name: "Total Quality Management",
            credits: 3,
          },
          {
            sno: 6,
            code: "MGT 655",
            name: "Managing People and Organizations",
            credits: 3,
          },
          {
            sno: 7,
            code: "SCM 640",
            name: "Supply Chain Management",
            credits: 3,
          },
          { sno: 8, code: "MGT 665", name: "Knowledge Management", credits: 3 },
          {
            sno: 9,
            code: "HRM 645",
            name: "Negotiation and Conflict Resolution",
            credits: 3,
          },
        ],
      },
    ],
  },
};
