import { motion } from 'framer-motion';
import { useState } from 'react';
import { Calendar, Tag, Users, ArrowRight } from 'lucide-react';

export function NewsEventCard({ item }) {
  const [expanded, setExpanded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="group relative h-full bg-white/80 backdrop-blur-lg rounded-2xl shadow-lg overflow-hidden border border-white/20 hover:shadow-xl transition-all duration-500"
    >
      <div className="relative aspect-[4/3]">
        {!imageLoaded && (
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: [0.5, 1, 0.5] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
            className="absolute inset-0 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
          />
        )}
        <img
          src={item.image}
          alt={item.title}
          className={`w-full h-full object-cover transition-all duration-700 group-hover:scale-105 ${
            imageLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          onLoad={() => setImageLoaded(true)}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent" />
      </div>

      <div className="relative p-6">
        <div className="flex items-center gap-2 text-sm text-gray-400 mb-3">
          <Calendar size={16} className="text-blue-400" />
          <span>
            {new Date(item.date).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
          </span>
        </div>

        <h3 className="text-2xl font-bold mb-3 bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
          {item.title}
        </h3>

        <div className="flex flex-wrap gap-2 mb-4">
          <span className="inline-flex items-center gap-1 px-3 py-1 bg-blue-500/10 text-blue-700 rounded-full text-sm font-medium">
            <Tag size={14} />
            {item.category}
          </span>
          {item.audience.map((audience) => (
            <span
              key={audience}
              className="inline-flex items-center gap-1 px-3 py-1 bg-green-500/10 text-green-700 rounded-full text-sm font-medium"
            >
              <Users size={14} />
              {audience}
            </span>
          ))}
        </div>

        <motion.div
          animate={{ height: expanded ? 'auto' : '4.5rem' }}
          className="overflow-hidden text-gray-600 leading-relaxed"
        >
          <p>{item.description}</p>
        </motion.div>

        <motion.button
          onClick={() => setExpanded(!expanded)}
          className="mt-4  inline-flex items-center gap-2 text-primary-600 hover:text-blue-700 transition-colors font-medium"
          whileHover={{ x: 5 }}
        >
          {expanded ? 'Show less' : 'Read more'}
          <ArrowRight
            size={16}
            className={`transition-transform duration-300 ${expanded ? 'rotate-180' : ''}`}
          />
        </motion.button>
      </div>
    </motion.div>
  );
}