/* eslint-disable react/prop-types */
const ImageHeading = ({ heading, imageUrl }) => {
  return (
    <div
      className="relative w-full overflow-hidden"
      style={{ height: "calc(50vh )" }}
    >
      {/* Base image layer */}
      <div className="absolute inset-0 w-full overflow-hidden h-[50vh]">
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center"
          }}
          src={imageUrl}
          alt="Background"
        />
      </div>

      {/* Semi-transparent white overlay with masked text */}
      <div className="absolute inset-0 flex items-center justify-start bg-white/75 h-[50vh]">
        <div className="container px-4 mx-auto sm:px-6 lg:px-8">
          {/* <div className=""></div> */}
          <h1
            className="text-[clamp(2rem,10vw,1.5rem)] md:text-[clamp(2rem,8vw,2.5rem)] lg:text-[clamp(3rem,10vw,4rem)] font-black text-transparent bg-clip-text"
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              WebkitBackgroundClip: "text",
            }}
          >
            {heading}
          </h1>
        </div>
      </div>

      {/* Red background at the bottom */}
      <div className="absolute bottom-0 left-0 right-0 h-[70px] bg-gradient-to-t from-30%  from-[#F9FAFB] via-[#F9FAFB]/50 to-transparent"></div>
    </div>
  );
};

export default ImageHeading;
