"use client";

import { useState } from "react";
import { motion } from "framer-motion";
import ImageHeading from "../components/ui/ImageHeading";

import GlobalTitle from "../components/shared/GlobalTitle";

// Import images for each section
import headerImage from "../assets/header/header2.jpeg";
import wellbeing from "../assets/lifeAtZums/wellbeing.jpeg";
import sports from "../assets/lifeAtZums/club.jpeg";
import cultural from "../assets/lifeAtZums/cultural.jpeg";
import Transport from "../assets/lifeAtZums/Transport.jpeg";
import club from "../assets/lifeAtZums/club2.jpeg";
import forum from "../assets/lifeAtZums/forum.jpeg";
import healthCare from "../assets/lifeAtZums/healthCare.jpg";
import language from "../assets/lifeAtZums/language.jpeg";
import wellbeing1 from "../assets/lifeAtZums/Support and Well-Being/Support and Well Being(1).jpg";
import wellbeing2 from "../assets/lifeAtZums/Support and Well-Being/Support and Well Being.jpg";
import wellbeing3 from "../assets/lifeAtZums/Support and Well-Being/Well Being.jpg";
import club1 from "../assets/lifeAtZums/Clubs/Copy of images (1).jpg";
import club2 from "../assets/lifeAtZums/Clubs/Copy of Picture1.jpg";
import club3 from "../assets/lifeAtZums/Clubs/Copy of Picture2.png";
import club4 from "../assets/lifeAtZums/Clubs/Copy of Picture3.png";
import club5 from "../assets/lifeAtZums/Clubs/Copy of Picture4.png";
import club6 from "../assets/lifeAtZums/Clubs/Copy of Picture5.jpg";
import club7 from "../assets/lifeAtZums/Clubs/Copy of Picture6.png";
import club8 from "../assets/lifeAtZums/Clubs/DSC_0449.jpg";
import club9 from "../assets/lifeAtZums/Clubs/DSC_0462.jpg";
import club10 from "../assets/lifeAtZums/Clubs/DSC_0471.jpg";
import club11 from "../assets/lifeAtZums/Clubs/DSC_0478.jpg";
import club12 from "../assets/lifeAtZums/Clubs/DSC_0483.jpg";
import club13 from "../assets/lifeAtZums/Clubs/Economics.jpg";
import club14 from "../assets/lifeAtZums/Clubs/Students Adda.jpeg";
import club15 from "../assets/lifeAtZums/Clubs/Students Group Photo.jpeg";
import club16 from "../assets/lifeAtZums/Clubs/WhatsApp Image 2024-05-25 at 9.33.12 PM (1).jpeg";
import club17 from "../assets/lifeAtZums/Clubs/WhatsApp Image 2024-05-25 at 9.33.12 PM.jpeg";
import transport1 from "../assets/lifeAtZums/Transport/Transport,.png";
import cultural1 from "../assets/lifeAtZums/Cultural Activities/458650110_495645876780879_7180204131920051717_n.jpg";
import cultural2 from "../assets/lifeAtZums/Cultural Activities/IMG-20220529-WA0024.jpg";
import cultural3 from "../assets/lifeAtZums/Cultural Activities/IMG_0339.jpg";
import cultural4 from "../assets/lifeAtZums/Cultural Activities/IMG_0595.jpg";
import cultural5 from "../assets/lifeAtZums/Cultural Activities/IMG_5690.png";
import cultural6 from "../assets/lifeAtZums/Cultural Activities/WhatsApp Image 2025-02-13 at 10.33.30 PM.jpeg";
import forum1 from "../assets/lifeAtZums/Students Forum/20240515_175740.jpg";
import forum2 from "../assets/lifeAtZums/Students Forum/20240515_182911.jpg";
import forum3 from "../assets/lifeAtZums/Students Forum/20240515_183008.jpg";
import forum4 from "../assets/lifeAtZums/Students Forum/20240515_183108.jpg";
import forum5 from "../assets/lifeAtZums/Students Forum/20240515_183338.jpg";
import forum6 from "../assets/lifeAtZums/Students Forum/20240515_183436.jpg";
import forum7 from "../assets/lifeAtZums/Students Forum/20240515_183929.jpg";
import forum8 from "../assets/lifeAtZums/Students Forum/20240515_184140.jpg";
import forum9 from "../assets/lifeAtZums/Students Forum/20240515_184314(0).jpg";
import forum10 from "../assets/lifeAtZums/Students Forum/WhatsApp Image 2024-04-25 at 12.05.50 PM (2).jpeg";
// import sports from "../assets/lifeAtZums";
import LifeAtZumsModal from "../components/lifeAtZums/LifeAtZumsModal";

// For each section, we'll create a gallery of images
// In a real application, you would have multiple images per section
// For this example, I'm using the same image multiple times as placeholders
const lifeAtZumsData = [
  {
    id: 1,
    title: "Support & Well-being",
    description:
      "We prioritize student well-being with counseling services, health programs, and mental health resources to ensure a balanced academic life.",
    image: wellbeing,
    gallery: [wellbeing1, wellbeing2, wellbeing3],
  },
  {
    id: 2,
    title: "Get Involved with Clubs & Organizations",
    description:
      "Join student-led clubs and organizations to develop leadership skills, make friends, and explore your passions outside the classroom.",
    image: club,
    gallery: [
      club1,
      club2,
      club3,
      club4,
      club5,
      club6,
      club7,
      club8,
      club9,
      club10,
      club11,
      club12,
      club13,
      club14,
      club15,
      club16,
      club17,
    ],
  },
  {
    id: 3,
    title: "Accommodation & Transport",
    description:
      "Our campus offers comfortable dormitories and reliable transport facilities to ensure a convenient and secure student life.",
    image: Transport,
    gallery: [transport1],
  },
  {
    id: 4,
    title: "Sports",
    description:
      "Engage in various sports activities, from competitive leagues to recreational games, promoting fitness and teamwork among students.",
    image: sports,
    gallery: [sports, sports, sports],
  },
  {
    id: 5,
    title: "Cultural Activities",
    description:
      "Experience diverse cultural events, music, theater, and art programs that celebrate creativity and traditions from around the world.",
    image: cultural,
    gallery: [cultural1, cultural2, cultural3, cultural4, cultural5, cultural6],
  },
  {
    id: 6,
    title: "Student Forum",
    description:
      "A platform for students to voice their opinions, discuss academic and extracurricular matters, and engage with the university community.",
    image: forum,
    gallery: [
      forum1,
      forum2,
      forum3,
      forum4,
      forum5,
      forum6,
      forum7,
      forum8,
      forum9,
      forum10,
    ],
  },
  {
    id: 7,
    title: "Medical Facilities",
    description:
      "Our on-campus medical center provides healthcare services, emergency assistance, and wellness programs for all students.",
    image: healthCare,
    gallery: [healthCare],
  },
  {
    id: 8,
    title: "Language Center",
    description:
      "Enhance your communication skills with language courses, workshops, and resources designed to support multilingual students.",
    image: language,
    gallery: [language],
  },
];

const LifeAtZums = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  const openModal = (section) => {
    setSelectedSection(section);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setTimeout(() => setSelectedSection(null), 300); // Clear selection after animation
  };

  return (
    <div className="min-h-screen bg-white">
      <>
        <ImageHeading heading="Life At ZUMS" imageUrl={headerImage} />
      </>

      {lifeAtZumsData.map((section, index) => (
        <section
          key={section.id}
          className="container px-4 py-2 mx-auto lg:py-12 sm:px-6 lg:px-8"
        >
          <div
            className={`flex flex-col ${
              index % 2 === 0 ? "lg:flex-row-reverse" : "lg:flex-row"
            } items-center`}
          >
            {/* Image with framer motion animation */}
            <motion.div
              className="relative w-full h-auto col-span-2 border-2 border-primary aspect-video md:w-2/3 cursor-pointer group"
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.8 },
              }}
              initial={{ opacity: 0, x: index % 2 === 0 ? 100 : -100 }}
              viewport={{ once: false }}
              onClick={() => openModal(section)}
            >
              <img
                src={section.image || "/placeholder.svg"}
                className={`absolute inset-0 object-cover object-center w-full h-full top-4 ${
                  index % 2 === 0 ? "-left-4" : "left-4"
                }`}
                alt={section.title}
              />
              <div className="absolute inset-0 flex items-center justify-center transition-opacity duration-300 bg-black/50 opacity-0 group-hover:opacity-100">
                <span className="px-4 py-2 text-white bg-primary rounded-md">
                  View Gallery
                </span>
              </div>
            </motion.div>

            {/* Text content */}
            <motion.div
              className="z-20 w-full p-12 bg-white lg:w-1/3"
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.8 },
              }}
              initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }}
              viewport={{ once: false }}
            >
              <GlobalTitle title={section?.title} />
              <p className="mt-8 text-gray-700">{section.description}</p>
            </motion.div>
          </div>
        </section>
      ))}

      {/* Image Modal */}
      <LifeAtZumsModal
        isOpen={modalOpen}
        onClose={closeModal}
        images={selectedSection?.gallery || []}
        title={selectedSection?.title || ""}
      />
    </div>
  );
};

export default LifeAtZums;
