import ImageHeading from "../components/ui/ImageHeading";
import discoverImage from "../assets/header/discoverZUMS.jpeg";
import { motion } from "framer-motion";
import { useState } from "react";
const coreValues = [
  {
    title: "Integrity",
    description:
      "We uphold the highest standards of honesty and ethical behavior, calling on every member of the ZUMS community to align their actions with the university's mission as an institution dedicated to education and social service.  ",
  },
  {
    title: "Diversity and Inclusiveness",
    description:
      "We are committed to fostering an open and welcoming environment for people from diverse backgrounds, perspectives, and orientations. Embracing and valuing diversity is a fundamental part of our culture, ensuring a sense of belonging for everyone.  ",
  },
  {
    title: "Responsibility",
    description:
      "We take responsibility for our decisions, actions, and their outcomes. Our commitment extends to cultivating a strong sense of accountability and responsibility among our students, ensuring they grow as conscientious individuals.  ",
  },
  {
    title: "Service",
    description:
      " Service is at the heart of ZUMS. We prioritize serving our students while encouraging staff, faculty, and students to contribute their skills and expertise for the betterment of others, creating a culture of giving back to the community.  ",
  },
  {
    title: "Excellence",
    description:
      "We strive for excellence in all that we do. By maximizing our intellectual, social, physical, and ethical capabilities, we aim to achieve the highest standards of performance as a leading institution in higher education and research.  ",
  },
];
const DiscoverZums = () => {
  const [activeValue, setActiveValue] = useState(null);
  const fadeInUp = {
    initial: { opacity: 0, y: 60 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  return (
    <div className="min-h-screen">
      <ImageHeading heading="Discover ZUMS" imageUrl={discoverImage} />
      <div className="container px-4 mx-auto sm:px-6 lg:px-8">
        {/* New Ambition & Commitment Section */}
        <div className="relative py-24">
          <motion.div
            className=""
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            {/* Ambition Section */}
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="relative grid grid-cols-1 gap-4 lg:grid-cols-2"
            >
              <div className="flex justify-center items-center h-full w-full">
                <h2 className="mb-8 text-6xl font-domine font-bold tracking-tight text-black text-center">
                  Ambition
                </h2>
              </div>

              <div className="relative bg-gray-100 p-4 lg:p-8">
                {/* <div className="absolute left-0 w-1 h-full bg-red-500" /> */}
                <p className="pl-6 text-lg leading-relaxed text-gray-700 border-l-4 border-primary">
                  ZUMS’s mission is the creation, dissemination, preservation,
                  and application of knowledge to contribute to the advancement
                  of global society. We prepare every student for personal and
                  professional success in a dynamic world emphasizing service,
                  social justice, and ethical leadership.
                </p>
              </div>
            </motion.div>

            {/* Commitment Section */}
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="relative mt-10 lg:mt-20 grid grid-cols-1 gap-4 lg:grid-cols-2"
            >
              <div className="relative bg-gray-100 p-4 lg:p-8 order-2 lg:order-1">
                <p className="pl-6 text-lg leading-relaxed text-gray-700 border-l-4 border-primary">
                  We aspire to be globally recognized for excellent teaching,
                  innovative research, personal and intellectual growth of our
                  students in an ever-changing world, and for the benefit we
                  bring to society.
                </p>
              </div>
              <div className="order-1 lg:order-2">
                <div className="flex justify-center items-center h-full w-full">
                  <h2 className="mb-8 font-domine text-6xl font-bold tracking-tight text-black text-center">
                    Commitment
                  </h2>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>

        {/* Core Values Section */}
        <div className="py-24">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="container mx-auto grid grid-cols-1 gap-8 lg:grid-cols-2"
          >
            <div className="">
              <h2 className="mb-12 text-6xl font-bold text-black font-domine">
                Values
              </h2>
              <p className="mb-12 text-lg text-gray-700">
                ZUMS is committed to student success, providing comprehensive
                support for academic, cultural, and personal development. We
                also dedicate 10% of our student intake to providing free
                education for students from disadvantaged communities. The core
                institutional values drive our mission and ensure its
                sustainable success.
              </p>
            </div>

            <div className="space-y-4">
              {coreValues.map((value, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                >
                  <button
                    onClick={() =>
                      setActiveValue(activeValue === index ? null : index)
                    }
                    className="flex items-center justify-between w-full p-6 text-left transition-all duration-200 bg-gray-100"
                  >
                    <h3 className="text-xl font-semibold">{value.title}</h3>
                    <motion.span
                      animate={{ rotate: activeValue === index ? 45 : 0 }}
                      transition={{ duration: 0.2 }}
                      className="text-primary text-2xl font-bold"
                    >
                      +
                    </motion.span>
                  </button>

                  <motion.div
                    initial={false}
                    animate={{
                      height: activeValue === index ? "auto" : 0,
                      opacity: activeValue === index ? 1 : 0,
                    }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <p className="p-6 text-gray-600 bg-gray-50">
                      {value.description}
                    </p>
                  </motion.div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverZums;
