import React, { useState } from "react";
import { motion } from "framer-motion";

const ResourceCard = ({ title, description, logoUrl }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const getBorderStyles = () => {
    const { x, y } = mousePosition;
    const width = 300; // Approximate width of the card
    const height = 250; // Approximate height of the card

    if (x === 0 && y === 0) return {};

    let gradientDirection = "";
    let gradientPosition = "0%";

    if (y < 20) {
      gradientDirection = "to right";
      gradientPosition = `${(x / width) * 100}%`;
    } else if (y > height - 20) {
      gradientDirection = "to right";
      gradientPosition = `${(x / width) * 100}%`;
    } else if (x < 20) {
      gradientDirection = "to bottom";
      gradientPosition = `${(y / height) * 100}%`;
    } else if (x > width - 20) {
      gradientDirection = "to bottom";
      gradientPosition = `${(y / height) * 100}%`;
    } else {
      return {};
    }

    return {
      backgroundImage: `linear-gradient(${gradientDirection}, transparent, var(--primary-color) ${gradientPosition}, transparent)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
    };
  };

  return (
    <motion.div
      className="bg-white rounded-xl shadow-lg px-6 py-12 flex flex-col items-center h-full text-center relative overflow-hidden"
      style={{
        "--primary-color": "#ED1C2E", // Tailwind blue-500
      }}
      whileHover={{ y: -5 }}
      onMouseMove={handleMouseMove}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <motion.div
        className="absolute inset-0 bg-gradient-to-br from-blue-50 to-purple-50 opacity-0"
        whileHover={{ opacity: 1 }}
      />
      <motion.div
        className="absolute inset-0 rounded-xl pointer-events-none"
        style={{
          ...getBorderStyles(),
          maskImage:
            "linear-gradient(to right, black 2px, transparent 2px), linear-gradient(to bottom, black 2px, transparent 2px), linear-gradient(to left, black 2px, transparent 2px), linear-gradient(to top, black 2px, transparent 2px)",
          maskComposite: "intersect",
          WebkitMaskComposite: "source-in",
          maskSize: "100% 100%",
        }}
      />
      <motion.div
        className="relative z-10 w-auto h-8 mb-8"
        whileHover={{ scale: 1.1 }}
      >
        <img
          src={logoUrl || "/placeholder.svg"}
          alt={`${title} logo`}
          // layout="fill"
          // objectFit="cover"
          className="w-full h-full"
        />
      </motion.div>
      <h3 className="relative z-10 text-xl font-bold mb-2 text-gray-800">
        {title}
      </h3>
      <p className="relative z-10 text-gray-600">{description}</p>
    </motion.div>
  );
};

export default ResourceCard;
