import React from "react";
import { motion } from "framer-motion";
import { Heart, Briefcase, BookOpen, Home } from "lucide-react";

function EmployeeBenefits() {
  const benefits = [
    {
      icon: Heart,
      title: "Health & Wellness",
      description: "Comprehensive health insurance and wellness programs",
    },
    {
      icon: Briefcase,
      title: "Work-Life Balance",
      description: "Flexible scheduling and paid time off",
    },
    {
      icon: BookOpen,
      title: "Education Benefits",
      description: "Tuition assistance and professional development",
    },
    {
      icon: Home,
      title: "Campus Perks",
      description: "Access to university facilities and events",
    },
  ];

  return (
    <section className="py-16 ">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold mb-12 text-center font-domine"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          Employee Benefits
        </motion.h2>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <motion.div
              key={benefit.title}
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
            >
              <div className="mb-4 inline-flex p-3 bg-primary text-white rounded-full">
                <benefit.icon className="w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default EmployeeBenefits;
