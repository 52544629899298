import { motion, AnimatePresence } from "framer-motion";
import { Clock, MapPin } from "lucide-react";
import { useState, useEffect } from "react";

// Sample data structure for library hours
const libraryHours = [
  { day: "Monday", hours: "9:00 AM - 9:00 PM", isOpen: true },
  { day: "Tuesday", hours: "9:00 AM - 9:00 PM", isOpen: true },
  { day: "Wednesday", hours: "9:00 AM - 9:00 PM", isOpen: true },
  { day: "Thursday", hours: "9:00 AM - 9:00 PM", isOpen: true },
  { day: "Friday", hours: "9:00 AM - 6:00 PM", isOpen: true },
  { day: "Saturday", hours: "10:00 AM - 5:00 PM", isOpen: true },
  { day: "Sunday", hours: "Closed", isOpen: false },
];

const LibraryHours = () => {
  const [currentDay, setCurrentDay] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  // Get current day on component mount
  useEffect(() => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    setCurrentDay(days[new Date().getDay()]);
    setIsVisible(true);
  }, []);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={containerVariants}
          className="max-w-2xl mx-auto p-6 bg-white rounded-2xl shadow-xl"
        >
          {/* Header Section */}
          <div className="mb-8 text-center">
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="w-16 h-16 mx-auto mb-4 bg-primary/10 rounded-full flex items-center justify-center"
            >
              <Clock className="w-8 h-8 text-primary" />
            </motion.div>
            <h2 className="text-2xl font-domine font-bold text-gray-900  mb-2">
              Library Hours
            </h2>
            {/* <div className="flex items-center justify-center text-muted-foreground">
              <MapPin className="w-4 h-4 mr-2" />
              <span>Main Campus Library</span>
            </div> */}
          </div>

          {/* Hours Grid */}
          <motion.div variants={containerVariants} className="grid gap-4">
            {libraryHours.map((schedule) => (
              <motion.div
                key={schedule.day}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                className={`p-4 rounded-lg transition-colors ${
                  currentDay === schedule.day ? "bg-primary/10 " : "bg-gray-50 "
                }`}
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center space-x-3">
                    <span
                      className={`font-medium ${
                        currentDay === schedule.day
                          ? "text-primary"
                          : "text-gray-900 "
                      }`}
                    >
                      {schedule.day}
                    </span>
                    {currentDay === schedule.day && (
                      <span className="px-2 py-1 text-xs rounded-full bg-primary/20 text-primary">
                        Today
                      </span>
                    )}
                  </div>
                  <div className="flex items-center">
                    <span
                      className={`text-sm ${
                        schedule.isOpen ? "text-gray-600 " : "text-red-500"
                      }`}
                    >
                      {schedule.hours}
                    </span>
                    <div
                      className={`ml-3 w-2 h-2 rounded-full ${
                        schedule.isOpen ? "bg-green-500" : "bg-red-500"
                      }`}
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* Footer Note */}
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="mt-6 text-sm text-center text-muted-foreground"
          >
            * Hours may vary during holidays and special events
          </motion.p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LibraryHours;
